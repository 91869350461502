import React from "react";
import TotalRow from "./TotalRow";
import AirportOutputRow from "./AirportOutputRow";
const AirportOutput = (props) => {
  ///.employment
  ///.labor_income
  ///.output
  //const [row, setRow] = useState();

  ////console.log("AO PROPS", props);

  let models = props.models;
  let fields = props.fields;
  let slug = props.slug;
  if (!slug) {
    //console.log('fields',fields);
    throw new Error("AirportOutput needs slug prop");
  }

  if (!models || models.length === 0) {
    return false;
  }
  if (!fields || fields.length === 0) {
    return false;
  }

  return (
    <div key={`panel-${slug}`} className="panel panel-default">
      <table className="table table-condensed table-bordered table-striped">
        <thead className="thead-dark thead-themed">
          <tr className="airport-output-header-row">
            <th className="item">Item</th>
            <th className="factor factor-input">Input</th>
            <th className="employment">Jobs</th>
            <th className="labor-income">Labor Income</th>
            <th className="output">Output</th>
          </tr>
        </thead>
        <tbody>
          {props.showTotalRow && (
            <TotalRow
              data={models}
              fields={fields}
              models={models}
              slug={slug}
            />
          )}

          {fields.map((field, i) => {
            return (
              <AirportOutputRow
                field={field}
                model={models[i]}
                key={`airport-output-row-${i}`}
                slug={slug}
                i={i}
              />
            );
          })}
        </tbody>
      </table>
    </div>
  );
};
export default AirportOutput;
