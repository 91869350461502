//economic impact analysis model for small airports

import PubSub from "../PubSub.js";

const EconomicImpactModel = (props) => {
  ///props
  ///.multipliers
  ///..labor_income
  ///..employment
  ///..output
  ///outputs
  ///.employment
  ///.labor_income
  ///.output

  ///.data
  ///..region_multiplier
  ///..airport_data
  ///..visitor_spending
  ///.inputs
  ///..airport_sponsored_activity
  ///...employment
  ///...annual_payroll
  ///...annual_operating_expenses
  ///...3_year_average_capital_expenses
  ///..visitor_activity
  ///...general_aviation_items
  ///....
  ///...commercial_service_item
  ///....
  ///..tenant_business_activity
  ///...air_transportation
  ///...aircraft_support_activities
  ///...aviation_schools
  ///const [outputs, setOutputs] = useState([]);
  //When the input is payroll

  /////console.log("eia props", props);

  const payrollModel = (props, origInput) => {
    let self = PubSub();
    const multipliers = props.multipliers;
    function recalc() {
      self.labor_income = origInput.value * multipliers.labor_income;
      self.employment = self.labor_income / multipliers.labor_income_per_worker;
      self.output = self.employment * multipliers.output_per_worker;
      self.emit("recalc", self, origInput);
    }
    origInput.on("change", recalc);
    recalc(); //initial calc.
    self.origInput = origInput;
    self.type = props.type;
    self.label = props.label;
    return self;
  };

  //When the input is employment numbers
  const employmentModel = (props, origInput) => {
    let self = PubSub();
    const multipliers = props.multipliers;
    function recalc() {
      self.employment = origInput.value * multipliers.employment;
      self.output = self.employment * multipliers.output_per_worker;
      self.labor_income = self.employment * multipliers.labor_income_per_worker;
      self.emit("recalc", self, origInput);
    }
    origInput.on("change", recalc);
    recalc(); //initial calc.
    self.origInput = origInput;
    self.type = props.type;
    self.label = props.label;
    return self;
  };

  //all others
  const businessModel = (props, origInput) => {
    let self = PubSub();
    const multipliers = props.multipliers;
    function recalc() {
      self.output = origInput.value * multipliers.output;
      self.employment = self.output / multipliers.output_per_worker;
      self.labor_income = self.employment * multipliers.labor_income_per_worker;
      self.emit("recalc", self, origInput);
    }
    origInput.on("change", recalc);
    recalc(); //initial calc.
    self.origInput = origInput;
    self.type = props.type;
    self.label = props.label;
    return self;
  };

  const nullModel = (props, origInput) => {
    let self = PubSub();
    return {
      ...self,
      type: props.type,
      label: props.label,
      employment: 0,
      labor_income: 0,
      output: 0,
      skip: true,
    };
  };

  //console.log(props);
  const rows = [];
  props.inputs.forEach((input, i) => {
    let row;
    //////console.log("input", input);
    switch (input.type) {
      case "payroll":
        row = payrollModel(input, props.origInputs[i]);
        break;

      case "employment":
        row = employmentModel(input, props.origInputs[i]);
        break;

      case "business":
        row = businessModel(input, props.origInputs[i]);
        break;
      default:
        row = nullModel(input, props.origInputs[i]);
        break;
    }
    rows.push(row);
  });

  ////console.log("rows", rows);
  return rows;
};

export default EconomicImpactModel;
