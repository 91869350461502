import Utils from '../data/Utils.js'

export function simpleTotal(data) {
	return data.reduce(function(accum,row,i){
		return {
			label: 'simpleTotal',
			employment: accum.employment + row.employment,
			labor_income: accum.labor_income + row.labor_income,
			output: accum.output + row.output
		}
	},{...Utils.EMPTY_MODEL});
}
export function getGrossSalesRecord(data) {
  if (!data) { return {...Utils.EMPTY_MODEL}; }
  return data.find(row => row.label.match(/Gross sales/i));
}
export function getEmploymentRecord(data) {
  if (!data) { return {...Utils.EMPTY_MODEL}; }
  return data.find(row => row.label.match(/Employment/i));
}
export function getPayrollRecord(data) {
  if (!data) { return {...Utils.EMPTY_MODEL}; }
  return data.find(row => row.label.match(/Payroll/i));
}
export function getOperatingExpensesRecord(data) {
  if (!data) { return {...Utils.EMPTY_MODEL}; }
  return data.find(row => row.label.match(/Operating Expense/i));
}
export function getCapitalExpensesRecord(data) {
  if (!data) { return {...Utils.EMPTY_MODEL}; }
  return data.find(row => row.label.match(/Capital Expense/i));
}
export function winnerTotal(data) {
  let payrollRec = getPayrollRecord(data)|| {...Utils.EMPTY_MODEL};
  let employRec = getEmploymentRecord(data)|| {...Utils.EMPTY_MODEL};
  let opExRec = getOperatingExpensesRecord(data)|| {...Utils.EMPTY_MODEL};
 
  let result = {...Utils.EMPTY_MODEL};
  let EPSILON = 0.0;////0.001;
  /* Note: each variable could have a different record as its own winner */
  ['labor_income','output','employment'].forEach(prop => {
    let winner = [opExRec, employRec, payrollRec].find(rec => !isNaN(rec[prop]) && rec[prop] > EPSILON);
    winner = winner || payrollRec;
    result[prop] += winner[prop];
  });
  return result;
}


export function hierarchyTotal(data){
  let capExRec = getCapitalExpensesRecord(data) || {...Utils.EMPTY_MODEL};
  let grossRec = getGrossSalesRecord(data) || {...Utils.EMPTY_MODEL};
  let result = winnerTotal(data);

  result = simpleTotal([
    result, 
    capExRec, //not always found
    grossRec //not always found
  ]);

  return {
    ...result,
    label: 'HIERARCHY TOTAL',
  }
}

function TotalHelper(data, hierarchy) {
  let totals = {...Utils.EMPTY_MODEL};
  if (data) {
    let capEx = getCapitalExpensesRecord(data);
    ///console.log('capEx',capEx)
    if (capEx || hierarchy) {
      totals = hierarchyTotal(data);
    }
    else {
      totals = simpleTotal(data);
    }
  }
  else {
    totals = data;
  }
  return totals;
}
export default TotalHelper;