import React, { useState } from "react";
import SummaryRow from "./SummaryRow.js";
import {isEmpty} from "../data/Utils.js";

function SummaryTable(props) {
  const summary = props.summary;
  const [localSummary, setLocalSummary] = useState(summary);

  if (!props.campfire) {
    return <span>Loading...</span>;
  }
  const campfire = props.campfire;
  campfire.on("updateTotals", function (summary) {
    ///console.log("Yay, a summary", summary);
    setLocalSummary(summary);
  });

  return (
    <div className="summary-wrap">
      <table className="table table-condensed table-bordered table-striped">
        <thead className="thead-dark thead-themed">
          <tr className="summary-header-row">
            <th className="label item">Item</th>
            <th className="employment">Jobs</th>
            <th className="labor-income">Labor Income</th>
            <th className="output">Output</th>
          </tr>
        </thead>
        <tbody>
          <SummaryRow {...localSummary.saTotal} label="Airport Activity" />
          <SummaryRow
            {...localSummary.aciTotal}
            label="Airport Capital Improvements"
          />
          {!isEmpty(localSummary.vgaTotal)&&<SummaryRow {...localSummary.vgaTotal} label="GA Visitor Activity" />}
          {!isEmpty(localSummary.vcsTotal)&&<SummaryRow {...localSummary.vcsTotal} label="CS Visitor Activity" />}
          <SummaryRow
            {...localSummary.tenantGrandTotal}
            label="Tenant Activity"
          />
          <SummaryRow
            {...localSummary.superMegaGrandTotal}
            className="grand-total-row"
            label="Total"
          />
        </tbody>
      </table>
    </div>
  );
}

export default SummaryTable;
