import accounting from "accounting";

const sorter = function (picker) {
  var func = function (a, b) {
    if (picker(a) < picker(b)) {
      return -1;
    }
    if (picker(a) > picker(b)) {
      return 1;
    }
    return 0;
  };
  return func;
};

function slugify(str) {
  var result = str.toLowerCase();
  result = result.replace(/\//g, "-");
  result = result.replace(/\./g, "-");
  result = result.replace(/:/g, "-");
  result = result.replace(/ /g, "-");
  result = result.replace(/--/g, "-");
  return result;
}

function formatPMValue(num) {
  let result = num;
  if (result < 100) {
    result = accounting.formatNumber(result, 2);
  } else {
    result = accounting.formatNumber(result, 0, ",");
  }
  return result;
}

const wordify = function (phraseString) {
  if (typeof phraseString == "number") {
    return phraseString.toString();
  }
  return phraseString.split(/ +/).map(function (o) {
    return o.trim().toUpperCase();
  });
};

//array/Set utils from https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Set

// intersect can be simulated via
const arrayIntersection = function (a, b) {
  let setA = new Set([...a]);
  let setB = new Set([...b]);
  let common = [...setA].filter((x) => setB.has(x));
  return common;
};

// difference can be simulated via
///let difference = new Set([...set1].filter(x => !set2.has(x)))
const arrayDifference = function (a, b) {
  let setA = new Set([...a]);
  let setB = new Set([...b]);
  let difference = [...setA].filter((x) => !setB.has(x));
  return difference;
};

const tagScore = function (a, b) {
  //a and b should both be arrays of strings
  var common = arrayIntersection(a, b);
  var extraTagCount = a.length + b.length - 2 * common.length; //extra tags in the hit.
  var score = 10 * common.length - extraTagCount; //penalize the rank of extra tags somewhat, Not to the order of mangitude that we rank common tags though.
  return score;
};

const EMPTY_MODEL = {
  label: "EMPTY_MODEL",
  employment: 0,
  labor_income: 0,
  output: 0,
};

export function numDigitsAfterDecimal(str) {
  let match = str.match(/\./);
  if (!match) {
    return 0;
  }
  return str.length - match.index - 1;
}

const formatters = {
  prettyNumbers: function (v) {
    if (v === null) {
      return "";
    }
    if (v === undefined) {
      return "";
    }
    if (v === 0) {
      return "0";
    }
    v = v.toString();
    if (v === "") {
      return v;
    }
    if (v.match(/\.$/)) {
      //ends with a single dot
      return v;
    }

    v = accounting.formatNumber(v, 0, ",");
    return v;
    /**
    let numDec = numDigitsAfterDecimal(v);
    if (numDec >= 3) {
      v = accounting.formatNumber(v, 2, ",");
    } else {
      v = accounting.formatNumber(v, numDec, ",");
    }
    return v;
    **/
  },
};

export function applyFormatters(initialValue, formatters) {
  let result = formatters.reduce(function (accum, fn) {
    return fn(accum);
  }, initialValue);

  return result;
}

export function prettyField(v) {
  if (v === "loading...") {
    return v;
  }
  let result = formatters.prettyNumbers(v);
  return result;
}

export function isEmpty(o) {
  //Check if the total of all number type property is zero
  let flag = true;
  if (typeof o === "undefined") return flag;
  Object.keys(o).forEach((k) => {
    if (typeof o[k] === "number" && o[k] !== 0) flag = false;
  });

  return flag;
}

const Utils = {
  applyFormatters,
  arrayIntersection,
  arrayDifference,
  formatPMValue,
  formatters,
  numDigitsAfterDecimal,
  prettyField,
  slugify,
  sorter,
  tagScore,
  wordify,
  EMPTY_MODEL,
  isEmpty,
};

export default Utils;
