import React from "react";
import accounting from "accounting";
import TotalHelper from "../model/TotalHelper.js";

function SummaryRow(props) {
  //console.log("SummaryRow props", props);

  let totals;
  if (props.data) {
    //if an array of records
    totals = TotalHelper(props.data);
  } else {
    totals = props;
  }

  const className = `total-row summary-row ${
    props.className ? props.className : ""
  }`;

  return (
    <tr className={className}>
      <td className="label item">{props.label || "Total"}</td>
      <td className="employment">
        {accounting.formatNumber(totals.employment, 0)}
      </td>
      <td className="labor-income">
        {accounting.formatMoney(totals.labor_income, "$", 0, ",")}
      </td>
      <td className="output">
        {accounting.formatMoney(totals.output, "$", 0, ",")}
      </td>
    </tr>
  );
}
export default SummaryRow;
