import React, { useState } from "react";
import Highcharts from "highcharts";
import HC_exporting from "highcharts/modules/exporting";
import HighchartsReact from "highcharts-react-official";

//must have to use the customized tool-bar
HC_exporting(Highcharts);

//must have to use the thousands separator option other than the default one
Highcharts.setOptions({
  lang: {
    thousandsSep: ",",
  },
});
//colors from background photo:
//#54abe0
//#244800
//#a48f7f
//#ed1026
//#4074a2
//#01106a
//#5a1c07

const dataHelper = (data) => ({
  Output: [
    { name: "Airport Capital Improvement", y: data.aciTotal.output },
    {
      name: "Airport Activity",
      y: data.saTotal.output === 0 ? 1 : data.saTotal.output,
    },
    { name: "GA Visitor Activity", y: data.vgaTotal.output },
    { name: "CS Visitor Activity", y: data.vcsTotal.output },
    { name: "Tenant Activity", y: data.tenantGrandTotal.output },
  ],
  Jobs: [
    { name: "Airport Capital Improvement", y: data.aciTotal.employment },
    //highchart doesn't accept all zero values, need to deal with this case.
    {
      name: "Airport Activity",
      y: data.saTotal.employment === 0 ? 1 : data.saTotal.employment,
    },
    { name: "GA Visitor Activity", y: data.vgaTotal.employment },
    { name: "CS Visitor Activity", y: data.vcsTotal.employment },
    { name: "Tenant Activity", y: data.tenantGrandTotal.employment },
  ],
  "Labor Income": [
    { name: "Airport Capital Improvement", y: data.aciTotal.labor_income },
    //highchart doesn't accept all zero values, need to deal with this case.
    {
      name: "Airport Activity",
      y: data.saTotal.label_income === 0 ? 1 : data.saTotal.labor_income,
    },
    { name: "GA Visitor Activity", y: data.vgaTotal.labor_income },
    { name: "CS Visitor Activity", y: data.vcsTotal.labor_income },
    { name: "Tenant Activity", y: data.tenantGrandTotal.labor_income },
  ],
});

function PieChart(props) {
  const summary = props.summary;
  const [localSummary, setLocalSummary] = useState(summary);

  const [chartTitle, setTitle] = useState("Output");
  const prefix = chartTitle !== "Jobs" ? "$" : "";
  ///console.log("Test Chart", props);

  if (!props.campfire) {
    return <span>Loading...</span>;
  }

  const campfire = props.campfire;
  campfire.on("updateTotals", function (summary) {
    ///console.log("TestChart: Yay, a summary", summary);
    setLocalSummary(summary);
  });

  if (!localSummary.aciTotal) {
    return <span>Loading aciTotal...</span>;
  }

  const data = dataHelper(localSummary);

  //console.log(data);
  const options = {
    chart: {
      type: "pie",
    },
    lang: {
      thousandsSep: ",",
    },
    title: {
      style: {
        color: "#751851",
        fontSize: "20px",
        fontWeight: "500",
        fontFamily: "Segoe UI",
      },
      text: undefined, //disabled, moved to h1
      ///text: "Summary of " + chartTitle,
    },
    tooltip: {
      pointFormat:
        "Percentage: <b>{point.percentage:.2f}%</b><br/>Value: <b>" +
        prefix +
        "{point.y:,.0f}</b>",
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        colors: [
          "#54abe0",
          "#244800",
          //"#a48f7f",
          "#b8715c",
          "#ed1026",
          "#01106a",
          "#5a1c07",
          "#4074a2",
        ],
        cursor: "pointer",
        dataLabels: {
          enabled: false,
        },

        showInLegend: true,
      },
    },
    series: [
      {
        type: "pie",
        name: chartTitle,
        //innerSize: '50%',
        data: data[chartTitle],
      },
    ],
    exporting: {
      menuItemDefinitions: {
        // Custom definition
        showJobs: {
          onclick: function () {
            setTitle("Jobs");
          },
          text: "Show Jobs",
        },
        showLabor: {
          onclick: function () {
            setTitle("Labor Income");
          },
          text: "Show Labor Income",
        },
        showOutput: {
          onclick: function () {
            setTitle("Output");
          },
          text: "Show Output",
        },
      },
      buttons: {
        contextButton: {
          menuItems: ["showJobs", "showLabor", "showOutput"],
        },
      },
    },
  };

  return (
    <div>
      <h1>Summary of {chartTitle}</h1>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
}

export default PieChart;
