import React, { useState, useEffect, useRef } from "react";
import "./App.css";
import AirportInput from "./view/AirportInput";
import AirportOutput from "./view/AirportOutput";
import AirportMap from "./view/AirportMap";

import SummaryTable from "./view/SummaryTable.js";
//import beep from "./view/Beeper.js";
import EconomicImpactModel from "./model/eia-model";
import TotalHelper, { simpleTotal, winnerTotal } from "./model/TotalHelper.js";
import Utils from "./data/Utils.js";
import PubSub from "./PubSub.js";

import constants, {
  AIRPORT_LIST,
  AIRPORT_ACTIVITY,
  REGION_MULTIPLIER,
  CAPITAL_EXPENSES,
  VISITOR_SPENDING,
  TENANT_ACTIVITY,
} from "./data/constants.js";
import PieChart from "./view/PieChart";
import PopupBox from "./view/PopupBox";

window.constants = constants;
window.Utils = Utils;

function makeHash() {
  return Math.random().toString(36).substr(2);
}

var cacheFormInput = {};
window.cacheFormInput = cacheFormInput;
var cacheSAInputNumericValue = {};
window.cacheSAInputNumericValue = cacheSAInputNumericValue;

function makeNumeric(v) {
  if (typeof v === "string") {
    v = v.replace(/,/g, "").replace(/\$/g, "");
  }
  let result = isNaN(+v) ? 0 : +v;
  return result;
}

function printPDF() {
  //printJS("root", "pdf");
  window.print();
  ///ReactPDF.render(App);
}

function useSAInput(initialValue, props) {
  //const ui = useFormInput(initialValue);
  //const [numericValue, setNumericValue] = useState(makeNumeric(initialValue));
  var self = PubSub({ type: "SAInput" });
  const hash = makeHash();
  const ref = useRef(false);
  var uiValue = initialValue;
  self.numericValue = makeNumeric(initialValue);
  self.value = self.numericValue;

  let immediateChange = (v) => {
    let nv = makeNumeric(v);
    if (isNaN(nv)) {
      console.log("immediateChange: returning false!!!!!");
      console.log("immediateChange: returning false!!!!!");
      console.log("immediateChange: returning false!!!!!");
      return false;
    }
    /// console.log("immediateChange: setValue!", hash, nv);
    cacheFormInput[hash] = nv;
    ///setValue(nv); /* STATE */
    ////console.log("setting numericValue", nv);
    self.numericValue = nv;
    self.value = self.numericValue;
    /* UI */
    if (ref && ref.current) {
      ref.current.value = Utils.prettyField(nv); //set ui in DOM
    }
    self.publish("change", self, nv);
  };

  function onChange(e) {
    immediateChange(e.target.value);
  }

  self = {
    ...self,
    ...props,
    initialValue,
    kind: "SAInput", //"type" is already used in the EIA model
    uiValue,
    ref,
    onChange,
    set: (v) => {
      immediateChange(v);
    },
  };
  return self;
}

function getRegionMultiplierRecord(airport, sector, keywordString) {
  let rmRecords = REGION_MULTIPLIER.filter(
    (rec) => rec.Region === airport.Region
  );
  let sectorHit = rmRecords.find((rec) => rec.Sector === sector);
  if (sectorHit) {
    return sectorHit;
  }

  let keywordTags = Utils.wordify(keywordString);
  let scoredCandidates = rmRecords.map((rmRec) => {
    let rmTags = Utils.wordify(rmRec.Description);
    let score = Utils.tagScore(rmTags, keywordTags);
    return { score: score, record: rmRec };
  });
  let sortedByScore = scoredCandidates.sort(Utils.sorter((o) => o.score));
  ///console.log('scoredCandidates', scoredCandidates,'sortedByScore',sortedByScore);

  let winner = sortedByScore[sortedByScore.length - 1];
  return winner.record;
  ////return false;
}

function getActivityInputs(props) {
  let airport = props.airport;

  return props.inputs.map((item) => {
    ///console.log('item',item);

    var mySector;
    if (!mySector && item.sector) {
      mySector = item.sector;
    } //fallback to hardcode
    /*
    if (!mySector) {
      console.log('unable to figure out sector',item);
      throw new Error('cant figure out sector, see console.log');
    }
    **/
    var keywords;
    if (!keywords && item.groupTitle) {
      keywords = item.groupTitle;
    }
    let record = getRegionMultiplierRecord(airport, mySector, keywords);
    // console.log('record',record);
    if (!record) {
      ////console.log("no multiplier, airport", airport, "item", item);
      throw new Error(
        `no multiplier for airport :${airport.Airport} field: ${item.label} ${item.groupTitle}`
      );
    }
    let multipliers = {
      employment: record.Employment,
      labor_income: record.Labor,
      output: record.Output,
      output_per_worker: record["Output per Worker"],
      labor_income_per_worker: record["Labor Income per Worker"],
    };

    //console.log('multipliers', multipliers);
    return {
      multipliers,
      type: item.type,
      value: item.value,
      label: item.label,
    };
  });
  //console.log('inputs',inputs);
  ///return inputs;
}

function App() {
  const [airport, setAirport] = useState(AIRPORT_LIST[1]); // default to Abilene
  const [airportActivity, setAirportActivity] = useState(false);
  const [capitalExpenses, setCapitalExpenses] = useState(false);
  const [visitorSpending, setVisitorSpending] = useState(false);
  const [tenantActivity, setTenantActivity] = useState(false);

  const [debugTA, setDebugTA] = useState("debug area");

  var currentSection = "sponsored";

  const campfire = PubSub({ foo: 123 });
  window.setDebugTA = setDebugTA;

  function goToSection(name) {
    currentSection = name;
    document
      .querySelectorAll("section.activity")
      .forEach((o) => o.classList.remove("show"));
    document.querySelector(`section.activity.${name}`).classList.add("show");
  }

  function whenMapReady() {
    goToSection("sponsored");
  }

  useEffect(() => {
    if (airport) {
      let rec = AIRPORT_ACTIVITY.find(
        (record) => record["Airport Name"] === airport.Airport
      );
      ///console.log('activity rec', rec);
      if (!rec) {
        throw new Error("no activity record for " + airport.Airport);
      }
      setAirportActivity(rec);
    }
  }, [airport]);

  useEffect(() => {
    if (airport) {
      let rec = CAPITAL_EXPENSES.find(
        (record) => record["Model List of Airports"] === airport.Airport
      );
      ///console.log('capex rec', rec);
      if (!rec) {
        throw new Error("no Capital Expenses record for " + airport.Airport);
      }
      setCapitalExpenses(rec);
    }
  }, [airport]);

  useEffect(() => {
    if (airport) {
      let rec = VISITOR_SPENDING.find(
        (vsRecord) =>
          vsRecord.Name === airport.Region || vsRecord.Region === airport.Region
      );
      ///console.log('visitor spending rec', rec);
      if (!rec) {
        ///console.log('vspend',VISITOR_SPENDING)
        throw new Error(
          "no Visitor Spending record for Region:" + airport.Region
        );
      }
      setVisitorSpending(rec);
    }
  }, [airport]);

  useEffect(() => {
    if (airport) {
      //NOTE: we expect multiple TENANT_ACTIVITY records for an airport, so filter(), don't find()
      let recs = TENANT_ACTIVITY.filter(
        (record) =>
          record["Airport Name"] === airport.Airport &&
          record.Region === airport.Region
      );
      console.log(
        "Tenant Activity recs",
        recs,
        "airport",
        airport.Airport,
        "Region",
        airport.Region
      );
      if (!recs) {
        throw new Error(
          `no Tenant Activity records for Airport: ${airport.Airport} Region: ${airport.Region}`
        );
      }
      setTenantActivity(recs);
    }
  }, [airport]);

  //eia-model inputs template
  // let inputs = [{
  //   multipliers:{
  //     employment: 1,
  //     labor_income: 1,
  //     output: 1
  //   },
  //   type: 'employment',
  //   value: 100
  // }];
  /////

  //console.log('App.js inputs', inputs);
  ///

  const saGroup = [
    useSAInput("loading...", {
      label: "Employment",
      type: "employment",
      sector: 408,
      init: () => airportActivity.FTE,
    }),
    useSAInput("loading...", {
      label: "Annual Payroll",
      type: "payroll",
      sector: 408,
      init: () => airportActivity["2017 Estimated Payroll"],
    }),
    useSAInput("loading...", {
      label: "Annual Operating Expenses",
      type: "business",
      sector: 408,
      init: () => airportActivity["2017 Estimated Operating Expenses"],
    }),
    useSAInput("loading...", {
      label: "3 Yr Avg Capital Expenses",
      type: "business",
      sector: 62,
      init: () => capitalExpenses["3 Yr Average"],
    }),
  ];

  /* TODO: double check the type, sector, and init function for each of these now, and again when copy+pasting  a new one */

  const origAV = +airport["Annual Visitors"];
  const visitorsPerAircraft = +airport["Visitors per Aircraft"];

  const [
    enplanements,
    csAV,
    vsGPT,
    vsRestaurants,
    vsHotels,
    vsRetail,
    vsAmusement,
  ] = [
    +airport["Non-hub CS CY18 Enplanements"],
    +airport["Non-hub CS CY18 Enplanements"] * 0.5,
    +visitorSpending["Transit and ground passenger transportation"],
    +visitorSpending["Full-service restaurants"],
    +visitorSpending["Hotels and motels, including casino hotels"],
    +visitorSpending["Retail - Miscellaneous store retailers"],
    +visitorSpending["Other amusement and recreation industries"],
  ];
  //TODO: update functionss on these with the numericValue
  const gaIO = useSAInput("loading...", {
    label: "Itinerant Operations",
    type: "null",
    sector: 408,
    init: () => airport.ITN_GA,
  });
  const gaAnnualVisitors = useSAInput("loading...", {
    label: "Annual Visitors",
    type: "null",
    // calculated: true,
    sector: 408,
    init: () => origAV,
    update: () => (gaIO.numericValue / 6) * visitorsPerAircraft, // IO/2 * 33.33%
  });

  const gaGroundPassengerTransport = useSAInput("loading...", {
    label: "Ground Passenger Transport",
    type: "business",
    calculated: true,
    sector: 412,
    init: () => origAV * vsGPT,
    update: () => gaAnnualVisitors.numericValue * vsGPT,
  });
  const gaFoodAndDrink = useSAInput("loading...", {
    label: "Food & Drink",
    type: "business",
    calculated: true,
    sector: 501,
    init: () => origAV * vsRestaurants,
    update: () => gaAnnualVisitors.numericValue * vsRestaurants,
  });
  const gaLodging = useSAInput("loading...", {
    label: "Lodging",
    type: "business",
    calculated: true,
    sector: 499,
    init: () => origAV * vsHotels,
    update: () => gaAnnualVisitors.numericValue * vsHotels,
  });
  const gaRetail = useSAInput("loading...", {
    label: "Retail",
    type: "business",
    calculated: true,
    sector: 406,
    init: () => origAV * vsRetail,
    update: () => gaAnnualVisitors.numericValue * vsRetail,
  });
  const gaOtherRecreation = useSAInput("loading...", {
    label: "Other Recreation",
    type: "business",
    calculated: true,
    sector: 496,
    init: () => origAV * vsAmusement,
    update: () => gaAnnualVisitors.numericValue * vsAmusement,
  });

  //commercial

  const csEnplanements = useSAInput("loading...", {
    label: "Enplanements",
    type: "null",
    sector: 408,
    init: () => enplanements,
  });

  const csAnnualVisitors = useSAInput("loading...", {
    label: "Annual Visitors",
    type: "null",
    //calculated: true,
    sector: 408,
    init: () => csAV,
    update: () => {
      return csEnplanements.numericValue * 0.5;
    },
  });
  const csGroundPassengerTransport = useSAInput("loading...", {
    label: "Ground Passenger Transport",
    type: "business",
    calculated: true,
    sector: 412,
    init: () => {
      return csAV * vsGPT;
    },
    update: () => {
      return csAnnualVisitors.numericValue * vsGPT;
    },
  });
  const csFoodAndDrink = useSAInput("loading...", {
    label: "Food & Drink",
    type: "business",
    calculated: true,
    sector: 501,
    init: () => csAV * vsRestaurants,
    update: () => csAnnualVisitors.numericValue * vsRestaurants,
  });
  const csLodging = useSAInput("loading...", {
    label: "Lodging",
    type: "business",
    calculated: true,
    sector: 499,
    init: () => csAV * vsHotels,
    update: () => csAnnualVisitors.numericValue * vsHotels,
  });
  const csRetail = useSAInput("loading...", {
    label: "Retail",
    type: "business",
    calculated: true,
    sector: 406,
    init: () => csAV * vsRetail,
    update: () => csAnnualVisitors.numericValue * vsRetail,
  });
  const csOtherRecreation = useSAInput("loading...", {
    label: "Other Recreation",
    type: "business",
    calculated: true,
    sector: 496,
    init: () => csAV * vsAmusement,
    update: () => csAnnualVisitors.numericValue * vsAmusement,
  });

  const vgaGroup = [
    gaIO,
    gaAnnualVisitors,
    gaGroundPassengerTransport,
    gaFoodAndDrink,
    gaLodging,
    gaRetail,
    gaOtherRecreation,
  ];
  const vcsGroup = [
    csEnplanements,
    csAnnualVisitors,
    csGroundPassengerTransport,
    csFoodAndDrink,
    csLodging,
    csRetail,
    csOtherRecreation,
  ];
  /***
  //tenant activity
  const tenantActivityGroupItems = [
    { title: "Air Transportation", sector: 408 }, //408
    { title: "Aircraft Support Activities", sector: 414 }, //414
    { title: "Aviation Schools", sector: 474 }, //474
    { title: "Hangar Rental and Development", sector: 440 }, //440
    { title: "Rental Car", sector: 442 },
    { title: "Parking Garage and Lots", sector: 512 },
    { title: "Air Ambulance", sector: 481 },
    { title: "Food & Drinking Places", sector: 503 },
    { title: "Retail - Miscellaneous store retailers", sector: 406 },
    { title: "Support Activities for Agriculture or Oil & Gas", sector: 38 }, //FIXME: double check this sector
    { title: "Other amusement and recreation industries", sector: 496 },
    { title: "Museum", sector: 493 },
    { title: "Other Professional Services", sector: 460 },
    { title: "Government Employment", sector: 0 }, //FIXME: check sector
    { title: "Manufacturing", sector: 394 },
    { title: "Other", sector: 0 }, //FIXME: sector ...
  ];

  const tenantActivityTypes = [
    { label: "Employment", taProp: "Employment", type: "employment" },
    { label: "Annual Payroll", taProp: "Payroll", type: "payroll" },
    {
      label: "Annual Operating Expenses",
      taProp: "Operating Expenses",
      type: "business",
    },
    {
      label: "Annual Capital Expenses",
      taProp: "Capital Improvements",
      type: "business",
      sector: 62,
    },
    { label: "Annual Gross Sales", taProp: "Gross Sales", type: "business" },
  ];
  ***/
  function getTADefault(taType, prop) {
    let taRecord = tenantActivity.find((taRec) => taRec.Type === taType);
    if (!taRecord) {
      return -1;
    }
    return taRecord[prop];
  }
  window.getTADefault = getTADefault;

  /****
We can't use callbacks in this nested loop because React throws an error:

./src/App.js
  Line 241:12:  React Hook "useSAInput" cannot be called inside a callback. React Hooks must be called in a React function component or a custom React Hook function  react-hooks/rules-of-hooks

Attempt #2 was to use nested for loops, but hooks aren't allowed in loops either,

So option #3 is to use loops to generate the code for all of the inputs, and then that
generated source code will be pasted in below
****/

  /****** DEBUG / DEV
  useEffect(() => {
    function generateTheTAHooksSourceCode() {
      const groups = tenantActivityGroupItems.map((groupItem) => {
        if (!tenantActivity || tenantActivity.length == 0) {
          return false;
        }
        /////console.log('tenantActivity', tenantActivity);
        return tenantActivityTypes.map((taType) => {
          let taRecord = tenantActivity.find(
            (taRec) => taRec.Type === taType.taProp
          );
          if (!taRecord) {
            console.log("no taRecord", taType);
            throw new Error(`no taRecord for ${taType}`);
          }

          return `
        useSAInput('loading...', { 
          label: '${taType.label}', 
          type: '${taType.type}', 
          sector: ${taType.sector || groupItem.sector}, 
          taProp: '${taType.taProp}',
          groupTitle: '${groupItem.title}',
          initDebug: () => ${getTADefault(taType.taProp, groupItem.title)},  
          init: () => getTADefault('${taType.taProp}','${groupItem.title}')  
        })`;
        });
      });
      let code = "Code: " + groups.join("\n");
      return code;
    }
    const theCode = generateTheTAHooksSourceCode();
    setDebugTA(theCode);
    //eslint-disable-next-line
  }, [tenantActivity]);
******/

  const airTransportationGroup = [
    useSAInput("loading...", {
      label: "Employment",
      type: "employment",
      //sector: undefined,
      sector: 408,
      taProp: "Employment",
      groupTitle: "Air Transportation",
      initDebug: () => 84,
      init: () => getTADefault("Employment", "Air Transportation"),
    }),
    useSAInput("loading...", {
      label: "Annual Payroll",
      type: "payroll",
      //sector: undefined,
      sector: 408,
      taProp: "Payroll",
      groupTitle: "Air Transportation",
      initDebug: () => 1660144,
      init: () => getTADefault("Payroll", "Air Transportation"),
    }),
    useSAInput("loading...", {
      label: "Annual Operating Expenses",
      type: "business",
      //sector: undefined,
      sector: 408,
      taProp: "Operating Expenses",
      groupTitle: "Air Transportation",
      initDebug: () => 3920090,
      init: () => getTADefault("Operating Expenses", "Air Transportation"),
    }),
    useSAInput("loading...", {
      label: "Annual Capital Expenses",
      type: "business",
      //sector: undefined,
      sector: 62,
      taProp: "Capital Improvements",
      groupTitle: "Air Transportation",
      initDebug: () => 555055,
      init: () => getTADefault("Capital Improvements", "Air Transportation"),
    }),
    useSAInput("loading...", {
      label: "Annual Gross Sales",
      type: "business",
      //sector: undefined,
      sector: 408,
      taProp: "Gross Sales",
      groupTitle: "Air Transportation",
      initDebug: () => 8527932,
      init: () => getTADefault("Gross Sales", "Air Transportation"),
    }),
  ];

  const aircraftSupportActivitiesGroup = [
    useSAInput("loading...", {
      label: "Employment",
      type: "employment",
      sector: 414,
      taProp: "Employment",
      groupTitle: "Aircraft Support Activities",
      initDebug: () => 259.5,
      init: () => getTADefault("Employment", "Aircraft Support Activities"),
    }),
    useSAInput("loading...", {
      label: "Annual Payroll",
      type: "payroll",
      sector: 414,
      taProp: "Payroll",
      groupTitle: "Aircraft Support Activities",
      initDebug: () => 10465973,
      init: () => getTADefault("Payroll", "Aircraft Support Activities"),
    }),
    useSAInput("loading...", {
      label: "Annual Operating Expenses",
      type: "business",
      sector: 414,
      taProp: "Operating Expenses",
      groupTitle: "Aircraft Support Activities",
      initDebug: () => 14086602,
      init: () =>
        getTADefault("Operating Expenses", "Aircraft Support Activities"),
    }),
    useSAInput("loading...", {
      label: "Annual Capital Expenses",
      type: "business",
      sector: 62,
      taProp: "Capital Improvements",
      groupTitle: "Aircraft Support Activities",
      initDebug: () => 296666.6667,
      init: () =>
        getTADefault("Capital Improvements", "Aircraft Support Activities"),
    }),
    useSAInput("loading...", {
      label: "Annual Gross Sales",
      type: "business",
      sector: 414,
      taProp: "Gross Sales",
      groupTitle: "Aircraft Support Activities",
      initDebug: () => 20321692,
      init: () => getTADefault("Gross Sales", "Aircraft Support Activities"),
    }),
  ];

  const aviationSchoolsGroup = [
    useSAInput("loading...", {
      label: "Employment",
      type: "employment",
      sector: 474,
      taProp: "Employment",
      groupTitle: "Aviation Schools",
      initDebug: () => 2,
      init: () => getTADefault("Employment", "Aviation Schools"),
    }),
    useSAInput("loading...", {
      label: "Annual Payroll",
      type: "payroll",
      sector: 474,
      taProp: "Payroll",
      groupTitle: "Aviation Schools",
      initDebug: () => 0,
      init: () => getTADefault("Payroll", "Aviation Schools"),
    }),
    useSAInput("loading...", {
      label: "Annual Operating Expenses",
      type: "business",
      sector: 474,
      taProp: "Operating Expenses",
      groupTitle: "Aviation Schools",
      initDebug: () => 0,
      init: () => getTADefault("Operating Expenses", "Aviation Schools"),
    }),
    useSAInput("loading...", {
      label: "Annual Capital Expenses",
      type: "business",
      sector: 62,
      taProp: "Capital Improvements",
      groupTitle: "Aviation Schools",
      initDebug: () => 0,
      init: () => getTADefault("Capital Improvements", "Aviation Schools"),
    }),
    useSAInput("loading...", {
      label: "Annual Gross Sales",
      type: "business",
      sector: 474,
      taProp: "Gross Sales",
      groupTitle: "Aviation Schools",
      initDebug: () => 0,
      init: () => getTADefault("Gross Sales", "Aviation Schools"),
    }),
  ];

  const hangarRentalAndDevelopmentGroup = [
    useSAInput("loading...", {
      label: "Employment",
      type: "employment",
      sector: 440,
      taProp: "Employment",
      groupTitle: "Hangar Rental and Development",
      initDebug: () => 0,
      init: () => getTADefault("Employment", "Hangar Rental and Development"),
    }),
    useSAInput("loading...", {
      label: "Annual Payroll",
      type: "payroll",
      sector: 440,
      taProp: "Payroll",
      groupTitle: "Hangar Rental and Development",
      initDebug: () => 0,
      init: () => getTADefault("Payroll", "Hangar Rental and Development"),
    }),
    useSAInput("loading...", {
      label: "Annual Operating Expenses",
      type: "business",
      sector: 440,
      taProp: "Operating Expenses",
      groupTitle: "Hangar Rental and Development",
      initDebug: () => 0,
      init: () =>
        getTADefault("Operating Expenses", "Hangar Rental and Development"),
    }),
    useSAInput("loading...", {
      label: "Annual Capital Expenses",
      type: "business",
      sector: 62,
      taProp: "Capital Improvements",
      groupTitle: "Hangar Rental and Development",
      initDebug: () => 0,
      init: () =>
        getTADefault("Capital Improvements", "Hangar Rental and Development"),
    }),
    useSAInput("loading...", {
      label: "Annual Gross Sales",
      type: "business",
      sector: 440,
      taProp: "Gross Sales",
      groupTitle: "Hangar Rental and Development",
      initDebug: () => 0,
      init: () => getTADefault("Gross Sales", "Hangar Rental and Development"),
    }),
  ];

  const rentalCarGroup = [
    useSAInput("loading...", {
      label: "Employment",
      type: "employment",
      sector: 442,
      taProp: "Employment",
      groupTitle: "Rental Car",
      initDebug: () => 17,
      init: () => getTADefault("Employment", "Rental Car"),
    }),
    useSAInput("loading...", {
      label: "Annual Payroll",
      type: "payroll",
      sector: 442,
      taProp: "Payroll",
      groupTitle: "Rental Car",
      initDebug: () => 0,
      init: () => getTADefault("Payroll", "Rental Car"),
    }),
    useSAInput("loading...", {
      label: "Annual Operating Expenses",
      type: "business",
      sector: 442,
      taProp: "Operating Expenses",
      groupTitle: "Rental Car",
      initDebug: () => 0,
      init: () => getTADefault("Operating Expenses", "Rental Car"),
    }),
    useSAInput("loading...", {
      label: "Annual Capital Expenses",
      type: "business",
      sector: 62,
      taProp: "Capital Improvements",
      groupTitle: "Rental Car",
      initDebug: () => 0,
      init: () => getTADefault("Capital Improvements", "Rental Car"),
    }),
    useSAInput("loading...", {
      label: "Annual Gross Sales",
      type: "business",
      sector: 442,
      taProp: "Gross Sales",
      groupTitle: "Rental Car",
      initDebug: () => 0,
      init: () => getTADefault("Gross Sales", "Rental Car"),
    }),
  ];

  const parkingGarageAndLotsGroup = [
    useSAInput("loading...", {
      label: "Employment",
      type: "employment",
      sector: 512,
      taProp: "Employment",
      groupTitle: "Parking Garage and Lots",
      initDebug: () => 0,
      init: () => getTADefault("Employment", "Parking Garage and Lots"),
    }),
    useSAInput("loading...", {
      label: "Annual Payroll",
      type: "payroll",
      sector: 512,
      taProp: "Payroll",
      groupTitle: "Parking Garage and Lots",
      initDebug: () => 0,
      init: () => getTADefault("Payroll", "Parking Garage and Lots"),
    }),
    useSAInput("loading...", {
      label: "Annual Operating Expenses",
      type: "business",
      sector: 512,
      taProp: "Operating Expenses",
      groupTitle: "Parking Garage and Lots",
      initDebug: () => 0,
      init: () => getTADefault("Operating Expenses", "Parking Garage and Lots"),
    }),
    useSAInput("loading...", {
      label: "Annual Capital Expenses",
      type: "business",
      sector: 62,
      taProp: "Capital Improvements",
      groupTitle: "Parking Garage and Lots",
      initDebug: () => 0,
      init: () =>
        getTADefault("Capital Improvements", "Parking Garage and Lots"),
    }),
    useSAInput("loading...", {
      label: "Annual Gross Sales",
      type: "business",
      sector: 512,
      taProp: "Gross Sales",
      groupTitle: "Parking Garage and Lots",
      initDebug: () => 0,
      init: () => getTADefault("Gross Sales", "Parking Garage and Lots"),
    }),
  ];

  const airAmbulanceGroup = [
    useSAInput("loading...", {
      label: "Employment",
      type: "employment",
      sector: 481,
      taProp: "Employment",
      groupTitle: "Air Ambulance",
      initDebug: () => 0,
      init: () => getTADefault("Employment", "Air Ambulance"),
    }),
    useSAInput("loading...", {
      label: "Annual Payroll",
      type: "payroll",
      sector: 481,
      taProp: "Payroll",
      groupTitle: "Air Ambulance",
      initDebug: () => 0,
      init: () => getTADefault("Payroll", "Air Ambulance"),
    }),
    useSAInput("loading...", {
      label: "Annual Operating Expenses",
      type: "business",
      sector: 481,
      taProp: "Operating Expenses",
      groupTitle: "Air Ambulance",
      initDebug: () => 0,
      init: () => getTADefault("Operating Expenses", "Air Ambulance"),
    }),
    useSAInput("loading...", {
      label: "Annual Capital Expenses",
      type: "business",
      sector: 62,
      taProp: "Capital Improvements",
      groupTitle: "Air Ambulance",
      initDebug: () => 0,
      init: () => getTADefault("Capital Improvements", "Air Ambulance"),
    }),
    useSAInput("loading...", {
      label: "Annual Gross Sales",
      type: "business",
      sector: 481,
      taProp: "Gross Sales",
      groupTitle: "Air Ambulance",
      initDebug: () => 0,
      init: () => getTADefault("Gross Sales", "Air Ambulance"),
    }),
  ];

  const foodAndDrinkingPlacesGroup = [
    useSAInput("loading...", {
      label: "Employment",
      type: "employment",
      sector: 503,
      taProp: "Employment",
      groupTitle: "Food & Drinking Places",
      initDebug: () => 2,
      init: () => getTADefault("Employment", "Food & Drinking Places"),
    }),
    useSAInput("loading...", {
      label: "Annual Payroll",
      type: "payroll",
      sector: 503,
      taProp: "Payroll",
      groupTitle: "Food & Drinking Places",
      initDebug: () => 0,
      init: () => getTADefault("Payroll", "Food & Drinking Places"),
    }),
    useSAInput("loading...", {
      label: "Annual Operating Expenses",
      type: "business",
      sector: 503,
      taProp: "Operating Expenses",
      groupTitle: "Food & Drinking Places",
      initDebug: () => 0,
      init: () => getTADefault("Operating Expenses", "Food & Drinking Places"),
    }),
    useSAInput("loading...", {
      label: "Annual Capital Expenses",
      type: "business",
      sector: 62,
      taProp: "Capital Improvements",
      groupTitle: "Food & Drinking Places",
      initDebug: () => 0,
      init: () =>
        getTADefault("Capital Improvements", "Food & Drinking Places"),
    }),
    useSAInput("loading...", {
      label: "Annual Gross Sales",
      type: "business",
      sector: 503,
      taProp: "Gross Sales",
      groupTitle: "Food & Drinking Places",
      initDebug: () => 0,
      init: () => getTADefault("Gross Sales", "Food & Drinking Places"),
    }),
  ];

  const retailMiscGroup = [
    useSAInput("loading...", {
      label: "Employment",
      type: "employment",
      sector: 406,
      taProp: "Employment",
      groupTitle: "Retail - Miscellaneous store retailers",
      initDebug: () => 0,
      init: () =>
        getTADefault("Employment", "Retail - Miscellaneous store retailers"),
    }),
    useSAInput("loading...", {
      label: "Annual Payroll",
      type: "payroll",
      sector: 406,
      taProp: "Payroll",
      groupTitle: "Retail - Miscellaneous store retailers",
      initDebug: () => 0,
      init: () =>
        getTADefault("Payroll", "Retail - Miscellaneous store retailers"),
    }),
    useSAInput("loading...", {
      label: "Annual Operating Expenses",
      type: "business",
      sector: 406,
      taProp: "Operating Expenses",
      groupTitle: "Retail - Miscellaneous store retailers",
      initDebug: () => 0,
      init: () =>
        getTADefault(
          "Operating Expenses",
          "Retail - Miscellaneous store retailers"
        ),
    }),
    useSAInput("loading...", {
      label: "Annual Capital Expenses",
      type: "business",
      sector: 62,
      taProp: "Capital Improvements",
      groupTitle: "Retail - Miscellaneous store retailers",
      initDebug: () => 0,
      init: () =>
        getTADefault(
          "Capital Improvements",
          "Retail - Miscellaneous store retailers"
        ),
    }),
    useSAInput("loading...", {
      label: "Annual Gross Sales",
      type: "business",
      sector: 406,
      taProp: "Gross Sales",
      groupTitle: "Retail - Miscellaneous store retailers",
      initDebug: () => 0,
      init: () =>
        getTADefault("Gross Sales", "Retail - Miscellaneous store retailers"),
    }),
  ];

  //FIXME: double check sector 38 on this...
  //The lack of sector spawned the use of keyword searching
  // based on groupTitle vs RM description
  // it should be safe to leave out the sector on some inputs.. need to test
  const supportAgOilGasGroup = [
    useSAInput("loading...", {
      label: "Employment",
      type: "employment",
      taProp: "Employment",
      groupTitle: "Support Activities for Agriculture or Oil & Gas",
      initDebug: () => 5,
      init: () =>
        getTADefault(
          "Employment",
          "Support Activities for Agriculture or Oil & Gas"
        ),
    }),
    useSAInput("loading...", {
      label: "Annual Payroll",
      type: "payroll",
      taProp: "Payroll",
      groupTitle: "Support Activities for Agriculture or Oil & Gas",
      initDebug: () => 0,
      init: () =>
        getTADefault(
          "Payroll",
          "Support Activities for Agriculture or Oil & Gas"
        ),
    }),
    useSAInput("loading...", {
      label: "Annual Operating Expenses",
      type: "business",
      taProp: "Operating Expenses",
      groupTitle: "Support Activities for Agriculture or Oil & Gas",
      initDebug: () => 0,
      init: () =>
        getTADefault(
          "Operating Expenses",
          "Support Activities for Agriculture or Oil & Gas"
        ),
    }),
    useSAInput("loading...", {
      label: "Annual Capital Expenses",
      type: "business",
      sector: 62, //still use this sector!
      taProp: "Capital Improvements",
      groupTitle: "Support Activities for Agriculture or Oil & Gas",
      initDebug: () => 0,
      init: () =>
        getTADefault(
          "Capital Improvements",
          "Support Activities for Agriculture or Oil & Gas"
        ),
    }),
    useSAInput("loading...", {
      label: "Annual Gross Sales",
      type: "business",
      taProp: "Gross Sales",
      groupTitle: "Support Activities for Agriculture or Oil & Gas",
      initDebug: () => 0,
      init: () =>
        getTADefault(
          "Gross Sales",
          "Support Activities for Agriculture or Oil & Gas"
        ),
    }),
  ];

  const otherAmuseAndRecGroup = [
    useSAInput("loading...", {
      label: "Employment",
      type: "employment",
      sector: 496,
      taProp: "Employment",
      groupTitle: "Other amusement and recreation industries",
      initDebug: () => 0,
      init: () =>
        getTADefault("Employment", "Other amusement and recreation industries"),
    }),
    useSAInput("loading...", {
      label: "Annual Payroll",
      type: "payroll",
      sector: 496,
      taProp: "Payroll",
      groupTitle: "Other amusement and recreation industries",
      initDebug: () => 0,
      init: () =>
        getTADefault("Payroll", "Other amusement and recreation industries"),
    }),
    useSAInput("loading...", {
      label: "Annual Operating Expenses",
      type: "business",
      sector: 496,
      taProp: "Operating Expenses",
      groupTitle: "Other amusement and recreation industries",
      initDebug: () => 0,
      init: () =>
        getTADefault(
          "Operating Expenses",
          "Other amusement and recreation industries"
        ),
    }),
    useSAInput("loading...", {
      label: "Annual Capital Expenses",
      type: "business",
      sector: 62,
      taProp: "Capital Improvements",
      groupTitle: "Other amusement and recreation industries",
      initDebug: () => 0,
      init: () =>
        getTADefault(
          "Capital Improvements",
          "Other amusement and recreation industries"
        ),
    }),
    useSAInput("loading...", {
      label: "Annual Gross Sales",
      type: "business",
      sector: 496,
      taProp: "Gross Sales",
      groupTitle: "Other amusement and recreation industries",
      initDebug: () => 0,
      init: () =>
        getTADefault(
          "Gross Sales",
          "Other amusement and recreation industries"
        ),
    }),
  ];

  const museumGroup = [
    useSAInput("loading...", {
      label: "Employment",
      type: "employment",
      sector: 493,
      taProp: "Employment",
      groupTitle: "Museum",
      initDebug: () => 0,
      init: () => getTADefault("Employment", "Museum"),
    }),
    useSAInput("loading...", {
      label: "Annual Payroll",
      type: "payroll",
      sector: 493,
      taProp: "Payroll",
      groupTitle: "Museum",
      initDebug: () => 0,
      init: () => getTADefault("Payroll", "Museum"),
    }),
    useSAInput("loading...", {
      label: "Annual Operating Expenses",
      type: "business",
      sector: 493,
      taProp: "Operating Expenses",
      groupTitle: "Museum",
      initDebug: () => 0,
      init: () => getTADefault("Operating Expenses", "Museum"),
    }),
    useSAInput("loading...", {
      label: "Annual Capital Expenses",
      type: "business",
      sector: 62,
      taProp: "Capital Improvements",
      groupTitle: "Museum",
      initDebug: () => 0,
      init: () => getTADefault("Capital Improvements", "Museum"),
    }),
    useSAInput("loading...", {
      label: "Annual Gross Sales",
      type: "business",
      sector: 493,
      taProp: "Gross Sales",
      groupTitle: "Museum",
      initDebug: () => 0,
      init: () => getTADefault("Gross Sales", "Museum"),
    }),
  ];

  const otherProfessionalServicesGroup = [
    useSAInput("loading...", {
      label: "Employment",
      type: "employment",
      sector: 460,
      taProp: "Employment",
      groupTitle: "Other Professional Services",
      initDebug: () => 0,
      init: () => getTADefault("Employment", "Other Professional Services"),
    }),
    useSAInput("loading...", {
      label: "Annual Payroll",
      type: "payroll",
      sector: 460,
      taProp: "Payroll",
      groupTitle: "Other Professional Services",
      initDebug: () => 0,
      init: () => getTADefault("Payroll", "Other Professional Services"),
    }),
    useSAInput("loading...", {
      label: "Annual Operating Expenses",
      type: "business",
      sector: 460,
      taProp: "Operating Expenses",
      groupTitle: "Other Professional Services",
      initDebug: () => 0,
      init: () =>
        getTADefault("Operating Expenses", "Other Professional Services"),
    }),
    useSAInput("loading...", {
      label: "Annual Capital Expenses",
      type: "business",
      sector: 62,
      taProp: "Capital Improvements",
      groupTitle: "Other Professional Services",
      initDebug: () => 0,
      init: () =>
        getTADefault("Capital Improvements", "Other Professional Services"),
    }),
    useSAInput("loading...", {
      label: "Annual Gross Sales",
      type: "business",
      sector: 460,
      taProp: "Gross Sales",
      groupTitle: "Other Professional Services",
      initDebug: () => 0,
      init: () => getTADefault("Gross Sales", "Other Professional Services"),
    }),
  ];

  const governmentEmploymentGroup = [
    useSAInput("loading...", {
      label: "Employment",
      type: "employment",
      taProp: "Employment",
      groupTitle: "Government Employment",
      initDebug: () => 15.5,
      init: () => getTADefault("Employment", "Government Employment"),
    }),
    useSAInput("loading...", {
      label: "Annual Payroll",
      type: "payroll",
      taProp: "Payroll",
      groupTitle: "Government Employment",
      initDebug: () => 157450,
      init: () => getTADefault("Payroll", "Government Employment"),
    }),
    useSAInput("loading...", {
      label: "Annual Operating Expenses",
      type: "business",
      taProp: "Operating Expenses",
      groupTitle: "Government Employment",
      initDebug: () => 0,
      init: () => getTADefault("Operating Expenses", "Government Employment"),
    }),
    useSAInput("loading...", {
      label: "Annual Capital Expenses",
      type: "business",
      sector: 62,
      taProp: "Capital Improvements",
      groupTitle: "Government Employment",
      initDebug: () => 333333.3333,
      init: () => getTADefault("Capital Improvements", "Government Employment"),
    }),
    useSAInput("loading...", {
      label: "Annual Gross Sales",
      type: "business",
      taProp: "Gross Sales",
      groupTitle: "Government Employment",
      initDebug: () => 0,
      init: () => getTADefault("Gross Sales", "Government Employment"),
    }),
  ];

  const manufacturingGroup = [
    useSAInput("loading...", {
      label: "Employment",
      type: "employment",
      sector: 394,
      taProp: "Employment",
      groupTitle: "Manufacturing",
      initDebug: () => 0,
      init: () => getTADefault("Employment", "Manufacturing"),
    }),
    useSAInput("loading...", {
      label: "Annual Payroll",
      type: "payroll",
      sector: 394,
      taProp: "Payroll",
      groupTitle: "Manufacturing",
      initDebug: () => 0,
      init: () => getTADefault("Payroll", "Manufacturing"),
    }),
    useSAInput("loading...", {
      label: "Annual Operating Expenses",
      type: "business",
      sector: 394,
      taProp: "Operating Expenses",
      groupTitle: "Manufacturing",
      initDebug: () => 0,
      init: () => getTADefault("Operating Expenses", "Manufacturing"),
    }),
    useSAInput("loading...", {
      label: "Annual Capital Expenses",
      type: "business",
      sector: 62,
      taProp: "Capital Improvements",
      groupTitle: "Manufacturing",
      initDebug: () => 0,
      init: () => getTADefault("Capital Improvements", "Manufacturing"),
    }),
    useSAInput("loading...", {
      label: "Annual Gross Sales",
      type: "business",
      sector: 394,
      taProp: "Gross Sales",
      groupTitle: "Manufacturing",
      initDebug: () => 0,
      init: () => getTADefault("Gross Sales", "Manufacturing"),
    }),
  ];

  const otherGroup = [
    useSAInput("loading...", {
      label: "Employment",
      type: "employment",
      taProp: "Employment",
      groupTitle: "Other",
      initDebug: () => 0,
      init: () => getTADefault("Employment", "Other"),
    }),
    useSAInput("loading...", {
      label: "Annual Payroll",
      type: "payroll",
      taProp: "Payroll",
      groupTitle: "Other",
      initDebug: () => 0,
      init: () => getTADefault("Payroll", "Other"),
    }),
    useSAInput("loading...", {
      label: "Annual Operating Expenses",
      type: "business",
      taProp: "Operating Expenses",
      groupTitle: "Other",
      initDebug: () => 0,
      init: () => getTADefault("Operating Expenses", "Other"),
    }),
    useSAInput("loading...", {
      label: "Annual Capital Expenses",
      type: "business",
      sector: 62,
      taProp: "Capital Improvements",
      groupTitle: "Other",
      initDebug: () => 0,
      init: () => getTADefault("Capital Improvements", "Other"),
    }),
    useSAInput("loading...", {
      label: "Annual Gross Sales",
      type: "business",
      taProp: "Gross Sales",
      groupTitle: "Other",
      initDebug: () => 0,
      init: () => getTADefault("Gross Sales", "Other"),
    }),
  ];

  const tenantGroups = [
    { title: "Air Transportation", fields: airTransportationGroup },
    {
      title: "Aircraft Support Activities",
      fields: aircraftSupportActivitiesGroup,
    },
    { title: "Aviation Schools", fields: aviationSchoolsGroup },
    {
      title: "Hangar Rental And Development",
      fields: hangarRentalAndDevelopmentGroup,
    },
    { title: "Rental Car", fields: rentalCarGroup },
    { title: "Parking Garages/Lots", fields: parkingGarageAndLotsGroup },
    { title: "Air Ambulance", fields: airAmbulanceGroup },
    { title: "Food and Drinking Places", fields: foodAndDrinkingPlacesGroup },
    {
      title: "Retail - Miscellaneous Store Retailers",
      fields: retailMiscGroup,
    },
    {
      title: "Support Activities for Agriculture or Oil & Gas",
      fields: supportAgOilGasGroup,
    },
    {
      title: "Other Amusement and Recreation Industries",
      fields: otherAmuseAndRecGroup,
    },
    { title: "Museum", fields: museumGroup },
    {
      title: "Other Professional Services",
      fields: otherProfessionalServicesGroup,
    },
    { title: "Government Employment", fields: governmentEmploymentGroup },
    { title: "Manufacturing", fields: manufacturingGroup },
    { title: "Other", fields: otherGroup },
  ];

  window.csEnplanements = csEnplanements;
  window.csAnnualVisitors = csAnnualVisitors;

  useEffect(() => {
    ///console.log('capEx',capitalExpenses);
    saGroup.forEach((field) => {
      if (field.init) {
        field.set(field.init());
      }
    });

    //eslint-disable-next-line
  }, [airportActivity, capitalExpenses]); // (saGroup would create an update cycle)
  window.saGroup = saGroup;

  useEffect(() => {
    ///console.log("uE a/visSpend", visitorSpending);
    if (visitorSpending) {
      vgaGroup.forEach((field) => {
        if (field.init) {
          ///console.log('vgaGroup setting...',field);
          field.set(field.init());
        }
      });
      vcsGroup.forEach((field) => {
        if (field.init) {
          ///console.log("vcsGroup setting...", field);
          field.set(field.init());
        }
      });
    }
    //eslint-disable-next-line
  }, [visitorSpending]); ///(vgaGroup would create an update cycle)
  //window.vgaGroup = vgaGroup;

  function updateField(field) {
    field.set(field.update());
  }
  function updateFieldGroup(fields) {
    fields.forEach((field) => updateField(field));
  }

  //cascade of updates for visitor inputs
  //GA

  gaIO.on("change", function () {
    updateField(gaAnnualVisitors);
  });

  gaAnnualVisitors.on("change", function () {
    updateFieldGroup([
      gaGroundPassengerTransport,
      gaFoodAndDrink,
      gaLodging,
      gaRetail,
      gaOtherRecreation,
    ]);
  });

  //CS
  csEnplanements.on("change", function () {
    updateField(csAnnualVisitors);
  });

  csAnnualVisitors.on("change", function () {
    updateFieldGroup([
      csGroundPassengerTransport,
      csFoodAndDrink,
      csLodging,
      csRetail,
      csOtherRecreation,
    ]);
  });

  window.allGroups = [saGroup, vgaGroup, vcsGroup, ...tenantGroups];

  useEffect(() => {
    if (tenantActivity) {
      tenantGroups.forEach((group) =>
        group.fields.forEach((field) => {
          if (field.init) {
            field.set(field.init());
          }
        })
      );
    }
    //eslint-disable-next-line
  }, [airport, tenantActivity]); //tenantGroups would create an update cycle

  /* Sponsored Activity Model */
  let saModel = EconomicImpactModel({
    origInputs: saGroup,
    inputs: getActivityInputs({
      inputs: saGroup,
      airport: airport,
    }),
  });
  window.saModel = saModel;
  ///console.log('saModel',saModel);

  /* Visitors - General Aviation */
  const vgaModel = EconomicImpactModel({
    origInputs: vgaGroup,
    inputs: getActivityInputs({
      inputs: vgaGroup,
      airport: airport,
    }),
  });

  /* Visitors - Commercial Service(?) */
  const vcsModel = EconomicImpactModel({
    origInputs: vcsGroup,
    inputs: getActivityInputs({
      inputs: vcsGroup,
      airport: airport,
    }),
  });

  const tenantModels = tenantGroups.map((group) => {
    return EconomicImpactModel({
      origInputs: group.fields,
      inputs: getActivityInputs({
        inputs: group.fields,
        airport: airport,
      }),
    });
  });

  var saTotal,
    aciTotal,
    vgaTotal,
    vcsTotal,
    tenantTotals,
    tenantGrandTotal,
    superMegaGrandTotal;

  var utTimer = false;

  function updateTotals() {
    clearTimeout(utTimer);
    utTimer = setTimeout(() => {
      ///console.log("updateTotals");
      saTotal = winnerTotal(saModel);
      aciTotal = saModel.find((o) =>
        o.label.match(/3.*Y.*r.*v.*g.*Capital.*Exp/i)
      );
      vgaTotal = TotalHelper(vgaModel);
      vcsTotal = TotalHelper(vcsModel);
      tenantTotals = tenantModels.map((model) => TotalHelper(model));
      tenantGrandTotal = simpleTotal(tenantTotals);
      superMegaGrandTotal = simpleTotal([
        saTotal,
        aciTotal,
        vgaTotal,
        vcsTotal,
        tenantGrandTotal,
      ]);

      /**
      console.log(
        "updateTotals",
        "saTotal",
        saTotal,
        "saModel",
        saModel,
        "tenantGrandTotal",
        tenantGrandTotal,
        "superMegaGrandTotal",
        superMegaGrandTotal
      );
      ***/

      campfire.emit("updateTotals", {
        saTotal,
        aciTotal,
        vgaTotal,
        vcsTotal,
        tenantGrandTotal,
        superMegaGrandTotal,
      });
    }, 10);
  }

  saModel.forEach((model) => {
    model.on("recalc", updateTotals);
  });
  vgaModel.forEach((model) => {
    model.on("recalc", updateTotals);
  });
  vcsModel.forEach((model) => {
    model.on("recalc", updateTotals);
  });
  tenantModels.forEach((group) =>
    group.forEach((model) => model.on("recalc", updateTotals))
  );

  /**
console.log(
  'tenantTotals',tenantTotals,
  'tenantGrandTotal',tenantGrandTotal,
  'superMegaGrandTotal', superMegaGrandTotal
);
**/

  /****
  async function loadTest() {
    let resp = await fetch("/data/test.txt");
    let text = await resp.text();
    console.log("RESP text", text);
  }
  ///loadTest();
***/

  if (
    !airport ||
    !airportActivity ||
    !visitorSpending ||
    !capitalExpenses ||
    !tenantActivity
  ) {
    return "Loading...";
  }
  //console.log("rerender!");
  //beep();
  updateTotals();

  return (
    <div className="App" id="container">
      <PopupBox className="margin2" />
      <header className="App-header flex-row align-items-center">
        <div className="nav-left opacity-full flex-column mobile-flex-row align-items-center ">
          <a className="site-title" href={process.env.PUBLIC_URL}>
            Small Airport Economic Impact Estimator
          </a>
          <div className="flex-row nav-buttons noprint">
            <a
              target="_blank"
              className="btn btn-sm btn-user-guide"
              href="/assets/0-7066-P2 Submittal.pdf"
            >
              User's Guide
            </a>
            <button className="btn btn-sm btn-print" onClick={printPDF}>
              Print
            </button>
          </div>
          <div className="spacer">&nbsp;</div>
        </div>
        <nav className="header-right navbar flex-row align-items-center">
          <img
            alt="Small Airport Economic Impact Estimator"
            src="/assets/images/header-bg-sasser-foo.jpg"
          />
        </nav>
      </header>

      <div className="flex-row top-row mobile-flex-column pad-lr space-between">
        <section className="airport-selection section-small white-bg pad1 margin1 flex-column">
          <div className="panel white-bg-80pct">
            <h1>Select Airport</h1>
            <AirportInput airport={airport} setAirport={setAirport} />
            <br />
            <table className="table table-bordered table-county-region">
              <tbody>
                <tr>
                  <th>County</th>
                  <td>{airport.County}</td>
                </tr>
                <tr>
                  <th>Region</th>
                  <td>{airport.Region}</td>
                </tr>
              </tbody>
            </table>

            <h1>Select Input/Output</h1>
            <div className="header-menu section-nav-row flex-row">
              <div className="btn-group margin1">
                <button
                  className={`btn btn-sm btn-primary btn-nav text-white ${
                    currentSection === "sponsored" && "selected"
                  }`}
                  onClick={() => goToSection("sponsored")}
                >
                  Airport
                </button>
                <button
                  className={`btn btn-sm btn-primary btn-nav text-white ${
                    currentSection === "visitor" && "selected"
                  }`}
                  onClick={() => goToSection("visitor")}
                >
                  Visitor
                </button>
                <button
                  className={`btn btn-sm btn-primary btn-nav text-white ${
                    currentSection === "tenant" && "selected"
                  }`}
                  onClick={() => goToSection("tenant")}
                >
                  Tenant
                </button>
              </div>
            </div>
          </div>
        </section>
        <section className="map section-small white-bg pad1 margin1">
          <div className="panel white-bg-80pct pad0-lr">
            <h1>Map</h1>
            <AirportMap airport={airport} whenMapReady={whenMapReady} />
          </div>
        </section>

        <section className="summary section-summary section-small white-bg pad1 margin1 flex-column">
          <div className="panel white-bg-80pct pad0-lr">
            <h1>Summary</h1>
            <SummaryTable
              campfire={campfire}
              summary={{
                saTotal,
                aciTotal,
                vgaTotal,
                vcsTotal,
                tenantGrandTotal,
                superMegaGrandTotal,
              }}
            />
          </div>
        </section>
      </div>

      <main className="page-wrap content screen-flex-row print-block pad-lr space-between">
        <section className="sponsored activity section-big  flex-column white-bg pad1 margin1">
          <div className="panel white-bg-80pct pad0-lr">
            <h1>Airport Activity</h1>
            <em>
              &nbsp;&nbsp;&nbsp;&nbsp;The estimator will only use one of the
              first three inputs to calculate the economic impact. Please see
              the User’s Guide for more information.
            </em>
            <AirportOutput
              slug="sponsored-activity"
              fields={saGroup}
              models={saModel}
            />
          </div>
        </section>

        <section className="visitor activity section-big flex-column white-bg pad1 margin1">
          <div className="panel white-bg-80pct pad0-lr">
            <h1>Visitor Activity</h1>
            <h2 className="margin1">General Aviation</h2>
            <AirportOutput
              slug="visitor-activity-ga"
              fields={vgaGroup}
              models={vgaModel}
              showTotalRow="true"
            />

            <h2 className="margin1">Commercial Service</h2>
            <AirportOutput
              slug="visitor-activity-cs"
              fields={vcsGroup}
              models={vcsModel}
              showTotalRow="true"
            />
          </div>
        </section>
        <section className="tenant activity section-big flex-column white-bg pad1 margin1">
          <div className="panel white-bg-80pct pad0-lr">
            <h1>Tenant Activity</h1>
            {tenantGroups.map((group, i) => (
              <div
                key={`tenant-group-${Utils.slugify(group.title)}`}
                className={`tenant-group tenant-group-${Utils.slugify(
                  group.title
                )}`}
              >
                <h2 className="margin1">{group.title}</h2>
                <AirportOutput
                  slug={Utils.slugify(group.title)}
                  fields={group.fields}
                  showTotalRow="true"
                  models={tenantModels[i]}
                />
              </div>
            ))}
          </div>
          <div className="debug display-none">
            <strong>DEBUG/IGNORE</strong>
            <button
              onClick={() => {
                window.maybeMatchFAA(); //from AirportMap.js
              }}
            >
              MATCH FAA
            </button>

            <button
              onClick={() => {
                setDebugTA(JSON.stringify(AIRPORT_LIST, null, 4));
              }}
            >
              debug AIRPORT_LIST
            </button>
            <textarea
              className="debug"
              value={debugTA}
              onChange={() => {}}
            ></textarea>
          </div>
        </section>

        <section className="chart section-small flex-column white-bg pad1 margin1">
          <div className="panel white-bg-80pct pad0-lr">
            <PieChart
              campfire={campfire}
              summary={{
                saTotal,
                aciTotal,
                vgaTotal,
                vcsTotal,
                tenantGrandTotal,
                superMegaGrandTotal,
              }}
            />
          </div>
        </section>
      </main>
    </div>
  );
}

export default App;
