//import { createContext } from "react";

export const MAPBOX_STREETS_V10_URL =
  "https://api.tiles.mapbox.com/styles/v1/mapbox/streets-v10/tiles/256/{z}/{x}/{y}?access_token={access_token}";

export const MAPBOX_ACCESSTOKEN =
  "pk.eyJ1IjoiamlzdW5nIiwiYSI6ImNqaXZ1YW9pNDJ4Nzkza3A4cGRtczhkNnUifQ.l8GpDnBNJtRoFMcId2hpSw";

export const KEYCODES = {
  TAB: 9,
  PERIOD: 46,
  DOWN: 40,
  UP: 38,
  LEFT: 37,
  RIGHT: 39,
};

export const REGION_MULTIPLIER = [
  {
    Region: "Panhandle",
    Sector: 19,
    Description: "Support Activities for Ag and Forestry",
    Output: 1.62569,
    Employment: 1.244956,
    Labor: 1.204208,
    "Output per Worker": 54021,
    "Labor Income per Worker": 50899,
  },
  {
    Region: "Panhandle",
    Sector: 38,
    Description: "Support activities for oil and gas operations",
    Output: 1.540452,
    Employment: 1.408255,
    Labor: 1.276537,
    "Output per Worker": 116144,
    "Labor Income per Worker": 64612,
  },
  {
    Region: "Panhandle",
    Sector: null,
    Description: "Support Activities for Agriculture or Oil & Gas",
    Output: 1.583071,
    Employment: 1.3266055,
    Labor: 1.2403725,
    "Output per Worker": 85082.5,
    "Labor Income per Worker": 57755.5,
  },
  {
    Region: "Panhandle",
    Sector: 62,
    Description: "Maintenance & Repair Construction",
    Output: 1.583794,
    Employment: 1.642266,
    Labor: 1.475086,
    "Output per Worker": 161262,
    "Labor Income per Worker": 59345,
  },
  {
    Region: "Panhandle",
    Sector: 394,
    Description: "Manufacturing",
    Output: 1.516591,
    Employment: 1.650679,
    Labor: 1.411424,
    "Output per Worker": 189478,
    "Labor Income per Worker": 78013,
  },
  {
    Region: "Panhandle",
    Sector: 406,
    Description: "Retail - Miscellaneous store retailers",
    Output: 1.616029,
    Employment: 1.187957,
    Labor: 1.373986,
    "Output per Worker": 84275,
    "Labor Income per Worker": 21930,
  },
  {
    Region: "Panhandle",
    Sector: 408,
    Description: "Air Transportation",
    Output: 1.680923,
    Employment: 2.20187,
    Labor: 1.737649,
    "Output per Worker": 326079,
    "Labor Income per Worker": 80802,
  },
  {
    Region: "Panhandle",
    Sector: 412,
    Description: "Transit and ground passenger transportation",
    Output: 1.845469,
    Employment: 1.184849,
    Labor: 1.873362,
    "Output per Worker": 34437,
    "Labor Income per Worker": 10065,
  },
  {
    Region: "Panhandle",
    Sector: 414,
    Description: "Aircraft Support Activities",
    Output: 1.739745,
    Employment: 1.735448,
    Labor: 1.800183,
    "Output per Worker": 135400,
    "Labor Income per Worker": 43830,
  },
  {
    Region: "Panhandle",
    Sector: 440,
    Description: "Hangar Rental/Development",
    Output: 1.537276,
    Employment: 1.466089,
    Labor: 1.819903,
    "Output per Worker": 125276,
    "Labor Income per Worker": 23809,
  },
  {
    Region: "Panhandle",
    Sector: 442,
    Description: "Rental Car",
    Output: 1.465293,
    Employment: 1.801578,
    Labor: 1.670308,
    "Output per Worker": 254726,
    "Labor Income per Worker": 55802,
  },
  {
    Region: "Panhandle",
    Sector: 460,
    Description: "Other Professional Services",
    Output: 1.675226,
    Employment: 1.337257,
    Labor: 1.315778,
    "Output per Worker": 68060,
    "Labor Income per Worker": 46015,
  },
  {
    Region: "Panhandle",
    Sector: 474,
    Description: "Aviation Schools",
    Output: 1.63891,
    Employment: 1.162203,
    Labor: 1.334935,
    "Output per Worker": 35225,
    "Labor Income per Worker": 20366,
  },
  {
    Region: "Panhandle",
    Sector: 481,
    Description: "Air Ambulance",
    Output: 1.615051,
    Employment: 1.596402,
    Labor: 1.310224,
    "Output per Worker": 137572,
    "Labor Income per Worker": 84546,
  },
  {
    Region: "Panhandle",
    Sector: 493,
    Description: "Museum",
    Output: 1.733933,
    Employment: 1.315874,
    Labor: 2.001746,
    "Output per Worker": 59522,
    "Labor Income per Worker": 13145,
  },
  {
    Region: "Panhandle",
    Sector: 496,
    Description: "Other amusement and recreation industries",
    Output: 1.519269,
    Employment: 1.232049,
    Labor: 1.516118,
    "Output per Worker": 64225,
    "Labor Income per Worker": 19323,
  },
  {
    Region: "Panhandle",
    Sector: 499,
    Description: "Hotels and motels, including casino hotels",
    Output: 1.53354,
    Employment: 1.344203,
    Labor: 1.671626,
    "Output per Worker": 92479,
    "Labor Income per Worker": 23256,
  },
  {
    Region: "Panhandle",
    Sector: 501,
    Description: "Full-service restaurants",
    Output: 1.599095,
    Employment: 1.19238,
    Labor: 1.414495,
    "Output per Worker": 49007,
    "Labor Income per Worker": 21993,
  },
  {
    Region: "Panhandle",
    Sector: 503,
    Description: "Food & Drinking Places",
    Output: 1.691888,
    Employment: 1.196717,
    Labor: 1.290217,
    "Output per Worker": 41924,
    "Labor Income per Worker": 30314,
  },
  {
    Region: "Panhandle",
    Sector: 512,
    Description: "Parking Garage and Lots",
    Output: 1.703717,
    Employment: 1.188523,
    Labor: 1.302651,
    "Output per Worker": 35335,
    "Labor Income per Worker": 25389,
  },
  {
    Region: "Panhandle",
    Sector: 531,
    Description: "* Employment and payroll of state govt, non-education",
    Output: 1.530367,
    Employment: 1.321509,
    Labor: 1.192085,
    "Output per Worker": 83491,
    "Labor Income per Worker": 70100,
  },
  {
    Region: "Panhandle",
    Sector: 532,
    Description: "* Employment and payroll of state govt, education",
    Output: 1.534117,
    Employment: 1.226801,
    Labor: 1.192085,
    "Output per Worker": 58483,
    "Labor Income per Worker": 49451,
  },
  {
    Region: "Panhandle",
    Sector: 533,
    Description: "* Employment and payroll of local govt, non-education",
    Output: 1.528659,
    Employment: 1.272732,
    Labor: 1.192085,
    "Output per Worker": 71053,
    "Labor Income per Worker": 59465,
  },
  {
    Region: "Panhandle",
    Sector: 534,
    Description: "* Employment and payroll of local govt, education",
    Output: 1.529013,
    Employment: 1.252101,
    Labor: 1.192085,
    "Output per Worker": 65634,
    "Labor Income per Worker": 54967,
  },
  {
    Region: "Panhandle",
    Sector: 535,
    Description: "* Employment and payroll of federal govt, non-military",
    Output: 1.478659,
    Employment: 1.523596,
    Labor: 1.192085,
    "Output per Worker": 150659,
    "Labor Income per Worker": 114163,
  },
  {
    Region: "Panhandle",
    Sector: 536,
    Description: "* Employment and payroll of federal govt, military",
    Output: 1.491003,
    Employment: 1.175182,
    Labor: 1.192085,
    "Output per Worker": 49139,
    "Labor Income per Worker": 38196,
  },
  {
    Region: "Panhandle",
    Sector: null,
    Description: "Government Employment",
    Output: 1.515303,
    Employment: 1.295320167,
    Labor: 1.192085,
    "Output per Worker": 79743.16667,
    "Labor Income per Worker": 64390.33333,
  },
  {
    Region: "Panhandle",
    Sector: null,
    Description: "Other",
    Output: 1.594521593,
    Employment: 1.388274136,
    Labor: 1.421681611,
    "Output per Worker": 100286.358,
    "Labor Income per Worker": 47479.32716,
  },
  {
    Region: "Upper Gulf Coast",
    Sector: 19,
    Description: "Support Activities for Ag and Forestry",
    Output: 1.823711,
    Employment: 1.182221455,
    Labor: 1.318334121,
    "Output per Worker": 34960,
    "Labor Income per Worker": 30749.02128,
  },
  {
    Region: "Upper Gulf Coast",
    Sector: 38,
    Description: "Support activities for oil and gas operations",
    Output: 1.744075,
    Employment: 1.903658021,
    Labor: 1.377601428,
    "Output per Worker": 206666,
    "Labor Income per Worker": 133663.5605,
  },
  {
    Region: "Upper Gulf Coast",
    Sector: null,
    Description: "Support Activities for Agriculture or Oil & Gas",
    Output: 1.783893,
    Employment: 1.542939738,
    Labor: 1.347967775,
    "Output per Worker": 120813,
    "Labor Income per Worker": 82206.29091,
  },
  {
    Region: "Upper Gulf Coast",
    Sector: 62,
    Description: "Maintenance & Repair Construction",
    Output: 1.827007,
    Employment: 1.930887747,
    Labor: 1.66157688,
    "Output per Worker": 192331,
    "Labor Income per Worker": 81817.81382,
  },
  {
    Region: "Upper Gulf Coast",
    Sector: 394,
    Description: "Manufacturing",
    Output: 1.860208,
    Employment: 1.981001711,
    Labor: 1.752786014,
    "Output per Worker": 197653,
    "Labor Income per Worker": 84003.27164,
  },
  {
    Region: "Upper Gulf Coast",
    Sector: 406,
    Description: "Retail - Miscellaneous store retailers",
    Output: 1.912367,
    Employment: 1.263202,
    Labor: 1.603959,
    "Output per Worker": 45903,
    "Labor Income per Worker": 24497.12101,
  },
  {
    Region: "Upper Gulf Coast",
    Sector: 408,
    Description: "Air transportation",
    Output: 1.905435,
    Employment: 2.808853,
    Labor: 1.92942,
    "Output per Worker": 387724,
    "Labor Income per Worker": 121792.7329,
  },
  {
    Region: "Upper Gulf Coast",
    Sector: 412,
    Description: "Transit and ground passenger transportation",
    Output: 2.071325,
    Employment: 1.289974,
    Labor: 1.869643,
    "Output per Worker": 45271.5719,
    "Labor Income per Worker": 20372.34964,
  },
  {
    Region: "Upper Gulf Coast",
    Sector: 414,
    Description: "Aircraft Support Activities",
    Output: 2.036684,
    Employment: 2.121664168,
    Labor: 1.966999772,
    "Output per Worker": 163544.1761,
    "Labor Income per Worker": 68321.8862,
  },
  {
    Region: "Upper Gulf Coast",
    Sector: 440,
    Description: "Hangar Rental/Development",
    Output: 1.605428,
    Employment: 1.754660929,
    Labor: 2.111029198,
    "Output per Worker": 188961.1618,
    "Labor Income per Worker": 36727.49301,
  },
  {
    Region: "Upper Gulf Coast",
    Sector: 442,
    Description: "Rental Car",
    Output: 1.626803,
    Employment: 2.010013444,
    Labor: 2.014754668,
    "Output per Worker": 269153.9163,
    "Labor Income per Worker": 59612.12501,
  },
  {
    Region: "Upper Gulf Coast",
    Sector: 460,
    Description: "Other Professional Services",
    Output: 1.92417,
    Employment: 1.632167559,
    Labor: 1.406402602,
    "Output per Worker": 102358.2258,
    "Labor Income per Worker": 85707.34184,
  },
  {
    Region: "Upper Gulf Coast",
    Sector: 474,
    Description: "Aviation Schools",
    Output: 1.934409,
    Employment: 1.226917043,
    Labor: 1.533479936,
    "Output per Worker": 36716.07039,
    "Labor Income per Worker": 23019.00528,
  },
  {
    Region: "Upper Gulf Coast",
    Sector: 481,
    Description: "Air Ambulance",
    Output: 1.894286,
    Employment: 1.599747727,
    Labor: 1.592323953,
    "Output per Worker": 108579.4345,
    "Labor Income per Worker": 58690.20416,
  },
  {
    Region: "Upper Gulf Coast",
    Sector: 493,
    Description: "Museum",
    Output: 2.109557,
    Employment: 1.58958601,
    Labor: 1.826524613,
    "Output per Worker": 83758.84315,
    "Labor Income per Worker": 38766.48893,
  },
  {
    Region: "Upper Gulf Coast",
    Sector: 496,
    Description: "Other amusement and recreation industries",
    Output: 1.775584,
    Employment: 1.365085,
    Labor: 1.742054,
    "Output per Worker": 75111.66101,
    "Labor Income per Worker": 27504.44804,
  },
  {
    Region: "Upper Gulf Coast",
    Sector: 499,
    Description: "Hotels and motels, including casino hotels",
    Output: 1.685946,
    Employment: 1.522996,
    Labor: 1.802671,
    "Output per Worker": 118882.6132,
    "Labor Income per Worker": 37426.87639,
  },
  {
    Region: "Upper Gulf Coast",
    Sector: 501,
    Description: "Full-service restaurants",
    Output: 1.883322,
    Employment: 1.274654,
    Labor: 1.637022,
    "Output per Worker": 51765.09268,
    "Labor Income per Worker": 25709.96987,
  },
  {
    Region: "Upper Gulf Coast",
    Sector: 503,
    Description: "Food & Drinking Places",
    Output: 2.027268,
    Employment: 1.259725749,
    Labor: 1.742053727,
    "Output per Worker": 40751.89838,
    "Labor Income per Worker": 31803.71775,
  },
  {
    Region: "Upper Gulf Coast",
    Sector: 512,
    Description: "Parking Garage and Lots",
    Output: 2.037528,
    Employment: 1.223766896,
    Labor: 1.489610074,
    "Output per Worker": 32138.12475,
    "Labor Income per Worker": 24381.54225,
  },
  {
    Region: "Upper Gulf Coast",
    Sector: 531,
    Description: "* Employment and payroll of state govt, non-education",
    Output: 1.671378,
    Employment: 1.524931435,
    Labor: 1.280161834,
    "Output per Worker": 115780.4509,
    "Labor Income per Worker": 97210.81904,
  },
  {
    Region: "Upper Gulf Coast",
    Sector: 532,
    Description: "* Employment and payroll of state govt, education",
    Output: 1.676125,
    Employment: 1.390686686,
    Labor: 1.280161828,
    "Output per Worker": 85566.0016,
    "Labor Income per Worker": 72350.35154,
  },
  {
    Region: "Upper Gulf Coast",
    Sector: 533,
    Description: "* Employment and payroll of local govt, non-education",
    Output: 1.669216,
    Employment: 1.414216146,
    Labor: 1.280161823,
    "Output per Worker": 91655.8758,
    "Labor Income per Worker": 76707.71795,
  },
  {
    Region: "Upper Gulf Coast",
    Sector: 534,
    Description: "* Employment and payroll of local govt, education",
    Output: 1.669663,
    Employment: 1.339004491,
    Labor: 1.280161797,
    "Output per Worker": 74963.29027,
    "Labor Income per Worker": 62779.45395,
  },
  {
    Region: "Upper Gulf Coast",
    Sector: 535,
    Description: "* Employment and payroll of federal govt, non-military",
    Output: 1.605921,
    Employment: 1.785815301,
    Labor: 1.280161801,
    "Output per Worker": 192045.4015,
    "Labor Income per Worker": 145523.3057,
  },
  {
    Region: "Upper Gulf Coast",
    Sector: 536,
    Description: "* Employment and payroll of federal govt, military",
    Output: 1.621547,
    Employment: 1.207667296,
    Labor: 1.280161821,
    "Output per Worker": 49475.87895,
    "Labor Income per Worker": 38457.42066,
  },
  {
    Region: "Upper Gulf Coast",
    Sector: null,
    Description: "Government Employment",
    Output: 1.652308333,
    Employment: 1.443720226,
    Labor: 1.280161817,
    "Output per Worker": 101581.1498,
    "Labor Income per Worker": 82171.51148,
  },
  {
    Region: "Upper Gulf Coast",
    Sector: null,
    Description: "Other",
    Output: 1.816117198,
    Employment: 1.577398659,
    Labor: 1.581012833,
    "Output per Worker": 119041.1422,
    "Labor Income per Worker": 61924.95707,
  },
  {
    Region: "North Texas",
    Sector: 19,
    Description: "Support Activities for Ag and Forestry",
    Output: 1.938147259,
    Employment: 1.189072012,
    Labor: 1.353292357,
    "Output per Worker": 32297.17459,
    "Labor Income per Worker": 28615.06833,
  },
  {
    Region: "North Texas",
    Sector: 38,
    Description: "Support activities for oil and gas operations",
    Output: 1.859983754,
    Employment: 1.645043404,
    Labor: 1.473746782,
    "Output per Worker": 121465.8407,
    "Labor Income per Worker": 75831.45801,
  },
  {
    Region: "North Texas",
    Sector: null,
    Description: "Support Activities for Agriculture or Oil & Gas",
    Output: 1.899065507,
    Employment: 1.417057708,
    Labor: 1.413519569,
    "Output per Worker": 76881.50764,
    "Labor Income per Worker": 52223.26317,
  },
  {
    Region: "North Texas",
    Sector: 62,
    Description: "Maintenance & Repair Construction",
    Output: 1.954681759,
    Employment: 2.009802556,
    Labor: 1.761105668,
    "Output per Worker": 172815.1395,
    "Labor Income per Worker": 73543.79167,
  },
  {
    Region: "North Texas",
    Sector: 394,
    Description: "Manufacturing",
    Output: 1.983302355,
    Employment: 1.935760792,
    Labor: 2.019799102,
    "Output per Worker": 166878.4244,
    "Labor Income per Worker": 56733.78255,
  },
  {
    Region: "North Texas",
    Sector: 406,
    Description: "Retail - Miscellaneous store retailers",
    Output: 2.056974,
    Employment: 1.299327,
    Labor: 1.656765,
    "Output per Worker": 45842.95918,
    "Labor Income per Worker": 24910.99029,
  },
  {
    Region: "North Texas",
    Sector: 408,
    Description: "Air transportation",
    Output: 1.847892,
    Employment: 2.887017,
    Labor: 1.925092,
    "Output per Worker": 372627.8478,
    "Labor Income per Worker": 120705.0894,
  },
  {
    Region: "North Texas",
    Sector: 412,
    Description: "Transit and ground passenger transportation",
    Output: 2.148984,
    Employment: 1.286345,
    Labor: 2.209514,
    "Output per Worker": 41303.90318,
    "Labor Income per Worker": 14209.65979,
  },
  {
    Region: "North Texas",
    Sector: 414,
    Description: "Aircraft Support Activities",
    Output: 2.172783483,
    Employment: 2.237764119,
    Labor: 2.08128335,
    "Output per Worker": 158032.6928,
    "Labor Income per Worker": 63834.82014,
  },
  {
    Region: "North Texas",
    Sector: 440,
    Description: "Hangar Rental/Development",
    Output: 1.611805419,
    Employment: 1.811841729,
    Labor: 2.259627907,
    "Output per Worker": 204393.3394,
    "Labor Income per Worker": 34029.09606,
  },
  {
    Region: "North Texas",
    Sector: 442,
    Description: "Rental Car",
    Output: 1.73032414,
    Employment: 2.14213251,
    Labor: 2.153835654,
    "Output per Worker": 265580.1032,
    "Labor Income per Worker": 57860.34213,
  },
  {
    Region: "North Texas",
    Sector: 460,
    Description: "Other Professional Services",
    Output: 2.110230727,
    Employment: 1.52824487,
    Labor: 1.542886045,
    "Output per Worker": 74341.45576,
    "Labor Income per Worker": 54038.88364,
  },
  {
    Region: "North Texas",
    Sector: 474,
    Description: "Aviation Schools",
    Output: 2.070051199,
    Employment: 1.285087264,
    Labor: 1.543771124,
    "Output per Worker": 42158.16981,
    "Labor Income per Worker": 28392.15535,
  },
  {
    Region: "North Texas",
    Sector: 481,
    Description: "Air Ambulance",
    Output: 2.00406344,
    Employment: 1.766590887,
    Labor: 1.58081383,
    "Output per Worker": 126264.4447,
    "Labor Income per Worker": 73996.93495,
  },
  {
    Region: "North Texas",
    Sector: 493,
    Description: "Museum",
    Output: 2.293145079,
    Employment: 1.682361646,
    Labor: 1.882348471,
    "Output per Worker": 86315.33982,
    "Labor Income per Worker": 41417.37977,
  },
  {
    Region: "North Texas",
    Sector: 496,
    Description: "Other amusement and recreation industries",
    Output: 1.875083,
    Employment: 1.425169,
    Labor: 1.759692,
    "Output per Worker": 79108.4432,
    "Labor Income per Worker": 30341.08916,
  },
  {
    Region: "North Texas",
    Sector: 499,
    Description: "Hotels and motels, including casino hotels",
    Output: 1.797055,
    Employment: 1.615412,
    Labor: 1.814753,
    "Output per Worker": 123155.6929,
    "Labor Income per Worker": 42191.46251,
  },
  {
    Region: "North Texas",
    Sector: 501,
    Description: "Full-service restaurants",
    Output: 2.000336,
    Employment: 1.320084,
    Labor: 1.646095,
    "Output per Worker": 54098.20491,
    "Labor Income per Worker": 28034.03294,
  },
  {
    Region: "North Texas",
    Sector: 503,
    Description: "Food & Drinking Places",
    Output: 2.11128322,
    Employment: 1.332096793,
    Labor: 1.465573267,
    "Output per Worker": 48851.4494,
    "Labor Income per Worker": 39061.95297,
  },
  {
    Region: "North Texas",
    Sector: 512,
    Description: "Parking Garage and Lots",
    Output: 2.272482176,
    Employment: 1.250893429,
    Labor: 1.546977264,
    "Output per Worker": 30531.0904,
    "Labor Income per Worker": 24177.11118,
  },
  {
    Region: "North Texas",
    Sector: 531,
    Description: "* Employment and payroll of state govt, non-education",
    Output: 1.778724166,
    Employment: 1.560329617,
    Labor: 1.31347537,
    "Output per Worker": 111792.2295,
    "Labor Income per Worker": 93862.26483,
  },
  {
    Region: "North Texas",
    Sector: 532,
    Description: "* Employment and payroll of state govt, education",
    Output: 1.784230248,
    Employment: 1.412448335,
    Labor: 1.313475355,
    "Output per Worker": 81710.46084,
    "Labor Income per Worker": 69090.29191,
  },
  {
    Region: "North Texas",
    Sector: 533,
    Description: "* Employment and payroll of local govt, non-education",
    Output: 1.776216662,
    Employment: 1.449001584,
    Labor: 1.313475347,
    "Output per Worker": 89870.38634,
    "Labor Income per Worker": 75213.42313,
  },
  {
    Region: "North Texas",
    Sector: 534,
    Description: "* Employment and payroll of local govt, education",
    Output: 1.776735281,
    Employment: 1.370602899,
    Labor: 1.313475362,
    "Output per Worker": 74128.88578,
    "Labor Income per Worker": 62080.65251,
  },
  {
    Region: "North Texas",
    Sector: 535,
    Description: "* Employment and payroll of federal govt, non-military",
    Output: 1.702801823,
    Employment: 1.773464957,
    Labor: 1.313475359,
    "Output per Worker": 170985.568,
    "Labor Income per Worker": 129565.1223,
  },
  {
    Region: "North Texas",
    Sector: 536,
    Description: "* Employment and payroll of federal govt, military",
    Output: 1.720926329,
    Employment: 1.282284205,
    Labor: 1.313475381,
    "Output per Worker": 60834.14182,
    "Labor Income per Worker": 47286.15645,
  },
  {
    Region: "North Texas",
    Sector: null,
    Description: "Government Employment",
    Output: 1.756605752,
    Employment: 1.474688599,
    Labor: 1.313475362,
    "Output per Worker": 98220.27872,
    "Labor Income per Worker": 79516.31852,
  },
  {
    Region: "North Texas",
    Sector: null,
    Description: "Other",
    Output: 1.927181992,
    Employment: 1.607034219,
    Labor: 1.640178479,
    "Output per Worker": 111499.4509,
    "Labor Income per Worker": 56324.5405,
  },
  {
    Region: "East Texas",
    Sector: 19,
    Description: "Support Activities for Ag and Forestry",
    Output: 1.633791881,
    Employment: 1.141379086,
    Labor: 1.220752948,
    "Output per Worker": 29070.84542,
    "Labor Income per Worker": 25213.30032,
  },
  {
    Region: "East Texas",
    Sector: 38,
    Description: "Support activities for oil and gas operations",
    Output: 1.559739157,
    Employment: 1.512164744,
    Labor: 1.272139971,
    "Output per Worker": 121750.8521,
    "Labor Income per Worker": 75466.68699,
  },
  {
    Region: "East Texas",
    Sector: null,
    Description: "Support Activities for Agriculture or Oil & Gas",
    Output: 1.596765519,
    Employment: 1.326771915,
    Labor: 1.246446459,
    "Output per Worker": 75410.84876,
    "Labor Income per Worker": 50339.99366,
  },
  {
    Region: "East Texas",
    Sector: 62,
    Description: "Maintenance & Repair Construction",
    Output: 1.596109,
    Employment: 1.668555,
    Labor: 1.501818,
    "Output per Worker": 152698.6915,
    "Labor Income per Worker": 55173.63161,
  },
  {
    Region: "East Texas",
    Sector: 394,
    Description: "Manufacturing",
    Output: 1.499995757,
    Employment: 1.627519003,
    Labor: 1.431012848,
    "Output per Worker": 172165.4129,
    "Labor Income per Worker": 61675.6624,
  },
  {
    Region: "East Texas",
    Sector: 406,
    Description: "Retail - Miscellaneous store retailers",
    Output: 1.627467,
    Employment: 1.208479,
    Labor: 1.373506,
    "Output per Worker": 42864.70502,
    "Labor Income per Worker": 21925.44427,
  },
  {
    Region: "East Texas",
    Sector: 408,
    Description: "Air transportation",
    Output: 1.660864,
    Employment: 2.219051,
    Labor: 1.737869,
    "Output per Worker": 312471.4895,
    "Labor Income per Worker": 76770.40516,
  },
  {
    Region: "East Texas",
    Sector: 412,
    Description: "Transit and ground passenger transportation",
    Output: 1.776288,
    Employment: 1.222477,
    Labor: 1.634245,
    "Output per Worker": 39953.37688,
    "Labor Income per Worker": 14661.27848,
  },
  {
    Region: "East Texas",
    Sector: 414,
    Description: "Aircraft Support Activities",
    Output: 1.778641385,
    Employment: 1.926477303,
    Labor: 1.721489978,
    "Output per Worker": 150113.9319,
    "Labor Income per Worker": 57657.69805,
  },
  {
    Region: "East Texas",
    Sector: 440,
    Description: "Hangar Rental/Development",
    Output: 1.539704367,
    Employment: 1.531815327,
    Labor: 1.97659787,
    "Output per Worker": 123172.0847,
    "Labor Income per Worker": 20330.17113,
  },
  {
    Region: "East Texas",
    Sector: 442,
    Description: "Rental Car",
    Output: 1.434237039,
    Employment: 1.742959869,
    Labor: 1.671680095,
    "Output per Worker": 233730.3589,
    "Labor Income per Worker": 46685.88638,
  },
  {
    Region: "East Texas",
    Sector: 460,
    Description: "Other Professional Services",
    Output: 1.680337965,
    Employment: 1.34386267,
    Labor: 1.317129191,
    "Output per Worker": 62206.58211,
    "Labor Income per Worker": 42247.90292,
  },
  {
    Region: "East Texas",
    Sector: 474,
    Description: "Aviation Schools",
    Output: 1.620818535,
    Employment: 1.128995546,
    Labor: 1.412183875,
    "Output per Worker": 25185.08436,
    "Labor Income per Worker": 11638.32236,
  },
  {
    Region: "East Texas",
    Sector: 481,
    Description: "Air Ambulance",
    Output: 1.608885299,
    Employment: 1.507503833,
    Labor: 1.34952881,
    "Output per Worker": 108432.5041,
    "Labor Income per Worker": 58342.85405,
  },
  {
    Region: "East Texas",
    Sector: 493,
    Description: "Museum",
    Output: 1.700140761,
    Employment: 1.451082435,
    Labor: 1.472004661,
    "Output per Worker": 81652.16162,
    "Labor Income per Worker": 36553.4155,
  },
  {
    Region: "East Texas",
    Sector: 496,
    Description: "Other amusement and recreation industries",
    Output: 1.50804419,
    Employment: 1.248515224,
    Labor: 1.493618815,
    "Output per Worker": 63259.69973,
    "Labor Income per Worker": 19425.8499,
  },
  {
    Region: "East Texas",
    Sector: 499,
    Description: "Hotels and motels, including casino hotels",
    Output: 1.50448199,
    Employment: 1.347027422,
    Labor: 1.658129169,
    "Output per Worker": 88365.31803,
    "Labor Income per Worker": 21007.41417,
  },
  {
    Region: "East Texas",
    Sector: 501,
    Description: "Full-service restaurants",
    Output: 1.565833723,
    Employment: 1.194795765,
    Labor: 1.386051649,
    "Output per Worker": 46451.57929,
    "Labor Income per Worker": 20206.70439,
  },
  {
    Region: "East Texas",
    Sector: 503,
    Description: "Food & Drinking Places",
    Output: 1.715124286,
    Employment: 1.203956818,
    Labor: 1.287604165,
    "Output per Worker": 38084.00033,
    "Labor Income per Worker": 28007.93654,
  },
  {
    Region: "East Texas",
    Sector: 512,
    Description: "Parking Garage and Lots",
    Output: 1.682287851,
    Employment: 1.201398707,
    Labor: 1.284538847,
    "Output per Worker": 35228.13671,
    "Labor Income per Worker": 25756.10561,
  },
  {
    Region: "East Texas",
    Sector: 531,
    Description: "* Employment and payroll of state govt, non-education",
    Output: 1.557953626,
    Employment: 1.353688574,
    Labor: 1.202121811,
    "Output per Worker": 80797.28412,
    "Labor Income per Worker": 67838.4843,
  },
  {
    Region: "East Texas",
    Sector: 532,
    Description: "* Employment and payroll of state govt, education",
    Output: 1.561898821,
    Employment: 1.310617922,
    Labor: 1.202121809,
    "Output per Worker": 70459.93278,
    "Labor Income per Worker": 59577.41011,
  },
  {
    Region: "East Texas",
    Sector: 533,
    Description: "* Employment and payroll of local govt, non-education",
    Output: 1.556157198,
    Employment: 1.301080623,
    Labor: 1.202121811,
    "Output per Worker": 69001.58696,
    "Labor Income per Worker": 57748.12788,
  },
  {
    Region: "East Texas",
    Sector: 534,
    Description: "* Employment and payroll of local govt, education",
    Output: 1.55652889,
    Employment: 1.270502171,
    Labor: 1.20212181,
    "Output per Worker": 61952.22143,
    "Labor Income per Worker": 51883.09371,
  },
  {
    Region: "East Texas",
    Sector: 535,
    Description: "* Employment and payroll of federal govt, non-military",
    Output: 1.50355536,
    Employment: 1.534453653,
    Labor: 1.202121805,
    "Output per Worker": 135280.9578,
    "Labor Income per Worker": 102509.7485,
  },
  {
    Region: "East Texas",
    Sector: 536,
    Description: "* Employment and payroll of federal govt, military",
    Output: 1.516541515,
    Employment: 1.191480604,
    Labor: 1.202121813,
    "Output per Worker": 47249.08396,
    "Labor Income per Worker": 36726.52924,
  },
  {
    Region: "East Texas",
    Sector: null,
    Description: "Government Employment",
    Output: 1.542105902,
    Employment: 1.326970591,
    Labor: 1.20212181,
    "Output per Worker": 77456.84452,
    "Labor Income per Worker": 62713.89895,
  },
  {
    Region: "East Texas",
    Sector: null,
    Description: "Other",
    Output: 1.595714778,
    Employment: 1.409021548,
    Labor: 1.402340741,
    "Output per Worker": 94239.46576,
    "Labor Income per Worker": 44743.85024,
  },
  {
    Region: "South Texas",
    Sector: 19,
    Description: "Support Activities for Ag and Forestry",
    Output: 1.833788293,
    Employment: 1.221381906,
    Labor: 1.292559576,
    "Output per Worker": 35117.61703,
    "Labor Income per Worker": 31435.84976,
  },
  {
    Region: "South Texas",
    Sector: 38,
    Description: "Support activities for oil and gas operations",
    Output: 1.738940099,
    Employment: 1.706294328,
    Labor: 1.36519692,
    "Output per Worker": 130182.2067,
    "Labor Income per Worker": 82641.83822,
  },
  {
    Region: "South Texas",
    Sector: null,
    Description: "Support Activities for Agriculture or Oil & Gas",
    Output: 1.786364196,
    Employment: 1.463838117,
    Labor: 1.328878248,
    "Output per Worker": 82649.91185,
    "Labor Income per Worker": 57038.84399,
  },
  {
    Region: "South Texas",
    Sector: 62,
    Description: "Maintenance & Repair Construction",
    Output: 1.814205815,
    Employment: 1.860204334,
    Labor: 1.725467156,
    "Output per Worker": 148001.5512,
    "Labor Income per Worker": 52802.70945,
  },
  {
    Region: "South Texas",
    Sector: 394,
    Description: "Manufacturing",
    Output: 1.680938075,
    Employment: 1.670796533,
    Labor: 1.944847623,
    "Output per Worker": 142818.8921,
    "Labor Income per Worker": 32855.39871,
  },
  {
    Region: "South Texas",
    Sector: 406,
    Description: "Retail - Miscellaneous store retailers",
    Output: 1.857923,
    Employment: 1.2686,
    Labor: 1.539526,
    "Output per Worker": 41843.02011,
    "Labor Income per Worker": 20906.73521,
  },
  {
    Region: "South Texas",
    Sector: 408,
    Description: "Air transportation",
    Output: 1.966528,
    Employment: 2.79056,
    Labor: 2.247999,
    "Output per Worker": 304733.7796,
    "Labor Income per Worker": 70986.32,
  },
  {
    Region: "South Texas",
    Sector: 412,
    Description: "Transit and ground passenger transportation",
    Output: 2.012233607,
    Employment: 1.287672,
    Labor: 1.859408,
    "Output per Worker": 41353.15872,
    "Labor Income per Worker": 15403.92788,
  },
  {
    Region: "South Texas",
    Sector: 414,
    Description: "Aircraft Support Activities",
    Output: 2.074111205,
    Employment: 2.102163531,
    Labor: 2.129508899,
    "Output per Worker": 133294.2512,
    "Labor Income per Worker": 44160.7691,
  },
  {
    Region: "South Texas",
    Sector: 440,
    Description: "Hangar Rental/Development",
    Output: 1.696781895,
    Employment: 1.753283086,
    Labor: 2.208644257,
    "Output per Worker": 137608.9125,
    "Labor Income per Worker": 25016.56231,
  },
  {
    Region: "South Texas",
    Sector: 442,
    Description: "Rental Car",
    Output: 1.59869507,
    Employment: 2.061682399,
    Labor: 1.923705579,
    "Output per Worker": 256278.1021,
    "Labor Income per Worker": 52096.37489,
  },
  {
    Region: "South Texas",
    Sector: 460,
    Description: "Other Professional Services",
    Output: 1.906412935,
    Employment: 1.449228753,
    Labor: 1.450991291,
    "Output per Worker": 63314.79048,
    "Labor Income per Worker": 41760.91977,
  },
  {
    Region: "South Texas",
    Sector: 474,
    Description: "Aviation Schools",
    Output: 1.866232463,
    Employment: 1.255255221,
    Labor: 1.43587697,
    "Output per Worker": 37713.11409,
    "Labor Income per Worker": 24006.02159,
  },
  {
    Region: "South Texas",
    Sector: 481,
    Description: "Air Ambulance",
    Output: 1.827408102,
    Employment: 1.629275979,
    Labor: 1.502779416,
    "Output per Worker": 103743.432,
    "Labor Income per Worker": 53988.97074,
  },
  {
    Region: "South Texas",
    Sector: 493,
    Description: "Museum",
    Output: 2.027293642,
    Employment: 1.600772463,
    Labor: 1.7430982,
    "Output per Worker": 78759.27092,
    "Labor Income per Worker": 33598.12302,
  },
  {
    Region: "South Texas",
    Sector: 496,
    Description: "Other amusement and recreation industries",
    Output: 1.722095369,
    Employment: 1.367788733,
    Labor: 1.658709022,
    "Output per Worker": 68788.68539,
    "Labor Income per Worker": 23271.04183,
  },
  {
    Region: "South Texas",
    Sector: 499,
    Description: "Hotels and motels, including casino hotels",
    Output: 1.666142556,
    Employment: 1.528618512,
    Labor: 1.753818103,
    "Output per Worker": 105243.1132,
    "Labor Income per Worker": 29935.79745,
  },
  {
    Region: "South Texas",
    Sector: 501,
    Description: "Full-service restaurants",
    Output: 1.802457226,
    Employment: 1.284921809,
    Labor: 1.523649978,
    "Output per Worker": 49516.34658,
    "Labor Income per Worker": 23354.5752,
  },
  {
    Region: "South Texas",
    Sector: 503,
    Description: "Food & Drinking Places",
    Output: 1.962942258,
    Employment: 1.277226751,
    Labor: 1.401743167,
    "Output per Worker": 39483.52244,
    "Labor Income per Worker": 29033.69576,
  },
  {
    Region: "South Texas",
    Sector: 512,
    Description: "Parking Garage and Lots",
    Output: 1.948997025,
    Employment: 1.259559089,
    Labor: 1.401038269,
    "Output per Worker": 34714.04396,
    "Labor Income per Worker": 25951.7397,
  },
  {
    Region: "South Texas",
    Sector: 531,
    Description: "* Employment and payroll of state govt, non-education",
    Output: 1.725618235,
    Employment: 1.49293498,
    Labor: 1.272438467,
    "Output per Worker": 88902.22401,
    "Labor Income per Worker": 74643.49989,
  },
  {
    Region: "South Texas",
    Sector: 532,
    Description: "* Employment and payroll of state govt, education",
    Output: 1.730748953,
    Employment: 1.399400589,
    Labor: 1.27243849,
    "Output per Worker": 71527.27452,
    "Labor Income per Worker": 60479.89368,
  },
  {
    Region: "South Texas",
    Sector: 533,
    Description: "* Employment and payroll of local govt, non-education",
    Output: 1.723281896,
    Employment: 1.416615523,
    Labor: 1.272438483,
    "Output per Worker": 75380.50189,
    "Labor Income per Worker": 63086.69503,
  },
  {
    Region: "South Texas",
    Sector: 534,
    Description: "* Employment and payroll of local govt, education",
    Output: 1.72376525,
    Employment: 1.390938948,
    Labor: 1.272438452,
    "Output per Worker": 70687.46237,
    "Labor Income per Worker": 59198.58412,
  },
  {
    Region: "South Texas",
    Sector: 535,
    Description: "* Employment and payroll of federal govt, non-military",
    Output: 1.65487356,
    Employment: 1.778570567,
    Labor: 1.27243848,
    "Output per Worker": 155586.425,
    "Labor Income per Worker": 117896.339,
  },
  {
    Region: "South Texas",
    Sector: 536,
    Description: "* Employment and payroll of federal govt, military",
    Output: 1.671762018,
    Employment: 1.574510074,
    Labor: 1.272438482,
    "Output per Worker": 111921.4644,
    "Labor Income per Worker": 86996.13947,
  },
  {
    Region: "South Texas",
    Sector: null,
    Description: "Government Employment",
    Output: 1.705008319,
    Employment: 1.508828447,
    Labor: 1.272438476,
    "Output per Worker": 95667.5587,
    "Labor Income per Worker": 77050.19186,
  },
  {
    Region: "South Texas",
    Sector: null,
    Description: "Other",
    Output: 1.804649965,
    Employment: 1.570404543,
    Labor: 1.56831537,
    "Output per Worker": 100178.9123,
    "Labor Income per Worker": 48503.61324,
  },
  {
    Region: "West Texas",
    Sector: 19,
    Description: "Support Activities for Ag and Forestry",
    Output: 1.645929615,
    Employment: 1.218619065,
    Labor: 1.211232553,
    "Output per Worker": 44805.86102,
    "Labor Income per Worker": 41353.68947,
  },
  {
    Region: "West Texas",
    Sector: 38,
    Description: "Support activities for oil and gas operations",
    Output: 1.570597911,
    Employment: 1.591645131,
    Labor: 1.262697235,
    "Output per Worker": 141991.1145,
    "Labor Income per Worker": 92590.92788,
  },
  {
    Region: "West Texas",
    Sector: null,
    Description: "Support Activities for Agriculture or Oil & Gas",
    Output: 1.608263763,
    Employment: 1.405132098,
    Labor: 1.236964894,
    "Output per Worker": 93398.48775,
    "Labor Income per Worker": 66972.30868,
  },
  {
    Region: "West Texas",
    Sector: 62,
    Description: "Maintenance & Repair Construction",
    Output: 1.676055875,
    Employment: 1.68555242,
    Labor: 1.603710341,
    "Output per Worker": 145207.2053,
    "Labor Income per Worker": 49789.90548,
  },
  {
    Region: "West Texas",
    Sector: 394,
    Description: "Manufacturing",
    Output: 1.536750192,
    Employment: 1.593962404,
    Labor: 1.520861798,
    "Output per Worker": 161112.732,
    "Labor Income per Worker": 51123.6364,
  },
  {
    Region: "West Texas",
    Sector: 406,
    Description: "Retail - Miscellaneous store retailers",
    Output: 1.663453,
    Employment: 1.207766,
    Labor: 1.393009,
    "Output per Worker": 42419.49756,
    "Labor Income per Worker": 21483.79617,
  },
  {
    Region: "West Texas",
    Sector: 408,
    Description: "Air transportation",
    Output: 1.754092,
    Employment: 2.402021,
    Labor: 1.868491,
    "Output per Worker": 320703.4628,
    "Labor Income per Worker": 82630.90766,
  },
  {
    Region: "West Texas",
    Sector: 412,
    Description: "Transit and ground passenger transportation",
    Output: 1.825487,
    Employment: 1.221113,
    Labor: 1.736565,
    "Output per Worker": 39801.74724,
    "Labor Income per Worker": 13707.70392,
  },
  {
    Region: "West Texas",
    Sector: 414,
    Description: "Aircraft Support Activities",
    Output: 1.863007402,
    Employment: 1.854216139,
    Labor: 1.940118931,
    "Output per Worker": 129743.6508,
    "Labor Income per Worker": 41327.45051,
  },
  {
    Region: "West Texas",
    Sector: 440,
    Description: "Hangar Rental/Development",
    Output: 1.543353262,
    Employment: 1.563052226,
    Labor: 1.853049371,
    "Output per Worker": 137514.9867,
    "Labor Income per Worker": 25778.69476,
  },
  {
    Region: "West Texas",
    Sector: 442,
    Description: "Rental Car",
    Output: 1.485121103,
    Employment: 1.846866618,
    Labor: 1.728541432,
    "Output per Worker": 254142.1222,
    "Labor Income per Worker": 52230.83784,
  },
  {
    Region: "West Texas",
    Sector: 460,
    Description: "Other Professional Services",
    Output: 1.68352161,
    Employment: 1.29854137,
    Labor: 1.34292548,
    "Output per Worker": 55817.68476,
    "Labor Income per Worker": 34832.87494,
  },
  {
    Region: "West Texas",
    Sector: 474,
    Description: "Aviation Schools",
    Output: 1.66805695,
    Employment: 1.143724923,
    Labor: 1.406797375,
    "Output per Worker": 27133.45673,
    "Labor Income per Worker": 13556.46795,
  },
  {
    Region: "West Texas",
    Sector: 481,
    Description: "Air Ambulance",
    Output: 1.641596658,
    Employment: 1.48075668,
    Labor: 1.376977806,
    "Output per Worker": 102183.0504,
    "Labor Income per Worker": 52897.42508,
  },
  {
    Region: "West Texas",
    Sector: 493,
    Description: "Museum",
    Output: 1.796144421,
    Employment: 1.425240636,
    Labor: 1.636356689,
    "Output per Worker": 71836.61915,
    "Labor Income per Worker": 26379.15093,
  },
  {
    Region: "West Texas",
    Sector: 496,
    Description: "Other amusement and recreation industries",
    Output: 1.56127141,
    Employment: 1.290629167,
    Labor: 1.47894906,
    "Output per Worker": 70517.23581,
    "Labor Income per Worker": 24451.01767,
  },
  {
    Region: "West Texas",
    Sector: 499,
    Description: "Hotels and motels, including casino hotels",
    Output: 1.549395033,
    Employment: 1.391122901,
    Labor: 1.654642006,
    "Output per Worker": 95469.3486,
    "Labor Income per Worker": 24846.57456,
  },
  {
    Region: "West Texas",
    Sector: 501,
    Description: "Full-service restaurants",
    Output: 1.628958132,
    Employment: 1.2101528,
    Labor: 1.415290187,
    "Output per Worker": 47646.45816,
    "Labor Income per Worker": 21433.96178,
  },
  {
    Region: "West Texas",
    Sector: 503,
    Description: "Food & Drinking Places",
    Output: 1.764587345,
    Employment: 1.205716379,
    Labor: 1.303529829,
    "Output per Worker": 38074.80323,
    "Labor Income per Worker": 27813.11191,
  },
  {
    Region: "West Texas",
    Sector: 512,
    Description: "Parking Garage and Lots",
    Output: 1.67319364,
    Employment: 1.210196264,
    Labor: 1.264778345,
    "Output per Worker": 39465.37185,
    "Labor Income per Worker": 30364.24951,
  },
  {
    Region: "West Texas",
    Sector: 531,
    Description: "* Employment and payroll of state govt, non-education",
    Output: 1.557020877,
    Employment: 1.390308437,
    Labor: 1.20015489,
    "Output per Worker": 91565.32556,
    "Labor Income per Worker": 76879.4733,
  },
  {
    Region: "West Texas",
    Sector: 532,
    Description: "* Employment and payroll of state govt, education",
    Output: 1.560959506,
    Employment: 1.316612456,
    Labor: 1.200154905,
    "Output per Worker": 73754.93132,
    "Labor Income per Worker": 62363.4911,
  },
  {
    Region: "West Texas",
    Sector: 533,
    Description: "* Employment and payroll of local govt, non-education",
    Output: 1.555227351,
    Employment: 1.313361377,
    Labor: 1.200154884,
    "Output per Worker": 73751.21756,
    "Labor Income per Worker": 61723.1298,
  },
  {
    Region: "West Texas",
    Sector: 534,
    Description: "* Employment and payroll of local govt, education",
    Output: 1.55559842,
    Employment: 1.278009744,
    Labor: 1.200154902,
    "Output per Worker": 65387.32893,
    "Labor Income per Worker": 54759.87502,
  },
  {
    Region: "West Texas",
    Sector: 535,
    Description: "* Employment and payroll of federal govt, non-military",
    Output: 1.502713783,
    Employment: 1.572111722,
    Labor: 1.200154892,
    "Output per Worker": 148714.9511,
    "Labor Income per Worker": 112689.4598,
  },
  {
    Region: "West Texas",
    Sector: 536,
    Description: "* Employment and payroll of federal govt, military",
    Output: 1.515678223,
    Employment: 1.436881373,
    Labor: 1.20015491,
    "Output per Worker": 110708.0805,
    "Labor Income per Worker": 86052.98531,
  },
  {
    Region: "West Texas",
    Sector: null,
    Description: "Government Employment",
    Output: 1.541199693,
    Employment: 1.384547518,
    Labor: 1.200154897,
    "Output per Worker": 93980.30583,
    "Labor Income per Worker": 75744.73573,
  },
  {
    Region: "West Texas",
    Sector: null,
    Description: "Other",
    Output: 1.626934599,
    Employment: 1.442142957,
    Labor: 1.430984171,
    "Output per Worker": 100623.9643,
    "Labor Income per Worker": 49102.88308,
  },
  {
    Region: "Central Texas",
    Sector: 19,
    Description: "Support Activities for Ag and Forestry",
    Output: 1.795033961,
    Employment: 1.189451781,
    Labor: 1.296065546,
    "Output per Worker": 34119.06454,
    "Labor Income per Worker": 30305.95503,
  },
  {
    Region: "Central Texas",
    Sector: 38,
    Description: "Support activities for oil and gas operations",
    Output: 1.728414958,
    Employment: 1.572761671,
    Labor: 1.398346342,
    "Output per Worker": 116179.8725,
    "Labor Income per Worker": 71289.99345,
  },
  {
    Region: "Central Texas",
    Sector: null,
    Description: "Support Activities for Agriculture or Oil & Gas",
    Output: 1.761724459,
    Employment: 1.381106726,
    Labor: 1.347205944,
    "Output per Worker": 75149.46853,
    "Labor Income per Worker": 50797.97424,
  },
  {
    Region: "Central Texas",
    Sector: 62,
    Description: "Maintenance & Repair Construction",
    Output: 1.781947223,
    Employment: 1.905357584,
    Labor: 1.61713077,
    "Output per Worker": 170710.6835,
    "Labor Income per Worker": 73050.31216,
  },
  {
    Region: "Central Texas",
    Sector: 394,
    Description: "Manufacturing",
    Output: 1.713671283,
    Employment: 1.705540858,
    Labor: 1.869678675,
    "Output per Worker": 153553.6058,
    "Labor Income per Worker": 43614.75074,
  },
  {
    Region: "Central Texas",
    Sector: 406,
    Description: "Retail - Miscellaneous store retailers",
    Output: 1.893229093,
    Employment: 1.28215,
    Labor: 1.527913,
    "Output per Worker": 46591.28969,
    "Labor Income per Worker": 25663.89666,
  },
  {
    Region: "Central Texas",
    Sector: 408,
    Description: "Air transportation",
    Output: 1.73196104,
    Employment: 2.446547,
    Labor: 2.066001,
    "Output per Worker": 306766.1473,
    "Labor Income per Worker": 72258.24919,
  },
  {
    Region: "Central Texas",
    Sector: 412,
    Description: "Transit and ground passenger transportation",
    Output: 1.919574735,
    Employment: 1.278674,
    Labor: 1.849957,
    "Output per Worker": 45940.83324,
    "Labor Income per Worker": 17430.80145,
  },
  {
    Region: "Central Texas",
    Sector: 414,
    Description: "Aircraft Support Activities",
    Output: 1.962731233,
    Employment: 2.007161308,
    Labor: 1.964624966,
    "Output per Worker": 143134.1903,
    "Labor Income per Worker": 51980.49575,
  },
  {
    Region: "Central Texas",
    Sector: 440,
    Description: "Hangar Rental/Development",
    Output: 1.609426247,
    Employment: 1.669943743,
    Labor: 2.234134746,
    "Output per Worker": 159366.5661,
    "Labor Income per Worker": 26434.59598,
  },
  {
    Region: "Central Texas",
    Sector: 442,
    Description: "Rental Car",
    Output: 1.602190007,
    Employment: 2.014753127,
    Labor: 1.918802466,
    "Output per Worker": 258041.832,
    "Labor Income per Worker": 56848.16541,
  },
  {
    Region: "Central Texas",
    Sector: 460,
    Description: "Other Professional Services",
    Output: 1.971027661,
    Employment: 1.472666356,
    Labor: 1.470632541,
    "Output per Worker": 69498.1459,
    "Labor Income per Worker": 49490.83432,
  },
  {
    Region: "Central Texas",
    Sector: 474,
    Description: "Aviation Schools",
    Output: 1.955171743,
    Employment: 1.261622952,
    Labor: 1.481129316,
    "Output per Worker": 39912.54758,
    "Labor Income per Worker": 26203.15443,
  },
  {
    Region: "Central Texas",
    Sector: 481,
    Description: "Air Ambulance",
    Output: 1.850246369,
    Employment: 1.61740045,
    Labor: 1.523785707,
    "Output per Worker": 108548.4303,
    "Labor Income per Worker": 58338.46031,
  },
  {
    Region: "Central Texas",
    Sector: 493,
    Description: "Museum",
    Output: 2.113216623,
    Employment: 1.552710809,
    Labor: 1.92553333,
    "Output per Worker": 73437.64976,
    "Labor Income per Worker": 28052.72816,
  },
  {
    Region: "Central Texas",
    Sector: 496,
    Description: "Other amusement and recreation industries",
    Output: 1.754982048,
    Employment: 1.372387714,
    Labor: 1.686052799,
    "Output per Worker": 72488.49077,
    "Labor Income per Worker": 25842.77199,
  },
  {
    Region: "Central Texas",
    Sector: 499,
    Description: "Hotels and motels, including casino hotels",
    Output: 1.675838323,
    Employment: 1.530737618,
    Labor: 1.761615319,
    "Output per Worker": 113516.6048,
    "Labor Income per Worker": 34294.40263,
  },
  {
    Region: "Central Texas",
    Sector: 501,
    Description: "Full-service restaurants",
    Output: 1.810659406,
    Employment: 1.275281521,
    Labor: 1.527222781,
    "Output per Worker": 51540.1442,
    "Labor Income per Worker": 25465.21668,
  },
  {
    Region: "Central Texas",
    Sector: 503,
    Description: "Food & Drinking Places",
    Output: 1.959581985,
    Employment: 1.257279662,
    Labor: 1.409758263,
    "Output per Worker": 40113.79325,
    "Labor Income per Worker": 30151.53452,
  },
  {
    Region: "Central Texas",
    Sector: 512,
    Description: "Parking Garage and Lots",
    Output: 2.037870502,
    Employment: 1.273375972,
    Labor: 1.423670891,
    "Output per Worker": 36641.46922,
    "Labor Income per Worker": 29491.22867,
  },
  {
    Region: "Central Texas",
    Sector: 531,
    Description: "* Employment and payroll of state govt, non-education",
    Output: 1.704359494,
    Employment: 1.468543375,
    Labor: 1.276195126,
    "Output per Worker": 94632.41135,
    "Labor Income per Worker": 79454.6773,
  },
  {
    Region: "Central Texas",
    Sector: 532,
    Description: "* Employment and payroll of state govt, education",
    Output: 1.709339632,
    Employment: 1.331204653,
    Labor: 1.276195141,
    "Output per Worker": 66424.25407,
    "Labor Income per Worker": 56165.04015,
  },
  {
    Region: "Central Texas",
    Sector: 533,
    Description: "* Employment and payroll of local govt, non-education",
    Output: 1.702091324,
    Employment: 1.409471095,
    Labor: 1.276195134,
    "Output per Worker": 82968.66902,
    "Labor Income per Worker": 69437.31259,
  },
  {
    Region: "Central Texas",
    Sector: 534,
    Description: "* Employment and payroll of local govt, education",
    Output: 1.702560456,
    Employment: 1.333261276,
    Labor: 1.276195127,
    "Output per Worker": 67481.64077,
    "Labor Income per Worker": 56513.80128,
  },
  {
    Region: "Central Texas",
    Sector: 535,
    Description: "* Employment and payroll of federal govt, non-military",
    Output: 1.635687315,
    Employment: 1.587552057,
    Labor: 1.276195138,
    "Output per Worker": 131488.352,
    "Labor Income per Worker": 99635.93487,
  },
  {
    Region: "Central Texas",
    Sector: 536,
    Description: "* Employment and payroll of federal govt, military",
    Output: 1.652080784,
    Employment: 1.491391653,
    Labor: 1.27619513,
    "Output per Worker": 107203.9661,
    "Labor Income per Worker": 83329.24266,
  },
  {
    Region: "Central Texas",
    Sector: null,
    Description: "Government Employment",
    Output: 1.684353168,
    Employment: 1.436904018,
    Labor: 1.276195133,
    "Output per Worker": 91699.88222,
    "Labor Income per Worker": 74089.33481,
  },
  {
    Region: "Central Texas",
    Sector: null,
    Description: "Other",
    Output: 1.793295225,
    Employment: 1.523156999,
    Labor: 1.564171383,
    "Output per Worker": 102116.6669,
    "Labor Income per Worker": 49838.1802,
  },
];

//useful resource https://www.airnav.com/airport/GRK

export const AIRPORT_LIST = [
  {
    Airport: "A. L. Mangham Jr. Regional Airport",
    County: "Nacogdoches",
    FAA: "OCH",
    Region: "East Texas",
    ITN_GA: 7500,
    "Itenerant Arrivals": 3750,
    "Transient Arrivals (Visitors)": 1250,
    "Visitors per Aircraft": 2.1,
    "Annual Visitors": 2625,
    "Non-hub CS CY18 Enplanements": "",
    lat: 31.578036,
    lon: -94.709547,
  },
  {
    Airport: "Abilene Regional Airport",
    County: "Taylor",
    FAA: "ABI",
    Region: "West Texas",
    ITN_GA: 17393,
    "Itenerant Arrivals": 8697,
    "Transient Arrivals (Visitors)": 2899,
    "Visitors per Aircraft": 3.7,
    "Annual Visitors": 10725,
    "Non-hub CS CY18 Enplanements": 77229,
    lat: 32.411326,
    lon: -99.681908,
  },
  {
    Airport: "Addison Airport",
    County: "Dallas",
    FAA: "ADS",
    Region: "North Texas",
    ITN_GA: 91819,
    "Itenerant Arrivals": 45910,
    "Transient Arrivals (Visitors)": 15302,
    "Visitors per Aircraft": 2.7,
    "Annual Visitors": 41314,
    "Non-hub CS CY18 Enplanements": "",
    lat: 32.968566,
    lon: -96.836458,
  },
  {
    Airport: "Albany Municipal",
    County: "Shackelford",
    FAA: "T23",
    Region: "West Texas",
    ITN_GA: 700,
    "Itenerant Arrivals": 350,
    "Transient Arrivals (Visitors)": 117,
    "Visitors per Aircraft": 2.8,
    "Annual Visitors": 327,
    "Non-hub CS CY18 Enplanements": "",
    lat: 32.720047,
    lon: -99.268674,
  },
  {
    Airport: "Alfred C. Bubba Thomas Airport",
    County: "San Patricio",
    FAA: "T69",
    Region: "South Texas",
    ITN_GA: 2000,
    "Itenerant Arrivals": 1000,
    "Transient Arrivals (Visitors)": 333,
    "Visitors per Aircraft": 2.8,
    "Annual Visitors": 933,
    "Non-hub CS CY18 Enplanements": "",
    lat: 28.042617,
    lon: -97.5435,
  },
  {
    Airport: "Alice International Airport",
    County: "Jim Wells",
    FAA: "ALI",
    Region: "South Texas",
    ITN_GA: 2200,
    "Itenerant Arrivals": 1100,
    "Transient Arrivals (Visitors)": 367,
    "Visitors per Aircraft": 2.8,
    "Annual Visitors": 1027,
    "Non-hub CS CY18 Enplanements": "",
    lat: 27.740895,
    lon: -98.026948,
  },
  {
    Airport: "Alpine Casparis Municipal Airport",
    County: "Brewster",
    FAA: "E38",
    Region: "West Texas",
    ITN_GA: 2190,
    "Itenerant Arrivals": 1095,
    "Transient Arrivals (Visitors)": 365,
    "Visitors per Aircraft": 2.8,
    "Annual Visitors": 1022,
    "Non-hub CS CY18 Enplanements": "",
    lat: 30.384225,
    lon: -103.683589,
  },
  {
    Airport: "Andrews County Airport",
    County: "Andrews",
    Region: "West Texas",
    ITN_GA: 1728,
    "Itenerant Arrivals": 864,
    "Transient Arrivals (Visitors)": 288,
    "Visitors per Aircraft": 2.8,
    "Annual Visitors": 806,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "E11",
    lat: 32.331115,
    lon: -102.529539,
  },
  {
    Airport: "Angelina County Airport",
    County: "Angelina",
    Region: "East Texas",
    ITN_GA: 12000,
    "Itenerant Arrivals": 6000,
    "Transient Arrivals (Visitors)": 2000,
    "Visitors per Aircraft": 2.7,
    "Annual Visitors": 5399,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "LFK",
    lat: 31.232684,
    lon: -94.755514,
  },
  {
    Airport: "Aransas County Airport",
    County: "Aransas",
    Region: "South Texas",
    ITN_GA: 5000,
    "Itenerant Arrivals": 2500,
    "Transient Arrivals (Visitors)": 833,
    "Visitors per Aircraft": 2.1,
    "Annual Visitors": 1750,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "RKP",
    lat: 28.086785,
    lon: -97.044617,
  },
  {
    Airport: "Arledge Field",
    County: "Haskell",
    FAA: "F56",
    Region: "West Texas",
    ITN_GA: 1600,
    "Itenerant Arrivals": 800,
    "Transient Arrivals (Visitors)": 267,
    "Visitors per Aircraft": 2.8,
    "Annual Visitors": 747,
    "Non-hub CS CY18 Enplanements": "",
    lat: 32.909874,
    lon: -99.736864,
  },
  {
    Airport: "Arlington Municipal Airport",
    County: "Tarrant",
    Region: "North Texas",
    ITN_GA: 34308,
    "Itenerant Arrivals": 17154,
    "Transient Arrivals (Visitors)": 5717,
    "Visitors per Aircraft": 2.7,
    "Annual Visitors": 15437,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "GKY",
    lat: 32.662426,
    lon: -97.093918,
  },
  {
    Airport: "Athens Municipal",
    County: "Henderson",
    Region: "East Texas",
    ITN_GA: 2900,
    "Itenerant Arrivals": 1450,
    "Transient Arrivals (Visitors)": 483,
    "Visitors per Aircraft": 2.8,
    "Annual Visitors": 1353,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "F44",
    lat: 32.163856,
    lon: -95.828357,
  },
  {
    Airport: "Austin-Bergstrom International",
    County: "Travis",
    Region: "Central Texas",
    ITN_GA: 47177,
    "Itenerant Arrivals": 23589,
    "Transient Arrivals (Visitors)": 7862,
    "Visitors per Aircraft": 3.7,
    "Annual Visitors": 29090,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "AUS",
    lat: 30.194535,
    lon: -97.669898,
  },
  {
    Airport: "Avenger Field",
    County: "Nolan",
    Region: "West Texas",
    ITN_GA: 1300,
    "Itenerant Arrivals": 650,
    "Transient Arrivals (Visitors)": 217,
    "Visitors per Aircraft": 2.8,
    "Annual Visitors": 607,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "SWW",
    lat: 32.467376,
    lon: -100.466558,
  },
  {
    Airport: "Bay City Regional Airport",
    County: "Matagorda",
    FAA: "BYY",
    Region: "Upper Gulf Coast",
    ITN_GA: 7000,
    "Itenerant Arrivals": 3500,
    "Transient Arrivals (Visitors)": 1167,
    "Visitors per Aircraft": 2.1,
    "Annual Visitors": 2450,
    "Non-hub CS CY18 Enplanements": "",
    lat: 28.973516,
    lon: -95.8608114,
  },
  {
    Airport: "Beaumont Municipal",
    County: "Jefferson",
    Region: "East Texas",
    ITN_GA: 500,
    "Itenerant Arrivals": 250,
    "Transient Arrivals (Visitors)": 83,
    "Visitors per Aircraft": 3.7,
    "Annual Visitors": 308,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "BMT",
    lat: 30.070445,
    lon: -94.215547,
  },
  {
    Airport: "Beeville Municipal",
    County: "Bee",
    Region: "South Texas",
    ITN_GA: 4320,
    "Itenerant Arrivals": 2160,
    "Transient Arrivals (Visitors)": 720,
    "Visitors per Aircraft": 2.8,
    "Annual Visitors": 2016,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "3R0",
    lat: 28.368645,
    lon: -97.797516,
  },
  {
    Airport: "Big Spring McMahon-Wrinkle",
    County: "Howard",
    Region: "West Texas",
    ITN_GA: 5600,
    "Itenerant Arrivals": 2800,
    "Transient Arrivals (Visitors)": 933,
    "Visitors per Aircraft": 2.1,
    "Annual Visitors": 1960,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "BPG",
    lat: 32.212615,
    lon: -101.521649,
  },
  {
    Airport: "Bishop Municipal Airport",
    County: "Potter",
    Region: "Panhandle",
    ITN_GA: 200,
    "Itenerant Arrivals": 100,
    "Transient Arrivals (Visitors)": 33,
    "Visitors per Aircraft": 2.8,
    "Annual Visitors": 93,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "07R",
    lat: 27.613648,
    lon: -97.752707,
  },
  {
    Airport: "Bowie Municipal Airport",
    County: "Montague",
    Region: "North Texas",
    ITN_GA: 1400,
    "Itenerant Arrivals": 700,
    "Transient Arrivals (Visitors)": 233,
    "Visitors per Aircraft": 2.8,
    "Annual Visitors": 653,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "0F2",
    lat: 33.601676,
    lon: -97.775568,
  },
  {
    Airport: "Brenham Municipal",
    County: "Washington",
    Region: "Central Texas",
    ITN_GA: 6900,
    "Itenerant Arrivals": 3450,
    "Transient Arrivals (Visitors)": 1150,
    "Visitors per Aircraft": 2.1,
    "Annual Visitors": 2415,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "11R",
    lat: 30.219005,
    lon: -96.374287,
  },
  {
    Airport: "Bridgeport Municipal Airport",
    County: "Wise",
    Region: "North Texas",
    ITN_GA: 5600,
    "Itenerant Arrivals": 2800,
    "Transient Arrivals (Visitors)": 933,
    "Visitors per Aircraft": 2.1,
    "Annual Visitors": 1960,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "1F9",
    lat: 33.175642,
    lon: -97.828776,
  },
  {
    Airport: "Brooks County",
    County: "Brooks",
    Region: "South Texas",
    ITN_GA: 5000,
    "Itenerant Arrivals": 2500,
    "Transient Arrivals (Visitors)": 833,
    "Visitors per Aircraft": 2.1,
    "Annual Visitors": 1750,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "T18",
    lat: 27.206835,
    lon: -98.121178,
  },
  {
    Airport: "Brownsville/South Padre Island International",
    County: "Cameron",
    Region: "South Texas",
    ITN_GA: 7402,
    "Itenerant Arrivals": 3701,
    "Transient Arrivals (Visitors)": 1234,
    "Visitors per Aircraft": 3.7,
    "Annual Visitors": 4564,
    "Non-hub CS CY18 Enplanements": 115711,
    FAA: "BRO",
    lat: 25.906834,
    lon: -97.425867,
  },
  {
    Airport: "Brownwood Regional",
    County: "Brown",
    Region: "West Texas",
    ITN_GA: 2500,
    "Itenerant Arrivals": 1250,
    "Transient Arrivals (Visitors)": 417,
    "Visitors per Aircraft": 2.8,
    "Annual Visitors": 1167,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "BWD",
    lat: 31.793166,
    lon: -98.956607,
  },
  {
    Airport: "Bruce Field",
    County: "Runnels",
    FAA: "E30",
    Region: "West Texas",
    ITN_GA: 1600,
    "Itenerant Arrivals": 800,
    "Transient Arrivals (Visitors)": 267,
    "Visitors per Aircraft": 2.8,
    "Annual Visitors": 747,
    "Non-hub CS CY18 Enplanements": "",
    lat: 31.674045,
    lon: -99.977538,
  },
  {
    Airport: "Burnet Municipal Airport",
    County: "Burnet",
    Region: "Central Texas",
    ITN_GA: 5100,
    "Itenerant Arrivals": 2550,
    "Transient Arrivals (Visitors)": 850,
    "Visitors per Aircraft": 2.1,
    "Annual Visitors": 1785,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "BMQ",
    lat: 30.738945,
    lon: -98.238588,
  },
  {
    Airport: "C David Campbell Field-Corsicana Municipal",
    County: "Navarro",
    Region: "North Texas",
    ITN_GA: 6300,
    "Itenerant Arrivals": 3150,
    "Transient Arrivals (Visitors)": 1050,
    "Visitors per Aircraft": 2.1,
    "Annual Visitors": 2205,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "CRS",
    lat: 32.027496,
    lon: -96.398047,
  },
  {
    Airport: "Caddo Mills Municipal",
    County: "Hunt",
    Region: "North Texas",
    ITN_GA: 11000,
    "Itenerant Arrivals": 5500,
    "Transient Arrivals (Visitors)": 1833,
    "Visitors per Aircraft": 2.7,
    "Annual Visitors": 4950,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "7F3",
    lat: 33.036226,
    lon: -96.243147,
  },
  {
    Airport: "Caldwell Municipal",
    County: "Burleson",
    Region: "Central Texas",
    ITN_GA: 2100,
    "Itenerant Arrivals": 1050,
    "Transient Arrivals (Visitors)": 350,
    "Visitors per Aircraft": 2.8,
    "Annual Visitors": 980,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "14R",
    lat: 30.515485,
    lon: -96.704097,
  },
  {
    Airport: "Calhoun County Airport",
    County: "Calhoun",
    Region: "South Texas",
    ITN_GA: 1800,
    "Itenerant Arrivals": 900,
    "Transient Arrivals (Visitors)": 300,
    "Visitors per Aircraft": 2.8,
    "Annual Visitors": 840,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "T97",
    lat: 28.654055,
    lon: -96.681317,
  },
  {
    Airport: "Cameron Municipal Airpark",
    County: "Milam",
    Region: "Central Texas",
    ITN_GA: 2000,
    "Itenerant Arrivals": 1000,
    "Transient Arrivals (Visitors)": 333,
    "Visitors per Aircraft": 2.8,
    "Annual Visitors": 933,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "T34",
    lat: 29.159695,
    lon: -95.450507,
  },
  {
    Airport: "Castroville Municipal",
    County: "Medina",
    Region: "South Texas",
    ITN_GA: 5200,
    "Itenerant Arrivals": 2600,
    "Transient Arrivals (Visitors)": 867,
    "Visitors per Aircraft": 2.1,
    "Annual Visitors": 1820,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "T89",
    lat: 29.341925,
    lon: -98.850908,
  },
  {
    Airport: "Center Municipal",
    County: "Shelby",
    Region: "East Texas",
    ITN_GA: 6100,
    "Itenerant Arrivals": 3050,
    "Transient Arrivals (Visitors)": 1017,
    "Visitors per Aircraft": 2.1,
    "Annual Visitors": 2135,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "F17",
    lat: 31.831586,
    lon: -94.156427,
  },
  {
    Airport: "Chambers County - Anahuac",
    County: "Chambers",
    Region: "Upper Gulf Coast",
    ITN_GA: 800,
    "Itenerant Arrivals": 400,
    "Transient Arrivals (Visitors)": 133,
    "Visitors per Aircraft": 2.8,
    "Annual Visitors": 373,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "T00",
    lat: 29.770005,
    lon: -94.663617,
  },
  {
    Airport: "Chambers County - Winnie Stowell",
    County: "Chambers",
    Region: "Upper Gulf Coast",
    ITN_GA: 1500,
    "Itenerant Arrivals": 750,
    "Transient Arrivals (Visitors)": 250,
    "Visitors per Aircraft": 2.8,
    "Annual Visitors": 700,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "T90",
    lat: 29.804115,
    lon: -94.431027,
  },
  {
    Airport: "Charles R Johnson",
    County: "Willacy",
    Region: "South Texas",
    ITN_GA: 100,
    "Itenerant Arrivals": 50,
    "Transient Arrivals (Visitors)": 17,
    "Visitors per Aircraft": 2.8,
    "Annual Visitors": 47,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "T05",
    lat: 26.562844,
    lon: -97.437767,
  },
  {
    Airport: "Cherokee County Airport",
    County: "Cherokee",
    Region: "East Texas",
    ITN_GA: 5000,
    "Itenerant Arrivals": 2500,
    "Transient Arrivals (Visitors)": 833,
    "Visitors per Aircraft": 2.1,
    "Annual Visitors": 1750,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "JSO",
    lat: 31.869346,
    lon: -95.217397,
  },
  {
    Airport: "Childress Municipal",
    County: "Childress",
    Region: "Panhandle",
    ITN_GA: 1400,
    "Itenerant Arrivals": 700,
    "Transient Arrivals (Visitors)": 233,
    "Visitors per Aircraft": 2.8,
    "Annual Visitors": 653,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "CDS",
    lat: 34.433726,
    lon: -100.288068,
  },
  {
    Airport: "Cisco Municipal",
    County: "Eastland",
    Region: "West Texas",
    ITN_GA: 360,
    "Itenerant Arrivals": 180,
    "Transient Arrivals (Visitors)": 60,
    "Visitors per Aircraft": 2.8,
    "Annual Visitors": 168,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "3F2",
    lat: 32.416851,
    lon: -98.997634,
  },
  {
    Airport: "City of Shamrock",
    County: "Wheeler",
    FAA: "2F1",
    Region: "Panhandle",
    ITN_GA: 300,
    "Itenerant Arrivals": 150,
    "Transient Arrivals (Visitors)": 50,
    "Visitors per Aircraft": 2.8,
    "Annual Visitors": 140,
    "Non-hub CS CY18 Enplanements": "",
    lat: 35.236166,
    lon: -100.184849,
  },
  {
    Airport: "City of Tulia/Swisher County Municipal",
    County: "Swisher",
    Region: "Panhandle",
    ITN_GA: 900,
    "Itenerant Arrivals": 450,
    "Transient Arrivals (Visitors)": 150,
    "Visitors per Aircraft": 2.8,
    "Annual Visitors": 420,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "Q06",
    lat: 34.566826,
    lon: -101.781469,
  },
  {
    Airport: "Clarksville/Red River County-J D Trissell Field",
    County: "Red River",
    Region: "East Texas",
    ITN_GA: 260,
    "Itenerant Arrivals": 130,
    "Transient Arrivals (Visitors)": 43,
    "Visitors per Aircraft": 2.8,
    "Annual Visitors": 121,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "7F6",
    lat: 33.593166,
    lon: -95.063567,
  },
  {
    Airport: "Cleburne Regional Airport",
    County: "Johnson",
    Region: "North Texas",
    ITN_GA: 8200,
    "Itenerant Arrivals": 4100,
    "Transient Arrivals (Visitors)": 1367,
    "Visitors per Aircraft": 2.1,
    "Annual Visitors": 2870,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "F18",
    lat: 32.353766,
    lon: -97.433758,
  },
  {
    Airport: "Cleveland Municipal",
    County: "Liberty",
    Region: "Upper Gulf Coast",
    ITN_GA: 4600,
    "Itenerant Arrivals": 2300,
    "Transient Arrivals (Visitors)": 767,
    "Visitors per Aircraft": 2.8,
    "Annual Visitors": 2146,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "6R3",
    lat: 30.356435,
    lon: -95.008017,
  },
  {
    Airport: "Clifton Municipal",
    County: "Bosque",
    Region: "West Texas",
    ITN_GA: 1600,
    "Itenerant Arrivals": 800,
    "Transient Arrivals (Visitors)": 267,
    "Visitors per Aircraft": 2.8,
    "Annual Visitors": 747,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "7F7",
    lat: 31.816826,
    lon: -97.566968,
  },
  {
    Airport: "Cochran County",
    County: "Cochran",
    Region: "West Texas",
    ITN_GA: 200,
    "Itenerant Arrivals": 100,
    "Transient Arrivals (Visitors)": 33,
    "Visitors per Aircraft": 2.8,
    "Annual Visitors": 93,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "F85",
    lat: 33.729266,
    lon: -102.733829,
  },
  {
    Airport: "Coleman Muncipal",
    County: "Coleman",
    Region: "West Texas",
    ITN_GA: 2500,
    "Itenerant Arrivals": 1250,
    "Transient Arrivals (Visitors)": 417,
    "Visitors per Aircraft": 2.8,
    "Annual Visitors": 1167,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "COM",
    lat: 31.841145,
    lon: -99.403618,
  },
  {
    Airport: "Colorado City Municipal Airport",
    County: "Mitchell",
    Region: "West Texas",
    ITN_GA: 300,
    "Itenerant Arrivals": 150,
    "Transient Arrivals (Visitors)": 50,
    "Visitors per Aircraft": 2.8,
    "Annual Visitors": 140,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "T88",
    lat: 32.469488,
    lon: -100.921673,
  },
  {
    Airport: "Comanche County - City",
    County: "Comanche",
    FAA: "MKN",
    Region: "West Texas",
    ITN_GA: 2300,
    "Itenerant Arrivals": 1150,
    "Transient Arrivals (Visitors)": 383,
    "Visitors per Aircraft": 2.8,
    "Annual Visitors": 1073,
    "Non-hub CS CY18 Enplanements": "",
    lat: 31.916815,
    lon: -98.600338,
  },
  {
    Airport: "Commerce Municipal",
    County: "Hunt",
    Region: "North Texas",
    ITN_GA: 4000,
    "Itenerant Arrivals": 2000,
    "Transient Arrivals (Visitors)": 667,
    "Visitors per Aircraft": 2.8,
    "Annual Visitors": 1866,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "2F7",
    lat: 33.292896,
    lon: -95.896427,
  },
  {
    Airport: "Conroe North-Houston Regional",
    County: "Montgomery",
    FAA: "CXO",
    Region: "Upper Gulf Coast",
    ITN_GA: 38966,
    "Itenerant Arrivals": 19483,
    "Transient Arrivals (Visitors)": 6494,
    "Visitors per Aircraft": 2.7,
    "Annual Visitors": 17533,
    "Non-hub CS CY18 Enplanements": "",
    lat: 30.351835,
    lon: -95.414477,
  },
  {
    Airport: "Corpus Christi International",
    County: "Nueces",
    Region: "South Texas",
    ITN_GA: 15515,
    "Itenerant Arrivals": 7758,
    "Transient Arrivals (Visitors)": 2586,
    "Visitors per Aircraft": 2.7,
    "Annual Visitors": 6981,
    "Non-hub CS CY18 Enplanements": 332499,
    FAA: "CRP",
    lat: 27.774515,
    lon: -97.502343,
  },
  {
    Airport: "Corsicana Muncipal Airport",
    County: "Navarro",
    FAA: "CRS",
    Region: "North Texas",
    ITN_GA: 6300,
    "Itenerant Arrivals": 3150,
    "Transient Arrivals (Visitors)": 1050,
    "Visitors per Aircraft": 2.1,
    "Annual Visitors": 2205,
    "Non-hub CS CY18 Enplanements": "",
    lat: 32.027496,
    lon: -96.398047,
  },
  {
    Airport: "Cotulla-La Salle County Airport",
    County: "La Salle",
    Region: "South Texas",
    ITN_GA: 6400,
    "Itenerant Arrivals": 3200,
    "Transient Arrivals (Visitors)": 1067,
    "Visitors per Aircraft": 2.1,
    "Annual Visitors": 2240,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "COT",
    lat: 28.454807,
    lon: -99.217722,
  },
  {
    Airport: "Coulter Field",
    County: "Brazos",
    FAA: "CFD",
    Region: "Central Texas",
    ITN_GA: 5400,
    "Itenerant Arrivals": 2700,
    "Transient Arrivals (Visitors)": 900,
    "Visitors per Aircraft": 2.1,
    "Annual Visitors": 1890,
    "Non-hub CS CY18 Enplanements": "",
    lat: 30.715695,
    lon: -96.331367,
  },
  {
    Airport: "Cox Field",
    County: "Lamar",
    Region: "East Texas",
    ITN_GA: 3552,
    "Itenerant Arrivals": 1776,
    "Transient Arrivals (Visitors)": 592,
    "Visitors per Aircraft": 2.8,
    "Annual Visitors": 1657,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "PRX",
    lat: 33.636616,
    lon: -95.450737,
  },
  {
    Airport: "Crane County",
    County: "Crane",
    Region: "West Texas",
    ITN_GA: 500,
    "Itenerant Arrivals": 250,
    "Transient Arrivals (Visitors)": 83,
    "Visitors per Aircraft": 2.8,
    "Annual Visitors": 233,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "E13",
    lat: 31.415145,
    lon: -102.362929,
  },
  {
    Airport: "Crosbyton Municipal Airport",
    County: "Crosby",
    Region: "West Texas",
    ITN_GA: 50,
    "Itenerant Arrivals": 25,
    "Transient Arrivals (Visitors)": 8,
    "Visitors per Aircraft": 2.8,
    "Annual Visitors": 23,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "8F3",
    lat: 33.623846,
    lon: -101.240849,
  },
  {
    Airport: "Crystal City Municipal",
    County: "Zavala",
    Region: "South Texas",
    ITN_GA: 0,
    "Itenerant Arrivals": 0,
    "Transient Arrivals (Visitors)": 0,
    "Visitors per Aircraft": 2.8,
    "Annual Visitors": 0,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "20R",
    lat: 28.696977,
    lon: -99.819562,
  },
  {
    Airport: "Cuero Muni",
    County: "De Witt",
    FAA: "T71",
    Region: "West Texas",
    ITN_GA: 60,
    "Itenerant Arrivals": 30,
    "Transient Arrivals (Visitors)": 10,
    "Visitors per Aircraft": 2.8,
    "Annual Visitors": 28,
    "Non-hub CS CY18 Enplanements": "",
    lat: 29.0877164,
    lon: -97.2721252,
  },
  {
    Airport: "Culberson County",
    County: "Culberson",
    Region: "West Texas",
    ITN_GA: 3000,
    "Itenerant Arrivals": 1500,
    "Transient Arrivals (Visitors)": 500,
    "Visitors per Aircraft": 2.8,
    "Annual Visitors": 1400,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "VHN",
    lat: 31.057845,
    lon: -104.783819,
  },
  {
    Airport: "Curtis Field",
    County: "McCulloch",
    FAA: "BBD",
    Region: "West Texas",
    ITN_GA: 2000,
    "Itenerant Arrivals": 1000,
    "Transient Arrivals (Visitors)": 333,
    "Visitors per Aircraft": 2.8,
    "Annual Visitors": 933,
    "Non-hub CS CY18 Enplanements": "",
    lat: 31.178175,
    lon: -99.324648,
  },
  {
    Airport: "Cypress River",
    County: "Marion",
    Region: "East Texas",
    ITN_GA: 800,
    "Itenerant Arrivals": 400,
    "Transient Arrivals (Visitors)": 133,
    "Visitors per Aircraft": 2.8,
    "Annual Visitors": 373,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "24F",
    lat: 32.745976,
    lon: -94.304367,
  },
  {
    Airport: "Dalhart Muni",
    County: "Dallam",
    Region: "Panhandle",
    ITN_GA: 2500,
    "Itenerant Arrivals": 1250,
    "Transient Arrivals (Visitors)": 417,
    "Visitors per Aircraft": 2.8,
    "Annual Visitors": 1167,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "DHT",
    lat: 36.022596,
    lon: -102.547289,
  },
  {
    Airport: "Dallas CBD Vertiport",
    County: "Dallas",
    FAA: "49T",
    Region: "North Texas",
    ITN_GA: 730,
    "Itenerant Arrivals": 365,
    "Transient Arrivals (Visitors)": 122,
    "Visitors per Aircraft": 2.8,
    "Annual Visitors": 341,
    "Non-hub CS CY18 Enplanements": "",
    lat: 32.7745135,
    lon: -96.8027993,
  },
  {
    Airport: "Dallas Executive",
    County: "Dallas",
    FAA: "RBD",
    Region: "North Texas",
    ITN_GA: 25923,
    "Itenerant Arrivals": 12962,
    "Transient Arrivals (Visitors)": 4320,
    "Visitors per Aircraft": 2.7,
    "Annual Visitors": 11664,
    "Non-hub CS CY18 Enplanements": "",
    lat: 32.680866,
    lon: -96.868198,
  },
  {
    Airport: "Dallas Love Field",
    County: "Dallas",
    Region: "North Texas",
    ITN_GA: 57532,
    "Itenerant Arrivals": 28766,
    "Transient Arrivals (Visitors)": 9588,
    "Visitors per Aircraft": 3.7,
    "Annual Visitors": 35475,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "DAL",
    lat: 32.847116,
    lon: -96.851778,
  },
  {
    Airport: "Dallas/Fort Worth International",
    County: "Tarrant",
    Region: "North Texas",
    ITN_GA: 6572,
    "Itenerant Arrivals": 3286,
    "Transient Arrivals (Visitors)": 1095,
    "Visitors per Aircraft": 3.7,
    "Annual Visitors": 4052,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "DFW",
    lat: 32.895956,
    lon: -97.037208,
  },
  {
    Airport: "Dan E Richards",
    County: "Cottle",
    Region: "North Texas",
    ITN_GA: 400,
    "Itenerant Arrivals": 200,
    "Transient Arrivals (Visitors)": 67,
    "Visitors per Aircraft": 2.8,
    "Annual Visitors": 187,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "3F6",
    lat: 34.027548,
    lon: -100.282782,
  },
  {
    Airport: "David Wayne Hooks Memorial",
    County: "Harris",
    Region: "Upper Gulf Coast",
    ITN_GA: 55843,
    "Itenerant Arrivals": 27922,
    "Transient Arrivals (Visitors)": 9306,
    "Visitors per Aircraft": 2.7,
    "Annual Visitors": 25127,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "DWH",
    lat: 30.061865,
    lon: -95.552787,
  },
  {
    Airport: "Decatur Muncipal Airport",
    County: "Wise",
    Region: "North Texas",
    ITN_GA: 4500,
    "Itenerant Arrivals": 2250,
    "Transient Arrivals (Visitors)": 750,
    "Visitors per Aircraft": 2.8,
    "Annual Visitors": 2100,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "8F7",
    lat: 33.254458,
    lon: -97.579664,
  },
  {
    Airport: "Del Rio International Airport",
    County: "Val Verde",
    Region: "South Texas",
    ITN_GA: 716,
    "Itenerant Arrivals": 358,
    "Transient Arrivals (Visitors)": 119,
    "Visitors per Aircraft": 2.8,
    "Annual Visitors": 334,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "DRT",
    lat: 29.364485,
    lon: -100.919088,
  },
  {
    Airport: "Dell City Municipal Airport",
    County: "Hudspeth",
    Region: "West Texas",
    ITN_GA: 200,
    "Itenerant Arrivals": 100,
    "Transient Arrivals (Visitors)": 33,
    "Visitors per Aircraft": 2.8,
    "Annual Visitors": 93,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "2E5",
    lat: 31.961785,
    lon: -105.196629,
  },
  {
    Airport: "Denton Enterprise Airport",
    County: "Denton",
    FAA: "DTO",
    Region: "North Texas",
    ITN_GA: 59281,
    "Itenerant Arrivals": 29641,
    "Transient Arrivals (Visitors)": 9879,
    "Visitors per Aircraft": 2.7,
    "Annual Visitors": 26674,
    "Non-hub CS CY18 Enplanements": "",
    lat: 33.200726,
    lon: -97.197988,
  },
  {
    Airport: "Denver City Airport",
    County: "Yoakum",
    Region: "West Texas",
    ITN_GA: 500,
    "Itenerant Arrivals": 250,
    "Transient Arrivals (Visitors)": 83,
    "Visitors per Aircraft": 2.8,
    "Annual Visitors": 233,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "E57",
    lat: 32.975105,
    lon: -102.842159,
  },
  {
    Airport: "Desoto Heliport",
    County: "Dallas",
    FAA: "73T",
    Region: "North Texas",
    ITN_GA: 0,
    "Itenerant Arrivals": 0,
    "Transient Arrivals (Visitors)": 0,
    "Visitors per Aircraft": 2.8,
    "Annual Visitors": 0,
    "Non-hub CS CY18 Enplanements": "",
    lat: 32.6325621,
    lon: -96.8567465,
  },
  {
    Airport: "Devine Municipal Airport",
    County: "Medina",
    Region: "South Texas",
    ITN_GA: 2500,
    "Itenerant Arrivals": 1250,
    "Transient Arrivals (Visitors)": 417,
    "Visitors per Aircraft": 2.8,
    "Annual Visitors": 1167,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "23R",
    lat: 29.137788,
    lon: -98.942873,
  },
  {
    Airport: "Dilley Airport",
    County: "Frio",
    Region: "South Texas",
    ITN_GA: 100,
    "Itenerant Arrivals": 50,
    "Transient Arrivals (Visitors)": 17,
    "Visitors per Aircraft": 2.8,
    "Annual Visitors": 47,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "24R",
    lat: 28.685655,
    lon: -99.187841,
  },
  {
    Airport: "Dimmit County Airport",
    County: "Dimmit",
    Region: "South Texas",
    ITN_GA: 700,
    "Itenerant Arrivals": 350,
    "Transient Arrivals (Visitors)": 117,
    "Visitors per Aircraft": 2.8,
    "Annual Visitors": 327,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "CZT",
    lat: 28.523227,
    lon: -99.827365,
  },
  {
    Airport: "Dimmit Municipal",
    County: "Dimmit",
    Region: "South Texas",
    ITN_GA: 400,
    "Itenerant Arrivals": 200,
    "Transient Arrivals (Visitors)": 67,
    "Visitors per Aircraft": 2.8,
    "Annual Visitors": 187,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "Q55",
    lat: 34.566746,
    lon: -102.322699,
  },
  {
    Airport: "Draughon-Miller Central Texas Regional Airport",
    County: "Bell",
    Region: "Central Texas",
    ITN_GA: 8400,
    "Itenerant Arrivals": 4200,
    "Transient Arrivals (Visitors)": 1400,
    "Visitors per Aircraft": 2.1,
    "Annual Visitors": 2940,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "TPL",
    lat: 31.152505,
    lon: -97.407788,
  },
  {
    Airport: "Dublin Muncipal Airport",
    County: "Erath",
    Region: "North Texas",
    ITN_GA: 50,
    "Itenerant Arrivals": 25,
    "Transient Arrivals (Visitors)": 8,
    "Visitors per Aircraft": 2.8,
    "Annual Visitors": 23,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "9F0",
    lat: 32.070009,
    lon: -98.326994,
  },
  {
    Airport: "Duval-Freer",
    County: "Duval",
    FAA: "T19",
    Region: "South Texas",
    ITN_GA: 100,
    "Itenerant Arrivals": 50,
    "Transient Arrivals (Visitors)": 17,
    "Visitors per Aircraft": 2.8,
    "Annual Visitors": 47,
    "Non-hub CS CY18 Enplanements": "",
    lat: 27.884936,
    lon: -98.59933,
  },
  {
    Airport: "Eagle Lake Regional",
    County: "Colorado",
    Region: "Upper Gulf Coast",
    ITN_GA: 2600,
    "Itenerant Arrivals": 1300,
    "Transient Arrivals (Visitors)": 433,
    "Visitors per Aircraft": 2.8,
    "Annual Visitors": 1213,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "ELA",
    lat: 29.603015,
    lon: -96.322487,
  },
  {
    Airport: "East Texas Regional",
    County: "Gregg",
    FAA: "GGG",
    Region: "East Texas",
    ITN_GA: 15502,
    "Itenerant Arrivals": 7751,
    "Transient Arrivals (Visitors)": 2583,
    "Visitors per Aircraft": 3.7,
    "Annual Visitors": 9559,
    "Non-hub CS CY18 Enplanements": 24588,
    lat: 32.384866,
    lon: -94.711717,
  },
  {
    Airport: "Easterwood Field",
    County: "Brazos",
    Region: "Central Texas",
    ITN_GA: 21690,
    "Itenerant Arrivals": 10845,
    "Transient Arrivals (Visitors)": 3615,
    "Visitors per Aircraft": 3.7,
    "Annual Visitors": 13374,
    "Non-hub CS CY18 Enplanements": 79309,
    FAA: "CLL",
    lat: 30.588595,
    lon: -96.363827,
  },
  {
    Airport: "Eastland Municipal",
    County: "Eastland",
    Region: "West Texas",
    ITN_GA: 4000,
    "Itenerant Arrivals": 2000,
    "Transient Arrivals (Visitors)": 667,
    "Visitors per Aircraft": 2.8,
    "Annual Visitors": 1866,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "ETN",
    lat: 32.415393,
    lon: -98.810602,
  },
  {
    Airport: "Edwards County Airport",
    County: "Edwards",
    FAA: "ECU",
    Region: "South Texas",
    ITN_GA: 400,
    "Itenerant Arrivals": 200,
    "Transient Arrivals (Visitors)": 67,
    "Visitors per Aircraft": 2.8,
    "Annual Visitors": 187,
    "Non-hub CS CY18 Enplanements": "",
    lat: 29.7314571,
    lon: -100.4412146,
  },
  {
    Airport: "El Paso Internatinal",
    County: "El Paso",
    Region: "West Texas",
    ITN_GA: 21005,
    "Itenerant Arrivals": 10503,
    "Transient Arrivals (Visitors)": 3500,
    "Visitors per Aircraft": 3.7,
    "Annual Visitors": 12952,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "ELP",
    lat: 31.806675,
    lon: -106.37782,
  },
  {
    Airport: "Eldorado Airport",
    County: "Schleicher",
    FAA: "27R",
    Region: "West Texas",
    ITN_GA: 500,
    "Itenerant Arrivals": 250,
    "Transient Arrivals (Visitors)": 83,
    "Visitors per Aircraft": 2.8,
    "Annual Visitors": 233,
    "Non-hub CS CY18 Enplanements": "",
    lat: 30.8679004,
    lon: -100.61309,
  },
  {
    Airport: "Ellington Field",
    County: "Harris",
    Region: "Upper Gulf Coast",
    ITN_GA: 41208,
    "Itenerant Arrivals": 20604,
    "Transient Arrivals (Visitors)": 6867,
    "Visitors per Aircraft": 2.7,
    "Annual Visitors": 18542,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "EFD",
    lat: 29.607335,
    lon: -95.158757,
  },
  {
    Airport: "Ennis Municipal",
    County: "Ellis",
    Region: "North Texas",
    ITN_GA: 900,
    "Itenerant Arrivals": 450,
    "Transient Arrivals (Visitors)": 150,
    "Visitors per Aircraft": 2.8,
    "Annual Visitors": 420,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "F41",
    lat: 32.329696,
    lon: -96.663897,
  },
  {
    Airport: "Fabens",
    County: "El Paso",
    Region: "West Texas",
    ITN_GA: 800,
    "Itenerant Arrivals": 400,
    "Transient Arrivals (Visitors)": 133,
    "Visitors per Aircraft": 2.8,
    "Annual Visitors": 373,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "E35",
    lat: 31.515675,
    lon: -106.14721,
  },
  {
    Airport: "Fayette Regional Air Center",
    County: "Fayette",
    FAA: "3T5",
    Region: "Central Texas",
    ITN_GA: 3100,
    "Itenerant Arrivals": 1550,
    "Transient Arrivals (Visitors)": 517,
    "Visitors per Aircraft": 2.8,
    "Annual Visitors": 1447,
    "Non-hub CS CY18 Enplanements": "",
    lat: 29.909539,
    lon: -96.9507872,
  },
  {
    Airport: "Fisher County Airport",
    County: "Fisher",
    Region: "West Texas",
    ITN_GA: 1200,
    "Itenerant Arrivals": 600,
    "Transient Arrivals (Visitors)": 200,
    "Visitors per Aircraft": 2.8,
    "Annual Visitors": 560,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "56F",
    lat: 32.825666,
    lon: -100.415398,
  },
  {
    Airport: "Floydada Municipal Airport",
    County: "Floyd",
    Region: "West Texas",
    ITN_GA: 2100,
    "Itenerant Arrivals": 1050,
    "Transient Arrivals (Visitors)": 350,
    "Visitors per Aircraft": 2.8,
    "Annual Visitors": 980,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "Q41",
    lat: 34.002306,
    lon: -101.330439,
  },
  {
    Airport: "Follett/Lipscomb County",
    County: "Lipscomb",
    Region: "Panhandle",
    ITN_GA: 100,
    "Itenerant Arrivals": 50,
    "Transient Arrivals (Visitors)": 17,
    "Visitors per Aircraft": 2.8,
    "Annual Visitors": 47,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "T93",
    lat: 36.440816,
    lon: -100.123839,
  },
  {
    Airport: "Fort Stockton/Pecos County",
    County: "Pecos",
    Region: "West Texas",
    ITN_GA: 1500,
    "Itenerant Arrivals": 750,
    "Transient Arrivals (Visitors)": 250,
    "Visitors per Aircraft": 2.8,
    "Annual Visitors": 700,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "FST",
    lat: 30.915675,
    lon: -102.916149,
  },
  {
    Airport: "Fox Stephens Field-Gilmer Municipal",
    County: "Upshur",
    Region: "East Texas",
    ITN_GA: 4400,
    "Itenerant Arrivals": 2200,
    "Transient Arrivals (Visitors)": 733,
    "Visitors per Aircraft": 2.8,
    "Annual Visitors": 2053,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "4F4",
    lat: 32.699006,
    lon: -94.948867,
  },
  {
    Airport: "Franklin County",
    County: "Franklin",
    Region: "East Texas",
    ITN_GA: 1800,
    "Itenerant Arrivals": 900,
    "Transient Arrivals (Visitors)": 300,
    "Visitors per Aircraft": 2.8,
    "Annual Visitors": 840,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "F53",
    lat: 33.215446,
    lon: -95.237447,
  },
  {
    Airport: "Ft. Worth Alliance",
    County: "Tarrant",
    Region: "North Texas",
    ITN_GA: 44782,
    "Itenerant Arrivals": 22391,
    "Transient Arrivals (Visitors)": 7463,
    "Visitors per Aircraft": 2.7,
    "Annual Visitors": 20150,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "AFW",
    lat: 32.987646,
    lon: -97.318818,
  },
  {
    Airport: "Ft. Worth Meacham",
    County: "Tarrant",
    Region: "North Texas",
    ITN_GA: 69878,
    "Itenerant Arrivals": 34939,
    "Transient Arrivals (Visitors)": 11645,
    "Visitors per Aircraft": 2.7,
    "Annual Visitors": 31442,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "FTW",
    lat: 32.819786,
    lon: -97.362448,
  },
  {
    Airport: "Ft. Worth Spinks",
    County: "Tarrant",
    Region: "North Texas",
    ITN_GA: 26327,
    "Itenerant Arrivals": 13164,
    "Transient Arrivals (Visitors)": 4387,
    "Visitors per Aircraft": 2.7,
    "Annual Visitors": 11846,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "FWS",
    lat: 32.565236,
    lon: -97.308088,
  },
  {
    Airport: "Gaines County Airport",
    County: "Gaines",
    Region: "West Texas",
    ITN_GA: 4000,
    "Itenerant Arrivals": 2000,
    "Transient Arrivals (Visitors)": 667,
    "Visitors per Aircraft": 2.8,
    "Annual Visitors": 1866,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "31F",
    lat: 32.675355,
    lon: -102.652689,
  },
  {
    Airport: "Gainesville Municipal",
    County: "Cooke",
    Region: "North Texas",
    ITN_GA: 3800,
    "Itenerant Arrivals": 1900,
    "Transient Arrivals (Visitors)": 633,
    "Visitors per Aircraft": 2.8,
    "Annual Visitors": 1773,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "GLE",
    lat: 33.65032,
    lon: -97.197279,
  },
  {
    Airport: "Garland/DFW Heliport",
    County: "Dallas",
    FAA: "T57",
    Region: "North Texas",
    ITN_GA: 1400,
    "Itenerant Arrivals": 700,
    "Transient Arrivals (Visitors)": 233,
    "Visitors per Aircraft": 2.8,
    "Annual Visitors": 653,
    "Non-hub CS CY18 Enplanements": "",
    lat: 32.887778,
    lon: -96.683611,
  },
  {
    Airport: "Garner Field Airport",
    County: "Uvalde",
    FAA: "UVA",
    Region: "South Texas",
    ITN_GA: 4000,
    "Itenerant Arrivals": 2000,
    "Transient Arrivals (Visitors)": 667,
    "Visitors per Aircraft": 2.8,
    "Annual Visitors": 1866,
    "Non-hub CS CY18 Enplanements": "",
    lat: 29.211355,
    lon: -99.743588,
  },
  {
    Airport: "Gatesville Municipal Airport",
    County: "Coryell",
    FAA: "GOP",
    Region: "Central Texas",
    ITN_GA: 200,
    "Itenerant Arrivals": 100,
    "Transient Arrivals (Visitors)": 33,
    "Visitors per Aircraft": 2.8,
    "Annual Visitors": 93,
    "Non-hub CS CY18 Enplanements": "",
    lat: 31.4209316,
    lon: -97.7985925,
  },
  {
    Airport: "George Bush Intercontinental/Houston",
    County: "Harris",
    Region: "Upper Gulf Coast",
    ITN_GA: 10530,
    "Itenerant Arrivals": 5265,
    "Transient Arrivals (Visitors)": 1755,
    "Visitors per Aircraft": 3.7,
    "Annual Visitors": 6493,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "IAH",
    lat: 29.980475,
    lon: -95.339727,
  },
  {
    Airport: "Georgetown Municipal",
    County: "Williamson",
    Region: "Central Texas",
    ITN_GA: 48270,
    "Itenerant Arrivals": 24135,
    "Transient Arrivals (Visitors)": 8044,
    "Visitors per Aircraft": 2.7,
    "Annual Visitors": 21719,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "GTU",
    lat: 30.678815,
    lon: -97.679388,
  },
  {
    Airport: "Giddings-Lee County Airport",
    County: "Lee",
    Region: "Central Texas",
    ITN_GA: 4300,
    "Itenerant Arrivals": 2150,
    "Transient Arrivals (Visitors)": 717,
    "Visitors per Aircraft": 2.8,
    "Annual Visitors": 2006,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "62H",
    lat: 30.169275,
    lon: -96.980017,
  },
  {
    Airport: "Gillespie County Airport",
    County: "Gillespie",
    Region: "South Texas",
    ITN_GA: 7300,
    "Itenerant Arrivals": 3650,
    "Transient Arrivals (Visitors)": 1217,
    "Visitors per Aircraft": 2.1,
    "Annual Visitors": 2555,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "T82",
    lat: 30.243695,
    lon: -98.909538,
  },
  {
    Airport: "Gladewater Municipal Airport",
    County: "Gregg",
    Region: "East Texas",
    ITN_GA: 4600,
    "Itenerant Arrivals": 2300,
    "Transient Arrivals (Visitors)": 767,
    "Visitors per Aircraft": 2.8,
    "Annual Visitors": 2146,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "07F",
    lat: 32.528846,
    lon: -94.971757,
  },
  {
    Airport: "Goldwaite Airport",
    County: "Mills",
    FAA: "T37",
    Region: "Central Texas",
    ITN_GA: 900,
    "Itenerant Arrivals": 450,
    "Transient Arrivals (Visitors)": 150,
    "Visitors per Aircraft": 2.8,
    "Annual Visitors": 420,
    "Non-hub CS CY18 Enplanements": "",
    lat: 31.4290667,
    lon: -98.6083556,
  },
  {
    Airport: "Graham Municipal Airport",
    County: "Young",
    Region: "North Texas",
    ITN_GA: 1200,
    "Itenerant Arrivals": 600,
    "Transient Arrivals (Visitors)": 200,
    "Visitors per Aircraft": 2.8,
    "Annual Visitors": 560,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "E15",
    lat: 33.113099,
    lon: -98.55407,
  },
  {
    Airport: "Granbury Regional Airport",
    County: "Hood",
    Region: "North Texas",
    ITN_GA: 8300,
    "Itenerant Arrivals": 4150,
    "Transient Arrivals (Visitors)": 1383,
    "Visitors per Aircraft": 2.1,
    "Annual Visitors": 2905,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "F55",
    lat: 32.444426,
    lon: -97.816958,
  },
  {
    Airport: "Grand Prairie Municipal",
    County: "Tarrant",
    Region: "North Texas",
    ITN_GA: 40790,
    "Itenerant Arrivals": 20395,
    "Transient Arrivals (Visitors)": 6798,
    "Visitors per Aircraft": 2.7,
    "Annual Visitors": 18354,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "GPM",
    lat: 32.698586,
    lon: -97.046538,
  },
  {
    Airport: "Greater Morris County",
    County: "Morris",
    Region: "East Texas",
    ITN_GA: 50,
    "Itenerant Arrivals": 25,
    "Transient Arrivals (Visitors)": 8,
    "Visitors per Aircraft": 2.8,
    "Annual Visitors": 23,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "8F5",
    lat: 33.126796,
    lon: -94.698827,
  },
  {
    Airport: "Greenville Municipal",
    County: "Hunt",
    FAA: "GVT",
    Region: "North Texas",
    ITN_GA: 4253,
    "Itenerant Arrivals": 2127,
    "Transient Arrivals (Visitors)": 709,
    "Visitors per Aircraft": 2.8,
    "Annual Visitors": 1985,
    "Non-hub CS CY18 Enplanements": "",
    lat: 33.067846,
    lon: -96.065337,
  },
  {
    Airport: "Groveton-Trinity County",
    County: "Trinity",
    Region: "East Texas",
    ITN_GA: 600,
    "Itenerant Arrivals": 300,
    "Transient Arrivals (Visitors)": 100,
    "Visitors per Aircraft": 2.8,
    "Annual Visitors": 280,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "33R",
    lat: 31.084915,
    lon: -95.164107,
  },
  {
    Airport: "Gruver Municipal",
    County: "Hansford",
    Region: "Panhandle",
    ITN_GA: 100,
    "Itenerant Arrivals": 50,
    "Transient Arrivals (Visitors)": 17,
    "Visitors per Aircraft": 2.8,
    "Annual Visitors": 47,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "E19",
    lat: 36.233736,
    lon: -101.432199,
  },
  {
    Airport: "H H Coffield Regional",
    County: "Milam",
    FAA: "RCK",
    Region: "Central Texas",
    ITN_GA: 1200,
    "Itenerant Arrivals": 600,
    "Transient Arrivals (Visitors)": 200,
    "Visitors per Aircraft": 2.8,
    "Annual Visitors": 560,
    "Non-hub CS CY18 Enplanements": "",
    lat: 30.6339185,
    lon: -96.9911744,
  },
  {
    Airport: "Hale County",
    County: "Hale",
    Region: "West Texas",
    ITN_GA: 2650,
    "Itenerant Arrivals": 1325,
    "Transient Arrivals (Visitors)": 442,
    "Visitors per Aircraft": 2.8,
    "Annual Visitors": 1237,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "PVW",
    lat: 34.168156,
    lon: -101.717349,
  },
  {
    Airport: "Hallettsville Muni",
    County: "Lavaca",
    Region: "South Texas",
    ITN_GA: 700,
    "Itenerant Arrivals": 350,
    "Transient Arrivals (Visitors)": 117,
    "Visitors per Aircraft": 2.8,
    "Annual Visitors": 327,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "34R",
    lat: 29.389965,
    lon: -96.956067,
  },
  {
    Airport: "Hall-Miller Municipal",
    County: "Cass",
    Region: "East Texas",
    ITN_GA: 5000,
    "Itenerant Arrivals": 2500,
    "Transient Arrivals (Visitors)": 833,
    "Visitors per Aircraft": 2.1,
    "Annual Visitors": 1750,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "ATA",
    lat: 33.101816,
    lon: -94.195337,
  },
  {
    Airport: "Hamilton Municipal",
    County: "Hamilton",
    Region: "Central Texas",
    ITN_GA: 955,
    "Itenerant Arrivals": 478,
    "Transient Arrivals (Visitors)": 159,
    "Visitors per Aircraft": 2.8,
    "Annual Visitors": 446,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "MNZ",
    lat: 31.665935,
    lon: -98.148648,
  },
  {
    Airport: "Harrison County Airport",
    County: "Harrison",
    Region: "East Texas",
    ITN_GA: 4000,
    "Itenerant Arrivals": 2000,
    "Transient Arrivals (Visitors)": 667,
    "Visitors per Aircraft": 2.8,
    "Annual Visitors": 1866,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "ASL",
    lat: 32.520506,
    lon: -94.307787,
  },
  {
    Airport: "Harrison Field",
    County: "Knox",
    Region: "West Texas",
    ITN_GA: 4000,
    "Itenerant Arrivals": 2000,
    "Transient Arrivals (Visitors)": 667,
    "Visitors per Aircraft": 2.8,
    "Annual Visitors": 1866,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "ASL",
    lat: 32.520506,
    lon: -94.307787,
  },
  {
    Airport: "Haskell Municipal Airport",
    County: "Haskell",
    Region: "West Texas",
    ITN_GA: 500,
    "Itenerant Arrivals": 250,
    "Transient Arrivals (Visitors)": 83,
    "Visitors per Aircraft": 2.8,
    "Annual Visitors": 233,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "15F",
    lat: 33.191566,
    lon: -99.717938,
  },
  {
    Airport: "Hawthorne Field",
    County: "Hardin",
    Region: "East Texas",
    ITN_GA: 1100,
    "Itenerant Arrivals": 550,
    "Transient Arrivals (Visitors)": 183,
    "Visitors per Aircraft": 2.8,
    "Annual Visitors": 513,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "45R",
    lat: 30.336345,
    lon: -94.257547,
  },
  {
    Airport: "Hearne Municipal",
    County: "Robertson",
    Region: "Central Texas",
    ITN_GA: 800,
    "Itenerant Arrivals": 400,
    "Transient Arrivals (Visitors)": 133,
    "Visitors per Aircraft": 2.8,
    "Annual Visitors": 373,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "T72",
    lat: 30.871835,
    lon: -96.622237,
  },
  {
    Airport: "Hemphill County",
    County: "Hemphill",
    Region: "Panhandle",
    ITN_GA: 4400,
    "Itenerant Arrivals": 2200,
    "Transient Arrivals (Visitors)": 733,
    "Visitors per Aircraft": 2.8,
    "Annual Visitors": 2053,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "HHF",
    lat: 35.895316,
    lon: -100.403649,
  },
  {
    Airport: "Hereford Municipal",
    County: "Deaf Smith",
    Region: "Panhandle",
    ITN_GA: 2600,
    "Itenerant Arrivals": 1300,
    "Transient Arrivals (Visitors)": 433,
    "Visitors per Aircraft": 2.8,
    "Annual Visitors": 1213,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "HRX",
    lat: 34.857626,
    lon: -102.327209,
  },
  {
    Airport: "Higgs/Libscomb County",
    County: "Lipscomb",
    FAA: "1X1",
    Region: "Panhandle",
    ITN_GA: 100,
    "Itenerant Arrivals": 50,
    "Transient Arrivals (Visitors)": 17,
    "Visitors per Aircraft": 2.8,
    "Annual Visitors": 47,
    "Non-hub CS CY18 Enplanements": "",
    lat: 36.105906,
    lon: -100.025719,
  },
  {
    Airport: "Hillsboro Municipal Airport",
    County: "Hill",
    Region: "Central Texas",
    ITN_GA: 2700,
    "Itenerant Arrivals": 1350,
    "Transient Arrivals (Visitors)": 450,
    "Visitors per Aircraft": 2.8,
    "Annual Visitors": 1260,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "INJ",
    lat: 32.083496,
    lon: -97.097238,
  },
  {
    Airport: "Houston County",
    County: "Houston",
    Region: "East Texas",
    ITN_GA: 2200,
    "Itenerant Arrivals": 1100,
    "Transient Arrivals (Visitors)": 367,
    "Visitors per Aircraft": 2.8,
    "Annual Visitors": 1027,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "T56",
    lat: 31.306966,
    lon: -95.403837,
  },
  {
    Airport: "Houston Southwest",
    County: "Harris",
    FAA: "AXH",
    Region: "Upper Gulf Coast",
    ITN_GA: 13200,
    "Itenerant Arrivals": 6600,
    "Transient Arrivals (Visitors)": 2200,
    "Visitors per Aircraft": 2.7,
    "Annual Visitors": 5939,
    "Non-hub CS CY18 Enplanements": "",
    lat: 29.506145,
    lon: -95.476927,
  },
  {
    Airport: "Huntsville Municipal",
    County: "Walker",
    Region: "Upper Gulf Coast",
    ITN_GA: 6300,
    "Itenerant Arrivals": 3150,
    "Transient Arrivals (Visitors)": 1050,
    "Visitors per Aircraft": 2.1,
    "Annual Visitors": 2205,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "UTS",
    lat: 30.746895,
    lon: -95.587177,
  },
  {
    Airport: "Hutchinson County",
    County: "Hutchinson",
    Region: "Panhandle",
    ITN_GA: 2000,
    "Itenerant Arrivals": 1000,
    "Transient Arrivals (Visitors)": 333,
    "Visitors per Aircraft": 2.8,
    "Annual Visitors": 933,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "BGD",
    lat: 35.700046,
    lon: -101.394059,
  },
  {
    Airport: "Jack Brooks Regional",
    County: "Jefferson",
    FAA: "BPT",
    Region: "East Texas",
    ITN_GA: 10143,
    "Itenerant Arrivals": 5072,
    "Transient Arrivals (Visitors)": 1690,
    "Visitors per Aircraft": 2.7,
    "Annual Visitors": 4564,
    "Non-hub CS CY18 Enplanements": 27694,
    lat: 29.950835,
    lon: -94.020697,
  },
  {
    Airport: "Jacksboro Municipal",
    County: "Jack",
    Region: "North Texas",
    ITN_GA: 500,
    "Itenerant Arrivals": 250,
    "Transient Arrivals (Visitors)": 83,
    "Visitors per Aircraft": 2.8,
    "Annual Visitors": 233,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "21F",
    lat: 33.227869,
    lon: -98.143328,
  },
  {
    Airport: "Jackson County",
    County: "Jackson",
    Region: "South Texas",
    ITN_GA: 2500,
    "Itenerant Arrivals": 1250,
    "Transient Arrivals (Visitors)": 417,
    "Visitors per Aircraft": 2.8,
    "Annual Visitors": 1167,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "26R",
    lat: 29.001015,
    lon: -96.581957,
  },
  {
    Airport: "Jasper County-Bell Field",
    County: "Jasper",
    Region: "East Texas",
    ITN_GA: 4000,
    "Itenerant Arrivals": 2000,
    "Transient Arrivals (Visitors)": 667,
    "Visitors per Aircraft": 2.8,
    "Annual Visitors": 1866,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "JAS",
    lat: 30.885715,
    lon: -94.034947,
  },
  {
    Airport: "Jim Hogg County",
    County: "Jim Hogg",
    Region: "South Texas",
    ITN_GA: 350,
    "Itenerant Arrivals": 175,
    "Transient Arrivals (Visitors)": 58,
    "Visitors per Aircraft": 2.8,
    "Annual Visitors": 163,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "HBV",
    lat: 27.349565,
    lon: -98.736978,
  },
  {
    Airport: "Jones Field Airport",
    County: "Fannin",
    Region: "North Texas",
    ITN_GA: 3400,
    "Itenerant Arrivals": 1700,
    "Transient Arrivals (Visitors)": 567,
    "Visitors per Aircraft": 2.8,
    "Annual Visitors": 1587,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "F00",
    lat: 33.611726,
    lon: -96.179397,
  },
  {
    Airport: "Karnes County",
    County: "Karnes",
    Region: "South Texas",
    ITN_GA: 1000,
    "Itenerant Arrivals": 500,
    "Transient Arrivals (Visitors)": 167,
    "Visitors per Aircraft": 2.8,
    "Annual Visitors": 467,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "2R9",
    lat: 28.827023,
    lon: -97.864791,
  },
  {
    Airport: "Kelly Field",
    County: "Bexar",
    FAA: "SKF",
    Region: "South Texas",
    ITN_GA: 800,
    "Itenerant Arrivals": 400,
    "Transient Arrivals (Visitors)": 133,
    "Visitors per Aircraft": 2.8,
    "Annual Visitors": 373,
    "Non-hub CS CY18 Enplanements": "",
    lat: 29.3842333,
    lon: -98.5811167,
  },

  {
    Airport: "Kent County",
    County: "Kent",
    Region: "West Texas",
    ITN_GA: 200,
    "Itenerant Arrivals": 100,
    "Transient Arrivals (Visitors)": 33,
    "Visitors per Aircraft": 2.8,
    "Annual Visitors": 93,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "22F",
    lat: 33.229266,
    lon: -100.568738,
  },
  {
    Airport: "Kerrville-Kerr County",
    County: "Kerr",
    FAA: "ERV",
    Region: "South Texas",
    ITN_GA: 40000,
    "Itenerant Arrivals": 20000,
    "Transient Arrivals (Visitors)": 6666,
    "Visitors per Aircraft": 2.7,
    "Annual Visitors": 17998,
    "Non-hub CS CY18 Enplanements": "",
    lat: 29.976745,
    lon: -99.085688,
  },
  {
    Airport: "Kickapoo Downtown",
    County: "Wichita",
    Region: "North Texas",
    ITN_GA: 8400,
    "Itenerant Arrivals": 4200,
    "Transient Arrivals (Visitors)": 1400,
    "Visitors per Aircraft": 2.1,
    "Annual Visitors": 2940,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "T47",
    lat: 33.861226,
    lon: -98.490448,
  },
  {
    Airport: "Killeen-Ft. Hood Regional",
    County: "Bell",
    FAA: "GRK",
    Region: "Central Texas",
    ITN_GA: 60,
    "Itenerant Arrivals": 30,
    "Transient Arrivals (Visitors)": 10,
    "Visitors per Aircraft": 3.7,
    "Annual Visitors": 37,
    "Non-hub CS CY18 Enplanements": 154328,
    lat: 31.06725,
    lon: -97.8289167,
  },
  {
    Airport: "Killeen-Skylark Field",
    County: "Bell",
    FAA: "ILE",
    Region: "Central Texas",
    ITN_GA: 7000,
    "Itenerant Arrivals": 3500,
    "Transient Arrivals (Visitors)": 1167,
    "Visitors per Aircraft": 2.1,
    "Annual Visitors": 2450,
    "Non-hub CS CY18 Enplanements": "",
    lat: 31.085835,
    lon: -97.686508,
  },
  {
    Airport: "Kimble County",
    County: "Kimble",
    Region: "West Texas",
    ITN_GA: 2000,
    "Itenerant Arrivals": 1000,
    "Transient Arrivals (Visitors)": 333,
    "Visitors per Aircraft": 2.8,
    "Annual Visitors": 933,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "JCT",
    lat: 30.511265,
    lon: -99.763468,
  },
  {
    Airport: "Kirbyville Airport",
    County: "Jasper",
    Region: "East Texas",
    ITN_GA: 200,
    "Itenerant Arrivals": 100,
    "Transient Arrivals (Visitors)": 33,
    "Visitors per Aircraft": 2.8,
    "Annual Visitors": 93,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "T12",
    lat: 30.646565,
    lon: -93.914907,
  },
  {
    Airport: "Kleberg County Airport",
    County: "Kleberg",
    Region: "South Texas",
    ITN_GA: 30,
    "Itenerant Arrivals": 15,
    "Transient Arrivals (Visitors)": 5,
    "Visitors per Aircraft": 2.8,
    "Annual Visitors": 14,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "T80",
    lat: 27.550865,
    lon: -98.030928,
  },
  {
    Airport: "La Porte Municipal",
    County: "Harris",
    Region: "Upper Gulf Coast",
    ITN_GA: 9683,
    "Itenerant Arrivals": 4842,
    "Transient Arrivals (Visitors)": 1614,
    "Visitors per Aircraft": 2.1,
    "Annual Visitors": 3389,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "T41",
    lat: 29.669255,
    lon: -95.064197,
  },
  {
    Airport: "Lago Vista Rusty Allen Airport",
    County: "Travis",
    Region: "Central Texas",
    ITN_GA: 9500,
    "Itenerant Arrivals": 4750,
    "Transient Arrivals (Visitors)": 1583,
    "Visitors per Aircraft": 2.1,
    "Annual Visitors": 3325,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "5R3",
    lat: 30.498595,
    lon: -97.969478,
  },
  {
    Airport: "Lamesa Municipal Airport",
    County: "Dawson",
    Region: "West Texas",
    ITN_GA: 3400,
    "Itenerant Arrivals": 1700,
    "Transient Arrivals (Visitors)": 567,
    "Visitors per Aircraft": 2.8,
    "Annual Visitors": 1587,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "2F5",
    lat: 32.756286,
    lon: -101.919479,
  },
  {
    Airport: "Lampasas Municipal Airport",
    County: "Lampasas",
    Region: "Central Texas",
    ITN_GA: 2700,
    "Itenerant Arrivals": 1350,
    "Transient Arrivals (Visitors)": 450,
    "Visitors per Aircraft": 2.8,
    "Annual Visitors": 1260,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "T28",
    lat: 31.106614,
    lon: -98.19669,
  },
  {
    Airport: "Lancaster Regional",
    County: "Dallas",
    Region: "North Texas",
    ITN_GA: 28020,
    "Itenerant Arrivals": 14010,
    "Transient Arrivals (Visitors)": 4670,
    "Visitors per Aircraft": 2.7,
    "Annual Visitors": 12608,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "LNC",
    lat: 32.579196,
    lon: -96.719057,
  },
  {
    Airport: "Laredo International",
    County: "Webb",
    Region: "South Texas",
    ITN_GA: 16360,
    "Itenerant Arrivals": 8180,
    "Transient Arrivals (Visitors)": 2726,
    "Visitors per Aircraft": 3.7,
    "Annual Visitors": 10088,
    "Non-hub CS CY18 Enplanements": 95858,
    FAA: "LRD",
    lat: 27.543745,
    lon: -99.461548,
  },
  {
    Airport: "Levelland Municipal",
    County: "Hockley",
    Region: "West Texas",
    ITN_GA: 4200,
    "Itenerant Arrivals": 2100,
    "Transient Arrivals (Visitors)": 700,
    "Visitors per Aircraft": 2.8,
    "Annual Visitors": 1960,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "Q24",
    lat: 33.549816,
    lon: -102.372739,
  },
  {
    Airport: "Liberty Municipal",
    County: "Gregg",
    Region: "East Texas",
    ITN_GA: 3640,
    "Itenerant Arrivals": 1820,
    "Transient Arrivals (Visitors)": 607,
    "Visitors per Aircraft": 2.8,
    "Annual Visitors": 1698,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "T78",
    lat: 30.077815,
    lon: -94.698567,
  },
  {
    Airport: "Live Oak County",
    County: "Live Oak",
    Region: "South Texas",
    ITN_GA: 1100,
    "Itenerant Arrivals": 550,
    "Transient Arrivals (Visitors)": 183,
    "Visitors per Aircraft": 2.8,
    "Annual Visitors": 513,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "8T6",
    lat: 28.366533,
    lon: -98.118919,
  },
  {
    Airport: "Livingston Municipal",
    County: "Polk",
    Region: "East Texas",
    ITN_GA: 2300,
    "Itenerant Arrivals": 1150,
    "Transient Arrivals (Visitors)": 383,
    "Visitors per Aircraft": 2.8,
    "Annual Visitors": 1073,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "00R",
    lat: 30.685865,
    lon: -95.017937,
  },
  {
    Airport: "Llano Municipal Airport",
    County: "Llano",
    Region: "Central Texas",
    ITN_GA: 5000,
    "Itenerant Arrivals": 2500,
    "Transient Arrivals (Visitors)": 833,
    "Visitors per Aircraft": 2.1,
    "Annual Visitors": 1750,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "6R9",
    lat: 30.784505,
    lon: -98.660258,
  },
  {
    Airport: "Lockhart Municipal",
    County: "Caldwell",
    Region: "Central Texas",
    ITN_GA: 3800,
    "Itenerant Arrivals": 1900,
    "Transient Arrivals (Visitors)": 633,
    "Visitors per Aircraft": 2.8,
    "Annual Visitors": 1773,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "50R",
    lat: 29.850335,
    lon: -97.672428,
  },
  {
    Airport: "Lubbock Preston Smith International",
    County: "Lubbock",
    Region: "West Texas",
    ITN_GA: 25515,
    "Itenerant Arrivals": 12758,
    "Transient Arrivals (Visitors)": 4252,
    "Visitors per Aircraft": 3.7,
    "Annual Visitors": 15733,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "LBB",
    lat: 33.663646,
    lon: -101.822789,
  },
  {
    Airport: "Madisonville Municipal",
    County: "Madison",
    Region: "Central Texas",
    ITN_GA: 1000,
    "Itenerant Arrivals": 500,
    "Transient Arrivals (Visitors)": 167,
    "Visitors per Aircraft": 2.8,
    "Annual Visitors": 467,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "51R",
    lat: 30.912835,
    lon: -95.952007,
  },
  {
    Airport: "Major Samuel B Cornelius Field",
    County: "Hansford",
    Region: "Panhandle",
    ITN_GA: 1100,
    "Itenerant Arrivals": 550,
    "Transient Arrivals (Visitors)": 183,
    "Visitors per Aircraft": 2.8,
    "Annual Visitors": 513,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "E42",
    lat: 36.221006,
    lon: -101.194509,
  },
  {
    Airport: "Marfa Municipal",
    County: "Presidio",
    Region: "West Texas",
    ITN_GA: 2000,
    "Itenerant Arrivals": 1000,
    "Transient Arrivals (Visitors)": 333,
    "Visitors per Aircraft": 2.8,
    "Annual Visitors": 933,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "MRF",
    lat: 30.371475,
    lon: -104.016699,
  },
  {
    Airport: "Marian Airpark",
    County: "Collingsworth",
    Region: "Panhandle",
    ITN_GA: 1000,
    "Itenerant Arrivals": 500,
    "Transient Arrivals (Visitors)": 167,
    "Visitors per Aircraft": 2.8,
    "Annual Visitors": 467,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "F06",
    lat: 34.845616,
    lon: -100.195959,
  },
  {
    Airport: "Marlin",
    County: "Falls",
    Region: "Central Texas",
    ITN_GA: 300,
    "Itenerant Arrivals": 150,
    "Transient Arrivals (Visitors)": 50,
    "Visitors per Aircraft": 2.8,
    "Annual Visitors": 140,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "T15",
    lat: 31.341112,
    lon: -96.852833,
  },
  {
    Airport: "Mason County Airport",
    County: "Mason",
    Region: "West Texas",
    ITN_GA: 1200,
    "Itenerant Arrivals": 600,
    "Transient Arrivals (Visitors)": 200,
    "Visitors per Aircraft": 2.8,
    "Annual Visitors": 560,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "T92",
    lat: 30.732235,
    lon: -99.184318,
  },
  {
    Airport: "Maverick County Memorial International",
    County: "Maverick",
    Region: "West Texas",
    ITN_GA: 1100,
    "Itenerant Arrivals": 550,
    "Transient Arrivals (Visitors)": 183,
    "Visitors per Aircraft": 2.8,
    "Annual Visitors": 513,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "5T9",
    lat: 28.815846,
    lon: -100.496,
  },
  {
    Airport: "McAllen Miller International",
    County: "Hidalgo",
    Region: "South Texas",
    ITN_GA: 25958,
    "Itenerant Arrivals": 12979,
    "Transient Arrivals (Visitors)": 4326,
    "Visitors per Aircraft": 3.7,
    "Annual Visitors": 16006,
    "Non-hub CS CY18 Enplanements": 347440,
    FAA: "MFE",
    lat: 26.175834,
    lon: -98.238618,
  },
  {
    Airport: "McCampbell-Porter Airport",
    County: "San Patricio",
    Region: "South Texas",
    ITN_GA: 10000,
    "Itenerant Arrivals": 5000,
    "Transient Arrivals (Visitors)": 1667,
    "Visitors per Aircraft": 2.7,
    "Annual Visitors": 4500,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "TFP",
    lat: 27.9130278,
    lon: -97.2115,
  },
  {
    Airport: "McGregor Executive",
    County: "McLennan",
    Region: "Central Texas",
    ITN_GA: 9000,
    "Itenerant Arrivals": 4500,
    "Transient Arrivals (Visitors)": 1500,
    "Visitors per Aircraft": 2.1,
    "Annual Visitors": 3150,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "PWG",
    lat: 31.484925,
    lon: -97.316538,
  },
  {
    Airport: "McKinley Field",
    County: "Frio",
    Region: "South Texas",
    ITN_GA: 600,
    "Itenerant Arrivals": 300,
    "Transient Arrivals (Visitors)": 100,
    "Visitors per Aircraft": 2.8,
    "Annual Visitors": 280,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "T30",
    lat: 28.826418,
    lon: -99.111983,
  },
  {
    Airport: "McKinney National",
    County: "Collin",
    Region: "North Texas",
    ITN_GA: 42016,
    "Itenerant Arrivals": 21008,
    "Transient Arrivals (Visitors)": 7002,
    "Visitors per Aircraft": 2.7,
    "Annual Visitors": 18905,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "TKI",
    lat: 33.177956,
    lon: -96.590537,
  },
  {
    Airport: "McLean/Gray County",
    County: "Gray",
    Region: "Panhandle",
    ITN_GA: 500,
    "Itenerant Arrivals": 250,
    "Transient Arrivals (Visitors)": 83,
    "Visitors per Aircraft": 2.8,
    "Annual Visitors": 233,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "2E7",
    lat: 35.246996,
    lon: -100.544029,
  },
  {
    Airport: "Memphis Municipal",
    County: "Hall",
    Region: "Panhandle",
    ITN_GA: 200,
    "Itenerant Arrivals": 100,
    "Transient Arrivals (Visitors)": 33,
    "Visitors per Aircraft": 2.8,
    "Annual Visitors": 93,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "F21",
    lat: 34.739596,
    lon: -100.529709,
  },
  {
    Airport: "Menard County Airport",
    County: "Menard",
    Region: "West Texas",
    ITN_GA: 312,
    "Itenerant Arrivals": 156,
    "Transient Arrivals (Visitors)": 52,
    "Visitors per Aircraft": 2.8,
    "Annual Visitors": 146,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "T50",
    lat: 30.9324722,
    lon: -99.8092278,
  },
  {
    Airport: "Mesquite Metro",
    County: "Dallas",
    Region: "North Texas",
    ITN_GA: 25316,
    "Itenerant Arrivals": 12658,
    "Transient Arrivals (Visitors)": 4219,
    "Visitors per Aircraft": 2.7,
    "Annual Visitors": 11391,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "HQZ",
    lat: 32.7469628,
    lon: -96.5304172,
  },
  {
    Airport: "Mexia-Limestone County",
    County: "Limestone",
    Region: "Central Texas",
    ITN_GA: 1100,
    "Itenerant Arrivals": 550,
    "Transient Arrivals (Visitors)": 183,
    "Visitors per Aircraft": 2.8,
    "Annual Visitors": 513,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "LXY",
    lat: 31.639836,
    lon: -96.514727,
  },
  {
    Airport: "Miami-Roberts County",
    County: "Roberts",
    Region: "Panhandle",
    ITN_GA: 100,
    "Itenerant Arrivals": 50,
    "Transient Arrivals (Visitors)": 17,
    "Visitors per Aircraft": 2.8,
    "Annual Visitors": 47,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "3E0",
    lat: 35.713936,
    lon: -100.603199,
  },
  {
    Airport: "Mid Valley",
    County: "Hidalgo",
    Region: "South Texas",
    ITN_GA: 433,
    "Itenerant Arrivals": 217,
    "Transient Arrivals (Visitors)": 72,
    "Visitors per Aircraft": 2.8,
    "Annual Visitors": 202,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "T65",
    lat: 26.177644,
    lon: -97.973068,
  },
  {
    Airport: "Midland Airpark",
    County: "Midland",
    Region: "West Texas",
    ITN_GA: 13000,
    "Itenerant Arrivals": 6500,
    "Transient Arrivals (Visitors)": 2166,
    "Visitors per Aircraft": 2.7,
    "Annual Visitors": 5849,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "MDD",
    lat: 32.036525,
    lon: -102.101039,
  },
  {
    Airport: "Midland International Air & Space Port",
    County: "Midland",
    Region: "West Texas",
    ITN_GA: 24276,
    "Itenerant Arrivals": 12138,
    "Transient Arrivals (Visitors)": 4046,
    "Visitors per Aircraft": 3.7,
    "Annual Visitors": 14969,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "MAF",
    lat: 31.942535,
    lon: -102.201919,
  },
  {
    Airport: "Mid-way Regional",
    County: "Ellis",
    Region: "North Texas",
    ITN_GA: 12400,
    "Itenerant Arrivals": 6200,
    "Transient Arrivals (Visitors)": 2066,
    "Visitors per Aircraft": 2.7,
    "Annual Visitors": 5579,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "4T6",
    lat: 32.456106,
    lon: -96.912418,
  },
  {
    Airport: "Mineral Wells Airport",
    County: "Palo Pinto",
    Region: "North Texas",
    ITN_GA: 8000,
    "Itenerant Arrivals": 4000,
    "Transient Arrivals (Visitors)": 1333,
    "Visitors per Aircraft": 2.1,
    "Annual Visitors": 2800,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "MWL",
    lat: 32.782608,
    lon: -98.060444,
  },
  {
    Airport: "Moore County Airport",
    County: "Moore",
    Region: "Panhandle",
    ITN_GA: 200,
    "Itenerant Arrivals": 100,
    "Transient Arrivals (Visitors)": 33,
    "Visitors per Aircraft": 2.8,
    "Annual Visitors": 93,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "DUX",
    lat: 35.857936,
    lon: -102.013109,
  },
  {
    Airport: "Mt. Pleasant Regional Airport",
    County: "Titus",
    Region: "East Texas",
    ITN_GA: 10500,
    "Itenerant Arrivals": 5250,
    "Transient Arrivals (Visitors)": 1750,
    "Visitors per Aircraft": 2.7,
    "Annual Visitors": 4725,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "LRO",
    lat: 32.8978333,
    lon: -79.7828611,
  },
  {
    Airport: "Muleshoe Municipal",
    County: "Bailey",
    Region: "West Texas",
    ITN_GA: 1000,
    "Itenerant Arrivals": 500,
    "Transient Arrivals (Visitors)": 167,
    "Visitors per Aircraft": 2.8,
    "Annual Visitors": 467,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "2T1",
    lat: 34.185146,
    lon: -102.641109,
  },
  {
    Airport: "Munday Municipal Airport",
    County: "Knox",
    Region: "West Texas",
    ITN_GA: 300,
    "Itenerant Arrivals": 150,
    "Transient Arrivals (Visitors)": 50,
    "Visitors per Aircraft": 2.8,
    "Annual Visitors": 140,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "37F",
    lat: 33.484826,
    lon: -99.586208,
  },
  {
    Airport: "Mustang Beach",
    County: "Nueces",
    Region: "South Texas",
    ITN_GA: 3000,
    "Itenerant Arrivals": 1500,
    "Transient Arrivals (Visitors)": 500,
    "Visitors per Aircraft": 2.8,
    "Annual Visitors": 1400,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "RAS",
    lat: 27.811179,
    lon: -97.088728,
  },
  {
    Airport: "Navasota Municipal Airport",
    County: "Grimes",
    Region: "Central Texas",
    ITN_GA: 3200,
    "Itenerant Arrivals": 1600,
    "Transient Arrivals (Visitors)": 533,
    "Visitors per Aircraft": 2.8,
    "Annual Visitors": 1493,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "60R",
    lat: 30.371895,
    lon: -96.113287,
  },
  {
    Airport: "New Braunfels Regional",
    County: "Comal",
    Region: "South Texas",
    ITN_GA: 20416,
    "Itenerant Arrivals": 10208,
    "Transient Arrivals (Visitors)": 3402,
    "Visitors per Aircraft": 2.7,
    "Annual Visitors": 9186,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "BAZ",
    lat: 29.704505,
    lon: -98.042228,
  },
  {
    Airport: "Newton Municipal",
    County: "Newton",
    Region: "East Texas",
    ITN_GA: 200,
    "Itenerant Arrivals": 100,
    "Transient Arrivals (Visitors)": 33,
    "Visitors per Aircraft": 2.8,
    "Annual Visitors": 93,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "61R",
    lat: 30.884375,
    lon: -93.741847,
  },
  {
    Airport: "North Texas Regional Airport",
    County: "Grayson",
    Region: "North Texas",
    ITN_GA: 35522,
    "Itenerant Arrivals": 17761,
    "Transient Arrivals (Visitors)": 5920,
    "Visitors per Aircraft": 2.7,
    "Annual Visitors": 15983,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "GYI",
    lat: 33.7141911,
    lon: -96.6743597,
  },
  {
    Airport: "Northwest Regional Airport",
    County: "Denton",
    Region: "North Texas",
    ITN_GA: 55000,
    "Itenerant Arrivals": 27500,
    "Transient Arrivals (Visitors)": 9166,
    "Visitors per Aircraft": 2.7,
    "Annual Visitors": 24748,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "52F",
    lat: 33.049846,
    lon: -97.232248,
  },
  {
    Airport: "Nueces County Airport",
    County: "Nueces",
    Region: "South Texas",
    ITN_GA: 3000,
    "Itenerant Arrivals": 1500,
    "Transient Arrivals (Visitors)": 500,
    "Visitors per Aircraft": 2.8,
    "Annual Visitors": 1400,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "T53",
    lat: 27.780188,
    lon: -97.690352,
  },
  {
    Airport: "Odessa-Schlemeyer Field",
    County: "Ector",
    Region: "West Texas",
    ITN_GA: 26000,
    "Itenerant Arrivals": 13000,
    "Transient Arrivals (Visitors)": 4333,
    "Visitors per Aircraft": 2.7,
    "Annual Visitors": 11699,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "ODO",
    lat: 31.920575,
    lon: -102.387099,
  },
  {
    Airport: "Oldham County",
    County: "Oldham",
    Region: "Panhandle",
    ITN_GA: 1100,
    "Itenerant Arrivals": 550,
    "Transient Arrivals (Visitors)": 183,
    "Visitors per Aircraft": 2.8,
    "Annual Visitors": 513,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "E52",
    lat: 35.232006,
    lon: -102.399099,
  },
  {
    Airport: "Olney Municipal",
    County: "Young",
    Region: "North Texas",
    ITN_GA: 2000,
    "Itenerant Arrivals": 1000,
    "Transient Arrivals (Visitors)": 333,
    "Visitors per Aircraft": 2.8,
    "Annual Visitors": 933,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "ONY",
    lat: 33.350917,
    lon: -98.818939,
  },
  {
    Airport: "Orange County Airport",
    County: "Orange",
    Region: "Panhandle",
    ITN_GA: 3020,
    "Itenerant Arrivals": 1510,
    "Transient Arrivals (Visitors)": 503,
    "Visitors per Aircraft": 2.8,
    "Annual Visitors": 1409,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "ORG",
    lat: 30.069175,
    lon: -93.800927,
  },
  {
    Airport: "Ozona Municipal",
    County: "Crockett",
    Region: "West Texas",
    ITN_GA: 1200,
    "Itenerant Arrivals": 600,
    "Transient Arrivals (Visitors)": 200,
    "Visitors per Aircraft": 2.8,
    "Annual Visitors": 560,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "OZA",
    lat: 30.735285,
    lon: -101.202978,
  },
  {
    Airport: "Palacios Municipal Airport",
    County: "Matagorda",
    Region: "Upper Gulf Coast",
    ITN_GA: 1400,
    "Itenerant Arrivals": 700,
    "Transient Arrivals (Visitors)": 233,
    "Visitors per Aircraft": 2.8,
    "Annual Visitors": 653,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "PSX",
    lat: 28.727525,
    lon: -96.250977,
  },
  {
    Airport: "Palestine Municipal Airport",
    County: "Anderson",
    Region: "East Texas",
    ITN_GA: 3200,
    "Itenerant Arrivals": 1600,
    "Transient Arrivals (Visitors)": 533,
    "Visitors per Aircraft": 2.8,
    "Annual Visitors": 1493,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "PSN",
    lat: 31.779696,
    lon: -95.706317,
  },
  {
    Airport: "Panhandle-Carson County Airport",
    County: "Carson",
    Region: "Panhandle",
    ITN_GA: 600,
    "Itenerant Arrivals": 300,
    "Transient Arrivals (Visitors)": 100,
    "Visitors per Aircraft": 2.8,
    "Annual Visitors": 280,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "T45",
    lat: 35.361369,
    lon: -101.366965,
  },
  {
    Airport: "Panola County-Sharpe Field",
    County: "Panola",
    Region: "East Texas",
    ITN_GA: 1000,
    "Itenerant Arrivals": 500,
    "Transient Arrivals (Visitors)": 167,
    "Visitors per Aircraft": 2.8,
    "Annual Visitors": 467,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "4F2",
    lat: 32.176086,
    lon: -94.298817,
  },
  {
    Airport: "Pearland Regional",
    County: "Brazoria",
    Region: "Upper Gulf Coast",
    ITN_GA: 20000,
    "Itenerant Arrivals": 10000,
    "Transient Arrivals (Visitors)": 3333,
    "Visitors per Aircraft": 2.7,
    "Annual Visitors": 8999,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "LVJ",
    lat: 29.521315,
    lon: -95.242177,
  },
  {
    Airport: "Pecos Municipal",
    County: "Reeves",
    Region: "West Texas",
    ITN_GA: 6865,
    "Itenerant Arrivals": 3433,
    "Transient Arrivals (Visitors)": 1144,
    "Visitors per Aircraft": 2.1,
    "Annual Visitors": 2403,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "PEQ",
    lat: 31.382365,
    lon: -103.510709,
  },
  {
    Airport: "Perry Lefors Field",
    County: "Gray",
    Region: "Panhandle",
    ITN_GA: 5100,
    "Itenerant Arrivals": 2550,
    "Transient Arrivals (Visitors)": 850,
    "Visitors per Aircraft": 2.1,
    "Annual Visitors": 1785,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "PPA",
    lat: 35.612996,
    lon: -100.996269,
  },
  {
    Airport: "Perryton/Ochiltree County Airport",
    County: "Ochiltree",
    Region: "Panhandle",
    ITN_GA: 2920,
    "Itenerant Arrivals": 1460,
    "Transient Arrivals (Visitors)": 487,
    "Visitors per Aircraft": 2.8,
    "Annual Visitors": 1363,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "PYX",
    lat: 36.413946,
    lon: -100.749729,
  },
  {
    Airport: "Pineland Municipal Airport",
    County: "Sabine",
    Region: "East Texas",
    ITN_GA: 200,
    "Itenerant Arrivals": 100,
    "Transient Arrivals (Visitors)": 33,
    "Visitors per Aircraft": 2.8,
    "Annual Visitors": 93,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "T24",
    lat: 31.233526,
    lon: -93.981867,
  },
  {
    Airport: "Pleasanton Municpal Airport",
    County: "Atascosa",
    Region: "South Texas",
    ITN_GA: 2000,
    "Itenerant Arrivals": 1000,
    "Transient Arrivals (Visitors)": 333,
    "Visitors per Aircraft": 2.8,
    "Annual Visitors": 933,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "PEZ",
    lat: 28.954195,
    lon: -98.519988,
  },
  {
    Airport: "Port Isabel Camreon County Airport",
    County: "Cameron",
    Region: "South Texas",
    ITN_GA: 11000,
    "Itenerant Arrivals": 5500,
    "Transient Arrivals (Visitors)": 1833,
    "Visitors per Aircraft": 2.7,
    "Annual Visitors": 4950,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "PIL",
    lat: 26.166214,
    lon: -97.345897,
  },
  {
    Airport: "Possum Kingdom Airport",
    County: "Palo Pinto",
    Region: "North Texas",
    ITN_GA: 200,
    "Itenerant Arrivals": 100,
    "Transient Arrivals (Visitors)": 33,
    "Visitors per Aircraft": 2.8,
    "Annual Visitors": 93,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "F35",
    lat: 32.922956,
    lon: -98.436221,
  },
  {
    Airport: "Post/Garza County Airport",
    County: "Garza",
    Region: "West Texas",
    ITN_GA: 1000,
    "Itenerant Arrivals": 500,
    "Transient Arrivals (Visitors)": 167,
    "Visitors per Aircraft": 2.8,
    "Annual Visitors": 467,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "5F1",
    lat: 33.2033056,
    lon: -101.3382222,
  },
  {
    Airport: "Presidio Lely international",
    County: "Presidio",
    Region: "West Texas",
    ITN_GA: 1500,
    "Itenerant Arrivals": 750,
    "Transient Arrivals (Visitors)": 250,
    "Visitors per Aircraft": 2.8,
    "Annual Visitors": 700,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "T77",
    lat: 29.634075,
    lon: -104.361599,
  },
  {
    Airport: "Quanah Municipal",
    County: "Hardeman",
    Region: "North Texas",
    ITN_GA: 1100,
    "Itenerant Arrivals": 550,
    "Transient Arrivals (Visitors)": 183,
    "Visitors per Aircraft": 2.8,
    "Annual Visitors": 513,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "F01",
    lat: 34.277086,
    lon: -99.759278,
  },
  {
    Airport: "Ralph M. Hall / Rockwall Municipal Airport",
    County: "Rockwall",
    Region: "North Texas",
    ITN_GA: 8000,
    "Itenerant Arrivals": 4000,
    "Transient Arrivals (Visitors)": 1333,
    "Visitors per Aircraft": 2.1,
    "Annual Visitors": 2800,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "F46",
    lat: 32.930596,
    lon: -96.435497,
  },
  {
    Airport: "Reagan County",
    County: "Reagan",
    Region: "West Texas",
    ITN_GA: 300,
    "Itenerant Arrivals": 150,
    "Transient Arrivals (Visitors)": 50,
    "Visitors per Aircraft": 2.8,
    "Annual Visitors": 140,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "E41",
    lat: 31.197495,
    lon: -101.473289,
  },
  {
    Airport: "Real County",
    County: "Real",
    Region: "South Texas",
    ITN_GA: 400,
    "Itenerant Arrivals": 200,
    "Transient Arrivals (Visitors)": 67,
    "Visitors per Aircraft": 2.8,
    "Annual Visitors": 187,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "49R",
    lat: 29.745505,
    lon: -99.760898,
  },
  {
    Airport: "Refugio County Airport-Rooke Field",
    County: "Refugio",
    Region: "South Texas",
    ITN_GA: 900,
    "Itenerant Arrivals": 450,
    "Transient Arrivals (Visitors)": 150,
    "Visitors per Aircraft": 2.8,
    "Annual Visitors": 420,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "RFG",
    lat: 28.291668,
    lon: -97.326651,
  },
  {
    Airport: "Rick Husband Amarillo Int'l Airport",
    County: "Potter",
    Region: "Panhandle",
    ITN_GA: 15177,
    "Itenerant Arrivals": 7589,
    "Transient Arrivals (Visitors)": 2529,
    "Visitors per Aircraft": 3.7,
    "Annual Visitors": 9358,
    "Non-hub CS CY18 Enplanements": 355705,
    FAA: "AMA",
    lat: 35.219376,
    lon: -101.705939,
  },
  {
    Airport: "Rio Grande City Municipal Airport",
    County: "Starr",
    Region: "South Texas",
    ITN_GA: 20,
    "Itenerant Arrivals": 10,
    "Transient Arrivals (Visitors)": 3,
    "Visitors per Aircraft": 2.8,
    "Annual Visitors": 9,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "67R",
    lat: 26.4242833,
    lon: -98.8460806,
  },
  {
    Airport: "Robert Lee",
    County: "Coke",
    Region: "West Texas",
    ITN_GA: 0,
    "Itenerant Arrivals": 0,
    "Transient Arrivals (Visitors)": 0,
    "Visitors per Aircraft": 2.8,
    "Annual Visitors": 0,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "54F",
    lat: 31.881815,
    lon: -100.540388,
  },
  {
    Airport: "Robert R. Wells",
    County: "Colorado",
    Region: "Upper Gulf Coast",
    ITN_GA: 1000,
    "Itenerant Arrivals": 500,
    "Transient Arrivals (Visitors)": 167,
    "Visitors per Aircraft": 2.8,
    "Annual Visitors": 467,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "66R",
    lat: 29.6415111,
    lon: -96.5159556,
  },
  {
    Airport: "Roger M. Dreyer Memorial",
    County: "Gonzales",
    Region: "South Texas",
    ITN_GA: 800,
    "Itenerant Arrivals": 400,
    "Transient Arrivals (Visitors)": 133,
    "Visitors per Aircraft": 2.8,
    "Annual Visitors": 373,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "T20",
    lat: 29.528015,
    lon: -97.461388,
  },
  {
    Airport: "Ron Duncan",
    Comment: "This is a duplicate of Eastland Municipal (ETN).",
    Comment2: "But it needs to be corrected carefully with testing so",
    Comment3: "that the data still joins together correctly",
    County: "Denton",
    Region: "North Texas",
    ITN_GA: 4000,
    "Itenerant Arrivals": 2000,
    "Transient Arrivals (Visitors)": 667,
    "Visitors per Aircraft": 2.8,
    "Annual Visitors": 1866,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "ETN",
    lat: 32.415393,
    lon: -98.810602,
  },
  {
    Airport: "Roy Hurd Memorial",
    County: "Ward",
    Region: "West Texas",
    ITN_GA: 2500,
    "Itenerant Arrivals": 1250,
    "Transient Arrivals (Visitors)": 417,
    "Visitors per Aircraft": 2.8,
    "Annual Visitors": 1167,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "E01",
    lat: 31.582475,
    lon: -102.909049,
  },
  {
    Airport: "Rusk County Airport",
    County: "Rusk",
    Region: "East Texas",
    ITN_GA: 3000,
    "Itenerant Arrivals": 1500,
    "Transient Arrivals (Visitors)": 500,
    "Visitors per Aircraft": 2.8,
    "Annual Visitors": 1400,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "F12",
    lat: 32.141726,
    lon: -94.851727,
  },
  {
    Airport: "San Angelo Regional - Mathis Field",
    County: "Tom Green",
    Region: "West Texas",
    ITN_GA: 17034,
    "Itenerant Arrivals": 8517,
    "Transient Arrivals (Visitors)": 2839,
    "Visitors per Aircraft": 3.7,
    "Annual Visitors": 10503,
    "Non-hub CS CY18 Enplanements": 55301,
    FAA: "SJT",
    lat: 31.357755,
    lon: -100.496318,
  },
  {
    Airport: "San Antonio International",
    County: "Bexar",
    Region: "South Texas",
    ITN_GA: 45549,
    "Itenerant Arrivals": 22775,
    "Transient Arrivals (Visitors)": 7591,
    "Visitors per Aircraft": 3.7,
    "Annual Visitors": 28086,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "SAT",
    lat: 29.533695,
    lon: -98.469788,
  },
  {
    Airport: "San Augustine County",
    County: "San Augustine",
    Region: "East Texas",
    ITN_GA: 100,
    "Itenerant Arrivals": 50,
    "Transient Arrivals (Visitors)": 17,
    "Visitors per Aircraft": 2.8,
    "Annual Visitors": 47,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "78R",
    lat: 31.539584,
    lon: -94.169345,
  },
  {
    Airport: "San Marcos Regional",
    County: "Travis",
    Region: "Central Texas",
    ITN_GA: 37512,
    "Itenerant Arrivals": 18756,
    "Transient Arrivals (Visitors)": 6251,
    "Visitors per Aircraft": 2.7,
    "Annual Visitors": 16879,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "HYI",
    lat: 29.893615,
    lon: -97.864698,
  },
  {
    Airport: "San Saba Municipal Airport",
    County: "San Saba",
    Region: "Central Texas",
    ITN_GA: 602,
    "Itenerant Arrivals": 301,
    "Transient Arrivals (Visitors)": 100,
    "Visitors per Aircraft": 2.8,
    "Annual Visitors": 281,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "81R",
    lat: 31.235009,
    lon: -98.716181,
  },
  {
    Airport: "Scholes Intl at Galveston",
    County: "Galveston",
    Region: "Upper Gulf Coast",
    ITN_GA: 15244,
    "Itenerant Arrivals": 7622,
    "Transient Arrivals (Visitors)": 2540,
    "Visitors per Aircraft": 2.7,
    "Annual Visitors": 6859,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "GLS",
    lat: 29.265325,
    lon: -94.860417,
  },

  {
    Airport: "Seymour Municipal Airport",
    County: "Baylor",
    Region: "North Texas",
    ITN_GA: 1100,
    "Itenerant Arrivals": 550,
    "Transient Arrivals (Visitors)": 183,
    "Visitors per Aircraft": 2.8,
    "Annual Visitors": 513,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "60F",
    lat: 33.646374,
    lon: -99.261757,
  },
  {
    Airport: "Sheppard AFB/Wichita Falls Municipal",
    County: "Wichita",
    Region: "North Texas",
    ITN_GA: 9551,
    "Itenerant Arrivals": 4776,
    "Transient Arrivals (Visitors)": 1592,
    "Visitors per Aircraft": 3.7,
    "Annual Visitors": 5889,
    "Non-hub CS CY18 Enplanements": 38026,
    FAA: "SPS",
    lat: 33.988806,
    lon: -98.491898,
  },
  {
    Airport: "Sherman Municipal",
    County: "Grayson",
    Region: "North Texas",
    ITN_GA: 3300,
    "Itenerant Arrivals": 1650,
    "Transient Arrivals (Visitors)": 550,
    "Visitors per Aircraft": 2.8,
    "Annual Visitors": 1540,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "SWI",
    lat: 33.624166,
    lon: -96.586138,
  },
  {
    Airport: "Slaton Municipal",
    County: "Lubbock",
    Region: "West Texas",
    ITN_GA: 5000,
    "Itenerant Arrivals": 2500,
    "Transient Arrivals (Visitors)": 833,
    "Visitors per Aircraft": 2.1,
    "Annual Visitors": 1750,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "F49",
    lat: 33.484816,
    lon: -101.660729,
  },
  {
    Airport: "Smiley Johnson/Bass Field",
    County: "Donley",
    Region: "Panhandle",
    ITN_GA: 600,
    "Itenerant Arrivals": 300,
    "Transient Arrivals (Visitors)": 100,
    "Visitors per Aircraft": 2.8,
    "Annual Visitors": 280,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "E34",
    lat: 34.910476,
    lon: -100.869789,
  },
  {
    Airport: "Smithville Crawford Municipal Airport",
    County: "Bastrop",
    Region: "Central Texas",
    ITN_GA: 4200,
    "Itenerant Arrivals": 2100,
    "Transient Arrivals (Visitors)": 700,
    "Visitors per Aircraft": 2.8,
    "Annual Visitors": 1960,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "84R",
    lat: 30.030485,
    lon: -97.166877,
  },
  {
    Airport: "Sonora Airport",
    County: "Sutton",
    Region: "West Texas",
    ITN_GA: 500,
    "Itenerant Arrivals": 250,
    "Transient Arrivals (Visitors)": 83,
    "Visitors per Aircraft": 2.8,
    "Annual Visitors": 233,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "E29",
    lat: 30.585715,
    lon: -100.648578,
  },
  {
    Airport: "South Texas Intl at Edinburg",
    County: "Hidalgo",
    Region: "South Texas",
    ITN_GA: 600,
    "Itenerant Arrivals": 300,
    "Transient Arrivals (Visitors)": 100,
    "Visitors per Aircraft": 2.8,
    "Annual Visitors": 280,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "25R",
    lat: 26.442014,
    lon: -98.129458,
  },
  {
    Airport: "South Texas Regional Airport",
    County: "Cameron",
    Region: "South Texas",
    ITN_GA: 5000,
    "Itenerant Arrivals": 2500,
    "Transient Arrivals (Visitors)": 833,
    "Visitors per Aircraft": 2.1,
    "Annual Visitors": 1750,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "HDO",
    lat: 29.359535,
    lon: -99.176678,
  },
  {
    Airport: "Stanton Municipal",
    County: "Martin",
    Region: "West Texas",
    ITN_GA: 500,
    "Itenerant Arrivals": 250,
    "Transient Arrivals (Visitors)": 83,
    "Visitors per Aircraft": 2.8,
    "Annual Visitors": 233,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "63F",
    lat: 32.173585,
    lon: -101.822479,
  },
  {
    Airport: "Stephens County",
    County: "Stephens",
    Region: "West Texas",
    ITN_GA: 3800,
    "Itenerant Arrivals": 1900,
    "Transient Arrivals (Visitors)": 633,
    "Visitors per Aircraft": 2.8,
    "Annual Visitors": 1773,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "BKD",
    lat: 32.721836,
    lon: -98.890015,
  },
  {
    Airport: "Stephenville Clark Regional Airport",
    County: "Erath",
    Region: "North Texas",
    ITN_GA: 4400,
    "Itenerant Arrivals": 2200,
    "Transient Arrivals (Visitors)": 733,
    "Visitors per Aircraft": 2.8,
    "Annual Visitors": 2053,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "SEP",
    lat: 32.215326,
    lon: -98.177678,
  },
  {
    Airport: "Stinson Municipal",
    County: "Bexar",
    Region: "South Texas",
    ITN_GA: 26888,
    "Itenerant Arrivals": 13444,
    "Transient Arrivals (Visitors)": 4481,
    "Visitors per Aircraft": 2.7,
    "Annual Visitors": 12098,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "SSF",
    lat: 29.337015,
    lon: -98.471148,
  },
  {
    Airport: "Stonewall County",
    County: "Stonewall",
    Region: "West Texas",
    ITN_GA: 300,
    "Itenerant Arrivals": 150,
    "Transient Arrivals (Visitors)": 50,
    "Visitors per Aircraft": 2.8,
    "Annual Visitors": 140,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "T60",
    lat: 33.172326,
    lon: -100.197608,
  },
  {
    Airport: "Sugarland Regional",
    County: "Harris",
    Region: "Upper Gulf Coast",
    ITN_GA: 39258,
    "Itenerant Arrivals": 19629,
    "Transient Arrivals (Visitors)": 6542,
    "Visitors per Aircraft": 2.7,
    "Annual Visitors": 17664,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "SGR",
    lat: 29.622255,
    lon: -95.656537,
  },
  {
    Airport: "Sulphur Springs Municipal",
    County: "Hopkins",
    Region: "East Texas",
    ITN_GA: 8100,
    "Itenerant Arrivals": 4050,
    "Transient Arrivals (Visitors)": 1350,
    "Visitors per Aircraft": 2.1,
    "Annual Visitors": 2835,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "SLR",
    lat: 33.159836,
    lon: -95.621147,
  },
  {
    Airport: "Sunray Municipal",
    County: "Moore",
    Region: "Panhandle",
    ITN_GA: 25,
    "Itenerant Arrivals": 13,
    "Transient Arrivals (Visitors)": 4,
    "Visitors per Aircraft": 2.8,
    "Annual Visitors": 12,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "Q43",
    lat: 36.029206,
    lon: -101.828789,
  },
  {
    Airport: "Taylor Brown Muncipal",
    Comment: "This airport is in Littlefield, TX",
    County: "Lamb",
    FAA: "LIU",
    Region: "West Texas",
    ITN_GA: 200,
    "Itenerant Arrivals": 100,
    "Transient Arrivals (Visitors)": 33,
    "Visitors per Aircraft": 2.8,
    "Annual Visitors": 93,
    "Non-hub CS CY18 Enplanements": "",
    lat: 33.9239442,
    lon: -102.3866738,
  },
  {
    Airport: "Taylor Municipal",
    County: "Williamson",
    Region: "Central Texas",
    ITN_GA: 4000,
    "Itenerant Arrivals": 2000,
    "Transient Arrivals (Visitors)": 667,
    "Visitors per Aircraft": 2.8,
    "Annual Visitors": 1866,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "T74",
    lat: 30.571945,
    lon: -97.443178,
  },
  {
    Airport: "T-Bar Airport",
    County: "Lynn",
    Region: "West Texas",
    ITN_GA: 100,
    "Itenerant Arrivals": 50,
    "Transient Arrivals (Visitors)": 17,
    "Visitors per Aircraft": 2.8,
    "Annual Visitors": 47,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "2F4",
    lat: 33.181,
    lon: -101.8193889,
  },
  {
    Airport: "Teague Municipal Airport",
    County: "Freestone",
    Region: "Central Texas",
    ITN_GA: 600,
    "Itenerant Arrivals": 300,
    "Transient Arrivals (Visitors)": 100,
    "Visitors per Aircraft": 2.8,
    "Annual Visitors": 280,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "68F",
    lat: 31.66175,
    lon: -96.3105,
  },
  {
    Airport: "Terrell County Airport",
    County: "Terrell",
    Region: "West Texas",
    ITN_GA: 8400,
    "Itenerant Arrivals": 4200,
    "Transient Arrivals (Visitors)": 1400,
    "Visitors per Aircraft": 2.1,
    "Annual Visitors": 2940,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "6R6",
    lat: 30.046025,
    lon: -102.212919,
  },
  {
    Airport: "Terrell Municipal Airport",
    County: "Kaufman",
    Region: "North Texas",
    ITN_GA: 8400,
    "Itenerant Arrivals": 4200,
    "Transient Arrivals (Visitors)": 1400,
    "Visitors per Aircraft": 2.1,
    "Annual Visitors": 2940,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "TRL",
    lat: 32.710056,
    lon: -96.267427,
  },
  {
    Airport: "Terry County",
    County: "Terry",
    Region: "West Texas",
    ITN_GA: 2100,
    "Itenerant Arrivals": 1050,
    "Transient Arrivals (Visitors)": 350,
    "Visitors per Aircraft": 2.8,
    "Annual Visitors": 980,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "Q26",
    lat: 33.173686,
    lon: -102.192629,
  },
  {
    Airport: "Texarkana Regional Airport- Webb Field",
    County: "Bowie",
    Region: "East Texas",
    ITN_GA: 10465,
    "Itenerant Arrivals": 5233,
    "Transient Arrivals (Visitors)": 1744,
    "Visitors per Aircraft": 3.7,
    "Annual Visitors": 6453,
    "Non-hub CS CY18 Enplanements": 37599,
    FAA: "TXK",
    lat: 33.4537222,
    lon: -93.9910278,
  },
  {
    Airport: "Texas Gulf Coast Regional",
    County: "Brazoria",
    Region: "Upper Gulf Coast",
    ITN_GA: 45831,
    "Itenerant Arrivals": 22916,
    "Transient Arrivals (Visitors)": 7638,
    "Visitors per Aircraft": 2.7,
    "Annual Visitors": 20622,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "LBX",
    lat: 29.108645,
    lon: -95.462087,
  },
  {
    Airport: "The Carter Memorial",
    County: "Caldwell",
    Region: "Central Texas",
    ITN_GA: 200,
    "Itenerant Arrivals": 100,
    "Transient Arrivals (Visitors)": 33,
    "Visitors per Aircraft": 2.8,
    "Annual Visitors": 93,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "T91",
    lat: 29.728285,
    lon: -97.659448,
  },
  {
    Airport: "Throckmorton Municipal",
    County: "Throckmorton",
    Region: "West Texas",
    ITN_GA: 65,
    "Itenerant Arrivals": 33,
    "Transient Arrivals (Visitors)": 11,
    "Visitors per Aircraft": 2.8,
    "Annual Visitors": 30,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "72F",
    lat: 33.179387,
    lon: -99.150923,
  },
  {
    Airport: "Tradewind",
    County: "Potter",
    Region: "Panhandle",
    ITN_GA: 9100,
    "Itenerant Arrivals": 4550,
    "Transient Arrivals (Visitors)": 1517,
    "Visitors per Aircraft": 2.1,
    "Annual Visitors": 3185,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "TDW",
    lat: 35.169896,
    lon: -101.825889,
  },
  {
    Airport: "TSTC Waco",
    County: "McLennan",
    Region: "Central Texas",
    ITN_GA: 17375,
    "Itenerant Arrivals": 8688,
    "Transient Arrivals (Visitors)": 2896,
    "Visitors per Aircraft": 2.7,
    "Annual Visitors": 7818,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "CNW",
    lat: 31.637836,
    lon: -97.074148,
  },
  {
    Airport: "Tyler County",
    County: "Tyler",
    Region: "East Texas",
    ITN_GA: 300,
    "Itenerant Arrivals": 150,
    "Transient Arrivals (Visitors)": 50,
    "Visitors per Aircraft": 2.8,
    "Annual Visitors": 140,
    "Non-hub CS CY18 Enplanements": 48532,
    FAA: "09R",
    lat: 30.775382,
    lon: -94.458812,
  },
  {
    Airport: "Tyler Pounds Regional",
    County: "Upton",
    Region: "West Texas",
    ITN_GA: 21120,
    "Itenerant Arrivals": 10560,
    "Transient Arrivals (Visitors)": 3520,
    "Visitors per Aircraft": 3.7,
    "Annual Visitors": 13023,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "TYR",
    lat: 32.354146,
    lon: -95.402397,
  },
  {
    Airport: "Upton County Airport",
    County: "Upton",
    Region: "West Texas",
    ITN_GA: 100,
    "Itenerant Arrivals": 50,
    "Transient Arrivals (Visitors)": 17,
    "Visitors per Aircraft": 2.8,
    "Annual Visitors": 47,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "E48",
    lat: 31.125155,
    lon: -102.225429,
  },
  {
    Airport: "Valley International",
    County: "Cameron",
    Region: "South Texas",
    ITN_GA: 9209,
    "Itenerant Arrivals": 4605,
    "Transient Arrivals (Visitors)": 1535,
    "Visitors per Aircraft": 3.7,
    "Annual Visitors": 5678,
    "Non-hub CS CY18 Enplanements": 302236,
    FAA: "HRL",
    lat: 26.228504,
    lon: -97.654397,
  },
  {
    Airport: "Van Zandt County Regional Airport",
    County: "Van Zandt",
    Region: "East Texas",
    ITN_GA: 1200,
    "Itenerant Arrivals": 600,
    "Transient Arrivals (Visitors)": 200,
    "Visitors per Aircraft": 2.8,
    "Annual Visitors": 560,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "76F",
    lat: 32.681526,
    lon: -95.984137,
  },
  {
    Airport: "Victoria Regional",
    County: "Victoria",
    Region: "South Texas",
    ITN_GA: 6699,
    "Itenerant Arrivals": 3350,
    "Transient Arrivals (Visitors)": 1116,
    "Visitors per Aircraft": 2.1,
    "Annual Visitors": 2344,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "VCT",
    lat: 28.852565,
    lon: -96.918497,
  },
  {
    Airport: "Waco Regional",
    County: "McLennan",
    Region: "Central Texas",
    ITN_GA: 22233,
    "Itenerant Arrivals": 11117,
    "Transient Arrivals (Visitors)": 3705,
    "Visitors per Aircraft": 3.7,
    "Annual Visitors": 13709,
    "Non-hub CS CY18 Enplanements": 57344,
    FAA: "ACT",
    lat: 31.611295,
    lon: -97.230528,
  },
  {
    Airport: "West Houston Airport",
    County: "Harris",
    Region: "Upper Gulf Coast",
    ITN_GA: 32000,
    "Itenerant Arrivals": 16000,
    "Transient Arrivals (Visitors)": 5333,
    "Visitors per Aircraft": 2.7,
    "Annual Visitors": 14399,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "IWS",
    lat: 29.818195,
    lon: -95.672617,
  },
  {
    Airport: "Wharton Regional Airport",
    County: "Wharton",
    Region: "Upper Gulf Coast",
    ITN_GA: 2920,
    "Itenerant Arrivals": 1460,
    "Transient Arrivals (Visitors)": 487,
    "Visitors per Aircraft": 2.8,
    "Annual Visitors": 1363,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "5R5",
    lat: 29.254285,
    lon: -96.154397,
  },
  {
    Airport: "Wheeler Municipal",
    County: "Wheeler",
    Region: "Panhandle",
    ITN_GA: 100,
    "Itenerant Arrivals": 50,
    "Transient Arrivals (Visitors)": 17,
    "Visitors per Aircraft": 2.8,
    "Annual Visitors": 47,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "T59",
    lat: 35.451086,
    lon: -100.199929,
  },
  {
    Airport: "Wilbarger County Airport",
    County: "Wilbarger",
    Region: "North Texas",
    ITN_GA: 1250,
    "Itenerant Arrivals": 625,
    "Transient Arrivals (Visitors)": 208,
    "Visitors per Aircraft": 2.8,
    "Annual Visitors": 583,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "F05",
    lat: 34.227283,
    lon: -99.283141,
  },
  {
    Airport: "William P Hobby",
    County: "Harris",
    Region: "Upper Gulf Coast",
    ITN_GA: 54303,
    "Itenerant Arrivals": 27152,
    "Transient Arrivals (Visitors)": 9050,
    "Visitors per Aircraft": 3.7,
    "Annual Visitors": 33484,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "HOU",
    lat: 29.645425,
    lon: -95.278897,
  },
  {
    Airport: "Winkler County",
    County: "Winkler",
    Region: "West Texas",
    ITN_GA: 2200,
    "Itenerant Arrivals": 1100,
    "Transient Arrivals (Visitors)": 367,
    "Visitors per Aircraft": 2.8,
    "Annual Visitors": 1027,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "INK",
    lat: 31.779635,
    lon: -103.201369,
  },
  {
    Airport: "Winnsboro Municipal Airport",
    County: "Franklin",
    Region: "East Texas",
    ITN_GA: 700,
    "Itenerant Arrivals": 350,
    "Transient Arrivals (Visitors)": 117,
    "Visitors per Aircraft": 2.8,
    "Annual Visitors": 327,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "F51",
    lat: 32.938856,
    lon: -95.278867,
  },
  {
    Airport: "Winston Field",
    County: "Scurry",
    Region: "West Texas",
    ITN_GA: 3600,
    "Itenerant Arrivals": 1800,
    "Transient Arrivals (Visitors)": 600,
    "Visitors per Aircraft": 2.8,
    "Annual Visitors": 1680,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "SNK",
    lat: 32.693396,
    lon: -100.950459,
  },
  {
    Airport: "Winters Municipal",
    County: "Runnels",
    Region: "West Texas",
    ITN_GA: 700,
    "Itenerant Arrivals": 350,
    "Transient Arrivals (Visitors)": 117,
    "Visitors per Aircraft": 2.8,
    "Annual Visitors": 327,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "77F",
    lat: 31.949876,
    lon: -99.993202,
  },
  {
    Airport: "Wood County",
    County: "Wood",
    Region: "East Texas",
    ITN_GA: 10000,
    "Itenerant Arrivals": 5000,
    "Transient Arrivals (Visitors)": 1667,
    "Visitors per Aircraft": 2.7,
    "Annual Visitors": 4500,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "3T1",
    lat: 32.741386,
    lon: -95.496517,
  },
  {
    Airport: "Yoakum County Airport",
    County: "Yoakum",
    Region: "West Texas",
    ITN_GA: 1000,
    "Itenerant Arrivals": 500,
    "Transient Arrivals (Visitors)": 167,
    "Visitors per Aircraft": 2.8,
    "Annual Visitors": 467,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "T85",
    lat: 29.313165,
    lon: -97.138407,
  },
  {
    Airport: "Yoakum Muni",
    County: "De Witt",
    Region: "West Texas",
    ITN_GA: 300,
    "Itenerant Arrivals": 150,
    "Transient Arrivals (Visitors)": 50,
    "Visitors per Aircraft": 2.8,
    "Annual Visitors": 140,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "T85",
    lat: 29.313165,
    lon: -97.138407,
  },
  {
    Airport: "Zapata County Airport",
    County: "Zapata",
    Region: "South Texas",
    ITN_GA: 1000,
    "Itenerant Arrivals": 500,
    "Transient Arrivals (Visitors)": 167,
    "Visitors per Aircraft": 2.8,
    "Annual Visitors": 467,
    "Non-hub CS CY18 Enplanements": "",
    FAA: "T86",
    lat: 26.968795,
    lon: -99.248918,
  },
];

export const VISITOR_SPENDING = [
  {
    Region: "Big Bend Region",
    Name: "West Texas",
    "Avg Length of Stay (Incl. Days)": 2.85,
    "Avg Per Person Per Day Spending": 144,
    "Avg Per Person Per Trip Spending": 410.4,
    "Transit and ground passenger transportation": 82.08,
    "Full-service restaurants": 94.392,
    "Hotels and motels, including casino hotels": 160.056,
    "Retail - Miscellaneous store retailers": 49.248,
    "Other amusement and recreation industries": 24.624,
  },
  {
    Region: "Gulf Coast Region",
    Name: "Upper Gulf Coast",
    "Avg Length of Stay (Incl. Days)": 2.16,
    "Avg Per Person Per Day Spending": 122.4,
    "Avg Per Person Per Trip Spending": 264.384,
    "Transit and ground passenger transportation": 52.8768,
    "Full-service restaurants": 60.80832,
    "Hotels and motels, including casino hotels": 103.10976,
    "Retail - Miscellaneous store retailers": 31.72608,
    "Other amusement and recreation industries": 15.86304,
  },
  {
    Region: "Hill Country Region",
    Name: "Central Texas",
    "Avg Length of Stay (Incl. Days)": 1.94,
    "Avg Per Person Per Day Spending": 133.6,
    "Avg Per Person Per Trip Spending": 259.184,
    "Transit and ground passenger transportation": 51.8368,
    "Full-service restaurants": 59.61232,
    "Hotels and motels, including casino hotels": 101.08176,
    "Retail - Miscellaneous store retailers": 31.10208,
    "Other amusement and recreation industries": 15.55104,
  },
  {
    Region: "Panhandle Plains Region",
    Name: "Panhandle",
    "Avg Length of Stay (Incl. Days)": 1.98,
    "Avg Per Person Per Day Spending": 99.4,
    "Avg Per Person Per Trip Spending": 196.812,
    "Transit and ground passenger transportation": 39.3624,
    "Full-service restaurants": 45.26676,
    "Hotels and motels, including casino hotels": 76.75668,
    "Retail - Miscellaneous store retailers": 23.61744,
    "Other amusement and recreation industries": 11.80872,
  },
  {
    Region: "Piney Woods Region",
    Name: "East Texas",
    "Avg Length of Stay (Incl. Days)": 1.61,
    "Avg Per Person Per Day Spending": 68.8,
    "Avg Per Person Per Trip Spending": 110.768,
    "Transit and ground passenger transportation": 22.1536,
    "Full-service restaurants": 25.47664,
    "Hotels and motels, including casino hotels": 43.19952,
    "Retail - Miscellaneous store retailers": 13.29216,
    "Other amusement and recreation industries": 6.64608,
  },
  {
    Region: "Praries and Lakes Region",
    Name: "North Texas",
    "Avg Length of Stay (Incl. Days)": 2.01,
    "Avg Per Person Per Day Spending": 128.1,
    "Avg Per Person Per Trip Spending": 257.481,
    "Transit and ground passenger transportation": 51.4962,
    "Full-service restaurants": 59.22063,
    "Hotels and motels, including casino hotels": 100.41759,
    "Retail - Miscellaneous store retailers": 30.89772,
    "Other amusement and recreation industries": 15.44886,
  },
  {
    Region: "South Texas Plains Region",
    Name: "South Texas",
    "Avg Length of Stay (Incl. Days)": 2,
    "Avg Per Person Per Day Spending": 137.8,
    "Avg Per Person Per Trip Spending": 275.6,
    "Transit and ground passenger transportation": 55.12,
    "Full-service restaurants": 63.388,
    "Hotels and motels, including casino hotels": 107.484,
    "Retail - Miscellaneous store retailers": 33.072,
    "Other amusement and recreation industries": 16.536,
  },
];

export const AIRPORT_ACTIVITY = [
  {
    "Airport Name": "A. L. Mangham Jr. Regional Airport",
    "2017 Full Time Employees": null,
    "2017 Part Time Employees": null,
    FTE: null,
    "2017 Estimated Payroll": null,
    "2017 Estimated Operating Expenses": null,
  },
  {
    "Airport Name": "Abilene Regional Airport",
    "2017 Full Time Employees": 20,
    "2017 Part Time Employees": 7,
    FTE: 23.5,
    "2017 Estimated Payroll": 1293185,
    "2017 Estimated Operating Expenses": 1969385,
  },
  {
    "Airport Name": "Addison Airport",
    "2017 Full Time Employees": 17,
    "2017 Part Time Employees": 5,
    FTE: 19.5,
    "2017 Estimated Payroll": 1957000,
    "2017 Estimated Operating Expenses": 2241631,
  },
  {
    "Airport Name": "Albany Municipal",
    "2017 Full Time Employees": 1,
    "2017 Part Time Employees": 0,
    FTE: 1,
    "2017 Estimated Payroll": 30000,
    "2017 Estimated Operating Expenses": 100000,
  },
  {
    "Airport Name": "Alfred C. Bubba Thomas Airport",
    "2017 Full Time Employees": 2,
    "2017 Part Time Employees": 1,
    FTE: 2.5,
    "2017 Estimated Payroll": 0,
    "2017 Estimated Operating Expenses": 60000,
  },
  {
    "Airport Name": "Alice International Airport",
    "2017 Full Time Employees": 1,
    "2017 Part Time Employees": 0,
    FTE: 1,
    "2017 Estimated Payroll": null,
    "2017 Estimated Operating Expenses": 76909,
  },
  {
    "Airport Name": "Alpine Casparis Municipal Airport",
    "2017 Full Time Employees": 2,
    "2017 Part Time Employees": 0,
    FTE: 2,
    "2017 Estimated Payroll": 93389,
    "2017 Estimated Operating Expenses": 573859,
  },
  {
    "Airport Name": "Andrews County Airport",
    "2017 Full Time Employees": 1,
    "2017 Part Time Employees": 1,
    FTE: 1.5,
    "2017 Estimated Payroll": 94324,
    "2017 Estimated Operating Expenses": 314213,
  },
  {
    "Airport Name": "Angelina County Airport",
    "2017 Full Time Employees": 7,
    "2017 Part Time Employees": 4,
    FTE: 9,
    "2017 Estimated Payroll": 437866,
    "2017 Estimated Operating Expenses": 1874691,
  },
  {
    "Airport Name": "Aransas County Airport",
    "2017 Full Time Employees": 5,
    "2017 Part Time Employees": 0,
    FTE: 5,
    "2017 Estimated Payroll": 266560,
    "2017 Estimated Operating Expenses": 820957,
  },
  {
    "Airport Name": "Arledge Field",
    "2017 Full Time Employees": null,
    "2017 Part Time Employees": 1,
    FTE: 0.5,
    "2017 Estimated Payroll": null,
    "2017 Estimated Operating Expenses": null,
  },
  {
    "Airport Name": "Arlington Municipal Airport",
    "2017 Full Time Employees": 9,
    "2017 Part Time Employees": 3,
    FTE: 10.5,
    "2017 Estimated Payroll": 695455,
    "2017 Estimated Operating Expenses": 987443,
  },
  {
    "Airport Name": "Athens Municipal",
    "2017 Full Time Employees": null,
    "2017 Part Time Employees": 1,
    FTE: 0.5,
    "2017 Estimated Payroll": null,
    "2017 Estimated Operating Expenses": null,
  },
  {
    "Airport Name": "Austin-Bergstrom International",
    "2017 Full Time Employees": null,
    "2017 Part Time Employees": null,
    FTE: 0,
    "2017 Estimated Payroll": null,
    "2017 Estimated Operating Expenses": null,
  },
  {
    "Airport Name": "Avenger Field",
    "2017 Full Time Employees": 0.5,
    "2017 Part Time Employees": 0,
    FTE: 0.5,
    "2017 Estimated Payroll": 31613,
    "2017 Estimated Operating Expenses": 222023,
  },
  {
    "Airport Name": "Bay City Regional Airport",
    "2017 Full Time Employees": 2,
    "2017 Part Time Employees": 0,
    FTE: 2,
    "2017 Estimated Payroll": 124037,
    "2017 Estimated Operating Expenses": 364387.89,
  },
  {
    "Airport Name": "Beaumont Municipal",
    "2017 Full Time Employees": 0,
    "2017 Part Time Employees": 0,
    FTE: 0,
    "2017 Estimated Payroll": 60000,
    "2017 Estimated Operating Expenses": 180000,
  },
  {
    "Airport Name": "Beeville Municipal",
    "2017 Full Time Employees": null,
    "2017 Part Time Employees": 1,
    FTE: 0.5,
    "2017 Estimated Payroll": null,
    "2017 Estimated Operating Expenses": null,
  },
  {
    "Airport Name": "Big Spring McMahon-Wrinkle",
    "2017 Full Time Employees": 6,
    "2017 Part Time Employees": 0,
    FTE: 6,
    "2017 Estimated Payroll": 468737,
    "2017 Estimated Operating Expenses": 1187069,
  },
  {
    "Airport Name": "Bishop Municipal Airport",
    "2017 Full Time Employees": 0,
    "2017 Part Time Employees": 5,
    FTE: 2.5,
    "2017 Estimated Payroll": null,
    "2017 Estimated Operating Expenses": 10000,
  },
  {
    "Airport Name": "Bowie Municipal Airport",
    "2017 Full Time Employees": 2,
    "2017 Part Time Employees": 0,
    FTE: 2,
    "2017 Estimated Payroll": 78744,
    "2017 Estimated Operating Expenses": 233454.2,
  },
  {
    "Airport Name": "Brenham Municipal",
    "2017 Full Time Employees": null,
    "2017 Part Time Employees": null,
    FTE: 0,
    "2017 Estimated Payroll": null,
    "2017 Estimated Operating Expenses": null,
  },
  {
    "Airport Name": "Bridgeport Municipal Airport",
    "2017 Full Time Employees": 0,
    "2017 Part Time Employees": 1,
    FTE: 0.5,
    "2017 Estimated Payroll": 20000,
    "2017 Estimated Operating Expenses": 0,
  },
  {
    "Airport Name": "Brooks County",
    "2017 Full Time Employees": 0,
    "2017 Part Time Employees": 3,
    FTE: 1.5,
    "2017 Estimated Payroll": 19000,
    "2017 Estimated Operating Expenses": 50000,
  },
  {
    "Airport Name": "Brownsville/South Padre Island International",
    "2017 Full Time Employees": 28,
    "2017 Part Time Employees": 2,
    FTE: 29,
    "2017 Estimated Payroll": 2352000,
    "2017 Estimated Operating Expenses": 7460000,
  },
  {
    "Airport Name": "Brownwood Regional",
    "2017 Full Time Employees": 4,
    "2017 Part Time Employees": 2,
    FTE: 5,
    "2017 Estimated Payroll": 306074,
    "2017 Estimated Operating Expenses": 1091078,
  },
  {
    "Airport Name": "Bruce Field",
    "2017 Full Time Employees": null,
    "2017 Part Time Employees": 1,
    FTE: 0.5,
    "2017 Estimated Payroll": null,
    "2017 Estimated Operating Expenses": null,
  },
  {
    "Airport Name": "Burnet Municipal Airport",
    "2017 Full Time Employees": 1,
    "2017 Part Time Employees": 0,
    FTE: 1,
    "2017 Estimated Payroll": 56644,
    "2017 Estimated Operating Expenses": 650778,
  },
  {
    "Airport Name": "C David Campbell Field-Corsicana Municipal",
    "2017 Full Time Employees": null,
    "2017 Part Time Employees": null,
    FTE: 0,
    "2017 Estimated Payroll": null,
    "2017 Estimated Operating Expenses": 97000,
  },
  {
    "Airport Name": "Caddo Mills Municipal",
    "2017 Full Time Employees": 0,
    "2017 Part Time Employees": 2,
    FTE: 1,
    "2017 Estimated Payroll": 25000,
    "2017 Estimated Operating Expenses": 45000,
  },
  {
    "Airport Name": "Caldwell Municipal",
    "2017 Full Time Employees": null,
    "2017 Part Time Employees": 1,
    FTE: 0.5,
    "2017 Estimated Payroll": null,
    "2017 Estimated Operating Expenses": null,
  },
  {
    "Airport Name": "Calhoun County Airport",
    "2017 Full Time Employees": 2,
    "2017 Part Time Employees": 2,
    FTE: 3,
    "2017 Estimated Payroll": 120000,
    "2017 Estimated Operating Expenses": 230000,
  },
  {
    "Airport Name": "Cameron Municipal Airpark",
    "2017 Full Time Employees": 0,
    "2017 Part Time Employees": 0,
    FTE: 0,
    "2017 Estimated Payroll": 0,
    "2017 Estimated Operating Expenses": 31000,
  },
  {
    "Airport Name": "Castroville Municipal",
    "2017 Full Time Employees": 2,
    "2017 Part Time Employees": 1,
    FTE: 2.5,
    "2017 Estimated Payroll": null,
    "2017 Estimated Operating Expenses": null,
  },
  {
    "Airport Name": "Center Municipal",
    "2017 Full Time Employees": 0,
    "2017 Part Time Employees": 1,
    FTE: 0.5,
    "2017 Estimated Payroll": 29000,
    "2017 Estimated Operating Expenses": 81800,
  },
  {
    "Airport Name": "Chambers County - Anahuac",
    "2017 Full Time Employees": 0,
    "2017 Part Time Employees": 3,
    FTE: 1.5,
    "2017 Estimated Payroll": 12800,
    "2017 Estimated Operating Expenses": 329000,
  },
  {
    "Airport Name": "Chambers County - Winnie Stowell",
    "2017 Full Time Employees": 0,
    "2017 Part Time Employees": 3,
    FTE: 1.5,
    "2017 Estimated Payroll": 12800,
    "2017 Estimated Operating Expenses": 329000,
  },
  {
    "Airport Name": "Charles R Johnson",
    "2017 Full Time Employees": null,
    "2017 Part Time Employees": 1,
    FTE: 0.5,
    "2017 Estimated Payroll": null,
    "2017 Estimated Operating Expenses": null,
  },
  {
    "Airport Name": "Cherokee County Airport",
    "2017 Full Time Employees": 3,
    "2017 Part Time Employees": 0,
    FTE: 3,
    "2017 Estimated Payroll": 80000,
    "2017 Estimated Operating Expenses": 500000,
  },
  {
    "Airport Name": "Childress Municipal",
    "2017 Full Time Employees": 1,
    "2017 Part Time Employees": null,
    FTE: 1,
    "2017 Estimated Payroll": null,
    "2017 Estimated Operating Expenses": null,
  },
  {
    "Airport Name": "Cisco Municipal",
    "2017 Full Time Employees": null,
    "2017 Part Time Employees": 1,
    FTE: 0.5,
    "2017 Estimated Payroll": null,
    "2017 Estimated Operating Expenses": null,
  },
  {
    "Airport Name": "City of Shamrock",
    "2017 Full Time Employees": 0,
    "2017 Part Time Employees": 0,
    FTE: 0,
    "2017 Estimated Payroll": 0,
    "2017 Estimated Operating Expenses": 5162.72,
  },
  {
    "Airport Name": "City of Tulia/Swisher County Municipal",
    "2017 Full Time Employees": null,
    "2017 Part Time Employees": 1,
    FTE: 0.5,
    "2017 Estimated Payroll": null,
    "2017 Estimated Operating Expenses": null,
  },
  {
    "Airport Name": "Clarksville/Red River County-J D Trissell Field",
    "2017 Full Time Employees": 0,
    "2017 Part Time Employees": 0,
    FTE: 0,
    "2017 Estimated Payroll": 0,
    "2017 Estimated Operating Expenses": 5000,
  },
  {
    "Airport Name": "Cleburne Regional Airport",
    "2017 Full Time Employees": 3,
    "2017 Part Time Employees": 2,
    FTE: 4,
    "2017 Estimated Payroll": 156963.31,
    "2017 Estimated Operating Expenses": 1005045.34,
  },
  {
    "Airport Name": "Cleveland Municipal",
    "2017 Full Time Employees": 1,
    "2017 Part Time Employees": null,
    FTE: 1,
    "2017 Estimated Payroll": null,
    "2017 Estimated Operating Expenses": null,
  },
  {
    "Airport Name": "Clifton Municipal",
    "2017 Full Time Employees": 0,
    "2017 Part Time Employees": 1,
    FTE: 0.5,
    "2017 Estimated Payroll": 0,
    "2017 Estimated Operating Expenses": 10000,
  },
  {
    "Airport Name": "Cochran County",
    "2017 Full Time Employees": null,
    "2017 Part Time Employees": 1,
    FTE: 0.5,
    "2017 Estimated Payroll": null,
    "2017 Estimated Operating Expenses": null,
  },
  {
    "Airport Name": "Coleman Muncipal",
    "2017 Full Time Employees": 0,
    "2017 Part Time Employees": 0,
    FTE: 0,
    "2017 Estimated Payroll": 0,
    "2017 Estimated Operating Expenses": 321000,
  },
  {
    "Airport Name": "Colorado City Municipal Airport",
    "2017 Full Time Employees": 0,
    "2017 Part Time Employees": 0,
    FTE: 0,
    "2017 Estimated Payroll": 0,
    "2017 Estimated Operating Expenses": null,
  },
  {
    "Airport Name": "Comanche County - City",
    "2017 Full Time Employees": null,
    "2017 Part Time Employees": 1,
    FTE: 0.5,
    "2017 Estimated Payroll": null,
    "2017 Estimated Operating Expenses": null,
  },
  {
    "Airport Name": "Commerce Municipal",
    "2017 Full Time Employees": null,
    "2017 Part Time Employees": 1,
    FTE: 0.5,
    "2017 Estimated Payroll": null,
    "2017 Estimated Operating Expenses": null,
  },
  {
    "Airport Name": "Conroe North-Houston Regional",
    "2017 Full Time Employees": 7,
    "2017 Part Time Employees": 6,
    FTE: 10,
    "2017 Estimated Payroll": 750000,
    "2017 Estimated Operating Expenses": 750000,
  },
  {
    "Airport Name": "Corpus Christi International",
    "2017 Full Time Employees": 66,
    "2017 Part Time Employees": 0,
    FTE: 66,
    "2017 Estimated Payroll": 4629154,
    "2017 Estimated Operating Expenses": 7456305,
  },
  {
    "Airport Name": "Corsicana Muncipal Airport",
    "2017 Full Time Employees": 0,
    "2017 Part Time Employees": 0,
    FTE: 0,
    "2017 Estimated Payroll": 0,
    "2017 Estimated Operating Expenses": 97000,
  },
  {
    "Airport Name": "Cotulla-La Salle County Airport",
    "2017 Full Time Employees": 0,
    "2017 Part Time Employees": 1,
    FTE: 0.5,
    "2017 Estimated Payroll": 18000,
    "2017 Estimated Operating Expenses": 30000,
  },
  {
    "Airport Name": "Coulter Field",
    "2017 Full Time Employees": 2,
    "2017 Part Time Employees": 4,
    FTE: 4,
    "2017 Estimated Payroll": 146000,
    "2017 Estimated Operating Expenses": 510000,
  },
  {
    "Airport Name": "Cox Field",
    "2017 Full Time Employees": 3,
    "2017 Part Time Employees": 0,
    FTE: 3,
    "2017 Estimated Payroll": 24900,
    "2017 Estimated Operating Expenses": 33600,
  },
  {
    "Airport Name": "Crane County",
    "2017 Full Time Employees": 0,
    "2017 Part Time Employees": 0,
    FTE: 0,
    "2017 Estimated Payroll": 0,
    "2017 Estimated Operating Expenses": 12000,
  },
  {
    "Airport Name": "Crosbyton Municipal Airport",
    "2017 Full Time Employees": 0,
    "2017 Part Time Employees": 0.5,
    FTE: 0.25,
    "2017 Estimated Payroll": null,
    "2017 Estimated Operating Expenses": 4000,
  },
  {
    "Airport Name": "Crystal City Municipal",
    "2017 Full Time Employees": null,
    "2017 Part Time Employees": 1,
    FTE: 0.5,
    "2017 Estimated Payroll": null,
    "2017 Estimated Operating Expenses": null,
  },
  {
    "Airport Name": "Cuero Muni",
    "2017 Full Time Employees": 0,
    "2017 Part Time Employees": 0,
    FTE: 0,
    "2017 Estimated Payroll": 0,
    "2017 Estimated Operating Expenses": 4800.5,
  },
  {
    "Airport Name": "Culberson County",
    "2017 Full Time Employees": null,
    "2017 Part Time Employees": 1,
    FTE: 0.5,
    "2017 Estimated Payroll": null,
    "2017 Estimated Operating Expenses": null,
  },
  {
    "Airport Name": "Curtis Field",
    "2017 Full Time Employees": 2,
    "2017 Part Time Employees": 1,
    FTE: 2.5,
    "2017 Estimated Payroll": 111539,
    "2017 Estimated Operating Expenses": 532249,
  },
  {
    "Airport Name": "Cypress River",
    "2017 Full Time Employees": null,
    "2017 Part Time Employees": 1,
    FTE: 0.5,
    "2017 Estimated Payroll": null,
    "2017 Estimated Operating Expenses": null,
  },
  {
    "Airport Name": "Dalhart Muni",
    "2017 Full Time Employees": null,
    "2017 Part Time Employees": 1,
    FTE: 0.5,
    "2017 Estimated Payroll": null,
    "2017 Estimated Operating Expenses": null,
  },
  {
    "Airport Name": "Dallas CBD Vertiport",
    "2017 Full Time Employees": 0,
    "2017 Part Time Employees": 0,
    FTE: 0,
    "2017 Estimated Payroll": 0,
    "2017 Estimated Operating Expenses": 312400,
  },
  {
    "Airport Name": "Dallas Executive",
    "2017 Full Time Employees": 7,
    "2017 Part Time Employees": 1,
    FTE: 7.5,
    "2017 Estimated Payroll": 500000,
    "2017 Estimated Operating Expenses": 2500000,
  },
  {
    "Airport Name": "Dallas Love Field",
    "2017 Full Time Employees": null,
    "2017 Part Time Employees": null,
    FTE: 0,
    "2017 Estimated Payroll": null,
    "2017 Estimated Operating Expenses": null,
  },
  {
    "Airport Name": "Dallas/Fort Worth International",
    "2017 Full Time Employees": null,
    "2017 Part Time Employees": null,
    FTE: 0,
    "2017 Estimated Payroll": null,
    "2017 Estimated Operating Expenses": null,
  },
  {
    "Airport Name": "Dan E Richards",
    "2017 Full Time Employees": 0,
    "2017 Part Time Employees": 0,
    FTE: 0,
    "2017 Estimated Payroll": 0,
    "2017 Estimated Operating Expenses": 4200,
  },
  {
    "Airport Name": "David Wayne Hooks Memorial",
    "2017 Full Time Employees": 20,
    "2017 Part Time Employees": 3,
    FTE: 21.5,
    "2017 Estimated Payroll": null,
    "2017 Estimated Operating Expenses": null,
  },
  {
    "Airport Name": "Decatur Muncipal Airport",
    "2017 Full Time Employees": 0,
    "2017 Part Time Employees": 1,
    FTE: 0.5,
    "2017 Estimated Payroll": 27000,
    "2017 Estimated Operating Expenses": 100000,
  },
  {
    "Airport Name": "Del Rio International Airport",
    "2017 Full Time Employees": 5,
    "2017 Part Time Employees": 1,
    FTE: 5.5,
    "2017 Estimated Payroll": 243200,
    "2017 Estimated Operating Expenses": 358900,
  },
  {
    "Airport Name": "Dell City Municipal Airport",
    "2017 Full Time Employees": 0,
    "2017 Part Time Employees": 0,
    FTE: 0,
    "2017 Estimated Payroll": 0,
    "2017 Estimated Operating Expenses": 0,
  },
  {
    "Airport Name": "Denton Enterprise Airport",
    "2017 Full Time Employees": 6,
    "2017 Part Time Employees": 0,
    FTE: 6,
    "2017 Estimated Payroll": 519113,
    "2017 Estimated Operating Expenses": 1270834,
  },
  {
    "Airport Name": "Denver City Airport",
    "2017 Full Time Employees": 0,
    "2017 Part Time Employees": 0.5,
    FTE: 0.25,
    "2017 Estimated Payroll": 0,
    "2017 Estimated Operating Expenses": 19833,
  },
  {
    "Airport Name": "Desoto Heliport",
    "2017 Full Time Employees": 1,
    "2017 Part Time Employees": 0,
    FTE: 1,
    "2017 Estimated Payroll": 40000,
    "2017 Estimated Operating Expenses": 325000,
  },
  {
    "Airport Name": "Devine Municipal Airport",
    "2017 Full Time Employees": 1,
    "2017 Part Time Employees": 0,
    FTE: 1,
    "2017 Estimated Payroll": 8800,
    "2017 Estimated Operating Expenses": 31710,
  },
  {
    "Airport Name": "Dilley Airport",
    "2017 Full Time Employees": 0,
    "2017 Part Time Employees": 0,
    FTE: 0,
    "2017 Estimated Payroll": 0,
    "2017 Estimated Operating Expenses": 2700,
  },
  {
    "Airport Name": "Dimmit County Airport",
    "2017 Full Time Employees": 2,
    "2017 Part Time Employees": 0,
    FTE: 2,
    "2017 Estimated Payroll": 32000,
    "2017 Estimated Operating Expenses": 25000,
  },
  {
    "Airport Name": "Dimmit Municipal",
    "2017 Full Time Employees": null,
    "2017 Part Time Employees": 1,
    FTE: 0.5,
    "2017 Estimated Payroll": null,
    "2017 Estimated Operating Expenses": null,
  },
  {
    "Airport Name": "Draughon-Miller Central Texas Regional Airport",
    "2017 Full Time Employees": 12,
    "2017 Part Time Employees": 2,
    FTE: 13,
    "2017 Estimated Payroll": 812634,
    "2017 Estimated Operating Expenses": 2267154,
  },
  {
    "Airport Name": "Dublin Muncipal Airport",
    "2017 Full Time Employees": 0,
    "2017 Part Time Employees": 1,
    FTE: 0.5,
    "2017 Estimated Payroll": 0,
    "2017 Estimated Operating Expenses": 22000,
  },
  {
    "Airport Name": "Duval-Freer",
    "2017 Full Time Employees": null,
    "2017 Part Time Employees": 1,
    FTE: 0.5,
    "2017 Estimated Payroll": null,
    "2017 Estimated Operating Expenses": null,
  },
  {
    "Airport Name": "Eagle Lake Regional",
    "2017 Full Time Employees": 0,
    "2017 Part Time Employees": 1,
    FTE: 0.5,
    "2017 Estimated Payroll": 60630.87,
    "2017 Estimated Operating Expenses": 170569.53,
  },
  {
    "Airport Name": "East Texas Regional",
    "2017 Full Time Employees": 13,
    "2017 Part Time Employees": 1,
    FTE: 13.5,
    "2017 Estimated Payroll": 1552776,
    "2017 Estimated Operating Expenses": 2055217,
  },
  {
    "Airport Name": "Easterwood Field",
    "2017 Full Time Employees": 17,
    "2017 Part Time Employees": 5,
    FTE: 19.5,
    "2017 Estimated Payroll": 1168027,
    "2017 Estimated Operating Expenses": 2402588,
  },
  {
    "Airport Name": "Eastland Municipal",
    "2017 Full Time Employees": null,
    "2017 Part Time Employees": null,
    FTE: 0,
    "2017 Estimated Payroll": null,
    "2017 Estimated Operating Expenses": 55583,
  },
  {
    "Airport Name": "Edwards County Airport",
    "2017 Full Time Employees": null,
    "2017 Part Time Employees": null,
    FTE: 0,
    "2017 Estimated Payroll": null,
    "2017 Estimated Operating Expenses": 7500,
  },
  {
    "Airport Name": "El Paso Internatinal",
    "2017 Full Time Employees": null,
    "2017 Part Time Employees": null,
    FTE: 0,
    "2017 Estimated Payroll": null,
    "2017 Estimated Operating Expenses": null,
  },
  {
    "Airport Name": "Eldorado Airport",
    "2017 Full Time Employees": 0,
    "2017 Part Time Employees": 0,
    FTE: 0,
    "2017 Estimated Payroll": 0,
    "2017 Estimated Operating Expenses": 11544.71,
  },
  {
    "Airport Name": "Ellington Field",
    "2017 Full Time Employees": 26,
    "2017 Part Time Employees": 0,
    FTE: 26,
    "2017 Estimated Payroll": 2367422,
    "2017 Estimated Operating Expenses": 4585703,
  },
  {
    "Airport Name": "Ennis Municipal",
    "2017 Full Time Employees": 0,
    "2017 Part Time Employees": 1,
    FTE: 0.5,
    "2017 Estimated Payroll": 0,
    "2017 Estimated Operating Expenses": 150000,
  },
  {
    "Airport Name": "Fabens",
    "2017 Full Time Employees": 1,
    "2017 Part Time Employees": 0,
    FTE: 1,
    "2017 Estimated Payroll": 21533.46,
    "2017 Estimated Operating Expenses": 32000,
  },
  {
    "Airport Name": "Fayette Regional Air Center",
    "2017 Full Time Employees": 2,
    "2017 Part Time Employees": 0,
    FTE: 2,
    "2017 Estimated Payroll": 50000,
    "2017 Estimated Operating Expenses": 99000,
  },
  {
    "Airport Name": "Fisher County Airport",
    "2017 Full Time Employees": null,
    "2017 Part Time Employees": null,
    FTE: 0,
    "2017 Estimated Payroll": null,
    "2017 Estimated Operating Expenses": 500,
  },
  {
    "Airport Name": "Floydada Municipal Airport",
    "2017 Full Time Employees": 0,
    "2017 Part Time Employees": 0,
    FTE: 0,
    "2017 Estimated Payroll": 0,
    "2017 Estimated Operating Expenses": 750,
  },
  {
    "Airport Name": "Follett/Lipscomb County",
    "2017 Full Time Employees": 0,
    "2017 Part Time Employees": 0,
    FTE: 0,
    "2017 Estimated Payroll": 0,
    "2017 Estimated Operating Expenses": 1400,
  },
  {
    "Airport Name": "Fort Stockton/Pecos County",
    "2017 Full Time Employees": 0,
    "2017 Part Time Employees": 0,
    FTE: 0,
    "2017 Estimated Payroll": 0,
    "2017 Estimated Operating Expenses": 18800,
  },
  {
    "Airport Name": "Fox Stephens Field-Gilmer Municipal",
    "2017 Full Time Employees": 0,
    "2017 Part Time Employees": 0,
    FTE: 0,
    "2017 Estimated Payroll": 0,
    "2017 Estimated Operating Expenses": 30610,
  },
  {
    "Airport Name": "Franklin County",
    "2017 Full Time Employees": 0,
    "2017 Part Time Employees": 0,
    FTE: 0,
    "2017 Estimated Payroll": 0,
    "2017 Estimated Operating Expenses": 10000,
  },
  {
    "Airport Name": "Ft. Worth Alliance",
    "2017 Full Time Employees": 21,
    "2017 Part Time Employees": 8,
    FTE: 25,
    "2017 Estimated Payroll": 955244,
    "2017 Estimated Operating Expenses": 1462039,
  },
  {
    "Airport Name": "Ft. Worth Meacham",
    "2017 Full Time Employees": 20,
    "2017 Part Time Employees": 0,
    FTE: 20,
    "2017 Estimated Payroll": 1200000,
    "2017 Estimated Operating Expenses": 1350000,
  },
  {
    "Airport Name": "Ft. Worth Spinks",
    "2017 Full Time Employees": 5,
    "2017 Part Time Employees": 0,
    FTE: 5,
    "2017 Estimated Payroll": 345000,
    "2017 Estimated Operating Expenses": 718000,
  },
  {
    "Airport Name": "Gaines County Airport",
    "2017 Full Time Employees": 1,
    "2017 Part Time Employees": null,
    FTE: 1,
    "2017 Estimated Payroll": null,
    "2017 Estimated Operating Expenses": null,
  },
  {
    "Airport Name": "Gainesville Municipal",
    "2017 Full Time Employees": 2,
    "2017 Part Time Employees": 1,
    FTE: 2.5,
    "2017 Estimated Payroll": 132000,
    "2017 Estimated Operating Expenses": 289000,
  },
  {
    "Airport Name": "Garland/DFW Heliport",
    "2017 Full Time Employees": 2,
    "2017 Part Time Employees": 0,
    FTE: 2,
    "2017 Estimated Payroll": 100000,
    "2017 Estimated Operating Expenses": 360000,
  },
  {
    "Airport Name": "Garner Field Airport",
    "2017 Full Time Employees": 0,
    "2017 Part Time Employees": 7,
    FTE: 3.5,
    "2017 Estimated Payroll": 75000,
    "2017 Estimated Operating Expenses": 367200,
  },
  {
    "Airport Name": "Gatesville Municipal Airport",
    "2017 Full Time Employees": 0,
    "2017 Part Time Employees": 0,
    FTE: 0,
    "2017 Estimated Payroll": 0,
    "2017 Estimated Operating Expenses": 17466,
  },
  {
    "Airport Name": "George Bush Intercontinental/Houston",
    "2017 Full Time Employees": null,
    "2017 Part Time Employees": null,
    FTE: 0,
    "2017 Estimated Payroll": null,
    "2017 Estimated Operating Expenses": null,
  },
  {
    "Airport Name": "Georgetown Municipal",
    "2017 Full Time Employees": 4,
    "2017 Part Time Employees": 5,
    FTE: 6.5,
    "2017 Estimated Payroll": 319770,
    "2017 Estimated Operating Expenses": 3022423,
  },
  {
    "Airport Name": "Giddings-Lee County Airport",
    "2017 Full Time Employees": 0,
    "2017 Part Time Employees": 0,
    FTE: 0,
    "2017 Estimated Payroll": 0,
    "2017 Estimated Operating Expenses": 71558,
  },
  {
    "Airport Name": "Gillespie County Airport",
    "2017 Full Time Employees": 1,
    "2017 Part Time Employees": 0,
    FTE: 1,
    "2017 Estimated Payroll": 62000,
    "2017 Estimated Operating Expenses": 180000,
  },
  {
    "Airport Name": "Gladewater Municipal Airport",
    "2017 Full Time Employees": 2,
    "2017 Part Time Employees": null,
    FTE: 2,
    "2017 Estimated Payroll": null,
    "2017 Estimated Operating Expenses": 28800,
  },
  {
    "Airport Name": "Goldwaite Airport",
    "2017 Full Time Employees": 0,
    "2017 Part Time Employees": 1,
    FTE: 0.5,
    "2017 Estimated Payroll": 2400,
    "2017 Estimated Operating Expenses": 10000,
  },
  {
    "Airport Name": "Graham Municipal Airport",
    "2017 Full Time Employees": 1,
    "2017 Part Time Employees": 0,
    FTE: 1,
    "2017 Estimated Payroll": 70000,
    "2017 Estimated Operating Expenses": 400000,
  },
  {
    "Airport Name": "Granbury Regional Airport",
    "2017 Full Time Employees": 2,
    "2017 Part Time Employees": 5,
    FTE: 4.5,
    "2017 Estimated Payroll": 214690,
    "2017 Estimated Operating Expenses": 1034400,
  },
  {
    "Airport Name": "Grand Prairie Municipal",
    "2017 Full Time Employees": 5,
    "2017 Part Time Employees": 2,
    FTE: 6,
    "2017 Estimated Payroll": 443912,
    "2017 Estimated Operating Expenses": 21616452,
  },
  {
    "Airport Name": "Greater Morris County",
    "2017 Full Time Employees": null,
    "2017 Part Time Employees": 0.5,
    FTE: 0.25,
    "2017 Estimated Payroll": null,
    "2017 Estimated Operating Expenses": null,
  },
  {
    "Airport Name": "Greenville Municipal",
    "2017 Full Time Employees": 1,
    "2017 Part Time Employees": 0,
    FTE: 1,
    "2017 Estimated Payroll": 66581,
    "2017 Estimated Operating Expenses": 160927,
  },
  {
    "Airport Name": "Groveton-Trinity County",
    "2017 Full Time Employees": null,
    "2017 Part Time Employees": 0.5,
    FTE: 0.25,
    "2017 Estimated Payroll": null,
    "2017 Estimated Operating Expenses": null,
  },
  {
    "Airport Name": "Gruver Municipal",
    "2017 Full Time Employees": 0,
    "2017 Part Time Employees": 0,
    FTE: 0,
    "2017 Estimated Payroll": 0,
    "2017 Estimated Operating Expenses": 4775.78,
  },
  {
    "Airport Name": "H H Coffield Regional",
    "2017 Full Time Employees": null,
    "2017 Part Time Employees": 1,
    FTE: 0.5,
    "2017 Estimated Payroll": null,
    "2017 Estimated Operating Expenses": null,
  },
  {
    "Airport Name": "Hale County",
    "2017 Full Time Employees": 0,
    "2017 Part Time Employees": 0,
    FTE: 0,
    "2017 Estimated Payroll": 0,
    "2017 Estimated Operating Expenses": 61450,
  },
  {
    "Airport Name": "Hallettsville Muni",
    "2017 Full Time Employees": 0,
    "2017 Part Time Employees": 0,
    FTE: 0,
    "2017 Estimated Payroll": 0,
    "2017 Estimated Operating Expenses": 95000,
  },
  {
    "Airport Name": "Hall-Miller Municipal",
    "2017 Full Time Employees": null,
    "2017 Part Time Employees": 1,
    FTE: 0.5,
    "2017 Estimated Payroll": null,
    "2017 Estimated Operating Expenses": null,
  },
  {
    "Airport Name": "Hamilton Municipal",
    "2017 Full Time Employees": 1,
    "2017 Part Time Employees": 0,
    FTE: 1,
    "2017 Estimated Payroll": 0,
    "2017 Estimated Operating Expenses": 102998,
  },
  {
    "Airport Name": "Harrison County Airport",
    "2017 Full Time Employees": 1,
    "2017 Part Time Employees": 1,
    FTE: 1.5,
    "2017 Estimated Payroll": 0,
    "2017 Estimated Operating Expenses": 0,
  },
  {
    "Airport Name": "Harrison Field",
    "2017 Full Time Employees": 0,
    "2017 Part Time Employees": 0,
    FTE: 0,
    "2017 Estimated Payroll": 0,
    "2017 Estimated Operating Expenses": 2200,
  },
  {
    "Airport Name": "Haskell Municipal Airport",
    "2017 Full Time Employees": 1,
    "2017 Part Time Employees": 0,
    FTE: 1,
    "2017 Estimated Payroll": 22000,
    "2017 Estimated Operating Expenses": 50050,
  },
  {
    "Airport Name": "Hawthorne Field",
    "2017 Full Time Employees": 0,
    "2017 Part Time Employees": 0,
    FTE: 0,
    "2017 Estimated Payroll": 0,
    "2017 Estimated Operating Expenses": 28000,
  },
  {
    "Airport Name": "Hearne Municipal",
    "2017 Full Time Employees": null,
    "2017 Part Time Employees": 1,
    FTE: 0.5,
    "2017 Estimated Payroll": null,
    "2017 Estimated Operating Expenses": null,
  },
  {
    "Airport Name": "Hemphill County",
    "2017 Full Time Employees": null,
    "2017 Part Time Employees": 1,
    FTE: 0.5,
    "2017 Estimated Payroll": null,
    "2017 Estimated Operating Expenses": null,
  },
  {
    "Airport Name": "Hereford Municipal",
    "2017 Full Time Employees": 1,
    "2017 Part Time Employees": 1,
    FTE: 1.5,
    "2017 Estimated Payroll": null,
    "2017 Estimated Operating Expenses": null,
  },
  {
    "Airport Name": "Higgs/Libscomb County",
    "2017 Full Time Employees": 0,
    "2017 Part Time Employees": 1,
    FTE: 0.5,
    "2017 Estimated Payroll": null,
    "2017 Estimated Operating Expenses": 1200,
  },
  {
    "Airport Name": "Hillsboro Municipal Airport",
    "2017 Full Time Employees": 1,
    "2017 Part Time Employees": 1,
    FTE: 1.5,
    "2017 Estimated Payroll": 71121,
    "2017 Estimated Operating Expenses": 246726,
  },
  {
    "Airport Name": "Houston County",
    "2017 Full Time Employees": null,
    "2017 Part Time Employees": 1,
    FTE: 0.5,
    "2017 Estimated Payroll": null,
    "2017 Estimated Operating Expenses": null,
  },
  {
    "Airport Name": "Houston Southwest",
    "2017 Full Time Employees": 8,
    "2017 Part Time Employees": 4,
    FTE: 10,
    "2017 Estimated Payroll": 441290,
    "2017 Estimated Operating Expenses": 601661,
  },
  {
    "Airport Name": "Huntsville Municipal",
    "2017 Full Time Employees": null,
    "2017 Part Time Employees": 1,
    FTE: 0.5,
    "2017 Estimated Payroll": null,
    "2017 Estimated Operating Expenses": null,
  },
  {
    "Airport Name": "Hutchinson County",
    "2017 Full Time Employees": 4,
    "2017 Part Time Employees": 4,
    FTE: 6,
    "2017 Estimated Payroll": 210000,
    "2017 Estimated Operating Expenses": 600000,
  },
  {
    "Airport Name": "Jack Brooks Regional",
    "2017 Full Time Employees": 26,
    "2017 Part Time Employees": null,
    FTE: 26,
    "2017 Estimated Payroll": null,
    "2017 Estimated Operating Expenses": null,
  },
  {
    "Airport Name": "Jacksboro Municipal",
    "2017 Full Time Employees": null,
    "2017 Part Time Employees": 1,
    FTE: 0.5,
    "2017 Estimated Payroll": null,
    "2017 Estimated Operating Expenses": null,
  },
  {
    "Airport Name": "Jackson County",
    "2017 Full Time Employees": 1.5,
    "2017 Part Time Employees": 1,
    FTE: 2,
    "2017 Estimated Payroll": null,
    "2017 Estimated Operating Expenses": 20000,
  },
  {
    "Airport Name": "Jasper County-Bell Field",
    "2017 Full Time Employees": null,
    "2017 Part Time Employees": 1,
    FTE: 0.5,
    "2017 Estimated Payroll": null,
    "2017 Estimated Operating Expenses": null,
  },
  {
    "Airport Name": "Jim Hogg County",
    "2017 Full Time Employees": 1,
    "2017 Part Time Employees": null,
    FTE: 1,
    "2017 Estimated Payroll": null,
    "2017 Estimated Operating Expenses": null,
  },
  {
    "Airport Name": "Jones Field Airport",
    "2017 Full Time Employees": 0,
    "2017 Part Time Employees": 1,
    FTE: 0.5,
    "2017 Estimated Payroll": 0,
    "2017 Estimated Operating Expenses": 33011,
  },
  {
    "Airport Name": "Karnes County",
    "2017 Full Time Employees": null,
    "2017 Part Time Employees": null,
    FTE: 0,
    "2017 Estimated Payroll": null,
    "2017 Estimated Operating Expenses": null,
  },
  {
    "Airport Name": "Kelly Field",
    "2017 Full Time Employees": null,
    "2017 Part Time Employees": null,
    FTE: 0,
    "2017 Estimated Payroll": null,
    "2017 Estimated Operating Expenses": null,
  },
  {
    "Airport Name": "Kenedy Regional",
    "2017 Full Time Employees": 1,
    "2017 Part Time Employees": 1,
    FTE: 1.5,
    "2017 Estimated Payroll": null,
    "2017 Estimated Operating Expenses": null,
  },
  {
    "Airport Name": "Kent County",
    "2017 Full Time Employees": 0,
    "2017 Part Time Employees": 0,
    FTE: 0,
    "2017 Estimated Payroll": 0,
    "2017 Estimated Operating Expenses": 4500,
  },
  {
    "Airport Name": "Kerrville-Kerr County",
    "2017 Full Time Employees": 2,
    "2017 Part Time Employees": 1,
    FTE: 2.5,
    "2017 Estimated Payroll": 150000,
    "2017 Estimated Operating Expenses": 400000,
  },
  {
    "Airport Name": "Kickapoo Downtown",
    "2017 Full Time Employees": 4,
    "2017 Part Time Employees": 2,
    FTE: 5,
    "2017 Estimated Payroll": 273310,
    "2017 Estimated Operating Expenses": 1417990,
  },
  {
    "Airport Name": "Killeen-Ft. Hood Regional",
    "2017 Full Time Employees": 37,
    "2017 Part Time Employees": 0,
    FTE: 37,
    "2017 Estimated Payroll": 1732680,
    "2017 Estimated Operating Expenses": 2635205,
  },
  {
    "Airport Name": "Killeen-Skylark Field",
    "2017 Full Time Employees": 4,
    "2017 Part Time Employees": 0,
    FTE: 4,
    "2017 Estimated Payroll": 172656,
    "2017 Estimated Operating Expenses": 430188,
  },
  {
    "Airport Name": "Kimble County",
    "2017 Full Time Employees": 1,
    "2017 Part Time Employees": 4,
    FTE: 3,
    "2017 Estimated Payroll": 15659.26,
    "2017 Estimated Operating Expenses": 176995.55,
  },
  {
    "Airport Name": "Kirbyville Airport",
    "2017 Full Time Employees": 0,
    "2017 Part Time Employees": 1,
    FTE: 0.5,
    "2017 Estimated Payroll": null,
    "2017 Estimated Operating Expenses": 30000,
  },
  {
    "Airport Name": "Kleberg County Airport",
    "2017 Full Time Employees": null,
    "2017 Part Time Employees": 1,
    FTE: 0.5,
    "2017 Estimated Payroll": 15000,
    "2017 Estimated Operating Expenses": 24000,
  },
  {
    "Airport Name": "La Porte Municipal",
    "2017 Full Time Employees": 2,
    "2017 Part Time Employees": 0,
    FTE: 2,
    "2017 Estimated Payroll": 18700,
    "2017 Estimated Operating Expenses": 129273,
  },
  {
    "Airport Name": "Lago Vista Rusty Allen Airport",
    "2017 Full Time Employees": 0,
    "2017 Part Time Employees": 3,
    FTE: 1.5,
    "2017 Estimated Payroll": null,
    "2017 Estimated Operating Expenses": 550393,
  },
  {
    "Airport Name": "Lamesa Municipal Airport",
    "2017 Full Time Employees": 0,
    "2017 Part Time Employees": 1,
    FTE: 0.5,
    "2017 Estimated Payroll": 4000,
    "2017 Estimated Operating Expenses": 38000,
  },
  {
    "Airport Name": "Lampasas Municipal Airport",
    "2017 Full Time Employees": 0,
    "2017 Part Time Employees": 0,
    FTE: 0,
    "2017 Estimated Payroll": 0,
    "2017 Estimated Operating Expenses": 135465,
  },
  {
    "Airport Name": "Lancaster Regional",
    "2017 Full Time Employees": 3,
    "2017 Part Time Employees": 3,
    FTE: 4.5,
    "2017 Estimated Payroll": 250000,
    "2017 Estimated Operating Expenses": 700000,
  },
  {
    "Airport Name": "Laredo International",
    "2017 Full Time Employees": 60,
    "2017 Part Time Employees": 0,
    FTE: 60,
    "2017 Estimated Payroll": 3024089,
    "2017 Estimated Operating Expenses": 6970891,
  },
  {
    "Airport Name": "Levelland Municipal",
    "2017 Full Time Employees": 2,
    "2017 Part Time Employees": 3,
    FTE: 3.5,
    "2017 Estimated Payroll": null,
    "2017 Estimated Operating Expenses": null,
  },
  {
    "Airport Name": "Liberty Municipal",
    "2017 Full Time Employees": null,
    "2017 Part Time Employees": 1,
    FTE: 0.5,
    "2017 Estimated Payroll": null,
    "2017 Estimated Operating Expenses": null,
  },
  {
    "Airport Name": "Live Oak County",
    "2017 Full Time Employees": null,
    "2017 Part Time Employees": 1,
    FTE: 0.5,
    "2017 Estimated Payroll": null,
    "2017 Estimated Operating Expenses": null,
  },
  {
    "Airport Name": "Livingston Municipal",
    "2017 Full Time Employees": null,
    "2017 Part Time Employees": 1,
    FTE: 0.5,
    "2017 Estimated Payroll": null,
    "2017 Estimated Operating Expenses": null,
  },
  {
    "Airport Name": "Llano Municipal Airport",
    "2017 Full Time Employees": 1,
    "2017 Part Time Employees": 1,
    FTE: 1.5,
    "2017 Estimated Payroll": 44211,
    "2017 Estimated Operating Expenses": 392660,
  },
  {
    "Airport Name": "Lockhart Municipal",
    "2017 Full Time Employees": 1,
    "2017 Part Time Employees": 1,
    FTE: 1.5,
    "2017 Estimated Payroll": null,
    "2017 Estimated Operating Expenses": null,
  },
  {
    "Airport Name": "Lubbock Preston Smith International",
    "2017 Full Time Employees": 49,
    "2017 Part Time Employees": 1,
    FTE: 49.5,
    "2017 Estimated Payroll": 3101139,
    "2017 Estimated Operating Expenses": 10365671,
  },
  {
    "Airport Name": "Madisonville Municipal",
    "2017 Full Time Employees": 0,
    "2017 Part Time Employees": 0,
    FTE: 0,
    "2017 Estimated Payroll": 18000,
    "2017 Estimated Operating Expenses": 25000,
  },
  {
    "Airport Name": "Major Samuel B Cornelius Field",
    "2017 Full Time Employees": 1,
    "2017 Part Time Employees": null,
    FTE: 1,
    "2017 Estimated Payroll": 66581,
    "2017 Estimated Operating Expenses": 160927,
  },
  {
    "Airport Name": "Marfa Municipal",
    "2017 Full Time Employees": 1,
    "2017 Part Time Employees": 3,
    FTE: 2.5,
    "2017 Estimated Payroll": 99200,
    "2017 Estimated Operating Expenses": 142200,
  },
  {
    "Airport Name": "Marian Airpark",
    "2017 Full Time Employees": null,
    "2017 Part Time Employees": 1,
    FTE: 0.5,
    "2017 Estimated Payroll": null,
    "2017 Estimated Operating Expenses": null,
  },
  {
    "Airport Name": "Marlin",
    "2017 Full Time Employees": null,
    "2017 Part Time Employees": 1,
    FTE: 0.5,
    "2017 Estimated Payroll": null,
    "2017 Estimated Operating Expenses": null,
  },
  {
    "Airport Name": "Mason County Airport",
    "2017 Full Time Employees": null,
    "2017 Part Time Employees": null,
    FTE: 0,
    "2017 Estimated Payroll": null,
    "2017 Estimated Operating Expenses": 85000,
  },
  {
    "Airport Name": "Maverick County Memorial International",
    "2017 Full Time Employees": 1,
    "2017 Part Time Employees": 1,
    FTE: 1.5,
    "2017 Estimated Payroll": null,
    "2017 Estimated Operating Expenses": null,
  },
  {
    "Airport Name": "McAllen Miller International",
    "2017 Full Time Employees": 38,
    "2017 Part Time Employees": 6,
    FTE: 41,
    "2017 Estimated Payroll": 1756815,
    "2017 Estimated Operating Expenses": 3993306,
  },
  {
    "Airport Name": "McCampbell-Porter Airport",
    "2017 Full Time Employees": 2,
    "2017 Part Time Employees": 1,
    FTE: 2.5,
    "2017 Estimated Payroll": 106000,
    "2017 Estimated Operating Expenses": 600000,
  },
  {
    "Airport Name": "McGregor Executive",
    "2017 Full Time Employees": 1,
    "2017 Part Time Employees": 0,
    FTE: 1,
    "2017 Estimated Payroll": 66753,
    "2017 Estimated Operating Expenses": 220468,
  },
  {
    "Airport Name": "McKinley Field",
    "2017 Full Time Employees": 1,
    "2017 Part Time Employees": 1,
    FTE: 1.5,
    "2017 Estimated Payroll": null,
    "2017 Estimated Operating Expenses": null,
  },
  {
    "Airport Name": "McKinney National",
    "2017 Full Time Employees": 24,
    "2017 Part Time Employees": 0,
    FTE: 24,
    "2017 Estimated Payroll": 1984778,
    "2017 Estimated Operating Expenses": 4501587,
  },
  {
    "Airport Name": "McLean/Gray County",
    "2017 Full Time Employees": null,
    "2017 Part Time Employees": 1,
    FTE: 0.5,
    "2017 Estimated Payroll": null,
    "2017 Estimated Operating Expenses": null,
  },
  {
    "Airport Name": "Memphis Municipal",
    "2017 Full Time Employees": null,
    "2017 Part Time Employees": 1,
    FTE: 0.5,
    "2017 Estimated Payroll": null,
    "2017 Estimated Operating Expenses": null,
  },
  {
    "Airport Name": "Menard County Airport",
    "2017 Full Time Employees": 0,
    "2017 Part Time Employees": 3,
    FTE: 1.5,
    "2017 Estimated Payroll": 13383,
    "2017 Estimated Operating Expenses": 20460,
  },
  {
    "Airport Name": "Mesquite Metro",
    "2017 Full Time Employees": 2,
    "2017 Part Time Employees": 9,
    FTE: 6.5,
    "2017 Estimated Payroll": 435500,
    "2017 Estimated Operating Expenses": 1577000,
  },
  {
    "Airport Name": "Mexia-Limestone County",
    "2017 Full Time Employees": 0,
    "2017 Part Time Employees": 0,
    FTE: 0,
    "2017 Estimated Payroll": 0,
    "2017 Estimated Operating Expenses": 68148,
  },
  {
    "Airport Name": "Miami-Roberts County",
    "2017 Full Time Employees": 0,
    "2017 Part Time Employees": 0,
    FTE: 0,
    "2017 Estimated Payroll": 0,
    "2017 Estimated Operating Expenses": 5000,
  },
  {
    "Airport Name": "Mid Valley",
    "2017 Full Time Employees": null,
    "2017 Part Time Employees": null,
    FTE: 0,
    "2017 Estimated Payroll": null,
    "2017 Estimated Operating Expenses": null,
  },
  {
    "Airport Name": "Midland Airpark",
    "2017 Full Time Employees": 0,
    "2017 Part Time Employees": 0.5,
    FTE: 0.25,
    "2017 Estimated Payroll": null,
    "2017 Estimated Operating Expenses": null,
  },
  {
    "Airport Name": "Midland International Air & Space Port",
    "2017 Full Time Employees": 30,
    "2017 Part Time Employees": 6,
    FTE: 33,
    "2017 Estimated Payroll": 1995959,
    "2017 Estimated Operating Expenses": 9452419,
  },
  {
    "Airport Name": "Mid-way Regional",
    "2017 Full Time Employees": 3,
    "2017 Part Time Employees": 0,
    FTE: 3,
    "2017 Estimated Payroll": 190000,
    "2017 Estimated Operating Expenses": 484000,
  },
  {
    "Airport Name": "Mineral Wells Airport",
    "2017 Full Time Employees": 4,
    "2017 Part Time Employees": 2,
    FTE: 5,
    "2017 Estimated Payroll": 242092,
    "2017 Estimated Operating Expenses": 870000,
  },
  {
    "Airport Name": "Moore County Airport",
    "2017 Full Time Employees": 2,
    "2017 Part Time Employees": 1,
    FTE: 2.5,
    "2017 Estimated Payroll": 142450.61,
    "2017 Estimated Operating Expenses": 1166744.36,
  },
  {
    "Airport Name": "Mt. Pleasant Regional Airport",
    "2017 Full Time Employees": 3,
    "2017 Part Time Employees": 1,
    FTE: 3.5,
    "2017 Estimated Payroll": 220697,
    "2017 Estimated Operating Expenses": 321147,
  },
  {
    "Airport Name": "Muleshoe Municipal",
    "2017 Full Time Employees": null,
    "2017 Part Time Employees": 1,
    FTE: 0.5,
    "2017 Estimated Payroll": null,
    "2017 Estimated Operating Expenses": null,
  },
  {
    "Airport Name": "Munday Municipal Airport",
    "2017 Full Time Employees": 0,
    "2017 Part Time Employees": 0,
    FTE: 0,
    "2017 Estimated Payroll": 0,
    "2017 Estimated Operating Expenses": 200,
  },
  {
    "Airport Name": "Mustang Beach",
    "2017 Full Time Employees": null,
    "2017 Part Time Employees": 1,
    FTE: 0.5,
    "2017 Estimated Payroll": null,
    "2017 Estimated Operating Expenses": null,
  },
  {
    "Airport Name": "Navasota Municipal Airport",
    "2017 Full Time Employees": 0,
    "2017 Part Time Employees": 2,
    FTE: 1,
    "2017 Estimated Payroll": 13353,
    "2017 Estimated Operating Expenses": 48000,
  },
  {
    "Airport Name": "New Braunfels Regional",
    "2017 Full Time Employees": 7,
    "2017 Part Time Employees": 0,
    FTE: 7,
    "2017 Estimated Payroll": null,
    "2017 Estimated Operating Expenses": 2602000,
  },
  {
    "Airport Name": "Newton Municipal",
    "2017 Full Time Employees": null,
    "2017 Part Time Employees": 0.5,
    FTE: 0.25,
    "2017 Estimated Payroll": null,
    "2017 Estimated Operating Expenses": null,
  },
  {
    "Airport Name": "North Texas Regional Airport",
    "2017 Full Time Employees": 3,
    "2017 Part Time Employees": 3,
    FTE: 4.5,
    "2017 Estimated Payroll": 277131,
    "2017 Estimated Operating Expenses": 989786,
  },
  {
    "Airport Name": "Northwest Regional Airport",
    "2017 Full Time Employees": 1,
    "2017 Part Time Employees": 0,
    FTE: 1,
    "2017 Estimated Payroll": 24000,
    "2017 Estimated Operating Expenses": 251200,
  },
  {
    "Airport Name": "Nueces County Airport",
    "2017 Full Time Employees": 1,
    "2017 Part Time Employees": 0,
    FTE: 1,
    "2017 Estimated Payroll": 57485,
    "2017 Estimated Operating Expenses": 65598,
  },
  {
    "Airport Name": "Odessa-Schlemeyer Field",
    "2017 Full Time Employees": 2,
    "2017 Part Time Employees": 2,
    FTE: 3,
    "2017 Estimated Payroll": 120363,
    "2017 Estimated Operating Expenses": 649258,
  },
  {
    "Airport Name": "Oldham County",
    "2017 Full Time Employees": null,
    "2017 Part Time Employees": 1,
    FTE: 0.5,
    "2017 Estimated Payroll": null,
    "2017 Estimated Operating Expenses": null,
  },
  {
    "Airport Name": "Olney Municipal",
    "2017 Full Time Employees": 1,
    "2017 Part Time Employees": 0,
    FTE: 1,
    "2017 Estimated Payroll": 0,
    "2017 Estimated Operating Expenses": 0,
  },
  {
    "Airport Name": "Orange County Airport",
    "2017 Full Time Employees": 1,
    "2017 Part Time Employees": 0,
    FTE: 1,
    "2017 Estimated Payroll": 52000,
    "2017 Estimated Operating Expenses": 143400,
  },
  {
    "Airport Name": "Ozona Municipal",
    "2017 Full Time Employees": null,
    "2017 Part Time Employees": 1,
    FTE: 0.5,
    "2017 Estimated Payroll": null,
    "2017 Estimated Operating Expenses": null,
  },
  {
    "Airport Name": "Palacios Municipal Airport",
    "2017 Full Time Employees": null,
    "2017 Part Time Employees": 2,
    FTE: 1,
    "2017 Estimated Payroll": 3731,
    "2017 Estimated Operating Expenses": 51912,
  },
  {
    "Airport Name": "Palestine Municipal Airport",
    "2017 Full Time Employees": null,
    "2017 Part Time Employees": 1,
    FTE: 0.5,
    "2017 Estimated Payroll": null,
    "2017 Estimated Operating Expenses": null,
  },
  {
    "Airport Name": "Panhandle-Carson County Airport",
    "2017 Full Time Employees": 0,
    "2017 Part Time Employees": 0,
    FTE: 0,
    "2017 Estimated Payroll": 0,
    "2017 Estimated Operating Expenses": 26500,
  },
  {
    "Airport Name": "Panola County-Sharpe Field",
    "2017 Full Time Employees": 1,
    "2017 Part Time Employees": null,
    FTE: 1,
    "2017 Estimated Payroll": null,
    "2017 Estimated Operating Expenses": null,
  },
  {
    "Airport Name": "Pearland Regional",
    "2017 Full Time Employees": 3,
    "2017 Part Time Employees": 0,
    FTE: 3,
    "2017 Estimated Payroll": 121000,
    "2017 Estimated Operating Expenses": 900000,
  },
  {
    "Airport Name": "Pecos Municipal",
    "2017 Full Time Employees": 2,
    "2017 Part Time Employees": 1,
    FTE: 2.5,
    "2017 Estimated Payroll": 100000,
    "2017 Estimated Operating Expenses": 600000,
  },
  {
    "Airport Name": "Perry Lefors Field",
    "2017 Full Time Employees": null,
    "2017 Part Time Employees": 1,
    FTE: 0.5,
    "2017 Estimated Payroll": null,
    "2017 Estimated Operating Expenses": null,
  },
  {
    "Airport Name": "Perryton/Ochiltree County Airport",
    "2017 Full Time Employees": 1,
    "2017 Part Time Employees": 0,
    FTE: 1,
    "2017 Estimated Payroll": 17500,
    "2017 Estimated Operating Expenses": 66000,
  },
  {
    "Airport Name": "Pineland Municipal Airport",
    "2017 Full Time Employees": 0,
    "2017 Part Time Employees": 0,
    FTE: 0,
    "2017 Estimated Payroll": 0,
    "2017 Estimated Operating Expenses": 6000,
  },
  {
    "Airport Name": "Pleasanton Municpal Airport",
    "2017 Full Time Employees": 1,
    "2017 Part Time Employees": 0,
    FTE: 1,
    "2017 Estimated Payroll": 25000,
    "2017 Estimated Operating Expenses": 56500,
  },
  {
    "Airport Name": "Port Isabel Camreon County Airport",
    "2017 Full Time Employees": 1,
    "2017 Part Time Employees": 1,
    FTE: 1.5,
    "2017 Estimated Payroll": 5602,
    "2017 Estimated Operating Expenses": 43000,
  },
  {
    "Airport Name": "Possum Kingdom Airport",
    "2017 Full Time Employees": 0,
    "2017 Part Time Employees": 0,
    FTE: 0,
    "2017 Estimated Payroll": 0,
    "2017 Estimated Operating Expenses": 14585,
  },
  {
    "Airport Name": "Post/Garza County Airport",
    "2017 Full Time Employees": 0,
    "2017 Part Time Employees": 0,
    FTE: 0,
    "2017 Estimated Payroll": 0,
    "2017 Estimated Operating Expenses": 10000,
  },
  {
    "Airport Name": "Presidio Lely international",
    "2017 Full Time Employees": 0,
    "2017 Part Time Employees": 2,
    FTE: 1,
    "2017 Estimated Payroll": 25800,
    "2017 Estimated Operating Expenses": 47800,
  },
  {
    "Airport Name": "Quanah Municipal",
    "2017 Full Time Employees": 0,
    "2017 Part Time Employees": 1,
    FTE: 0.5,
    "2017 Estimated Payroll": 10000,
    "2017 Estimated Operating Expenses": 66771,
  },
  {
    "Airport Name": "Ralph M. Hall / Rockwall Municipal Airport",
    "2017 Full Time Employees": 0,
    "2017 Part Time Employees": 0,
    FTE: 0,
    "2017 Estimated Payroll": 0,
    "2017 Estimated Operating Expenses": 49000,
  },
  {
    "Airport Name": "Reagan County",
    "2017 Full Time Employees": null,
    "2017 Part Time Employees": 1,
    FTE: 0.5,
    "2017 Estimated Payroll": null,
    "2017 Estimated Operating Expenses": null,
  },
  {
    "Airport Name": "Real County",
    "2017 Full Time Employees": null,
    "2017 Part Time Employees": null,
    FTE: 0,
    "2017 Estimated Payroll": null,
    "2017 Estimated Operating Expenses": null,
  },
  {
    "Airport Name": "Refugio County Airport-Rooke Field",
    "2017 Full Time Employees": 0,
    "2017 Part Time Employees": 0,
    FTE: 0,
    "2017 Estimated Payroll": 0,
    "2017 Estimated Operating Expenses": 125164,
  },
  {
    "Airport Name": "Rick Husband Amarillo Int'l Airport",
    "2017 Full Time Employees": 53,
    "2017 Part Time Employees": 0,
    FTE: 53,
    "2017 Estimated Payroll": 3400000,
    "2017 Estimated Operating Expenses": 7272000,
  },
  {
    "Airport Name": "Rio Grande City Municipal Airport",
    "2017 Full Time Employees": 1,
    "2017 Part Time Employees": 0,
    FTE: 1,
    "2017 Estimated Payroll": 6000,
    "2017 Estimated Operating Expenses": 5000,
  },
  {
    "Airport Name": "Robert Lee",
    "2017 Full Time Employees": null,
    "2017 Part Time Employees": 1,
    FTE: 0.5,
    "2017 Estimated Payroll": null,
    "2017 Estimated Operating Expenses": null,
  },
  {
    "Airport Name": "Robert R. Wells",
    "2017 Full Time Employees": 2,
    "2017 Part Time Employees": 0,
    FTE: 2,
    "2017 Estimated Payroll": 6000,
    "2017 Estimated Operating Expenses": 51000,
  },
  {
    "Airport Name": "Roger M. Dreyer Memorial",
    "2017 Full Time Employees": null,
    "2017 Part Time Employees": 1,
    FTE: 0.5,
    "2017 Estimated Payroll": null,
    "2017 Estimated Operating Expenses": null,
  },
  {
    "Airport Name": "Ron Duncan",
    "2017 Full Time Employees": 0,
    "2017 Part Time Employees": 0,
    FTE: 0,
    "2017 Estimated Payroll": 0,
    "2017 Estimated Operating Expenses": 55583,
  },
  {
    "Airport Name": "Roy Hurd Memorial",
    "2017 Full Time Employees": 0,
    "2017 Part Time Employees": 0,
    FTE: 0,
    "2017 Estimated Payroll": null,
    "2017 Estimated Operating Expenses": 24500,
  },
  {
    "Airport Name": "Rusk County Airport",
    "2017 Full Time Employees": 2,
    "2017 Part Time Employees": 1,
    FTE: 2.5,
    "2017 Estimated Payroll": 90000,
    "2017 Estimated Operating Expenses": 600000,
  },
  {
    "Airport Name": "San Angelo Regional - Mathis Field",
    "2017 Full Time Employees": 11,
    "2017 Part Time Employees": 0,
    FTE: 11,
    "2017 Estimated Payroll": 294377,
    "2017 Estimated Operating Expenses": 90699,
  },
  {
    "Airport Name": "San Antonio International",
    "2017 Full Time Employees": null,
    "2017 Part Time Employees": null,
    FTE: 0,
    "2017 Estimated Payroll": null,
    "2017 Estimated Operating Expenses": null,
  },
  {
    "Airport Name": "San Augustine County",
    "2017 Full Time Employees": null,
    "2017 Part Time Employees": 1,
    FTE: 0.5,
    "2017 Estimated Payroll": null,
    "2017 Estimated Operating Expenses": null,
  },
  {
    "Airport Name": "San Marcos Regional",
    "2017 Full Time Employees": 6,
    "2017 Part Time Employees": 1,
    FTE: 6.5,
    "2017 Estimated Payroll": 250000,
    "2017 Estimated Operating Expenses": 450000,
  },
  {
    "Airport Name": "San Saba Municipal Airport",
    "2017 Full Time Employees": 0,
    "2017 Part Time Employees": 0,
    FTE: 0,
    "2017 Estimated Payroll": 0,
    "2017 Estimated Operating Expenses": 7000,
  },
  {
    "Airport Name": "Scholes Intl at Galveston",
    "2017 Full Time Employees": 8,
    "2017 Part Time Employees": 0,
    FTE: 8,
    "2017 Estimated Payroll": 442723,
    "2017 Estimated Operating Expenses": 1003434,
  },
  {
    "Airport Name": "Scurry County/Winston Field",
    "2017 Full Time Employees": 3,
    "2017 Part Time Employees": 2,
    FTE: 4,
    "2017 Estimated Payroll": 213325,
    "2017 Estimated Operating Expenses": 479750,
  },
  {
    "Airport Name": "Seymour Municipal Airport",
    "2017 Full Time Employees": 3,
    "2017 Part Time Employees": 0,
    FTE: 3,
    "2017 Estimated Payroll": 7500,
    "2017 Estimated Operating Expenses": 10500,
  },
  {
    "Airport Name": "Sheppard AFB/Wichita Falls Municipal",
    "2017 Full Time Employees": 2,
    "2017 Part Time Employees": 2,
    FTE: 3,
    "2017 Estimated Payroll": 165726,
    "2017 Estimated Operating Expenses": 1315963,
  },
  {
    "Airport Name": "Sherman Municipal",
    "2017 Full Time Employees": 1,
    "2017 Part Time Employees": null,
    FTE: 1,
    "2017 Estimated Payroll": null,
    "2017 Estimated Operating Expenses": null,
  },
  {
    "Airport Name": "Slaton Municipal",
    "2017 Full Time Employees": null,
    "2017 Part Time Employees": 1,
    FTE: 0.5,
    "2017 Estimated Payroll": null,
    "2017 Estimated Operating Expenses": null,
  },
  {
    "Airport Name": "Smiley Johnson/Bass Field",
    "2017 Full Time Employees": 0,
    "2017 Part Time Employees": 0,
    FTE: 0,
    "2017 Estimated Payroll": 0,
    "2017 Estimated Operating Expenses": 2721,
  },
  {
    "Airport Name": "Smithville Crawford Municipal Airport",
    "2017 Full Time Employees": 0,
    "2017 Part Time Employees": 0,
    FTE: 0,
    "2017 Estimated Payroll": 0,
    "2017 Estimated Operating Expenses": 46810,
  },
  {
    "Airport Name": "Sonora Airport",
    "2017 Full Time Employees": 0,
    "2017 Part Time Employees": 1,
    FTE: 0.5,
    "2017 Estimated Payroll": 5000,
    "2017 Estimated Operating Expenses": 57761,
  },
  {
    "Airport Name": "South Texas Intl at Edinburg",
    "2017 Full Time Employees": 6,
    "2017 Part Time Employees": 0,
    FTE: 6,
    "2017 Estimated Payroll": 263603,
    "2017 Estimated Operating Expenses": 680774,
  },
  {
    "Airport Name": "South Texas Regional Airport",
    "2017 Full Time Employees": 4,
    "2017 Part Time Employees": 0,
    FTE: 4,
    "2017 Estimated Payroll": 223328,
    "2017 Estimated Operating Expenses": 772852,
  },
  {
    "Airport Name": "Stanton Municipal",
    "2017 Full Time Employees": 0,
    "2017 Part Time Employees": 0,
    FTE: 0,
    "2017 Estimated Payroll": 0,
    "2017 Estimated Operating Expenses": 7225,
  },
  {
    "Airport Name": "Stephens County",
    "2017 Full Time Employees": null,
    "2017 Part Time Employees": 1,
    FTE: 0.5,
    "2017 Estimated Payroll": null,
    "2017 Estimated Operating Expenses": null,
  },
  {
    "Airport Name": "Stephenville Clark Regional Airport",
    "2017 Full Time Employees": 0,
    "2017 Part Time Employees": 2,
    FTE: 1,
    "2017 Estimated Payroll": 12000,
    "2017 Estimated Operating Expenses": 103566,
  },
  {
    "Airport Name": "Stinson Municipal",
    "2017 Full Time Employees": 8,
    "2017 Part Time Employees": 0,
    FTE: 8,
    "2017 Estimated Payroll": 485000,
    "2017 Estimated Operating Expenses": 1000000,
  },
  {
    "Airport Name": "Stonewall County",
    "2017 Full Time Employees": null,
    "2017 Part Time Employees": 1,
    FTE: 0.5,
    "2017 Estimated Payroll": null,
    "2017 Estimated Operating Expenses": null,
  },
  {
    "Airport Name": "Sugarland Regional",
    "2017 Full Time Employees": 44,
    "2017 Part Time Employees": 1,
    FTE: 44.5,
    "2017 Estimated Payroll": 2656076,
    "2017 Estimated Operating Expenses": 10195369,
  },
  {
    "Airport Name": "Sulphur Springs Municipal",
    "2017 Full Time Employees": 1,
    "2017 Part Time Employees": 5,
    FTE: 3.5,
    "2017 Estimated Payroll": 90000,
    "2017 Estimated Operating Expenses": 502800,
  },
  {
    "Airport Name": "Sunray Municipal",
    "2017 Full Time Employees": null,
    "2017 Part Time Employees": 1,
    FTE: 0.5,
    "2017 Estimated Payroll": null,
    "2017 Estimated Operating Expenses": null,
  },
  {
    "Airport Name": "Taylor Brown Muncipal",
    "2017 Full Time Employees": null,
    "2017 Part Time Employees": 1,
    FTE: 0.5,
    "2017 Estimated Payroll": null,
    "2017 Estimated Operating Expenses": null,
  },
  {
    "Airport Name": "Taylor Municipal",
    "2017 Full Time Employees": 1,
    "2017 Part Time Employees": 1,
    FTE: 1.5,
    "2017 Estimated Payroll": 47500,
    "2017 Estimated Operating Expenses": 397000,
  },
  {
    "Airport Name": "T-Bar Airport",
    "2017 Full Time Employees": 0,
    "2017 Part Time Employees": 0,
    FTE: 0,
    "2017 Estimated Payroll": 0,
    "2017 Estimated Operating Expenses": 1000,
  },
  {
    "Airport Name": "Teague Municipal Airport",
    "2017 Full Time Employees": 0,
    "2017 Part Time Employees": 0,
    FTE: 0,
    "2017 Estimated Payroll": 0,
    "2017 Estimated Operating Expenses": 11677.91,
  },
  {
    "Airport Name": "Terrell County Airport",
    "2017 Full Time Employees": 1,
    "2017 Part Time Employees": 0,
    FTE: 1,
    "2017 Estimated Payroll": 14000,
    "2017 Estimated Operating Expenses": 24000,
  },
  {
    "Airport Name": "Terrell Municipal Airport",
    "2017 Full Time Employees": 4,
    "2017 Part Time Employees": 4,
    FTE: 6,
    "2017 Estimated Payroll": 0,
    "2017 Estimated Operating Expenses": 344998,
  },
  {
    "Airport Name": "Terry County",
    "2017 Full Time Employees": 0,
    "2017 Part Time Employees": 1,
    FTE: 0.5,
    "2017 Estimated Payroll": 13474,
    "2017 Estimated Operating Expenses": 205306,
  },
  {
    "Airport Name": "Texarkana Regional Airport- Webb Field",
    "2017 Full Time Employees": null,
    "2017 Part Time Employees": null,
    FTE: 0,
    "2017 Estimated Payroll": 635000,
    "2017 Estimated Operating Expenses": 602127,
  },
  {
    "Airport Name": "Texas Gulf Coast Regional",
    "2017 Full Time Employees": 12,
    "2017 Part Time Employees": 1,
    FTE: 12.5,
    "2017 Estimated Payroll": 959330,
    "2017 Estimated Operating Expenses": 3741000,
  },
  {
    "Airport Name": "The Carter Memorial",
    "2017 Full Time Employees": null,
    "2017 Part Time Employees": 1,
    FTE: 0.5,
    "2017 Estimated Payroll": null,
    "2017 Estimated Operating Expenses": null,
  },
  {
    "Airport Name": "Throckmorton Municipal",
    "2017 Full Time Employees": 0,
    "2017 Part Time Employees": 0,
    FTE: 0,
    "2017 Estimated Payroll": 0,
    "2017 Estimated Operating Expenses": 6700,
  },
  {
    "Airport Name": "Tradewind",
    "2017 Full Time Employees": 5,
    "2017 Part Time Employees": null,
    FTE: 5,
    "2017 Estimated Payroll": null,
    "2017 Estimated Operating Expenses": null,
  },
  {
    "Airport Name": "TSTC Waco",
    "2017 Full Time Employees": 0,
    "2017 Part Time Employees": 10,
    FTE: 5,
    "2017 Estimated Payroll": 409000,
    "2017 Estimated Operating Expenses": 740000,
  },
  {
    "Airport Name": "Tyler County",
    "2017 Full Time Employees": 0,
    "2017 Part Time Employees": 0,
    FTE: 0,
    "2017 Estimated Payroll": 0,
    "2017 Estimated Operating Expenses": 13000,
  },
  {
    "Airport Name": "Tyler Pounds Regional",
    "2017 Full Time Employees": 10,
    "2017 Part Time Employees": 0,
    FTE: 10,
    "2017 Estimated Payroll": 394306,
    "2017 Estimated Operating Expenses": 1498340,
  },
  {
    "Airport Name": "Upton County Airport",
    "2017 Full Time Employees": 0,
    "2017 Part Time Employees": 1,
    FTE: 0.5,
    "2017 Estimated Payroll": 14600,
    "2017 Estimated Operating Expenses": 54000,
  },
  {
    "Airport Name": "Valley International",
    "2017 Full Time Employees": 45,
    "2017 Part Time Employees": 7,
    FTE: 48.5,
    "2017 Estimated Payroll": 2339000,
    "2017 Estimated Operating Expenses": 5882000,
  },
  {
    "Airport Name": "Van Zandt County Regional Airport",
    "2017 Full Time Employees": 0,
    "2017 Part Time Employees": 2,
    FTE: 1,
    "2017 Estimated Payroll": 12000,
    "2017 Estimated Operating Expenses": 30000,
  },
  {
    "Airport Name": "Victoria Regional",
    "2017 Full Time Employees": 17,
    "2017 Part Time Employees": 0,
    FTE: 17,
    "2017 Estimated Payroll": 696735,
    "2017 Estimated Operating Expenses": 5338157,
  },
  {
    "Airport Name": "Waco Regional",
    "2017 Full Time Employees": 9,
    "2017 Part Time Employees": 0,
    FTE: 9,
    "2017 Estimated Payroll": 900000,
    "2017 Estimated Operating Expenses": 400000,
  },
  {
    "Airport Name": "Weslaco Mid-Valley",
    "2017 Full Time Employees": 4,
    "2017 Part Time Employees": 0,
    FTE: 4,
    "2017 Estimated Payroll": 194814,
    "2017 Estimated Operating Expenses": 480100,
  },
  {
    "Airport Name": "West Houston Airport",
    "2017 Full Time Employees": 32,
    "2017 Part Time Employees": 0,
    FTE: 32,
    "2017 Estimated Payroll": null,
    "2017 Estimated Operating Expenses": null,
  },
  {
    "Airport Name": "Wharton Regional Airport",
    "2017 Full Time Employees": 1,
    "2017 Part Time Employees": 1,
    FTE: 1.5,
    "2017 Estimated Payroll": 88107,
    "2017 Estimated Operating Expenses": 290101.18,
  },
  {
    "Airport Name": "Wheeler Municipal",
    "2017 Full Time Employees": null,
    "2017 Part Time Employees": 1,
    FTE: 0.5,
    "2017 Estimated Payroll": null,
    "2017 Estimated Operating Expenses": null,
  },
  {
    "Airport Name": "Wilbarger County Airport",
    "2017 Full Time Employees": 2,
    "2017 Part Time Employees": 2,
    FTE: 3,
    "2017 Estimated Payroll": 112288,
    "2017 Estimated Operating Expenses": 364582,
  },
  {
    "Airport Name": "William P Hobby",
    "2017 Full Time Employees": null,
    "2017 Part Time Employees": null,
    FTE: 0,
    "2017 Estimated Payroll": null,
    "2017 Estimated Operating Expenses": null,
  },
  {
    "Airport Name": "Winkler County",
    "2017 Full Time Employees": null,
    "2017 Part Time Employees": 1,
    FTE: 0.5,
    "2017 Estimated Payroll": null,
    "2017 Estimated Operating Expenses": null,
  },
  {
    "Airport Name": "Winnsboro Municipal Airport",
    "2017 Full Time Employees": 0,
    "2017 Part Time Employees": 0,
    FTE: 0,
    "2017 Estimated Payroll": 0,
    "2017 Estimated Operating Expenses": 35000,
  },
  {
    "Airport Name": "Winston Field",
    "2017 Full Time Employees": null,
    "2017 Part Time Employees": null,
    FTE: 0,
    "2017 Estimated Payroll": 213325,
    "2017 Estimated Operating Expenses": 479750,
  },
  {
    "Airport Name": "Winters Municipal",
    "2017 Full Time Employees": null,
    "2017 Part Time Employees": 1,
    FTE: 0.5,
    "2017 Estimated Payroll": null,
    "2017 Estimated Operating Expenses": null,
  },
  {
    "Airport Name": "Wood County",
    "2017 Full Time Employees": null,
    "2017 Part Time Employees": 1,
    FTE: 0.5,
    "2017 Estimated Payroll": null,
    "2017 Estimated Operating Expenses": null,
  },
  {
    "Airport Name": "Yoakum County Airport",
    "2017 Full Time Employees": 0,
    "2017 Part Time Employees": 2,
    FTE: 1,
    "2017 Estimated Payroll": 10000,
    "2017 Estimated Operating Expenses": 30000,
  },
  {
    "Airport Name": "Yoakum Muni",
    "2017 Full Time Employees": 0,
    "2017 Part Time Employees": 0,
    FTE: 0,
    "2017 Estimated Payroll": 0,
    "2017 Estimated Operating Expenses": 8605,
  },
  {
    "Airport Name": "Zapata County Airport",
    "2017 Full Time Employees": 1,
    "2017 Part Time Employees": 1,
    FTE: 1.5,
    "2017 Estimated Payroll": 49597,
    "2017 Estimated Operating Expenses": 151175,
  },
];
export const TENANT_ACTIVITY = [
  {
    "Airport Name": "A. L. Mangham Jr. Regional Airport",
    County: "Nacogdoches",
    Region: "East Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Abilene Regional Airport",
    County: "Taylor, Jones",
    Region: "West Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 5,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 84,
    "Aircraft Support Activities": 463.5,
    "Hangar Rental and Development": 0,
    "Rental Car": 17,
    "Other Professional Services": 0,
    "Aviation Schools": 2,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 2,
    "Parking Garage and Lots": 0,
    "Government Employment": 15.5,
    Other: 0,
  },
  {
    "Airport Name": "Addison Airport",
    County: "Dallas",
    Region: "North Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 468,
    "Aircraft Support Activities": 259.5,
    "Hangar Rental and Development": 10.5,
    "Rental Car": 14,
    "Other Professional Services": 0,
    "Aviation Schools": 153,
    "Air Ambulance": 0,
    Museum: 15,
    "Other amusement and recreation industries": 1,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 138,
    Other: 0,
  },
  {
    "Airport Name": "Albany Municipal",
    County: "Shackelford",
    Region: "West Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 1,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Alfred C. Bubba Thomas Airport",
    County: "San Patricio",
    Region: "South Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 3,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 3.5,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Alice International Airport",
    County: "Jim Wells",
    Region: "South Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 4,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Alpine Casparis Municipal Airport",
    County: "Brewster",
    Region: "West Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 20,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 3,
    "Air Ambulance": 4,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 3,
    Other: 0,
  },
  {
    "Airport Name": "Andrews County Airport",
    County: "Andrews",
    Region: "West Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 2,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 2,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Angelina County Airport",
    County: "Angelina",
    Region: "East Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 10,
    "Aircraft Support Activities": 1,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 12,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 4.5,
    "Parking Garage and Lots": 0,
    "Government Employment": 2,
    Other: 0,
  },
  {
    "Airport Name": "Aransas County Airport",
    County: "Aransas",
    Region: "South Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 10,
    "Aircraft Support Activities": 2,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Arledge Field",
    County: "Haskell",
    Region: "West Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 2,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Arlington Municipal Airport",
    County: "Tarrant",
    Region: "North Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 221,
    "Aircraft Support Activities": 81,
    "Hangar Rental and Development": 3,
    "Rental Car": 5,
    "Other Professional Services": 0,
    "Aviation Schools": 16,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 2,
    Other: 0,
  },
  {
    "Airport Name": "Athens Municipal",
    County: "Henderson",
    Region: "East Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 5,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Austin-Bergstrom International",
    County: "Travis",
    Region: "Central Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 2237.350896,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 1864.45908,
    "Parking Garage and Lots": 0,
    "Government Employment": 3356.026343,
    Other: 0,
  },
  {
    "Airport Name": "Avenger Field",
    County: "Nolan",
    Region: "West Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 1,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 2,
    Other: 0,
  },
  {
    "Airport Name": "Bay City Regional Airport",
    County: "Matagorda",
    Region: "Upper Gulf Coast",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 12,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Beaumont Municipal",
    County: "Jefferson",
    Region: "East Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 3,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 2,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Beeville Municipal",
    County: "Bee",
    Region: "South Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 1,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Big Spring McMahon-Wrinkle",
    County: "Howard",
    Region: "West Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 6,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 2,
    "Air Ambulance": 14,
    Museum: 1.5,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Bishop Municipal Airport",
    County: "Potter",
    Region: "Panhandle",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Bowie Municipal Airport",
    County: "Montague",
    Region: "North Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Brenham Municipal",
    County: "Washington",
    Region: "Central Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 6,
    "Aircraft Support Activities": 5,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Bridgeport Municipal Airport",
    County: "Wise",
    Region: "North Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 2.5,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 1.5,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Brooks County",
    County: "Brooks",
    Region: "South Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Brownsville/South Padre Island International",
    County: "Cameron",
    Region: "South Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 58.5,
    "Aircraft Support Activities": 160,
    "Hangar Rental and Development": 0,
    "Rental Car": 29,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 5.5,
    "Parking Garage and Lots": 0,
    "Government Employment": 38.12718979,
    Other: 0,
  },
  {
    "Airport Name": "Brownwood Regional",
    County: "Brown",
    Region: "West Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 14,
    "Aircraft Support Activities": 2,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Bruce Field",
    County: "Runnels",
    Region: "West Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Burnet Municipal Airport",
    County: "Burnet",
    Region: "Central Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 6,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 3,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "C David Campbell Field-Corsicana Municipal",
    County: "Navarro",
    Region: "North Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 1.5,
    "Aircraft Support Activities": 1,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 2,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Caddo Mills Municipal",
    County: "Hunt",
    Region: "North Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 6,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Caldwell Municipal",
    County: "Burleson",
    Region: "Central Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 1,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Calhoun County Airport",
    County: "Calhoun",
    Region: "South Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 5.5,
    "Aircraft Support Activities": 2,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Cameron Municipal Airpark",
    County: "Milam",
    Region: "Central Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Castroville Municipal",
    County: "Medina",
    Region: "South Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 3.5,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 9.5,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Center Municipal",
    County: "Shelby",
    Region: "East Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Chambers County - Anahuac",
    County: "Chambers",
    Region: "Upper Gulf Coast",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 1,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Chambers County - Winnie Stowell",
    County: "Chambers",
    Region: "Upper Gulf Coast",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 40.5,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Charles R Johnson",
    County: "Willacy",
    Region: "South Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Cherokee County Airport",
    County: "Cherokee",
    Region: "East Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 6.5,
    "Aircraft Support Activities": 20.5,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 1.5,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Childress Municipal",
    County: "Childress",
    Region: "Panhandle",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 8,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Cisco Municipal",
    County: "Eastland",
    Region: "West Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "City of Shamrock",
    County: "Wheeler",
    Region: "Panhandle",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "City of Tulia/Swisher County Municipal",
    County: "Swisher",
    Region: "Panhandle",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Clarksville/Red River County-J D Trissell Field",
    County: "Red River",
    Region: "East Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 1,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Cleburne Regional Airport",
    County: "Johnson",
    Region: "North Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 22,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 2,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 2,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Cleveland Municipal",
    County: "Liberty",
    Region: "Upper Gulf Coast",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 1,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Clifton Municipal",
    County: "Bosque",
    Region: "West Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 2.5,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 2,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Cochran County",
    County: "Cochran",
    Region: "West Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Coleman Muncipal",
    County: "Coleman",
    Region: "West Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Colorado City Municipal Airport",
    County: "Mitchell",
    Region: "West Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 2,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0.5,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Comanche County - City",
    County: "Comanche",
    Region: "West Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Commerce Municipal",
    County: "Hunt",
    Region: "North Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 1,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Conroe North-Houston Regional",
    County: "Harris",
    Region: "Upper Gulf Coast",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 80,
    "Aircraft Support Activities": 97,
    "Hangar Rental and Development": 0,
    "Rental Car": 3.5,
    "Other Professional Services": 0,
    "Aviation Schools": 23,
    "Air Ambulance": 5,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 20,
    "Parking Garage and Lots": 0,
    "Government Employment": 210,
    Other: 0,
  },
  {
    "Airport Name": "Corpus Christi International",
    County: "Nueces",
    Region: "South Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 124.5,
    "Aircraft Support Activities": 74,
    "Hangar Rental and Development": 0,
    "Rental Car": 52,
    "Other Professional Services": 0,
    "Aviation Schools": 6.5,
    "Air Ambulance": 25,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 20,
    "Parking Garage and Lots": 7,
    "Government Employment": 372,
    Other: 0,
  },
  {
    "Airport Name": "Corsicana Muncipal Airport",
    County: "Navarro",
    Region: "North Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Cotulla-La Salle County Airport",
    County: "La Salle",
    Region: "South Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 5.5,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Coulter Field",
    County: "Brazos",
    Region: "Central Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 8,
    "Aircraft Support Activities": 3,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 3,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Cox Field",
    County: "Lamar",
    Region: "East Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 2,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 7,
    "Aircraft Support Activities": 1.5,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Crane County",
    County: "Crane",
    Region: "West Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Crosbyton Municipal Airport",
    County: "Crosby",
    Region: "West Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 5.5,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Crystal City Municipal",
    County: "Zavala",
    Region: "South Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Cuero Muni",
    County: "De Witt",
    Region: "West Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Culberson County",
    County: "Culberson",
    Region: "West Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 2,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Curtis Field",
    County: "McCulloch",
    Region: "West Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 2,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Cypress River",
    County: "Marion",
    Region: "East Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Dalhart Muni",
    County: "Dallam",
    Region: "Panhandle",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 6.5,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 3,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 5,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Dallas CBD Vertiport",
    County: "Denton",
    Region: "North Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Dallas Executive",
    County: "Denton",
    Region: "North Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 7,
    "Aircraft Support Activities": 36,
    "Hangar Rental and Development": 4,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 6.5,
    "Air Ambulance": 7,
    Museum: 28,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 9,
    "Parking Garage and Lots": 0,
    "Government Employment": 12,
    Other: 0,
  },
  {
    "Airport Name": "Dallas Love Field",
    County: "Denton",
    Region: "North Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 2491.04984,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 2075.874867,
    "Parking Garage and Lots": 0,
    "Government Employment": 3736.57476,
    Other: 0,
  },
  {
    "Airport Name": "Dallas/Fort Worth International",
    County: "Denton",
    Region: "North Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 11869.2963,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 9891.080247,
    "Parking Garage and Lots": 0,
    "Government Employment": 17803.94444,
    Other: 0,
  },
  {
    "Airport Name": "Dan E Richards",
    County: "Cottle",
    Region: "North Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 5,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "David Wayne Hooks Memorial",
    County: "Harris",
    Region: "Upper Gulf Coast",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 35.5,
    "Aircraft Support Activities": 61.12618441,
    "Hangar Rental and Development": 0,
    "Rental Car": 1,
    "Other Professional Services": 0,
    "Aviation Schools": 78,
    "Air Ambulance": 24,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 6,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Decatur Muncipal Airport",
    County: "Wise",
    Region: "North Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 3,
    "Aircraft Support Activities": 16.5,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Del Rio International Airport",
    County: "Val Verde",
    Region: "South Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 18,
    "Aircraft Support Activities": 19,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 7,
    Other: 0,
  },
  {
    "Airport Name": "Dell City Municipal Airport",
    County: "Hudspeth",
    Region: "West Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 1,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Denton Enterprise Airport",
    County: "Denton",
    Region: "North Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 219.5,
    "Aircraft Support Activities": 90,
    "Hangar Rental and Development": 1,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 80,
    "Air Ambulance": 0,
    Museum: 2,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 1,
    Other: 0,
  },
  {
    "Airport Name": "Denver City Airport",
    County: "Yoakum",
    Region: "West Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Desoto Heliport",
    County: "Dallas",
    Region: "North Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 12,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Devine Municipal Airport",
    County: "Medina",
    Region: "South Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 2,
    "Aircraft Support Activities": 7,
    "Hangar Rental and Development": 1,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Dilley Airport",
    County: "Frio",
    Region: "South Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Dimmit County Airport",
    County: "Dimmit",
    Region: "South Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 2,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 10,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Dimmit Municipal",
    County: "Dimmit",
    Region: "South Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 1,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Draughon-Miller Central Texas Regional Airport",
    County: "Bell",
    Region: "Central Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 27,
    "Aircraft Support Activities": 7,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 8,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Dublin Muncipal Airport",
    County: "Erath",
    Region: "North Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 1,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Duval-Freer",
    County: "Duval",
    Region: "South Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Eagle Lake Regional",
    County: "Colorado",
    Region: "Upper Gulf Coast",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 7,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 4,
    "Aircraft Support Activities": 1.5,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 3,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "East Texas Regional",
    County: "Gregg",
    Region: "East Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 41.5,
    "Aircraft Support Activities": 71,
    "Hangar Rental and Development": 1,
    "Rental Car": 6,
    "Other Professional Services": 0,
    "Aviation Schools": 25,
    "Air Ambulance": 5,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 10,
    Other: 0,
  },
  {
    "Airport Name": "Easterwood Field",
    County: "Brazos",
    Region: "Central Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 57.5,
    "Aircraft Support Activities": 5,
    "Hangar Rental and Development": 0,
    "Rental Car": 15.5,
    "Other Professional Services": 0,
    "Aviation Schools": 6.5,
    "Air Ambulance": 1,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 12.5,
    Other: 0,
  },
  {
    "Airport Name": "Eastland Municipal",
    County: "Eastland",
    Region: "West Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 3,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 3,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Edwards County Airport",
    County: "Edwards",
    Region: "South Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "El Paso International",
    County: "El Paso",
    Region: "West Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 1085.480822,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 1266.394293,
    "Parking Garage and Lots": 0,
    "Government Employment": 1266.394293,
    Other: 0,
  },
  {
    "Airport Name": "Eldorado Airport",
    County: "Schleicher",
    Region: "West Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Ellington Field",
    County: "Harris",
    Region: "Upper Gulf Coast",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 23.5,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 10,
    "Air Ambulance": 0,
    Museum: 15,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 1073.375,
    Other: 0,
  },
  {
    "Airport Name": "Ennis Municipal",
    County: "Ellis",
    Region: "North Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 3.5,
    "Aircraft Support Activities": 1,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Fabens",
    County: "El Paso",
    Region: "West Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 2,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Fayette Regional Air Center",
    County: "Fayette",
    Region: "Central Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 2.5,
    "Aircraft Support Activities": 1,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 2,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Fisher County Airport",
    County: "Fisher",
    Region: "West Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Floydada Municipal Airport",
    County: "Floyd",
    Region: "West Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 5,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 2,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Follett/Lipscomb County",
    County: "Lipscomb",
    Region: "Panhandle",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Fort Stockton/Pecos County",
    County: "Pecos",
    Region: "West Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 1,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 2.5,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Fox Stephens Field-Gilmer Municipal",
    County: "Upshur",
    Region: "East Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 5.5,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Franklin County",
    County: "Franklin",
    Region: "East Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Ft. Worth Alliance",
    County: "Denton",
    Region: "North Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 411,
    "Aircraft Support Activities": 583,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 27,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 27,
    Other: 0,
  },
  {
    "Airport Name": "Ft. Worth Meacham",
    County: "Denton",
    Region: "North Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 193.5,
    "Aircraft Support Activities": 295,
    "Hangar Rental and Development": 0,
    "Rental Car": 8,
    "Other Professional Services": 0,
    "Aviation Schools": 80,
    "Air Ambulance": 16,
    Museum: 4.5,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 2,
    "Parking Garage and Lots": 0,
    "Government Employment": 16,
    Other: 0,
  },
  {
    "Airport Name": "Ft. Worth Spinks",
    County: "Denton",
    Region: "North Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 44,
    "Aircraft Support Activities": 42.5,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 13.5,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Gaines County Airport",
    County: "Gaines",
    Region: "West Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 1,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Gainesville Municipal",
    County: "Cooke",
    Region: "North Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 1.5,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 9.5,
    "Aircraft Support Activities": 774.5,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Garland/DFW Heliport",
    County: "Dallas",
    Region: "North Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 21,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Garner Field Airport",
    County: "Uvalde",
    Region: "South Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 5,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 3.5,
    "Aircraft Support Activities": 67,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 3,
    "Air Ambulance": 25.5,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 4.5,
    "Parking Garage and Lots": 0,
    "Government Employment": 25,
    Other: 0,
  },
  {
    "Airport Name": "Gatesville Municipal Airport",
    County: "Coryell",
    Region: "Central Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 3,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "George Bush Intercontinental/Houston",
    County: "Harris",
    Region: "Upper Gulf Coast",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 10192.8,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 8494,
    "Parking Garage and Lots": 0,
    "Government Employment": 15289.2,
    Other: 0,
  },
  {
    "Airport Name": "Georgetown Municipal",
    County: "Williamson",
    Region: "Central Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 19.5,
    "Aircraft Support Activities": 57,
    "Hangar Rental and Development": 2,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 53.5,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Giddings-Lee County Airport",
    County: "Lee",
    Region: "Central Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 1.5,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Gillespie County Airport",
    County: "Gillespie",
    Region: "South Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 7.5,
    "Aircraft Support Activities": 7,
    "Hangar Rental and Development": 1,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 25,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Gladewater Municipal Airport",
    County: "Gregg",
    Region: "East Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 1,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 4,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Goldwaite Airport",
    County: "Mills",
    Region: "Central Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Graham Municipal Airport",
    County: "Young",
    Region: "North Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 4,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Granbury Regional Airport",
    County: "Hood",
    Region: "North Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 11.5,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 4,
    "Air Ambulance": 24,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Grand Prairie Municipal",
    County: "Tarrant",
    Region: "North Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 7,
    "Aircraft Support Activities": 518,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 205,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 2.5,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Greater Morris County",
    County: "Morris",
    Region: "East Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Greenville Municipal",
    County: "Hunt",
    Region: "North Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Groveton-Trinity County",
    County: "Trinity",
    Region: "East Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Gruver Municipal",
    County: "Hansford",
    Region: "Panhandle",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 6,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "H H Coffield Regional",
    County: "Milam",
    Region: "Central Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Hale County",
    County: "Hale",
    Region: "West Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 20,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Hallettsville Muni",
    County: "Lavaca",
    Region: "South Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0.5,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Hall-Miller Municipal",
    County: "Cass",
    Region: "East Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Hamilton Municipal",
    County: "Hamilton",
    Region: "Central Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Harrison County Airport",
    County: "Harrison",
    Region: "East Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 3,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0.5,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Harrison Field",
    County: "Knox",
    Region: "West Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 4,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Haskell Municipal Airport",
    County: "Haskell",
    Region: "West Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 3,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Hawthorne Field",
    County: "Hardin",
    Region: "East Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 1.5,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Hearne Municipal",
    County: "Robertson",
    Region: "Central Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 4,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Hemphill County",
    County: "Hemphill",
    Region: "Panhandle",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 3,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Hereford Municipal",
    County: "Deaf Smith",
    Region: "Panhandle",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 8,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 1,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Higgs/Libscomb County",
    County: "Lipscomb",
    Region: "Panhandle",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Hillsboro Municipal Airport",
    County: "Hill",
    Region: "Central Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 5.5,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 1.5,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 1,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Houston County",
    County: "Houston",
    Region: "East Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Houston Southwest",
    County: "Harris",
    Region: "Upper Gulf Coast",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 2,
    "Aircraft Support Activities": 29,
    "Hangar Rental and Development": 2,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 4.5,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Huntsville Municipal",
    County: "Walker",
    Region: "Upper Gulf Coast",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 2,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 2,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Hutchinson County",
    County: "Hutchinson",
    Region: "Panhandle",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 20,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Jack Brooks Regional",
    County: "Jefferson",
    Region: "East Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 5,
    "Aircraft Support Activities": 16,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 12,
    "Parking Garage and Lots": 0,
    "Government Employment": 15,
    Other: 0,
  },
  {
    "Airport Name": "Jacksboro Municipal",
    County: "Jack",
    Region: "North Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 1,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Jackson County",
    County: "Jackson",
    Region: "South Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 1,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Jasper County-Bell Field",
    County: "Jasper",
    Region: "East Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 1,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Jim Hogg County",
    County: "Jim Hogg",
    Region: "South Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Jones Field Airport",
    County: "Fannin",
    Region: "North Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 3,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 2,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Karnes County",
    County: "Karnes",
    Region: "South Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Kelly Field",
    County: "Bexar",
    Region: "South Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Kenedy Regional",
    County: "Karnes",
    Region: "South Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Kent County",
    County: "Kent",
    Region: "West Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Kerrville-Kerr County",
    County: "Kerr",
    Region: "South Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 12,
    "Aircraft Support Activities": 154.5,
    "Hangar Rental and Development": 1,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 1,
    "Air Ambulance": 14.5,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Kickapoo Downtown",
    County: "Wichita",
    Region: "North Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 4,
    "Aircraft Support Activities": 1,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 8.5,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Killeen-Ft. Hood Regional",
    County: "Bell",
    Region: "Central Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 32,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 39,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 4,
    "Parking Garage and Lots": 8,
    "Government Employment": 20,
    Other: 0,
  },
  {
    "Airport Name": "Killeen-Skylark Field",
    County: "Bell",
    Region: "Central Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 17,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Kimble County",
    County: "Kimble",
    Region: "West Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 1,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Kirbyville Airport",
    County: "Jasper",
    Region: "East Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0.5,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Kleberg County Airport",
    County: "Kleberg",
    Region: "South Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 4.5,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "La Porte Municipal",
    County: "Harris",
    Region: "Upper Gulf Coast",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 17.5,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Lago Vista Rusty Allen Airport",
    County: "Travis",
    Region: "Central Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 9,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Lamesa Municipal Airport",
    County: "Dawson",
    Region: "West Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 7.5,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 1,
    "Aircraft Support Activities": 3.5,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 3.5,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Lampasas Municipal Airport",
    County: "Lampasas",
    Region: "Central Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Lancaster Regional",
    County: "Dallas",
    Region: "North Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 4.5,
    "Hangar Rental and Development": 9,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 5.5,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 2.5,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Laredo International",
    County: "Webb",
    Region: "South Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 127.5,
    "Aircraft Support Activities": 6,
    "Hangar Rental and Development": 0,
    "Rental Car": 26.5,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 20.5,
    "Parking Garage and Lots": 0,
    "Government Employment": 44.28253248,
    Other: 0,
  },
  {
    "Airport Name": "Levelland Municipal",
    County: "Hockley",
    Region: "West Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 4,
    "Aircraft Support Activities": 4,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Liberty Municipal",
    County: "Gregg",
    Region: "East Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Live Oak County",
    County: "Live Oak",
    Region: "South Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Livingston Municipal",
    County: "Polk",
    Region: "East Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 1,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Llano Municipal Airport",
    County: "Llano",
    Region: "Central Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 1.5,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Lockhart Municipal",
    County: "Caldwell",
    Region: "Central Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 1,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 2,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Lubbock Preston Smith International",
    County: "Lubbock",
    Region: "West Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 250.5,
    "Aircraft Support Activities": 16,
    "Hangar Rental and Development": 0,
    "Rental Car": 52,
    "Other Professional Services": 0,
    "Aviation Schools": 10,
    "Air Ambulance": 28,
    Museum: 4,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 50,
    "Parking Garage and Lots": 29,
    "Government Employment": 63,
    Other: 0,
  },
  {
    "Airport Name": "Madisonville Municipal",
    County: "Madison",
    Region: "Central Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 5,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Major Samuel B Cornelius Field",
    County: "Hansford",
    Region: "Panhandle",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 4895,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 1,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 2,
    "Air Ambulance": 14,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Marfa Municipal",
    County: "Presidio",
    Region: "West Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 2,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 1,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Marian Airpark",
    County: "Collingsworth",
    Region: "Panhandle",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Marlin",
    County: "Falls",
    Region: "Central Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Mason County Airport",
    County: "Mason",
    Region: "West Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Maverick County Memorial International",
    County: "Mason",
    Region: "West Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 1.5,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "McAllen Miller International",
    County: "Hidalgo",
    Region: "South Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 106,
    "Aircraft Support Activities": 7,
    "Hangar Rental and Development": 3,
    "Rental Car": 35,
    "Other Professional Services": 0,
    "Aviation Schools": 5,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 37,
    "Parking Garage and Lots": 9,
    "Government Employment": 133,
    Other: 0,
  },
  {
    "Airport Name": "McCampbell-Porter Airport",
    County: "San Patricio",
    Region: "South Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 7.5,
    "Aircraft Support Activities": 3,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "McGregor Executive",
    County: "McLennan",
    Region: "Central Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 1,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 28.5,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 1,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "McKinley Field",
    County: "Frio",
    Region: "South Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 1,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "McKinney National",
    County: "Denton",
    Region: "North Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 141,
    "Aircraft Support Activities": 22.5,
    "Hangar Rental and Development": 0,
    "Rental Car": 6,
    "Other Professional Services": 0,
    "Aviation Schools": 29,
    "Air Ambulance": 60,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "McLean/Gray County",
    County: "Gray",
    Region: "Panhandle",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Memphis Municipal",
    County: "Hall",
    Region: "Panhandle",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Menard County Airport",
    County: "Menard",
    Region: "West Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Mesquite Metro",
    County: "Dallas",
    Region: "North Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 18,
    "Hangar Rental and Development": 9.5,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 21.5,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 9,
    Other: 0,
  },
  {
    "Airport Name": "Mexia-Limestone County",
    County: "Limestone",
    Region: "Central Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 18.5,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Miami-Roberts County",
    County: "Roberts",
    Region: "Panhandle",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Mid Valley",
    County: "Hidalgo",
    Region: "South Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 1,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 2,
    "Hangar Rental and Development": 2,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Midland Airpark",
    County: "Midland",
    Region: "West Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 25,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Midland International Air & Space Port",
    County: "Midland",
    Region: "West Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 392,
    "Aircraft Support Activities": 98,
    "Hangar Rental and Development": 5,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 24,
    "Air Ambulance": 0,
    Museum: 71,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 37.5,
    "Parking Garage and Lots": 0,
    "Government Employment": 105,
    Other: 0,
  },
  {
    "Airport Name": "Mid-way Regional",
    County: "Ellis",
    Region: "North Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 7,
    "Aircraft Support Activities": 9,
    "Hangar Rental and Development": 0,
    "Rental Car": 5.5,
    "Other Professional Services": 0,
    "Aviation Schools": 23,
    "Air Ambulance": 7.5,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Mineral Wells Airport",
    County: "Palo Pinto",
    Region: "North Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 171,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 1,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Moore County Airport",
    County: "Moore",
    Region: "Panhandle",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 3,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 1.5,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 7,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Mt. Pleasant Regional Airport",
    County: "Titus",
    Region: "East Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 2.5,
    "Aircraft Support Activities": 4.5,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 5,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Muleshoe Municipal",
    County: "Bailey",
    Region: "West Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Munday Municipal Airport",
    County: "Knox",
    Region: "West Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 3,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Mustang Beach",
    County: "Nueces",
    Region: "South Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Navasota Municipal Airport",
    County: "Grimes",
    Region: "Central Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 1,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "New Braunfels Regional",
    County: "Comal",
    Region: "South Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 6,
    "Aircraft Support Activities": 20,
    "Hangar Rental and Development": 5,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 13,
    "Air Ambulance": 8,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Newton Municipal",
    County: "Newton",
    Region: "East Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "North Texas Regional Airport",
    County: "Grayson",
    Region: "North Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 9,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 73,
    "Air Ambulance": 0,
    Museum: 4,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Northwest Regional Airport",
    County: "Denton",
    Region: "North Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 1,
    "Aircraft Support Activities": 35,
    "Hangar Rental and Development": 3,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 4,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Nueces County Airport",
    County: "Nueces",
    Region: "South Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 3,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Odessa-Schlemeyer Field",
    County: "Ector",
    Region: "West Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 29.5,
    "Aircraft Support Activities": 6,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Oldham County",
    County: "Oldham",
    Region: "Panhandle",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Olney Municipal",
    County: "Young",
    Region: "North Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 5,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 266,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Orange County Airport",
    County: "Orange",
    Region: "Panhandle",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 1.5,
    "Aircraft Support Activities": 2,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Ozona Municipal",
    County: "Crockett",
    Region: "West Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 1,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Palacios Municipal Airport",
    County: "Matagorda",
    Region: "Upper Gulf Coast",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Palestine Municipal Airport",
    County: "Anderson",
    Region: "East Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 1,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Panhandle-Carson County Airport",
    County: "Carson",
    Region: "Panhandle",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 5,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Panola County-Sharpe Field",
    County: "Panola",
    Region: "East Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Pearland Regional",
    County: "Brazoria",
    Region: "Upper Gulf Coast",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 8,
    "Aircraft Support Activities": 12,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 3,
    "Air Ambulance": 6,
    Museum: 0,
    "Other amusement and recreation industries": 3,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Pecos Municipal",
    County: "Reeves",
    Region: "West Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 2,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Perry Lefors Field",
    County: "Gray",
    Region: "Panhandle",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 2,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Perryton/Ochiltree County Airport",
    County: "Ochiltree",
    Region: "Panhandle",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 4,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 3,
    "Aircraft Support Activities": 1,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Pineland Municipal Airport",
    County: "Sabine",
    Region: "East Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 4,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 3,
    "Aircraft Support Activities": 1,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Pleasanton Municpal Airport",
    County: "Atascosa",
    Region: "South Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 2,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Port Isabel Camreon County Airport",
    County: "Cameron",
    Region: "South Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 4.5,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Possum Kingdom Airport",
    County: "Palo Pinto",
    Region: "North Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Post/Garza County Airport",
    County: "Garza",
    Region: "West Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Presidio Lely international",
    County: "Presidio",
    Region: "West Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Quanah Municipal",
    County: "Hardeman",
    Region: "North Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 3,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Ralph M. Hall / Rockwall Municipal Airport",
    County: "Rockwall",
    Region: "North Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 3,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0.5,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 1,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Reagan County",
    County: "Reagan",
    Region: "West Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Real County",
    County: "Real",
    Region: "South Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Refugio County Airport-Rooke Field",
    County: "Refugio",
    Region: "South Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 1,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Rick Husband Amarillo Int'l Airport",
    County: "Potter",
    Region: "Panhandle",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 152.5,
    "Aircraft Support Activities": 1140,
    "Hangar Rental and Development": 1,
    "Rental Car": 50,
    "Other Professional Services": 0,
    "Aviation Schools": 4,
    "Air Ambulance": 35,
    Museum: 3,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 35,
    "Parking Garage and Lots": 6.5,
    "Government Employment": 87,
    Other: 0,
  },
  {
    "Airport Name": "Rio Grande City Municipal Airport",
    County: "Starr",
    Region: "South Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Robert Lee",
    County: "Coke",
    Region: "West Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Robert R. Wells",
    County: "Colorado",
    Region: "Upper Gulf Coast",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Roger M. Dreyer Memorial",
    County: "Gonzales",
    Region: "South Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Ron Duncan",
    County: "Denton",
    Region: "North Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Roy Hurd Memorial",
    County: "Ward",
    Region: "West Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 1,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 3,
    "Aircraft Support Activities": 2.5,
    "Hangar Rental and Development": 0.5,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Rusk County Airport",
    County: "Rusk",
    Region: "East Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0.5,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "San Angelo Regional - Mathis Field",
    County: "Tom Green",
    Region: "West Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 23.5,
    "Aircraft Support Activities": 111,
    "Hangar Rental and Development": 0,
    "Rental Car": 7.5,
    "Other Professional Services": 0,
    "Aviation Schools": 4,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 14.5,
    "Parking Garage and Lots": 0,
    "Government Employment": 23.60777745,
    Other: 0,
  },
  {
    "Airport Name": "San Antonio International",
    County: "Bexar",
    Region: "South Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 1632.3,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 1360.25,
    "Parking Garage and Lots": 0,
    "Government Employment": 2448.45,
    Other: 0,
  },
  {
    "Airport Name": "San Augustine County",
    County: "San Augustine",
    Region: "East Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "San Marcos Regional",
    County: "Travis",
    Region: "Central Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 110,
    "Aircraft Support Activities": 47,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 30.5,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "San Saba Municipal Airport",
    County: "San Saba",
    Region: "Central Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Scholes Intl at Galveston",
    County: "Galveston",
    Region: "Upper Gulf Coast",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 4,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 60,
    "Aircraft Support Activities": 5,
    "Hangar Rental and Development": 3.5,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 5,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 25,
    Other: 0,
  },
  {
    "Airport Name": "Scurry County/Winston Field",
    County: "Scurry",
    Region: "West Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Seymour Municipal Airport",
    County: "Baylor",
    Region: "North Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 2,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Sheppard AFB/Wichita Falls Municipal",
    County: "Wichita",
    Region: "North Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 22,
    "Aircraft Support Activities": 31.5381937,
    "Hangar Rental and Development": 0,
    "Rental Car": 12.5,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 10.09221068,
    Other: 0,
  },
  {
    "Airport Name": "Sherman Municipal",
    County: "Grayson",
    Region: "North Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 3,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Slaton Municipal",
    County: "Lubbock",
    Region: "West Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 2,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 1.5,
    "Aircraft Support Activities": 2,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Smiley Johnson/Bass Field",
    County: "Donley",
    Region: "Panhandle",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Smithville Crawford Municipal Airport",
    County: "Bastrop",
    Region: "Central Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 4,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 1,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Sonora Airport",
    County: "Sutton",
    Region: "West Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "South Texas Intl at Edinburg",
    County: "Hidalgo",
    Region: "South Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 2,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 14,
    Other: 0,
  },
  {
    "Airport Name": "South Texas Regional Airport",
    County: "Cameron",
    Region: "South Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 33.5,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 16,
    "Air Ambulance": 0.5,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Stanton Municipal",
    County: "Martin",
    Region: "West Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Stephens County",
    County: "Stephens",
    Region: "West Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0.5,
    "Aircraft Support Activities": 31.5,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0.5,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Stephenville Clark Regional Airport",
    County: "Erath",
    Region: "North Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 3.5,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 6,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Stinson Municipal",
    County: "Bexar",
    Region: "South Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 10.5,
    "Aircraft Support Activities": 9,
    "Hangar Rental and Development": 2,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 28,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 2.5,
    "Parking Garage and Lots": 0,
    "Government Employment": 23.5,
    Other: 0,
  },
  {
    "Airport Name": "Stonewall County",
    County: "Stonewall",
    Region: "West Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Sugarland Regional",
    County: "Harris",
    Region: "Upper Gulf Coast",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 95.5,
    "Aircraft Support Activities": 26,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 23.5,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Sulphur Springs Municipal",
    County: "Hopkins",
    Region: "East Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 7,
    "Aircraft Support Activities": 17,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0.5,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Sunray Municipal",
    County: "Moore",
    Region: "Panhandle",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 8.5,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Taylor Brown Muncipal",
    County: "Lamb",
    Region: "West Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 3.5,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 1,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Taylor Municipal",
    County: "Williamson",
    Region: "Central Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 11.5,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 7,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "T-Bar Airport",
    County: "Lynn",
    Region: "West Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0.5,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Teague Municipal Airport",
    County: "Freestone",
    Region: "Central Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Terrell County Airport",
    County: "Terrell",
    Region: "West Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 1,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Terrell Municipal Airport",
    County: "Kaufman",
    Region: "North Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 3.5,
    "Aircraft Support Activities": 1,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 1.5,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Terry County",
    County: "Terry",
    Region: "West Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 2,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 1,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0.5,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Texarkana Regional Airport- Webb Field",
    County: "Bowie",
    Region: "East Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 30.5,
    "Aircraft Support Activities": 7,
    "Hangar Rental and Development": 0,
    "Rental Car": 10,
    "Other Professional Services": 0,
    "Aviation Schools": 4,
    "Air Ambulance": 6,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 23.5,
    Other: 0,
  },
  {
    "Airport Name": "Texas Gulf Coast Regional",
    County: "Brazoria",
    Region: "Upper Gulf Coast",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 9,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 5.5,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 7,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "The Carter Memorial",
    County: "Caldwell",
    Region: "Central Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Throckmorton Municipal",
    County: "Throckmorton",
    Region: "West Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Tradewind",
    County: "Potter",
    Region: "Panhandle",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "TSTC Waco",
    County: "McLennan",
    Region: "Central Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 7,
    "Aircraft Support Activities": 1022,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 100,
    Other: 0,
  },
  {
    "Airport Name": "Tyler County",
    County: "Tyler",
    Region: "East Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Tyler Pounds Regional",
    County: "Upton",
    Region: "West Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 30.5,
    "Aircraft Support Activities": 14,
    "Hangar Rental and Development": 27,
    "Rental Car": 8.5,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 85,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 9,
    "Parking Garage and Lots": 3,
    "Government Employment": 53,
    Other: 0,
  },
  {
    "Airport Name": "Upton County Airport",
    County: "Upton",
    Region: "West Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Valley International",
    County: "Cameron",
    Region: "South Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 240.5,
    "Aircraft Support Activities": 109.5,
    "Hangar Rental and Development": 9,
    "Rental Car": 34.5,
    "Other Professional Services": 0,
    "Aviation Schools": 1.5,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 20.5,
    "Parking Garage and Lots": 5,
    "Government Employment": 48.5,
    Other: 0,
  },
  {
    "Airport Name": "Van Zandt County Regional Airport",
    County: "Van Zandt",
    Region: "East Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 1.5,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Victoria Regional",
    County: "Victoria",
    Region: "South Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 18,
    "Aircraft Support Activities": 8.5,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 1,
    "Air Ambulance": 25,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 13,
    Other: 0,
  },
  {
    "Airport Name": "Waco Regional",
    County: "McLennan",
    Region: "Central Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 50,
    "Aircraft Support Activities": 168.5,
    "Hangar Rental and Development": 0,
    "Rental Car": 9,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 16,
    Other: 0,
  },
  {
    "Airport Name": "Weslaco Mid-Valley",
    County: "Hidalgo",
    Region: "South Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "West Houston Airport",
    County: "Harris",
    Region: "Upper Gulf Coast",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 18,
    "Hangar Rental and Development": 2,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 2,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Wharton Regional Airport",
    County: "Wharton",
    Region: "Upper Gulf Coast",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 2,
    "Aircraft Support Activities": 2,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 3.5,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Wheeler Municipal",
    County: "Wheeler",
    Region: "Panhandle",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Wilbarger County Airport",
    County: "Wilbarger",
    Region: "North Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 4,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 2,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "William P Hobby",
    County: "Harris",
    Region: "Upper Gulf Coast",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Winkler County",
    County: "Winkler",
    Region: "West Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 1,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Winnsboro Municipal Airport",
    County: "Franklin",
    Region: "East Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Winston Field",
    County: "Scurry",
    Region: "West Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 1,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 1,
    "Air Ambulance": 5,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Winters Municipal",
    County: "Runnels",
    Region: "West Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Wood County",
    County: "Wood",
    Region: "East Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 1.5,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Yoakum County Airport",
    County: "Yoakum",
    Region: "West Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 3,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 3,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Yoakum Muni",
    County: "De Witt",
    Region: "West Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Zapata County Airport",
    County: "Zapata",
    Region: "South Texas",
    Type: "Employment",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "A. L. Mangham Jr. Regional Airport",
    County: "Nacogdoches",
    Region: "East Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Abilene Regional Airport",
    County: "Taylor, Jones",
    Region: "West Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 1660144,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 157450,
    Other: 0,
  },
  {
    "Airport Name": "Addison Airport",
    County: "Dallas",
    Region: "North Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 15369488,
    "Aircraft Support Activities": 10465973,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Albany Municipal",
    County: "Shackelford",
    Region: "West Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Alfred C. Bubba Thomas Airport",
    County: "San Patricio",
    Region: "South Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": null,
    Manufacturing: null,
    "Retail - Miscellaneous store retailers": null,
    "Air Transportation": null,
    "Aircraft Support Activities": null,
    "Hangar Rental and Development": null,
    "Rental Car": null,
    "Other Professional Services": null,
    "Aviation Schools": null,
    "Air Ambulance": null,
    Museum: null,
    "Other amusement and recreation industries": null,
    "Food & Drinking Places": null,
    "Parking Garage and Lots": null,
    "Government Employment": null,
    Other: null,
  },
  {
    "Airport Name": "Alice International Airport",
    County: "Jim Wells",
    Region: "South Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Alpine Casparis Municipal Airport",
    County: "Brewster",
    Region: "West Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Andrews County Airport",
    County: "Andrews",
    Region: "West Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Angelina County Airport",
    County: "Angelina",
    Region: "East Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Aransas County Airport",
    County: "Aransas",
    Region: "South Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Arledge Field",
    County: "Haskell",
    Region: "West Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Arlington Municipal Airport",
    County: "Tarrant",
    Region: "North Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 20000000,
    "Aircraft Support Activities": 4500000,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 300000,
    Other: 0,
  },
  {
    "Airport Name": "Athens Municipal",
    County: "Henderson",
    Region: "East Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Austin-Bergstrom International",
    County: "Travis",
    Region: "Central Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 130935300,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 109112750,
    "Parking Garage and Lots": 0,
    "Government Employment": 196402950,
    Other: 0,
  },
  {
    "Airport Name": "Avenger Field",
    County: "Nolan",
    Region: "West Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Bay City Regional Airport",
    County: "Matagorda",
    Region: "Upper Gulf Coast",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Beaumont Municipal",
    County: "Jefferson",
    Region: "East Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Beeville Municipal",
    County: "Bee",
    Region: "South Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Big Spring McMahon-Wrinkle",
    County: "Howard",
    Region: "West Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 24630,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Bishop Municipal Airport",
    County: "Potter",
    Region: "Panhandle",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Bowie Municipal Airport",
    County: "Montague",
    Region: "North Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Brenham Municipal",
    County: "Washington",
    Region: "Central Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Bridgeport Municipal Airport",
    County: "Wise",
    Region: "North Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Brooks County",
    County: "Brooks",
    Region: "South Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Brownsville/South Padre Island International",
    County: "Cameron",
    Region: "South Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Brownwood Regional",
    County: "Brown",
    Region: "West Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Bruce Field",
    County: "Runnels",
    Region: "West Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Burnet Municipal Airport",
    County: "Burnet",
    Region: "Central Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "C David Campbell Field-Corsicana Municipal",
    County: "Navarro",
    Region: "North Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Caddo Mills Municipal",
    County: "Hunt",
    Region: "North Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Caldwell Municipal",
    County: "Burleson",
    Region: "Central Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Calhoun County Airport",
    County: "Calhoun",
    Region: "South Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Cameron Municipal Airpark",
    County: "Milam",
    Region: "Central Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Castroville Municipal",
    County: "Medina",
    Region: "South Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Center Municipal",
    County: "Shelby",
    Region: "East Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Chambers County - Anahuac",
    County: "Chambers",
    Region: "Upper Gulf Coast",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Chambers County - Winnie Stowell",
    County: "Chambers",
    Region: "Upper Gulf Coast",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 1000000,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Charles R Johnson",
    County: "Willacy",
    Region: "South Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Cherokee County Airport",
    County: "Cherokee",
    Region: "East Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 640000,
    "Aircraft Support Activities": 490000,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Childress Municipal",
    County: "Childress",
    Region: "Panhandle",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Cisco Municipal",
    County: "Eastland",
    Region: "West Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "City of Shamrock",
    County: "Wheeler",
    Region: "Panhandle",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "City of Tulia/Swisher County Municipal",
    County: "Swisher",
    Region: "Panhandle",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Clarksville/Red River County-J D Trissell Field",
    County: "Red River",
    Region: "East Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Cleburne Regional Airport",
    County: "Johnson",
    Region: "North Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 175000,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 100000,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Cleveland Municipal",
    County: "Liberty",
    Region: "Upper Gulf Coast",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Clifton Municipal",
    County: "Bosque",
    Region: "West Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Cochran County",
    County: "Cochran",
    Region: "West Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Coleman Muncipal",
    County: "Coleman",
    Region: "West Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Colorado City Municipal Airport",
    County: "Mitchell",
    Region: "West Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Comanche County - City",
    County: "Comanche",
    Region: "West Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Commerce Municipal",
    County: "Hunt",
    Region: "North Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Conroe North-Houston Regional",
    County: "Harris",
    Region: "Upper Gulf Coast",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 105000,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Corpus Christi International",
    County: "Nueces",
    Region: "South Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 633128.83,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Corsicana Muncipal Airport",
    County: "Navarro",
    Region: "North Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Cotulla-La Salle County Airport",
    County: "La Salle",
    Region: "South Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 141903,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Coulter Field",
    County: "Brazos",
    Region: "Central Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Cox Field",
    County: "Lamar",
    Region: "East Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 91000,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Crane County",
    County: "Crane",
    Region: "West Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Crosbyton Municipal Airport",
    County: "Crosby",
    Region: "West Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Crystal City Municipal",
    County: "Zavala",
    Region: "South Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Cuero Muni",
    County: "De Witt",
    Region: "West Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Culberson County",
    County: "Culberson",
    Region: "West Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Curtis Field",
    County: "McCulloch",
    Region: "West Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Cypress River",
    County: "Marion",
    Region: "East Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Dalhart Muni",
    County: "Dallam",
    Region: "Panhandle",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Dallas CBD Vertiport",
    County: "Denton",
    Region: "North Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Dallas Executive",
    County: "Denton",
    Region: "North Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 290112,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Dallas Love Field",
    County: "Denton",
    Region: "North Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 107538000,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 89615000,
    "Parking Garage and Lots": 0,
    "Government Employment": 161307000,
    Other: 0,
  },
  {
    "Airport Name": "Dallas/Fort Worth International",
    County: "Denton",
    Region: "North Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 775075800,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 645896500,
    "Parking Garage and Lots": 0,
    "Government Employment": 1162613700,
    Other: 0,
  },
  {
    "Airport Name": "Dan E Richards",
    County: "Cottle",
    Region: "North Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "David Wayne Hooks Memorial",
    County: "Harris",
    Region: "Upper Gulf Coast",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Decatur Muncipal Airport",
    County: "Wise",
    Region: "North Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Del Rio International Airport",
    County: "Val Verde",
    Region: "South Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Dell City Municipal Airport",
    County: "Hudspeth",
    Region: "West Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Denton Enterprise Airport",
    County: "Denton",
    Region: "North Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 5433750,
    "Aircraft Support Activities": 204000,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Denver City Airport",
    County: "Yoakum",
    Region: "West Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Desoto Heliport",
    County: "Dallas",
    Region: "North Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Devine Municipal Airport",
    County: "Medina",
    Region: "South Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Dilley Airport",
    County: "Frio",
    Region: "South Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Dimmit County Airport",
    County: "Dimmit",
    Region: "South Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Dimmit Municipal",
    County: "Dimmit",
    Region: "South Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Draughon-Miller Central Texas Regional Airport",
    County: "Bell",
    Region: "Central Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Dublin Muncipal Airport",
    County: "Erath",
    Region: "North Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Duval-Freer",
    County: "Duval",
    Region: "South Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Eagle Lake Regional",
    County: "Colorado",
    Region: "Upper Gulf Coast",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "East Texas Regional",
    County: "Gregg",
    Region: "East Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Easterwood Field",
    County: "Brazos",
    Region: "Central Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 600000,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 500000,
    Other: 0,
  },
  {
    "Airport Name": "Eastland Municipal",
    County: "Eastland",
    Region: "West Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Edwards County Airport",
    County: "Edwards",
    Region: "South Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "El Paso International",
    County: "El Paso",
    Region: "West Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 36416100,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 42485450,
    "Parking Garage and Lots": 0,
    "Government Employment": 42485450,
    Other: 0,
  },
  {
    "Airport Name": "Eldorado Airport",
    County: "Schleicher",
    Region: "West Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Ellington Field",
    County: "Harris",
    Region: "Upper Gulf Coast",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Ennis Municipal",
    County: "Ellis",
    Region: "North Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Fabens",
    County: "El Paso",
    Region: "West Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Fayette Regional Air Center",
    County: "Fayette",
    Region: "Central Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Fisher County Airport",
    County: "Fisher",
    Region: "West Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Floydada Municipal Airport",
    County: "Floyd",
    Region: "West Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Follett/Lipscomb County",
    County: "Lipscomb",
    Region: "Panhandle",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Fort Stockton/Pecos County",
    County: "Pecos",
    Region: "West Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Fox Stephens Field-Gilmer Municipal",
    County: "Upshur",
    Region: "East Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Franklin County",
    County: "Franklin",
    Region: "East Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Ft. Worth Alliance",
    County: "Denton",
    Region: "North Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 443333,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Ft. Worth Meacham",
    County: "Denton",
    Region: "North Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 7300000,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Ft. Worth Spinks",
    County: "Denton",
    Region: "North Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 180000,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Gaines County Airport",
    County: "Gaines",
    Region: "West Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Gainesville Municipal",
    County: "Cooke",
    Region: "North Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Garland/DFW Heliport",
    County: "Dallas",
    Region: "North Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Garner Field Airport",
    County: "Uvalde",
    Region: "South Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Gatesville Municipal Airport",
    County: "Coryell",
    Region: "Central Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "George Bush Intercontinental/Houston",
    County: "Harris",
    Region: "Upper Gulf Coast",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 786156300,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 655130250,
    "Parking Garage and Lots": 0,
    "Government Employment": 1179234450,
    Other: 0,
  },
  {
    "Airport Name": "Georgetown Municipal",
    County: "Williamson",
    Region: "Central Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 121000,
    "Aircraft Support Activities": 286000,
    "Hangar Rental and Development": 28000,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 360000,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Giddings-Lee County Airport",
    County: "Lee",
    Region: "Central Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 76000,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Gillespie County Airport",
    County: "Gillespie",
    Region: "South Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 350000,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 200000,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Gladewater Municipal Airport",
    County: "Gregg",
    Region: "East Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 8800,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Goldwaite Airport",
    County: "Mills",
    Region: "Central Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Graham Municipal Airport",
    County: "Young",
    Region: "North Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 250000,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Granbury Regional Airport",
    County: "Hood",
    Region: "North Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 30000,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 11300,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Grand Prairie Municipal",
    County: "Tarrant",
    Region: "North Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Greater Morris County",
    County: "Morris",
    Region: "East Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Greenville Municipal",
    County: "Hunt",
    Region: "North Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Groveton-Trinity County",
    County: "Trinity",
    Region: "East Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Gruver Municipal",
    County: "Hansford",
    Region: "Panhandle",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "H H Coffield Regional",
    County: "Milam",
    Region: "Central Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Hale County",
    County: "Hale",
    Region: "West Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Hallettsville Muni",
    County: "Lavaca",
    Region: "South Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Hall-Miller Municipal",
    County: "Cass",
    Region: "East Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Hamilton Municipal",
    County: "Hamilton",
    Region: "Central Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Harrison County Airport",
    County: "Harrison",
    Region: "East Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 150000,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Harrison Field",
    County: "Knox",
    Region: "West Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Haskell Municipal Airport",
    County: "Haskell",
    Region: "West Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Hawthorne Field",
    County: "Hardin",
    Region: "East Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Hearne Municipal",
    County: "Robertson",
    Region: "Central Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Hemphill County",
    County: "Hemphill",
    Region: "Panhandle",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Hereford Municipal",
    County: "Deaf Smith",
    Region: "Panhandle",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Higgs/Libscomb County",
    County: "Lipscomb",
    Region: "Panhandle",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Hillsboro Municipal Airport",
    County: "Hill",
    Region: "Central Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 150000,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Houston County",
    County: "Houston",
    Region: "East Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Houston Southwest",
    County: "Harris",
    Region: "Upper Gulf Coast",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Huntsville Municipal",
    County: "Walker",
    Region: "Upper Gulf Coast",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Hutchinson County",
    County: "Hutchinson",
    Region: "Panhandle",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Jack Brooks Regional",
    County: "Jefferson",
    Region: "East Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Jacksboro Municipal",
    County: "Jack",
    Region: "North Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Jackson County",
    County: "Jackson",
    Region: "South Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Jasper County-Bell Field",
    County: "Jasper",
    Region: "East Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Jim Hogg County",
    County: "Jim Hogg",
    Region: "South Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Jones Field Airport",
    County: "Fannin",
    Region: "North Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Karnes County",
    County: "Karnes",
    Region: "South Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Kelly Field",
    County: "Bexar",
    Region: "South Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Kenedy Regional",
    County: "Karnes",
    Region: "South Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Kent County",
    County: "Kent",
    Region: "West Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Kerrville-Kerr County",
    County: "Kerr",
    Region: "South Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 4200000,
    "Hangar Rental and Development": 18000,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 985000,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Kickapoo Downtown",
    County: "Wichita",
    Region: "North Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Killeen-Ft. Hood Regional",
    County: "Bell",
    Region: "Central Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 516000,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 500000,
    Other: 0,
  },
  {
    "Airport Name": "Killeen-Skylark Field",
    County: "Bell",
    Region: "Central Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Kimble County",
    County: "Kimble",
    Region: "West Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Kirbyville Airport",
    County: "Jasper",
    Region: "East Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Kleberg County Airport",
    County: "Kleberg",
    Region: "South Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "La Porte Municipal",
    County: "Harris",
    Region: "Upper Gulf Coast",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Lago Vista Rusty Allen Airport",
    County: "Travis",
    Region: "Central Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Lamesa Municipal Airport",
    County: "Dawson",
    Region: "West Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Lampasas Municipal Airport",
    County: "Lampasas",
    Region: "Central Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Lancaster Regional",
    County: "Dallas",
    Region: "North Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Laredo International",
    County: "Webb",
    Region: "South Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Levelland Municipal",
    County: "Hockley",
    Region: "West Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Liberty Municipal",
    County: "Gregg",
    Region: "East Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Live Oak County",
    County: "Live Oak",
    Region: "South Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Livingston Municipal",
    County: "Polk",
    Region: "East Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Llano Municipal Airport",
    County: "Llano",
    Region: "Central Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Lockhart Municipal",
    County: "Caldwell",
    Region: "Central Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Lubbock Preston Smith International",
    County: "Lubbock",
    Region: "West Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 1130000,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Madisonville Municipal",
    County: "Madison",
    Region: "Central Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Major Samuel B Cornelius Field",
    County: "Hansford",
    Region: "Panhandle",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 625187949,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Marfa Municipal",
    County: "Presidio",
    Region: "West Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 99200,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Marian Airpark",
    County: "Collingsworth",
    Region: "Panhandle",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Marlin",
    County: "Falls",
    Region: "Central Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Mason County Airport",
    County: "Mason",
    Region: "West Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Maverick County Memorial International",
    County: "Mason",
    Region: "West Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "McAllen Miller International",
    County: "Hidalgo",
    Region: "South Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 169000,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 266945,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "McCampbell-Porter Airport",
    County: "San Patricio",
    Region: "South Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "McGregor Executive",
    County: "McLennan",
    Region: "Central Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "McKinley Field",
    County: "Frio",
    Region: "South Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "McKinney National",
    County: "Denton",
    Region: "North Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 200000,
    "Aircraft Support Activities": 154530,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 225000,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "McLean/Gray County",
    County: "Gray",
    Region: "Panhandle",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Memphis Municipal",
    County: "Hall",
    Region: "Panhandle",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Menard County Airport",
    County: "Menard",
    Region: "West Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Mesquite Metro",
    County: "Dallas",
    Region: "North Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Mexia-Limestone County",
    County: "Limestone",
    Region: "Central Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Miami-Roberts County",
    County: "Roberts",
    Region: "Panhandle",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Mid Valley",
    County: "Hidalgo",
    Region: "South Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Midland Airpark",
    County: "Midland",
    Region: "West Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Midland International Air & Space Port",
    County: "Midland",
    Region: "West Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 636000,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 425527,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Mid-way Regional",
    County: "Ellis",
    Region: "North Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 30000,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Mineral Wells Airport",
    County: "Palo Pinto",
    Region: "North Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Moore County Airport",
    County: "Moore",
    Region: "Panhandle",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Mt. Pleasant Regional Airport",
    County: "Titus",
    Region: "East Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 234500,
    "Aircraft Support Activities": 102000,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Muleshoe Municipal",
    County: "Bailey",
    Region: "West Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Munday Municipal Airport",
    County: "Knox",
    Region: "West Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Mustang Beach",
    County: "Nueces",
    Region: "South Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Navasota Municipal Airport",
    County: "Grimes",
    Region: "Central Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "New Braunfels Regional",
    County: "Comal",
    Region: "South Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Newton Municipal",
    County: "Newton",
    Region: "East Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "North Texas Regional Airport",
    County: "Grayson",
    Region: "North Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 1122917,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Northwest Regional Airport",
    County: "Denton",
    Region: "North Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Nueces County Airport",
    County: "Nueces",
    Region: "South Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Odessa-Schlemeyer Field",
    County: "Ector",
    Region: "West Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 611383,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Oldham County",
    County: "Oldham",
    Region: "Panhandle",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Olney Municipal",
    County: "Young",
    Region: "North Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Orange County Airport",
    County: "Orange",
    Region: "Panhandle",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Ozona Municipal",
    County: "Crockett",
    Region: "West Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Palacios Municipal Airport",
    County: "Matagorda",
    Region: "Upper Gulf Coast",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Palestine Municipal Airport",
    County: "Anderson",
    Region: "East Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Panhandle-Carson County Airport",
    County: "Carson",
    Region: "Panhandle",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Panola County-Sharpe Field",
    County: "Panola",
    Region: "East Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Pearland Regional",
    County: "Brazoria",
    Region: "Upper Gulf Coast",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Pecos Municipal",
    County: "Reeves",
    Region: "West Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Perry Lefors Field",
    County: "Gray",
    Region: "Panhandle",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Perryton/Ochiltree County Airport",
    County: "Ochiltree",
    Region: "Panhandle",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Pineland Municipal Airport",
    County: "Sabine",
    Region: "East Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Pleasanton Municpal Airport",
    County: "Atascosa",
    Region: "South Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Port Isabel Camreon County Airport",
    County: "Cameron",
    Region: "South Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Possum Kingdom Airport",
    County: "Palo Pinto",
    Region: "North Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Post/Garza County Airport",
    County: "Garza",
    Region: "West Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Presidio Lely international",
    County: "Presidio",
    Region: "West Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 25800,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Quanah Municipal",
    County: "Hardeman",
    Region: "North Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Ralph M. Hall / Rockwall Municipal Airport",
    County: "Rockwall",
    Region: "North Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Reagan County",
    County: "Reagan",
    Region: "West Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Real County",
    County: "Real",
    Region: "South Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Refugio County Airport-Rooke Field",
    County: "Refugio",
    Region: "South Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Rick Husband Amarillo Int'l Airport",
    County: "Potter",
    Region: "Panhandle",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Rio Grande City Municipal Airport",
    County: "Starr",
    Region: "South Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Robert Lee",
    County: "Coke",
    Region: "West Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Robert R. Wells",
    County: "Colorado",
    Region: "Upper Gulf Coast",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Roger M. Dreyer Memorial",
    County: "Gonzales",
    Region: "South Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Ron Duncan",
    County: "Denton",
    Region: "North Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Roy Hurd Memorial",
    County: "Ward",
    Region: "West Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Rusk County Airport",
    County: "Rusk",
    Region: "East Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "San Angelo Regional - Mathis Field",
    County: "Tom Green",
    Region: "West Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 300000,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 20000,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 258000,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "San Antonio International",
    County: "Bexar",
    Region: "South Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 89200200,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 74333500,
    "Parking Garage and Lots": 0,
    "Government Employment": 133800300,
    Other: 0,
  },
  {
    "Airport Name": "San Augustine County",
    County: "San Augustine",
    Region: "East Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "San Marcos Regional",
    County: "Travis",
    Region: "Central Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "San Saba Municipal Airport",
    County: "San Saba",
    Region: "Central Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Scholes Intl at Galveston",
    County: "Galveston",
    Region: "Upper Gulf Coast",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 1200,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 4803171,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 1700000,
    Other: 0,
  },
  {
    "Airport Name": "Scurry County/Winston Field",
    County: "Scurry",
    Region: "West Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Seymour Municipal Airport",
    County: "Baylor",
    Region: "North Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Sheppard AFB/Wichita Falls Municipal",
    County: "Wichita",
    Region: "North Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Sherman Municipal",
    County: "Grayson",
    Region: "North Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Slaton Municipal",
    County: "Lubbock",
    Region: "West Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Smiley Johnson/Bass Field",
    County: "Donley",
    Region: "Panhandle",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Smithville Crawford Municipal Airport",
    County: "Bastrop",
    Region: "Central Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Sonora Airport",
    County: "Sutton",
    Region: "West Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "South Texas Intl at Edinburg",
    County: "Hidalgo",
    Region: "South Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "South Texas Regional Airport",
    County: "Cameron",
    Region: "South Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Stanton Municipal",
    County: "Martin",
    Region: "West Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Stephens County",
    County: "Stephens",
    Region: "West Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 1400000,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Stephenville Clark Regional Airport",
    County: "Erath",
    Region: "North Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Stinson Municipal",
    County: "Bexar",
    Region: "South Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Stonewall County",
    County: "Stonewall",
    Region: "West Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Sugarland Regional",
    County: "Harris",
    Region: "Upper Gulf Coast",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 3555000,
    "Aircraft Support Activities": 268123,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 400,
    "Aviation Schools": 900000,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Sulphur Springs Municipal",
    County: "Hopkins",
    Region: "East Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 65000,
    "Aircraft Support Activities": 90000,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Sunray Municipal",
    County: "Moore",
    Region: "Panhandle",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Taylor Brown Muncipal",
    County: "Lamb",
    Region: "West Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Taylor Municipal",
    County: "Williamson",
    Region: "Central Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "T-Bar Airport",
    County: "Lynn",
    Region: "West Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Teague Municipal Airport",
    County: "Freestone",
    Region: "Central Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Terrell County Airport",
    County: "Terrell",
    Region: "West Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Terrell Municipal Airport",
    County: "Kaufman",
    Region: "North Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 40000,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Terry County",
    County: "Terry",
    Region: "West Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Texarkana Regional Airport- Webb Field",
    County: "Bowie",
    Region: "East Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 180000,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Texas Gulf Coast Regional",
    County: "Brazoria",
    Region: "Upper Gulf Coast",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "The Carter Memorial",
    County: "Caldwell",
    Region: "Central Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Throckmorton Municipal",
    County: "Throckmorton",
    Region: "West Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Tradewind",
    County: "Potter",
    Region: "Panhandle",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "TSTC Waco",
    County: "McLennan",
    Region: "Central Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Tyler County",
    County: "Tyler",
    Region: "East Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Tyler Pounds Regional",
    County: "Upton",
    Region: "West Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 396000,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Upton County Airport",
    County: "Upton",
    Region: "West Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Valley International",
    County: "Cameron",
    Region: "South Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Van Zandt County Regional Airport",
    County: "Van Zandt",
    Region: "East Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 9000,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Victoria Regional",
    County: "Victoria",
    Region: "South Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 30000,
    "Air Ambulance": 1040000,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 593000,
    Other: 0,
  },
  {
    "Airport Name": "Waco Regional",
    County: "McLennan",
    Region: "Central Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Weslaco Mid-Valley",
    County: "Hidalgo",
    Region: "South Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "West Houston Airport",
    County: "Harris",
    Region: "Upper Gulf Coast",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Wharton Regional Airport",
    County: "Wharton",
    Region: "Upper Gulf Coast",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Wheeler Municipal",
    County: "Wheeler",
    Region: "Panhandle",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Wilbarger County Airport",
    County: "Wilbarger",
    Region: "North Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "William P Hobby",
    County: "Harris",
    Region: "Upper Gulf Coast",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Winkler County",
    County: "Winkler",
    Region: "West Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Winnsboro Municipal Airport",
    County: "Franklin",
    Region: "East Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Winston Field",
    County: "Scurry",
    Region: "West Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Winters Municipal",
    County: "Runnels",
    Region: "West Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Wood County",
    County: "Wood",
    Region: "East Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Yoakum County Airport",
    County: "Yoakum",
    Region: "West Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Yoakum Muni",
    County: "De Witt",
    Region: "West Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Zapata County Airport",
    County: "Zapata",
    Region: "South Texas",
    Type: "Payroll",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "A. L. Mangham Jr. Regional Airport",
    County: "Nacogdoches",
    Region: "East Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Abilene Regional Airport",
    County: "Taylor, Jones",
    Region: "West Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 3920090,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Addison Airport",
    County: "Dallas",
    Region: "North Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 72369920,
    "Aircraft Support Activities": 14086602,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Albany Municipal",
    County: "Shackelford",
    Region: "West Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Alfred C. Bubba Thomas Airport",
    County: "San Patricio",
    Region: "South Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": null,
    Manufacturing: null,
    "Retail - Miscellaneous store retailers": null,
    "Air Transportation": null,
    "Aircraft Support Activities": null,
    "Hangar Rental and Development": null,
    "Rental Car": null,
    "Other Professional Services": null,
    "Aviation Schools": null,
    "Air Ambulance": null,
    Museum: null,
    "Other amusement and recreation industries": null,
    "Food & Drinking Places": null,
    "Parking Garage and Lots": null,
    "Government Employment": null,
    Other: null,
  },
  {
    "Airport Name": "Alice International Airport",
    County: "Jim Wells",
    Region: "South Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Alpine Casparis Municipal Airport",
    County: "Brewster",
    Region: "West Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Andrews County Airport",
    County: "Andrews",
    Region: "West Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Angelina County Airport",
    County: "Angelina",
    Region: "East Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Aransas County Airport",
    County: "Aransas",
    Region: "South Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Arledge Field",
    County: "Haskell",
    Region: "West Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Arlington Municipal Airport",
    County: "Tarrant",
    Region: "North Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 35000000,
    "Aircraft Support Activities": 10800000,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 625000,
    Other: 0,
  },
  {
    "Airport Name": "Athens Municipal",
    County: "Henderson",
    Region: "East Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Austin-Bergstrom International",
    County: "Travis",
    Region: "Central Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 478166633.2,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 398472194.3,
    "Parking Garage and Lots": 0,
    "Government Employment": 717249949.7,
    Other: 0,
  },
  {
    "Airport Name": "Avenger Field",
    County: "Nolan",
    Region: "West Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Bay City Regional Airport",
    County: "Matagorda",
    Region: "Upper Gulf Coast",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Beaumont Municipal",
    County: "Jefferson",
    Region: "East Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Beeville Municipal",
    County: "Bee",
    Region: "South Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Big Spring McMahon-Wrinkle",
    County: "Howard",
    Region: "West Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 84186,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Bishop Municipal Airport",
    County: "Potter",
    Region: "Panhandle",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Bowie Municipal Airport",
    County: "Montague",
    Region: "North Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Brenham Municipal",
    County: "Washington",
    Region: "Central Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Bridgeport Municipal Airport",
    County: "Wise",
    Region: "North Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Brooks County",
    County: "Brooks",
    Region: "South Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Brownsville/South Padre Island International",
    County: "Cameron",
    Region: "South Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Brownwood Regional",
    County: "Brown",
    Region: "West Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Bruce Field",
    County: "Runnels",
    Region: "West Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Burnet Municipal Airport",
    County: "Burnet",
    Region: "Central Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "C David Campbell Field-Corsicana Municipal",
    County: "Navarro",
    Region: "North Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Caddo Mills Municipal",
    County: "Hunt",
    Region: "North Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Caldwell Municipal",
    County: "Burleson",
    Region: "Central Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Calhoun County Airport",
    County: "Calhoun",
    Region: "South Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Cameron Municipal Airpark",
    County: "Milam",
    Region: "Central Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Castroville Municipal",
    County: "Medina",
    Region: "South Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Center Municipal",
    County: "Shelby",
    Region: "East Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Chambers County - Anahuac",
    County: "Chambers",
    Region: "Upper Gulf Coast",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Chambers County - Winnie Stowell",
    County: "Chambers",
    Region: "Upper Gulf Coast",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 5000000,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Charles R Johnson",
    County: "Willacy",
    Region: "South Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Cherokee County Airport",
    County: "Cherokee",
    Region: "East Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 740000,
    "Aircraft Support Activities": 1020000,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Childress Municipal",
    County: "Childress",
    Region: "Panhandle",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Cisco Municipal",
    County: "Eastland",
    Region: "West Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "City of Shamrock",
    County: "Wheeler",
    Region: "Panhandle",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "City of Tulia/Swisher County Municipal",
    County: "Swisher",
    Region: "Panhandle",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Clarksville/Red River County-J D Trissell Field",
    County: "Red River",
    Region: "East Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Cleburne Regional Airport",
    County: "Johnson",
    Region: "North Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 300000,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 250000,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Cleveland Municipal",
    County: "Liberty",
    Region: "Upper Gulf Coast",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Clifton Municipal",
    County: "Bosque",
    Region: "West Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Cochran County",
    County: "Cochran",
    Region: "West Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Coleman Muncipal",
    County: "Coleman",
    Region: "West Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Colorado City Municipal Airport",
    County: "Mitchell",
    Region: "West Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Comanche County - City",
    County: "Comanche",
    Region: "West Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Commerce Municipal",
    County: "Hunt",
    Region: "North Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Conroe North-Houston Regional",
    County: "Harris",
    Region: "Upper Gulf Coast",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 175000,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Corpus Christi International",
    County: "Nueces",
    Region: "South Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 57904.92,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Corsicana Muncipal Airport",
    County: "Navarro",
    Region: "North Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Cotulla-La Salle County Airport",
    County: "La Salle",
    Region: "South Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 756815,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Coulter Field",
    County: "Brazos",
    Region: "Central Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Cox Field",
    County: "Lamar",
    Region: "East Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 460000,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Crane County",
    County: "Crane",
    Region: "West Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Crosbyton Municipal Airport",
    County: "Crosby",
    Region: "West Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Crystal City Municipal",
    County: "Zavala",
    Region: "South Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Cuero Muni",
    County: "De Witt",
    Region: "West Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Culberson County",
    County: "Culberson",
    Region: "West Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Curtis Field",
    County: "McCulloch",
    Region: "West Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Cypress River",
    County: "Marion",
    Region: "East Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Dalhart Muni",
    County: "Dallam",
    Region: "Panhandle",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Dallas CBD Vertiport",
    County: "Denton",
    Region: "North Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Dallas Executive",
    County: "Denton",
    Region: "North Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 681607,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Dallas Love Field",
    County: "Denton",
    Region: "North Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 429719196.5,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 358099330.4,
    "Parking Garage and Lots": 0,
    "Government Employment": 644578794.7,
    Other: 0,
  },
  {
    "Airport Name": "Dallas/Fort Worth International",
    County: "Denton",
    Region: "North Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 2666722814,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 2222269011,
    "Parking Garage and Lots": 0,
    "Government Employment": 4000084221,
    Other: 0,
  },
  {
    "Airport Name": "Dan E Richards",
    County: "Cottle",
    Region: "North Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "David Wayne Hooks Memorial",
    County: "Harris",
    Region: "Upper Gulf Coast",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Decatur Muncipal Airport",
    County: "Wise",
    Region: "North Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Del Rio International Airport",
    County: "Val Verde",
    Region: "South Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Dell City Municipal Airport",
    County: "Hudspeth",
    Region: "West Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Denton Enterprise Airport",
    County: "Denton",
    Region: "North Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 16307250,
    "Aircraft Support Activities": 671612,
    "Hangar Rental and Development": 19000,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Denver City Airport",
    County: "Yoakum",
    Region: "West Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Desoto Heliport",
    County: "Dallas",
    Region: "North Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Devine Municipal Airport",
    County: "Medina",
    Region: "South Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Dilley Airport",
    County: "Frio",
    Region: "South Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Dimmit County Airport",
    County: "Dimmit",
    Region: "South Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Dimmit Municipal",
    County: "Dimmit",
    Region: "South Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Draughon-Miller Central Texas Regional Airport",
    County: "Bell",
    Region: "Central Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Dublin Muncipal Airport",
    County: "Erath",
    Region: "North Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Duval-Freer",
    County: "Duval",
    Region: "South Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Eagle Lake Regional",
    County: "Colorado",
    Region: "Upper Gulf Coast",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "East Texas Regional",
    County: "Gregg",
    Region: "East Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 12000,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Easterwood Field",
    County: "Brazos",
    Region: "Central Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 1750000,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Eastland Municipal",
    County: "Eastland",
    Region: "West Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Edwards County Airport",
    County: "Edwards",
    Region: "South Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "El Paso International",
    County: "El Paso",
    Region: "West Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 138343825.9,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 161401130.2,
    "Parking Garage and Lots": 0,
    "Government Employment": 161401130.2,
    Other: 0,
  },
  {
    "Airport Name": "Eldorado Airport",
    County: "Schleicher",
    Region: "West Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Ellington Field",
    County: "Harris",
    Region: "Upper Gulf Coast",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Ennis Municipal",
    County: "Ellis",
    Region: "North Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Fabens",
    County: "El Paso",
    Region: "West Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Fayette Regional Air Center",
    County: "Fayette",
    Region: "Central Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Fisher County Airport",
    County: "Fisher",
    Region: "West Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Floydada Municipal Airport",
    County: "Floyd",
    Region: "West Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Follett/Lipscomb County",
    County: "Lipscomb",
    Region: "Panhandle",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Fort Stockton/Pecos County",
    County: "Pecos",
    Region: "West Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Fox Stephens Field-Gilmer Municipal",
    County: "Upshur",
    Region: "East Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Franklin County",
    County: "Franklin",
    Region: "East Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Ft. Worth Alliance",
    County: "Denton",
    Region: "North Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 1330000,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Ft. Worth Meacham",
    County: "Denton",
    Region: "North Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 17800000,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Ft. Worth Spinks",
    County: "Denton",
    Region: "North Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 500000,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Gaines County Airport",
    County: "Gaines",
    Region: "West Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Gainesville Municipal",
    County: "Cooke",
    Region: "North Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Garland/DFW Heliport",
    County: "Dallas",
    Region: "North Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Garner Field Airport",
    County: "Uvalde",
    Region: "South Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Gatesville Municipal Airport",
    County: "Coryell",
    Region: "Central Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "George Bush Intercontinental/Houston",
    County: "Harris",
    Region: "Upper Gulf Coast",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 2454012833,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 2045010695,
    "Parking Garage and Lots": 0,
    "Government Employment": 3681019250,
    Other: 0,
  },
  {
    "Airport Name": "Georgetown Municipal",
    County: "Williamson",
    Region: "Central Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 362000,
    "Aircraft Support Activities": 1775000,
    "Hangar Rental and Development": 42000,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 485500,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Giddings-Lee County Airport",
    County: "Lee",
    Region: "Central Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 113300,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Gillespie County Airport",
    County: "Gillespie",
    Region: "South Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 1200000,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 100000,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Gladewater Municipal Airport",
    County: "Gregg",
    Region: "East Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 57530,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Goldwaite Airport",
    County: "Mills",
    Region: "Central Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Graham Municipal Airport",
    County: "Young",
    Region: "North Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 250000,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Granbury Regional Airport",
    County: "Hood",
    Region: "North Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 60000,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 108300,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Grand Prairie Municipal",
    County: "Tarrant",
    Region: "North Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Greater Morris County",
    County: "Morris",
    Region: "East Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Greenville Municipal",
    County: "Hunt",
    Region: "North Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Groveton-Trinity County",
    County: "Trinity",
    Region: "East Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Gruver Municipal",
    County: "Hansford",
    Region: "Panhandle",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "H H Coffield Regional",
    County: "Milam",
    Region: "Central Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Hale County",
    County: "Hale",
    Region: "West Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Hallettsville Muni",
    County: "Lavaca",
    Region: "South Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Hall-Miller Municipal",
    County: "Cass",
    Region: "East Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Hamilton Municipal",
    County: "Hamilton",
    Region: "Central Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Harrison County Airport",
    County: "Harrison",
    Region: "East Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 150000,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Harrison Field",
    County: "Knox",
    Region: "West Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Haskell Municipal Airport",
    County: "Haskell",
    Region: "West Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Hawthorne Field",
    County: "Hardin",
    Region: "East Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Hearne Municipal",
    County: "Robertson",
    Region: "Central Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Hemphill County",
    County: "Hemphill",
    Region: "Panhandle",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Hereford Municipal",
    County: "Deaf Smith",
    Region: "Panhandle",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Higgs/Libscomb County",
    County: "Lipscomb",
    Region: "Panhandle",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Hillsboro Municipal Airport",
    County: "Hill",
    Region: "Central Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 745000,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Houston County",
    County: "Houston",
    Region: "East Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Houston Southwest",
    County: "Harris",
    Region: "Upper Gulf Coast",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Huntsville Municipal",
    County: "Walker",
    Region: "Upper Gulf Coast",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Hutchinson County",
    County: "Hutchinson",
    Region: "Panhandle",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Jack Brooks Regional",
    County: "Jefferson",
    Region: "East Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Jacksboro Municipal",
    County: "Jack",
    Region: "North Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Jackson County",
    County: "Jackson",
    Region: "South Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Jasper County-Bell Field",
    County: "Jasper",
    Region: "East Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Jim Hogg County",
    County: "Jim Hogg",
    Region: "South Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Jones Field Airport",
    County: "Fannin",
    Region: "North Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Karnes County",
    County: "Karnes",
    Region: "South Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Kelly Field",
    County: "Bexar",
    Region: "South Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Kenedy Regional",
    County: "Karnes",
    Region: "South Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Kent County",
    County: "Kent",
    Region: "West Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Kerrville-Kerr County",
    County: "Kerr",
    Region: "South Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 6400000,
    "Hangar Rental and Development": 25000,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 2180000,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Kickapoo Downtown",
    County: "Wichita",
    Region: "North Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 200000,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Killeen-Ft. Hood Regional",
    County: "Bell",
    Region: "Central Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 745000,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 1000000,
    Other: 0,
  },
  {
    "Airport Name": "Killeen-Skylark Field",
    County: "Bell",
    Region: "Central Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Kimble County",
    County: "Kimble",
    Region: "West Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Kirbyville Airport",
    County: "Jasper",
    Region: "East Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Kleberg County Airport",
    County: "Kleberg",
    Region: "South Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "La Porte Municipal",
    County: "Harris",
    Region: "Upper Gulf Coast",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Lago Vista Rusty Allen Airport",
    County: "Travis",
    Region: "Central Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Lamesa Municipal Airport",
    County: "Dawson",
    Region: "West Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Lampasas Municipal Airport",
    County: "Lampasas",
    Region: "Central Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Lancaster Regional",
    County: "Dallas",
    Region: "North Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Laredo International",
    County: "Webb",
    Region: "South Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Levelland Municipal",
    County: "Hockley",
    Region: "West Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Liberty Municipal",
    County: "Gregg",
    Region: "East Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Live Oak County",
    County: "Live Oak",
    Region: "South Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Livingston Municipal",
    County: "Polk",
    Region: "East Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Llano Municipal Airport",
    County: "Llano",
    Region: "Central Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Lockhart Municipal",
    County: "Caldwell",
    Region: "Central Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Lubbock Preston Smith International",
    County: "Lubbock",
    Region: "West Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 3125000,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Madisonville Municipal",
    County: "Madison",
    Region: "Central Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Major Samuel B Cornelius Field",
    County: "Hansford",
    Region: "Panhandle",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 1824999288,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Marfa Municipal",
    County: "Presidio",
    Region: "West Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 142200,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Marian Airpark",
    County: "Collingsworth",
    Region: "Panhandle",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Marlin",
    County: "Falls",
    Region: "Central Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Mason County Airport",
    County: "Mason",
    Region: "West Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Maverick County Memorial International",
    County: "Mason",
    Region: "West Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "McAllen Miller International",
    County: "Hidalgo",
    Region: "South Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 580000,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 775927,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "McCampbell-Porter Airport",
    County: "San Patricio",
    Region: "South Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "McGregor Executive",
    County: "McLennan",
    Region: "Central Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "McKinley Field",
    County: "Frio",
    Region: "South Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "McKinney National",
    County: "Denton",
    Region: "North Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 835000,
    "Aircraft Support Activities": 305008,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 313000,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "McLean/Gray County",
    County: "Gray",
    Region: "Panhandle",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Memphis Municipal",
    County: "Hall",
    Region: "Panhandle",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Menard County Airport",
    County: "Menard",
    Region: "West Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Mesquite Metro",
    County: "Dallas",
    Region: "North Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Mexia-Limestone County",
    County: "Limestone",
    Region: "Central Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Miami-Roberts County",
    County: "Roberts",
    Region: "Panhandle",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Mid Valley",
    County: "Hidalgo",
    Region: "South Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Midland Airpark",
    County: "Midland",
    Region: "West Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Midland International Air & Space Port",
    County: "Midland",
    Region: "West Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 813000,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 1015549,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Mid-way Regional",
    County: "Ellis",
    Region: "North Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 120000,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Mineral Wells Airport",
    County: "Palo Pinto",
    Region: "North Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Moore County Airport",
    County: "Moore",
    Region: "Panhandle",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Mt. Pleasant Regional Airport",
    County: "Titus",
    Region: "East Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 815000,
    "Aircraft Support Activities": 210000,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Muleshoe Municipal",
    County: "Bailey",
    Region: "West Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Munday Municipal Airport",
    County: "Knox",
    Region: "West Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Mustang Beach",
    County: "Nueces",
    Region: "South Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Navasota Municipal Airport",
    County: "Grimes",
    Region: "Central Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "New Braunfels Regional",
    County: "Comal",
    Region: "South Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Newton Municipal",
    County: "Newton",
    Region: "East Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "North Texas Regional Airport",
    County: "Grayson",
    Region: "North Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 3368750,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Northwest Regional Airport",
    County: "Denton",
    Region: "North Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Nueces County Airport",
    County: "Nueces",
    Region: "South Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Odessa-Schlemeyer Field",
    County: "Ector",
    Region: "West Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 1362210,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Oldham County",
    County: "Oldham",
    Region: "Panhandle",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Olney Municipal",
    County: "Young",
    Region: "North Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Orange County Airport",
    County: "Orange",
    Region: "Panhandle",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Ozona Municipal",
    County: "Crockett",
    Region: "West Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Palacios Municipal Airport",
    County: "Matagorda",
    Region: "Upper Gulf Coast",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Palestine Municipal Airport",
    County: "Anderson",
    Region: "East Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Panhandle-Carson County Airport",
    County: "Carson",
    Region: "Panhandle",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Panola County-Sharpe Field",
    County: "Panola",
    Region: "East Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Pearland Regional",
    County: "Brazoria",
    Region: "Upper Gulf Coast",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Pecos Municipal",
    County: "Reeves",
    Region: "West Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Perry Lefors Field",
    County: "Gray",
    Region: "Panhandle",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Perryton/Ochiltree County Airport",
    County: "Ochiltree",
    Region: "Panhandle",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Pineland Municipal Airport",
    County: "Sabine",
    Region: "East Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Pleasanton Municpal Airport",
    County: "Atascosa",
    Region: "South Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Port Isabel Camreon County Airport",
    County: "Cameron",
    Region: "South Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Possum Kingdom Airport",
    County: "Palo Pinto",
    Region: "North Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Post/Garza County Airport",
    County: "Garza",
    Region: "West Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Presidio Lely international",
    County: "Presidio",
    Region: "West Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 47800,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Quanah Municipal",
    County: "Hardeman",
    Region: "North Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Ralph M. Hall / Rockwall Municipal Airport",
    County: "Rockwall",
    Region: "North Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Reagan County",
    County: "Reagan",
    Region: "West Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Real County",
    County: "Real",
    Region: "South Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Refugio County Airport-Rooke Field",
    County: "Refugio",
    Region: "South Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Rick Husband Amarillo Int'l Airport",
    County: "Potter",
    Region: "Panhandle",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Rio Grande City Municipal Airport",
    County: "Starr",
    Region: "South Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Robert Lee",
    County: "Coke",
    Region: "West Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Robert R. Wells",
    County: "Colorado",
    Region: "Upper Gulf Coast",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Roger M. Dreyer Memorial",
    County: "Gonzales",
    Region: "South Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Ron Duncan",
    County: "Denton",
    Region: "North Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Roy Hurd Memorial",
    County: "Ward",
    Region: "West Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Rusk County Airport",
    County: "Rusk",
    Region: "East Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "San Angelo Regional - Mathis Field",
    County: "Tom Green",
    Region: "West Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 600000,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 60000,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 458000,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "San Antonio International",
    County: "Bexar",
    Region: "South Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 327476057.1,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 272896714.2,
    "Parking Garage and Lots": 0,
    "Government Employment": 491214085.6,
    Other: 0,
  },
  {
    "Airport Name": "San Augustine County",
    County: "San Augustine",
    Region: "East Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "San Marcos Regional",
    County: "Travis",
    Region: "Central Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "San Saba Municipal Airport",
    County: "San Saba",
    Region: "Central Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Scholes Intl at Galveston",
    County: "Galveston",
    Region: "Upper Gulf Coast",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 2500,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 11823142,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 2000000,
    Other: 0,
  },
  {
    "Airport Name": "Scurry County/Winston Field",
    County: "Scurry",
    Region: "West Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Seymour Municipal Airport",
    County: "Baylor",
    Region: "North Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Sheppard AFB/Wichita Falls Municipal",
    County: "Wichita",
    Region: "North Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Sherman Municipal",
    County: "Grayson",
    Region: "North Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Slaton Municipal",
    County: "Lubbock",
    Region: "West Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Smiley Johnson/Bass Field",
    County: "Donley",
    Region: "Panhandle",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Smithville Crawford Municipal Airport",
    County: "Bastrop",
    Region: "Central Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Sonora Airport",
    County: "Sutton",
    Region: "West Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "South Texas Intl at Edinburg",
    County: "Hidalgo",
    Region: "South Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "South Texas Regional Airport",
    County: "Cameron",
    Region: "South Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Stanton Municipal",
    County: "Martin",
    Region: "West Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Stephens County",
    County: "Stephens",
    Region: "West Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 1700000,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Stephenville Clark Regional Airport",
    County: "Erath",
    Region: "North Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Stinson Municipal",
    County: "Bexar",
    Region: "South Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Stonewall County",
    County: "Stonewall",
    Region: "West Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Sugarland Regional",
    County: "Harris",
    Region: "Upper Gulf Coast",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 10448000,
    "Aircraft Support Activities": 493500,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 28063,
    "Aviation Schools": 2100000,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Sulphur Springs Municipal",
    County: "Hopkins",
    Region: "East Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 502800,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Sunray Municipal",
    County: "Moore",
    Region: "Panhandle",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Taylor Brown Muncipal",
    County: "Lamb",
    Region: "West Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Taylor Municipal",
    County: "Williamson",
    Region: "Central Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "T-Bar Airport",
    County: "Lynn",
    Region: "West Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Teague Municipal Airport",
    County: "Freestone",
    Region: "Central Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Terrell County Airport",
    County: "Terrell",
    Region: "West Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Terrell Municipal Airport",
    County: "Kaufman",
    Region: "North Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 161000,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Terry County",
    County: "Terry",
    Region: "West Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Texarkana Regional Airport- Webb Field",
    County: "Bowie",
    Region: "East Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 872920,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Texas Gulf Coast Regional",
    County: "Brazoria",
    Region: "Upper Gulf Coast",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "The Carter Memorial",
    County: "Caldwell",
    Region: "Central Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Throckmorton Municipal",
    County: "Throckmorton",
    Region: "West Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Tradewind",
    County: "Potter",
    Region: "Panhandle",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "TSTC Waco",
    County: "McLennan",
    Region: "Central Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Tyler County",
    County: "Tyler",
    Region: "East Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Tyler Pounds Regional",
    County: "Upton",
    Region: "West Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Upton County Airport",
    County: "Upton",
    Region: "West Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Valley International",
    County: "Cameron",
    Region: "South Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Van Zandt County Regional Airport",
    County: "Van Zandt",
    Region: "East Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 49000,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Victoria Regional",
    County: "Victoria",
    Region: "South Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 36000,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 100000,
    "Air Ambulance": 4000000,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 700100,
    Other: 0,
  },
  {
    "Airport Name": "Waco Regional",
    County: "McLennan",
    Region: "Central Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Weslaco Mid-Valley",
    County: "Hidalgo",
    Region: "South Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "West Houston Airport",
    County: "Harris",
    Region: "Upper Gulf Coast",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Wharton Regional Airport",
    County: "Wharton",
    Region: "Upper Gulf Coast",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Wheeler Municipal",
    County: "Wheeler",
    Region: "Panhandle",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Wilbarger County Airport",
    County: "Wilbarger",
    Region: "North Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "William P Hobby",
    County: "Harris",
    Region: "Upper Gulf Coast",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Winkler County",
    County: "Winkler",
    Region: "West Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Winnsboro Municipal Airport",
    County: "Franklin",
    Region: "East Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Winston Field",
    County: "Scurry",
    Region: "West Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Winters Municipal",
    County: "Runnels",
    Region: "West Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Wood County",
    County: "Wood",
    Region: "East Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Yoakum County Airport",
    County: "Yoakum",
    Region: "West Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Yoakum Muni",
    County: "De Witt",
    Region: "West Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Zapata County Airport",
    County: "Zapata",
    Region: "South Texas",
    Type: "Operating Expenses",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "A. L. Mangham Jr. Regional Airport",
    County: "Nacogdoches",
    Region: "East Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Abilene Regional Airport",
    County: "Taylor, Jones",
    Region: "West Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 555055,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 333333.3333,
    Other: 0,
  },
  {
    "Airport Name": "Addison Airport",
    County: "Dallas",
    Region: "North Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 583063.6667,
    "Aircraft Support Activities": 296666.6667,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Albany Municipal",
    County: "Shackelford",
    Region: "West Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Alfred C. Bubba Thomas Airport",
    County: "San Patricio",
    Region: "South Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": null,
    Manufacturing: null,
    "Retail - Miscellaneous store retailers": null,
    "Air Transportation": null,
    "Aircraft Support Activities": null,
    "Hangar Rental and Development": null,
    "Rental Car": null,
    "Other Professional Services": null,
    "Aviation Schools": null,
    "Air Ambulance": null,
    Museum: null,
    "Other amusement and recreation industries": null,
    "Food & Drinking Places": null,
    "Parking Garage and Lots": null,
    "Government Employment": null,
    Other: null,
  },
  {
    "Airport Name": "Alice International Airport",
    County: "Jim Wells",
    Region: "South Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Alpine Casparis Municipal Airport",
    County: "Brewster",
    Region: "West Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Andrews County Airport",
    County: "Andrews",
    Region: "West Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Angelina County Airport",
    County: "Angelina",
    Region: "East Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Aransas County Airport",
    County: "Aransas",
    Region: "South Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Arledge Field",
    County: "Haskell",
    Region: "West Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Arlington Municipal Airport",
    County: "Tarrant",
    Region: "North Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 3000000,
    "Aircraft Support Activities": 175500,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 20000,
    Other: 0,
  },
  {
    "Airport Name": "Athens Municipal",
    County: "Henderson",
    Region: "East Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Austin-Bergstrom International",
    County: "Travis",
    Region: "Central Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Avenger Field",
    County: "Nolan",
    Region: "West Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Bay City Regional Airport",
    County: "Matagorda",
    Region: "Upper Gulf Coast",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Beaumont Municipal",
    County: "Jefferson",
    Region: "East Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Beeville Municipal",
    County: "Bee",
    Region: "South Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Big Spring McMahon-Wrinkle",
    County: "Howard",
    Region: "West Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 10000,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Bishop Municipal Airport",
    County: "Potter",
    Region: "Panhandle",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Bowie Municipal Airport",
    County: "Montague",
    Region: "North Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Brenham Municipal",
    County: "Washington",
    Region: "Central Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Bridgeport Municipal Airport",
    County: "Wise",
    Region: "North Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Brooks County",
    County: "Brooks",
    Region: "South Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Brownsville/South Padre Island International",
    County: "Cameron",
    Region: "South Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Brownwood Regional",
    County: "Brown",
    Region: "West Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Bruce Field",
    County: "Runnels",
    Region: "West Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Burnet Municipal Airport",
    County: "Burnet",
    Region: "Central Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "C David Campbell Field-Corsicana Municipal",
    County: "Navarro",
    Region: "North Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Caddo Mills Municipal",
    County: "Hunt",
    Region: "North Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Caldwell Municipal",
    County: "Burleson",
    Region: "Central Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Calhoun County Airport",
    County: "Calhoun",
    Region: "South Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Cameron Municipal Airpark",
    County: "Milam",
    Region: "Central Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Castroville Municipal",
    County: "Medina",
    Region: "South Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Center Municipal",
    County: "Shelby",
    Region: "East Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Chambers County - Anahuac",
    County: "Chambers",
    Region: "Upper Gulf Coast",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Chambers County - Winnie Stowell",
    County: "Chambers",
    Region: "Upper Gulf Coast",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 1066666.667,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Charles R Johnson",
    County: "Willacy",
    Region: "South Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Cherokee County Airport",
    County: "Cherokee",
    Region: "East Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 50000,
    "Aircraft Support Activities": 8333.333333,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Childress Municipal",
    County: "Childress",
    Region: "Panhandle",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Cisco Municipal",
    County: "Eastland",
    Region: "West Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "City of Shamrock",
    County: "Wheeler",
    Region: "Panhandle",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "City of Tulia/Swisher County Municipal",
    County: "Swisher",
    Region: "Panhandle",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Clarksville/Red River County-J D Trissell Field",
    County: "Red River",
    Region: "East Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Cleburne Regional Airport",
    County: "Johnson",
    Region: "North Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 43333.33333,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 140000,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Cleveland Municipal",
    County: "Liberty",
    Region: "Upper Gulf Coast",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Clifton Municipal",
    County: "Bosque",
    Region: "West Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Cochran County",
    County: "Cochran",
    Region: "West Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Coleman Muncipal",
    County: "Coleman",
    Region: "West Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Colorado City Municipal Airport",
    County: "Mitchell",
    Region: "West Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Comanche County - City",
    County: "Comanche",
    Region: "West Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Commerce Municipal",
    County: "Hunt",
    Region: "North Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Conroe North-Houston Regional",
    County: "Harris",
    Region: "Upper Gulf Coast",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 5000,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Corpus Christi International",
    County: "Nueces",
    Region: "South Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Corsicana Muncipal Airport",
    County: "Navarro",
    Region: "North Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Cotulla-La Salle County Airport",
    County: "La Salle",
    Region: "South Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 20000,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Coulter Field",
    County: "Brazos",
    Region: "Central Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Cox Field",
    County: "Lamar",
    Region: "East Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 16166.66667,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Crane County",
    County: "Crane",
    Region: "West Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Crosbyton Municipal Airport",
    County: "Crosby",
    Region: "West Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Crystal City Municipal",
    County: "Zavala",
    Region: "South Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Cuero Muni",
    County: "De Witt",
    Region: "West Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Culberson County",
    County: "Culberson",
    Region: "West Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Curtis Field",
    County: "McCulloch",
    Region: "West Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Cypress River",
    County: "Marion",
    Region: "East Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Dalhart Muni",
    County: "Dallam",
    Region: "Panhandle",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Dallas CBD Vertiport",
    County: "Denton",
    Region: "North Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Dallas Executive",
    County: "Denton",
    Region: "North Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 54000,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Dallas Love Field",
    County: "Denton",
    Region: "North Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Dallas/Fort Worth International",
    County: "Denton",
    Region: "North Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Dan E Richards",
    County: "Cottle",
    Region: "North Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "David Wayne Hooks Memorial",
    County: "Harris",
    Region: "Upper Gulf Coast",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Decatur Muncipal Airport",
    County: "Wise",
    Region: "North Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Del Rio International Airport",
    County: "Val Verde",
    Region: "South Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Dell City Municipal Airport",
    County: "Hudspeth",
    Region: "West Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Denton Enterprise Airport",
    County: "Denton",
    Region: "North Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 3366083.333,
    "Aircraft Support Activities": 833.3333333,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Denver City Airport",
    County: "Yoakum",
    Region: "West Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Desoto Heliport",
    County: "Dallas",
    Region: "North Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Devine Municipal Airport",
    County: "Medina",
    Region: "South Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Dilley Airport",
    County: "Frio",
    Region: "South Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Dimmit County Airport",
    County: "Dimmit",
    Region: "South Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Dimmit Municipal",
    County: "Dimmit",
    Region: "South Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Draughon-Miller Central Texas Regional Airport",
    County: "Bell",
    Region: "Central Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Dublin Muncipal Airport",
    County: "Erath",
    Region: "North Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Duval-Freer",
    County: "Duval",
    Region: "South Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Eagle Lake Regional",
    County: "Colorado",
    Region: "Upper Gulf Coast",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "East Texas Regional",
    County: "Gregg",
    Region: "East Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Easterwood Field",
    County: "Brazos",
    Region: "Central Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 3333333.333,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Eastland Municipal",
    County: "Eastland",
    Region: "West Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Edwards County Airport",
    County: "Edwards",
    Region: "South Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "El Paso International",
    County: "El Paso",
    Region: "West Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Eldorado Airport",
    County: "Schleicher",
    Region: "West Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Ellington Field",
    County: "Harris",
    Region: "Upper Gulf Coast",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Ennis Municipal",
    County: "Ellis",
    Region: "North Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Fabens",
    County: "El Paso",
    Region: "West Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Fayette Regional Air Center",
    County: "Fayette",
    Region: "Central Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Fisher County Airport",
    County: "Fisher",
    Region: "West Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Floydada Municipal Airport",
    County: "Floyd",
    Region: "West Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Follett/Lipscomb County",
    County: "Lipscomb",
    Region: "Panhandle",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Fort Stockton/Pecos County",
    County: "Pecos",
    Region: "West Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Fox Stephens Field-Gilmer Municipal",
    County: "Upshur",
    Region: "East Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Franklin County",
    County: "Franklin",
    Region: "East Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Ft. Worth Alliance",
    County: "Denton",
    Region: "North Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 274444.3333,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Ft. Worth Meacham",
    County: "Denton",
    Region: "North Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 9316666.667,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Ft. Worth Spinks",
    County: "Denton",
    Region: "North Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 71666.66667,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Gaines County Airport",
    County: "Gaines",
    Region: "West Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Gainesville Municipal",
    County: "Cooke",
    Region: "North Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Garland/DFW Heliport",
    County: "Dallas",
    Region: "North Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Garner Field Airport",
    County: "Uvalde",
    Region: "South Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Gatesville Municipal Airport",
    County: "Coryell",
    Region: "Central Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "George Bush Intercontinental/Houston",
    County: "Harris",
    Region: "Upper Gulf Coast",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Georgetown Municipal",
    County: "Williamson",
    Region: "Central Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 79333.33333,
    "Hangar Rental and Development": 1666.666667,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 103833.3333,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Giddings-Lee County Airport",
    County: "Lee",
    Region: "Central Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 8000,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Gillespie County Airport",
    County: "Gillespie",
    Region: "South Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 198333.3333,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 90000,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Gladewater Municipal Airport",
    County: "Gregg",
    Region: "East Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 13733.33333,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Goldwaite Airport",
    County: "Mills",
    Region: "Central Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Graham Municipal Airport",
    County: "Young",
    Region: "North Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Granbury Regional Airport",
    County: "Hood",
    Region: "North Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 466.6666667,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 41333.33333,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Grand Prairie Municipal",
    County: "Tarrant",
    Region: "North Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Greater Morris County",
    County: "Morris",
    Region: "East Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Greenville Municipal",
    County: "Hunt",
    Region: "North Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Groveton-Trinity County",
    County: "Trinity",
    Region: "East Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Gruver Municipal",
    County: "Hansford",
    Region: "Panhandle",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "H H Coffield Regional",
    County: "Milam",
    Region: "Central Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Hale County",
    County: "Hale",
    Region: "West Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Hallettsville Muni",
    County: "Lavaca",
    Region: "South Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Hall-Miller Municipal",
    County: "Cass",
    Region: "East Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Hamilton Municipal",
    County: "Hamilton",
    Region: "Central Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Harrison County Airport",
    County: "Harrison",
    Region: "East Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 43333.33333,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Harrison Field",
    County: "Knox",
    Region: "West Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Haskell Municipal Airport",
    County: "Haskell",
    Region: "West Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Hawthorne Field",
    County: "Hardin",
    Region: "East Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Hearne Municipal",
    County: "Robertson",
    Region: "Central Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Hemphill County",
    County: "Hemphill",
    Region: "Panhandle",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Hereford Municipal",
    County: "Deaf Smith",
    Region: "Panhandle",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Higgs/Libscomb County",
    County: "Lipscomb",
    Region: "Panhandle",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Hillsboro Municipal Airport",
    County: "Hill",
    Region: "Central Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 175000,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Houston County",
    County: "Houston",
    Region: "East Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Houston Southwest",
    County: "Harris",
    Region: "Upper Gulf Coast",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Huntsville Municipal",
    County: "Walker",
    Region: "Upper Gulf Coast",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Hutchinson County",
    County: "Hutchinson",
    Region: "Panhandle",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Jack Brooks Regional",
    County: "Jefferson",
    Region: "East Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Jacksboro Municipal",
    County: "Jack",
    Region: "North Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Jackson County",
    County: "Jackson",
    Region: "South Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Jasper County-Bell Field",
    County: "Jasper",
    Region: "East Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Jim Hogg County",
    County: "Jim Hogg",
    Region: "South Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Jones Field Airport",
    County: "Fannin",
    Region: "North Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Karnes County",
    County: "Karnes",
    Region: "South Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Kelly Field",
    County: "Bexar",
    Region: "South Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Kenedy Regional",
    County: "Karnes",
    Region: "South Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Kent County",
    County: "Kent",
    Region: "West Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Kerrville-Kerr County",
    County: "Kerr",
    Region: "South Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 1000000,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Kickapoo Downtown",
    County: "Wichita",
    Region: "North Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Killeen-Ft. Hood Regional",
    County: "Bell",
    Region: "Central Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 666666.6667,
    Other: 0,
  },
  {
    "Airport Name": "Killeen-Skylark Field",
    County: "Bell",
    Region: "Central Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Kimble County",
    County: "Kimble",
    Region: "West Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Kirbyville Airport",
    County: "Jasper",
    Region: "East Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Kleberg County Airport",
    County: "Kleberg",
    Region: "South Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "La Porte Municipal",
    County: "Harris",
    Region: "Upper Gulf Coast",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Lago Vista Rusty Allen Airport",
    County: "Travis",
    Region: "Central Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Lamesa Municipal Airport",
    County: "Dawson",
    Region: "West Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Lampasas Municipal Airport",
    County: "Lampasas",
    Region: "Central Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Lancaster Regional",
    County: "Dallas",
    Region: "North Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Laredo International",
    County: "Webb",
    Region: "South Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Levelland Municipal",
    County: "Hockley",
    Region: "West Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Liberty Municipal",
    County: "Gregg",
    Region: "East Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Live Oak County",
    County: "Live Oak",
    Region: "South Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Livingston Municipal",
    County: "Polk",
    Region: "East Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Llano Municipal Airport",
    County: "Llano",
    Region: "Central Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Lockhart Municipal",
    County: "Caldwell",
    Region: "Central Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Lubbock Preston Smith International",
    County: "Lubbock",
    Region: "West Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 70000,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Madisonville Municipal",
    County: "Madison",
    Region: "Central Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Major Samuel B Cornelius Field",
    County: "Hansford",
    Region: "Panhandle",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Marfa Municipal",
    County: "Presidio",
    Region: "West Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 328783.3333,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Marian Airpark",
    County: "Collingsworth",
    Region: "Panhandle",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Marlin",
    County: "Falls",
    Region: "Central Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Mason County Airport",
    County: "Mason",
    Region: "West Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Maverick County Memorial International",
    County: "Mason",
    Region: "West Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "McAllen Miller International",
    County: "Hidalgo",
    Region: "South Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "McCampbell-Porter Airport",
    County: "San Patricio",
    Region: "South Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "McGregor Executive",
    County: "McLennan",
    Region: "Central Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "McKinley Field",
    County: "Frio",
    Region: "South Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "McKinney National",
    County: "Denton",
    Region: "North Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "McLean/Gray County",
    County: "Gray",
    Region: "Panhandle",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Memphis Municipal",
    County: "Hall",
    Region: "Panhandle",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Menard County Airport",
    County: "Menard",
    Region: "West Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Mesquite Metro",
    County: "Dallas",
    Region: "North Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Mexia-Limestone County",
    County: "Limestone",
    Region: "Central Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Miami-Roberts County",
    County: "Roberts",
    Region: "Panhandle",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Mid Valley",
    County: "Hidalgo",
    Region: "South Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Midland Airpark",
    County: "Midland",
    Region: "West Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Midland International Air & Space Port",
    County: "Midland",
    Region: "West Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 57333.33333,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Mid-way Regional",
    County: "Ellis",
    Region: "North Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Mineral Wells Airport",
    County: "Palo Pinto",
    Region: "North Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Moore County Airport",
    County: "Moore",
    Region: "Panhandle",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Mt. Pleasant Regional Airport",
    County: "Titus",
    Region: "East Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Muleshoe Municipal",
    County: "Bailey",
    Region: "West Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Munday Municipal Airport",
    County: "Knox",
    Region: "West Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Mustang Beach",
    County: "Nueces",
    Region: "South Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Navasota Municipal Airport",
    County: "Grimes",
    Region: "Central Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "New Braunfels Regional",
    County: "Comal",
    Region: "South Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Newton Municipal",
    County: "Newton",
    Region: "East Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "North Texas Regional Airport",
    County: "Grayson",
    Region: "North Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 695139,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Northwest Regional Airport",
    County: "Denton",
    Region: "North Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Nueces County Airport",
    County: "Nueces",
    Region: "South Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Odessa-Schlemeyer Field",
    County: "Ector",
    Region: "West Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 617000,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Oldham County",
    County: "Oldham",
    Region: "Panhandle",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Olney Municipal",
    County: "Young",
    Region: "North Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Orange County Airport",
    County: "Orange",
    Region: "Panhandle",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Ozona Municipal",
    County: "Crockett",
    Region: "West Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Palacios Municipal Airport",
    County: "Matagorda",
    Region: "Upper Gulf Coast",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Palestine Municipal Airport",
    County: "Anderson",
    Region: "East Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Panhandle-Carson County Airport",
    County: "Carson",
    Region: "Panhandle",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Panola County-Sharpe Field",
    County: "Panola",
    Region: "East Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Pearland Regional",
    County: "Brazoria",
    Region: "Upper Gulf Coast",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Pecos Municipal",
    County: "Reeves",
    Region: "West Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Perry Lefors Field",
    County: "Gray",
    Region: "Panhandle",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Perryton/Ochiltree County Airport",
    County: "Ochiltree",
    Region: "Panhandle",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Pineland Municipal Airport",
    County: "Sabine",
    Region: "East Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Pleasanton Municpal Airport",
    County: "Atascosa",
    Region: "South Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Port Isabel Camreon County Airport",
    County: "Cameron",
    Region: "South Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Possum Kingdom Airport",
    County: "Palo Pinto",
    Region: "North Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Post/Garza County Airport",
    County: "Garza",
    Region: "West Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Presidio Lely international",
    County: "Presidio",
    Region: "West Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 149600,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Quanah Municipal",
    County: "Hardeman",
    Region: "North Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Ralph M. Hall / Rockwall Municipal Airport",
    County: "Rockwall",
    Region: "North Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Reagan County",
    County: "Reagan",
    Region: "West Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Real County",
    County: "Real",
    Region: "South Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Refugio County Airport-Rooke Field",
    County: "Refugio",
    Region: "South Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Rick Husband Amarillo Int'l Airport",
    County: "Potter",
    Region: "Panhandle",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Rio Grande City Municipal Airport",
    County: "Starr",
    Region: "South Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Robert Lee",
    County: "Coke",
    Region: "West Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Robert R. Wells",
    County: "Colorado",
    Region: "Upper Gulf Coast",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Roger M. Dreyer Memorial",
    County: "Gonzales",
    Region: "South Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Ron Duncan",
    County: "Denton",
    Region: "North Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Roy Hurd Memorial",
    County: "Ward",
    Region: "West Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Rusk County Airport",
    County: "Rusk",
    Region: "East Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "San Angelo Regional - Mathis Field",
    County: "Tom Green",
    Region: "West Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 4166.666667,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 863.3333333,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "San Antonio International",
    County: "Bexar",
    Region: "South Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "San Augustine County",
    County: "San Augustine",
    Region: "East Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "San Marcos Regional",
    County: "Travis",
    Region: "Central Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "San Saba Municipal Airport",
    County: "San Saba",
    Region: "Central Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Scholes Intl at Galveston",
    County: "Galveston",
    Region: "Upper Gulf Coast",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 1666.666667,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 1307161.5,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 41666.66667,
    Other: 0,
  },
  {
    "Airport Name": "Scurry County/Winston Field",
    County: "Scurry",
    Region: "West Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Seymour Municipal Airport",
    County: "Baylor",
    Region: "North Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Sheppard AFB/Wichita Falls Municipal",
    County: "Wichita",
    Region: "North Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Sherman Municipal",
    County: "Grayson",
    Region: "North Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Slaton Municipal",
    County: "Lubbock",
    Region: "West Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Smiley Johnson/Bass Field",
    County: "Donley",
    Region: "Panhandle",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Smithville Crawford Municipal Airport",
    County: "Bastrop",
    Region: "Central Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Sonora Airport",
    County: "Sutton",
    Region: "West Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "South Texas Intl at Edinburg",
    County: "Hidalgo",
    Region: "South Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "South Texas Regional Airport",
    County: "Cameron",
    Region: "South Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Stanton Municipal",
    County: "Martin",
    Region: "West Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Stephens County",
    County: "Stephens",
    Region: "West Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Stephenville Clark Regional Airport",
    County: "Erath",
    Region: "North Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Stinson Municipal",
    County: "Bexar",
    Region: "South Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Stonewall County",
    County: "Stonewall",
    Region: "West Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Sugarland Regional",
    County: "Harris",
    Region: "Upper Gulf Coast",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 1636333.333,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 10666.66667,
    "Aviation Schools": 20000,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Sulphur Springs Municipal",
    County: "Hopkins",
    Region: "East Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 341116.6667,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Sunray Municipal",
    County: "Moore",
    Region: "Panhandle",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Taylor Brown Muncipal",
    County: "Lamb",
    Region: "West Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Taylor Municipal",
    County: "Williamson",
    Region: "Central Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "T-Bar Airport",
    County: "Lynn",
    Region: "West Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Teague Municipal Airport",
    County: "Freestone",
    Region: "Central Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Terrell County Airport",
    County: "Terrell",
    Region: "West Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Terrell Municipal Airport",
    County: "Kaufman",
    Region: "North Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 17633.33333,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Terry County",
    County: "Terry",
    Region: "West Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Texarkana Regional Airport- Webb Field",
    County: "Bowie",
    Region: "East Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 170000,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Texas Gulf Coast Regional",
    County: "Brazoria",
    Region: "Upper Gulf Coast",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "The Carter Memorial",
    County: "Caldwell",
    Region: "Central Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Throckmorton Municipal",
    County: "Throckmorton",
    Region: "West Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Tradewind",
    County: "Potter",
    Region: "Panhandle",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "TSTC Waco",
    County: "McLennan",
    Region: "Central Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Tyler County",
    County: "Tyler",
    Region: "East Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Tyler Pounds Regional",
    County: "Upton",
    Region: "West Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Upton County Airport",
    County: "Upton",
    Region: "West Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Valley International",
    County: "Cameron",
    Region: "South Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Van Zandt County Regional Airport",
    County: "Van Zandt",
    Region: "East Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 10666.66667,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Victoria Regional",
    County: "Victoria",
    Region: "South Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 2666.666667,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 50000,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 3000,
    Other: 0,
  },
  {
    "Airport Name": "Waco Regional",
    County: "McLennan",
    Region: "Central Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Weslaco Mid-Valley",
    County: "Hidalgo",
    Region: "South Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "West Houston Airport",
    County: "Harris",
    Region: "Upper Gulf Coast",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Wharton Regional Airport",
    County: "Wharton",
    Region: "Upper Gulf Coast",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Wheeler Municipal",
    County: "Wheeler",
    Region: "Panhandle",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Wilbarger County Airport",
    County: "Wilbarger",
    Region: "North Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "William P Hobby",
    County: "Harris",
    Region: "Upper Gulf Coast",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Winkler County",
    County: "Winkler",
    Region: "West Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Winnsboro Municipal Airport",
    County: "Franklin",
    Region: "East Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Winston Field",
    County: "Scurry",
    Region: "West Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Winters Municipal",
    County: "Runnels",
    Region: "West Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Wood County",
    County: "Wood",
    Region: "East Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Yoakum County Airport",
    County: "Yoakum",
    Region: "West Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Yoakum Muni",
    County: "De Witt",
    Region: "West Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Zapata County Airport",
    County: "Zapata",
    Region: "South Texas",
    Type: "Capital Improvements",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "A. L. Mangham Jr. Regional Airport",
    County: "Nacogdoches",
    Region: "East Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Abilene Regional Airport",
    County: "Taylor, Jones",
    Region: "West Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 8527932,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Addison Airport",
    County: "Dallas",
    Region: "North Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 72897745,
    "Aircraft Support Activities": 20321692,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Albany Municipal",
    County: "Shackelford",
    Region: "West Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Alfred C. Bubba Thomas Airport",
    County: "San Patricio",
    Region: "South Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": null,
    Manufacturing: null,
    "Retail - Miscellaneous store retailers": null,
    "Air Transportation": null,
    "Aircraft Support Activities": null,
    "Hangar Rental and Development": null,
    "Rental Car": null,
    "Other Professional Services": null,
    "Aviation Schools": null,
    "Air Ambulance": null,
    Museum: null,
    "Other amusement and recreation industries": null,
    "Food & Drinking Places": null,
    "Parking Garage and Lots": null,
    "Government Employment": null,
    Other: null,
  },
  {
    "Airport Name": "Alice International Airport",
    County: "Jim Wells",
    Region: "South Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Alpine Casparis Municipal Airport",
    County: "Brewster",
    Region: "West Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Andrews County Airport",
    County: "Andrews",
    Region: "West Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Angelina County Airport",
    County: "Angelina",
    Region: "East Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Aransas County Airport",
    County: "Aransas",
    Region: "South Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Arledge Field",
    County: "Haskell",
    Region: "West Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Arlington Municipal Airport",
    County: "Tarrant",
    Region: "North Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Athens Municipal",
    County: "Henderson",
    Region: "East Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Austin-Bergstrom International",
    County: "Travis",
    Region: "Central Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Avenger Field",
    County: "Nolan",
    Region: "West Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Bay City Regional Airport",
    County: "Matagorda",
    Region: "Upper Gulf Coast",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Beaumont Municipal",
    County: "Jefferson",
    Region: "East Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Beeville Municipal",
    County: "Bee",
    Region: "South Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Big Spring McMahon-Wrinkle",
    County: "Howard",
    Region: "West Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 109721,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Bishop Municipal Airport",
    County: "Potter",
    Region: "Panhandle",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Bowie Municipal Airport",
    County: "Montague",
    Region: "North Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Brenham Municipal",
    County: "Washington",
    Region: "Central Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Bridgeport Municipal Airport",
    County: "Wise",
    Region: "North Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Brooks County",
    County: "Brooks",
    Region: "South Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Brownsville/South Padre Island International",
    County: "Cameron",
    Region: "South Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Brownwood Regional",
    County: "Brown",
    Region: "West Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Bruce Field",
    County: "Runnels",
    Region: "West Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Burnet Municipal Airport",
    County: "Burnet",
    Region: "Central Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "C David Campbell Field-Corsicana Municipal",
    County: "Navarro",
    Region: "North Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Caddo Mills Municipal",
    County: "Hunt",
    Region: "North Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Caldwell Municipal",
    County: "Burleson",
    Region: "Central Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Calhoun County Airport",
    County: "Calhoun",
    Region: "South Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Cameron Municipal Airpark",
    County: "Milam",
    Region: "Central Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Castroville Municipal",
    County: "Medina",
    Region: "South Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Center Municipal",
    County: "Shelby",
    Region: "East Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Chambers County - Anahuac",
    County: "Chambers",
    Region: "Upper Gulf Coast",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Chambers County - Winnie Stowell",
    County: "Chambers",
    Region: "Upper Gulf Coast",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 6700000,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Charles R Johnson",
    County: "Willacy",
    Region: "South Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Cherokee County Airport",
    County: "Cherokee",
    Region: "East Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 1714000,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Childress Municipal",
    County: "Childress",
    Region: "Panhandle",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Cisco Municipal",
    County: "Eastland",
    Region: "West Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "City of Shamrock",
    County: "Wheeler",
    Region: "Panhandle",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "City of Tulia/Swisher County Municipal",
    County: "Swisher",
    Region: "Panhandle",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Clarksville/Red River County-J D Trissell Field",
    County: "Red River",
    Region: "East Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Cleburne Regional Airport",
    County: "Johnson",
    Region: "North Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 350000,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 310000,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Cleveland Municipal",
    County: "Liberty",
    Region: "Upper Gulf Coast",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Clifton Municipal",
    County: "Bosque",
    Region: "West Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Cochran County",
    County: "Cochran",
    Region: "West Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Coleman Muncipal",
    County: "Coleman",
    Region: "West Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Colorado City Municipal Airport",
    County: "Mitchell",
    Region: "West Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Comanche County - City",
    County: "Comanche",
    Region: "West Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Commerce Municipal",
    County: "Hunt",
    Region: "North Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Conroe North-Houston Regional",
    County: "Harris",
    Region: "Upper Gulf Coast",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 175000,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Corpus Christi International",
    County: "Nueces",
    Region: "South Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Corsicana Muncipal Airport",
    County: "Navarro",
    Region: "North Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Cotulla-La Salle County Airport",
    County: "La Salle",
    Region: "South Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 876116,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Coulter Field",
    County: "Brazos",
    Region: "Central Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Cox Field",
    County: "Lamar",
    Region: "East Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 465000,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Crane County",
    County: "Crane",
    Region: "West Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Crosbyton Municipal Airport",
    County: "Crosby",
    Region: "West Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Crystal City Municipal",
    County: "Zavala",
    Region: "South Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Cuero Muni",
    County: "De Witt",
    Region: "West Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Culberson County",
    County: "Culberson",
    Region: "West Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Curtis Field",
    County: "McCulloch",
    Region: "West Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Cypress River",
    County: "Marion",
    Region: "East Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Dalhart Muni",
    County: "Dallam",
    Region: "Panhandle",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Dallas CBD Vertiport",
    County: "Denton",
    Region: "North Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Dallas Executive",
    County: "Denton",
    Region: "North Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 1824283,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Dallas Love Field",
    County: "Denton",
    Region: "North Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Dallas/Fort Worth International",
    County: "Denton",
    Region: "North Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Dan E Richards",
    County: "Cottle",
    Region: "North Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "David Wayne Hooks Memorial",
    County: "Harris",
    Region: "Upper Gulf Coast",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Decatur Muncipal Airport",
    County: "Wise",
    Region: "North Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Del Rio International Airport",
    County: "Val Verde",
    Region: "South Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Dell City Municipal Airport",
    County: "Hudspeth",
    Region: "West Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Denton Enterprise Airport",
    County: "Denton",
    Region: "North Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 21346875,
    "Aircraft Support Activities": 1659000,
    "Hangar Rental and Development": 104000,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Denver City Airport",
    County: "Yoakum",
    Region: "West Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Desoto Heliport",
    County: "Dallas",
    Region: "North Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Devine Municipal Airport",
    County: "Medina",
    Region: "South Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Dilley Airport",
    County: "Frio",
    Region: "South Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Dimmit County Airport",
    County: "Dimmit",
    Region: "South Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Dimmit Municipal",
    County: "Dimmit",
    Region: "South Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Draughon-Miller Central Texas Regional Airport",
    County: "Bell",
    Region: "Central Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Dublin Muncipal Airport",
    County: "Erath",
    Region: "North Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Duval-Freer",
    County: "Duval",
    Region: "South Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Eagle Lake Regional",
    County: "Colorado",
    Region: "Upper Gulf Coast",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "East Texas Regional",
    County: "Gregg",
    Region: "East Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Easterwood Field",
    County: "Brazos",
    Region: "Central Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 5000000,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Eastland Municipal",
    County: "Eastland",
    Region: "West Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Edwards County Airport",
    County: "Edwards",
    Region: "South Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "El Paso International",
    County: "El Paso",
    Region: "West Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Eldorado Airport",
    County: "Schleicher",
    Region: "West Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Ellington Field",
    County: "Harris",
    Region: "Upper Gulf Coast",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Ennis Municipal",
    County: "Ellis",
    Region: "North Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Fabens",
    County: "El Paso",
    Region: "West Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Fayette Regional Air Center",
    County: "Fayette",
    Region: "Central Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Fisher County Airport",
    County: "Fisher",
    Region: "West Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Floydada Municipal Airport",
    County: "Floyd",
    Region: "West Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Follett/Lipscomb County",
    County: "Lipscomb",
    Region: "Panhandle",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Fort Stockton/Pecos County",
    County: "Pecos",
    Region: "West Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Fox Stephens Field-Gilmer Municipal",
    County: "Upshur",
    Region: "East Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Franklin County",
    County: "Franklin",
    Region: "East Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Ft. Worth Alliance",
    County: "Denton",
    Region: "North Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 1741667,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Ft. Worth Meacham",
    County: "Denton",
    Region: "North Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Ft. Worth Spinks",
    County: "Denton",
    Region: "North Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 520000,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Gaines County Airport",
    County: "Gaines",
    Region: "West Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Gainesville Municipal",
    County: "Cooke",
    Region: "North Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Garland/DFW Heliport",
    County: "Dallas",
    Region: "North Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Garner Field Airport",
    County: "Uvalde",
    Region: "South Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Gatesville Municipal Airport",
    County: "Coryell",
    Region: "Central Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "George Bush Intercontinental/Houston",
    County: "Harris",
    Region: "Upper Gulf Coast",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Georgetown Municipal",
    County: "Williamson",
    Region: "Central Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 969000,
    "Aircraft Support Activities": 2800000,
    "Hangar Rental and Development": 55000,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 200000,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Giddings-Lee County Airport",
    County: "Lee",
    Region: "Central Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 365300,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Gillespie County Airport",
    County: "Gillespie",
    Region: "South Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 1600000,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Gladewater Municipal Airport",
    County: "Gregg",
    Region: "East Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 70000,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Goldwaite Airport",
    County: "Mills",
    Region: "Central Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Graham Municipal Airport",
    County: "Young",
    Region: "North Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 400000,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Granbury Regional Airport",
    County: "Hood",
    Region: "North Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 120000,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 186000,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Grand Prairie Municipal",
    County: "Tarrant",
    Region: "North Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Greater Morris County",
    County: "Morris",
    Region: "East Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Greenville Municipal",
    County: "Hunt",
    Region: "North Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Groveton-Trinity County",
    County: "Trinity",
    Region: "East Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Gruver Municipal",
    County: "Hansford",
    Region: "Panhandle",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "H H Coffield Regional",
    County: "Milam",
    Region: "Central Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Hale County",
    County: "Hale",
    Region: "West Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Hallettsville Muni",
    County: "Lavaca",
    Region: "South Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Hall-Miller Municipal",
    County: "Cass",
    Region: "East Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Hamilton Municipal",
    County: "Hamilton",
    Region: "Central Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Harrison County Airport",
    County: "Harrison",
    Region: "East Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 300000,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Harrison Field",
    County: "Knox",
    Region: "West Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Haskell Municipal Airport",
    County: "Haskell",
    Region: "West Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Hawthorne Field",
    County: "Hardin",
    Region: "East Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Hearne Municipal",
    County: "Robertson",
    Region: "Central Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Hemphill County",
    County: "Hemphill",
    Region: "Panhandle",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Hereford Municipal",
    County: "Deaf Smith",
    Region: "Panhandle",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Higgs/Libscomb County",
    County: "Lipscomb",
    Region: "Panhandle",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Hillsboro Municipal Airport",
    County: "Hill",
    Region: "Central Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 750000,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Houston County",
    County: "Houston",
    Region: "East Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Houston Southwest",
    County: "Harris",
    Region: "Upper Gulf Coast",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Huntsville Municipal",
    County: "Walker",
    Region: "Upper Gulf Coast",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Hutchinson County",
    County: "Hutchinson",
    Region: "Panhandle",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Jack Brooks Regional",
    County: "Jefferson",
    Region: "East Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Jacksboro Municipal",
    County: "Jack",
    Region: "North Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Jackson County",
    County: "Jackson",
    Region: "South Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Jasper County-Bell Field",
    County: "Jasper",
    Region: "East Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Jim Hogg County",
    County: "Jim Hogg",
    Region: "South Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Jones Field Airport",
    County: "Fannin",
    Region: "North Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Karnes County",
    County: "Karnes",
    Region: "South Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Kelly Field",
    County: "Bexar",
    Region: "South Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Kenedy Regional",
    County: "Karnes",
    Region: "South Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Kent County",
    County: "Kent",
    Region: "West Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Kerrville-Kerr County",
    County: "Kerr",
    Region: "South Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 5000000,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Kickapoo Downtown",
    County: "Wichita",
    Region: "North Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Killeen-Ft. Hood Regional",
    County: "Bell",
    Region: "Central Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Killeen-Skylark Field",
    County: "Bell",
    Region: "Central Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Kimble County",
    County: "Kimble",
    Region: "West Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Kirbyville Airport",
    County: "Jasper",
    Region: "East Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Kleberg County Airport",
    County: "Kleberg",
    Region: "South Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "La Porte Municipal",
    County: "Harris",
    Region: "Upper Gulf Coast",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Lago Vista Rusty Allen Airport",
    County: "Travis",
    Region: "Central Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Lamesa Municipal Airport",
    County: "Dawson",
    Region: "West Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Lampasas Municipal Airport",
    County: "Lampasas",
    Region: "Central Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Lancaster Regional",
    County: "Dallas",
    Region: "North Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Laredo International",
    County: "Webb",
    Region: "South Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Levelland Municipal",
    County: "Hockley",
    Region: "West Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Liberty Municipal",
    County: "Gregg",
    Region: "East Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Live Oak County",
    County: "Live Oak",
    Region: "South Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Livingston Municipal",
    County: "Polk",
    Region: "East Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Llano Municipal Airport",
    County: "Llano",
    Region: "Central Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Lockhart Municipal",
    County: "Caldwell",
    Region: "Central Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Lubbock Preston Smith International",
    County: "Lubbock",
    Region: "West Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 8200000,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Madisonville Municipal",
    County: "Madison",
    Region: "Central Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Major Samuel B Cornelius Field",
    County: "Hansford",
    Region: "Panhandle",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 2077178516,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Marfa Municipal",
    County: "Presidio",
    Region: "West Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 432000,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Marian Airpark",
    County: "Collingsworth",
    Region: "Panhandle",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Marlin",
    County: "Falls",
    Region: "Central Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Mason County Airport",
    County: "Mason",
    Region: "West Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Maverick County Memorial International",
    County: "Mason",
    Region: "West Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "McAllen Miller International",
    County: "Hidalgo",
    Region: "South Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 968978,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "McCampbell-Porter Airport",
    County: "San Patricio",
    Region: "South Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "McGregor Executive",
    County: "McLennan",
    Region: "Central Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "McKinley Field",
    County: "Frio",
    Region: "South Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "McKinney National",
    County: "Denton",
    Region: "North Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 425237,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "McLean/Gray County",
    County: "Gray",
    Region: "Panhandle",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Memphis Municipal",
    County: "Hall",
    Region: "Panhandle",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Menard County Airport",
    County: "Menard",
    Region: "West Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Mesquite Metro",
    County: "Dallas",
    Region: "North Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Mexia-Limestone County",
    County: "Limestone",
    Region: "Central Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Miami-Roberts County",
    County: "Roberts",
    Region: "Panhandle",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Mid Valley",
    County: "Hidalgo",
    Region: "South Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Midland Airpark",
    County: "Midland",
    Region: "West Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Midland International Air & Space Port",
    County: "Midland",
    Region: "West Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 2159061,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Mid-way Regional",
    County: "Ellis",
    Region: "North Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 51000,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Mineral Wells Airport",
    County: "Palo Pinto",
    Region: "North Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Moore County Airport",
    County: "Moore",
    Region: "Panhandle",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Mt. Pleasant Regional Airport",
    County: "Titus",
    Region: "East Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 300000,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Muleshoe Municipal",
    County: "Bailey",
    Region: "West Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Munday Municipal Airport",
    County: "Knox",
    Region: "West Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Mustang Beach",
    County: "Nueces",
    Region: "South Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Navasota Municipal Airport",
    County: "Grimes",
    Region: "Central Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "New Braunfels Regional",
    County: "Comal",
    Region: "South Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Newton Municipal",
    County: "Newton",
    Region: "East Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "North Texas Regional Airport",
    County: "Grayson",
    Region: "North Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 4411458,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Northwest Regional Airport",
    County: "Denton",
    Region: "North Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 8823194,
    "Hangar Rental and Development": 266226,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Nueces County Airport",
    County: "Nueces",
    Region: "South Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Odessa-Schlemeyer Field",
    County: "Ector",
    Region: "West Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 2259730,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Oldham County",
    County: "Oldham",
    Region: "Panhandle",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Olney Municipal",
    County: "Young",
    Region: "North Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 80000000,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Orange County Airport",
    County: "Orange",
    Region: "Panhandle",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Ozona Municipal",
    County: "Crockett",
    Region: "West Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Palacios Municipal Airport",
    County: "Matagorda",
    Region: "Upper Gulf Coast",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Palestine Municipal Airport",
    County: "Anderson",
    Region: "East Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Panhandle-Carson County Airport",
    County: "Carson",
    Region: "Panhandle",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Panola County-Sharpe Field",
    County: "Panola",
    Region: "East Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Pearland Regional",
    County: "Brazoria",
    Region: "Upper Gulf Coast",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Pecos Municipal",
    County: "Reeves",
    Region: "West Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Perry Lefors Field",
    County: "Gray",
    Region: "Panhandle",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Perryton/Ochiltree County Airport",
    County: "Ochiltree",
    Region: "Panhandle",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Pineland Municipal Airport",
    County: "Sabine",
    Region: "East Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Pleasanton Municpal Airport",
    County: "Atascosa",
    Region: "South Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Port Isabel Camreon County Airport",
    County: "Cameron",
    Region: "South Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Possum Kingdom Airport",
    County: "Palo Pinto",
    Region: "North Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Post/Garza County Airport",
    County: "Garza",
    Region: "West Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Presidio Lely international",
    County: "Presidio",
    Region: "West Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 136000,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Quanah Municipal",
    County: "Hardeman",
    Region: "North Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Ralph M. Hall / Rockwall Municipal Airport",
    County: "Rockwall",
    Region: "North Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Reagan County",
    County: "Reagan",
    Region: "West Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Real County",
    County: "Real",
    Region: "South Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Refugio County Airport-Rooke Field",
    County: "Refugio",
    Region: "South Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Rick Husband Amarillo Int'l Airport",
    County: "Potter",
    Region: "Panhandle",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Rio Grande City Municipal Airport",
    County: "Starr",
    Region: "South Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Robert Lee",
    County: "Coke",
    Region: "West Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Robert R. Wells",
    County: "Colorado",
    Region: "Upper Gulf Coast",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Roger M. Dreyer Memorial",
    County: "Gonzales",
    Region: "South Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Ron Duncan",
    County: "Denton",
    Region: "North Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Roy Hurd Memorial",
    County: "Ward",
    Region: "West Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 72000,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Rusk County Airport",
    County: "Rusk",
    Region: "East Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "San Angelo Regional - Mathis Field",
    County: "Tom Green",
    Region: "West Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 800000,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 80000,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 552000,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "San Antonio International",
    County: "Bexar",
    Region: "South Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "San Augustine County",
    County: "San Augustine",
    Region: "East Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "San Marcos Regional",
    County: "Travis",
    Region: "Central Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "San Saba Municipal Airport",
    County: "San Saba",
    Region: "Central Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Scholes Intl at Galveston",
    County: "Galveston",
    Region: "Upper Gulf Coast",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 24000,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Scurry County/Winston Field",
    County: "Scurry",
    Region: "West Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Seymour Municipal Airport",
    County: "Baylor",
    Region: "North Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Sheppard AFB/Wichita Falls Municipal",
    County: "Wichita",
    Region: "North Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Sherman Municipal",
    County: "Grayson",
    Region: "North Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Slaton Municipal",
    County: "Lubbock",
    Region: "West Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Smiley Johnson/Bass Field",
    County: "Donley",
    Region: "Panhandle",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Smithville Crawford Municipal Airport",
    County: "Bastrop",
    Region: "Central Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Sonora Airport",
    County: "Sutton",
    Region: "West Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "South Texas Intl at Edinburg",
    County: "Hidalgo",
    Region: "South Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "South Texas Regional Airport",
    County: "Cameron",
    Region: "South Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Stanton Municipal",
    County: "Martin",
    Region: "West Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Stephens County",
    County: "Stephens",
    Region: "West Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Stephenville Clark Regional Airport",
    County: "Erath",
    Region: "North Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Stinson Municipal",
    County: "Bexar",
    Region: "South Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Stonewall County",
    County: "Stonewall",
    Region: "West Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Sugarland Regional",
    County: "Harris",
    Region: "Upper Gulf Coast",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 1342900,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 55065,
    "Aviation Schools": 2200000,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Sulphur Springs Municipal",
    County: "Hopkins",
    Region: "East Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Sunray Municipal",
    County: "Moore",
    Region: "Panhandle",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Taylor Brown Muncipal",
    County: "Lamb",
    Region: "West Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Taylor Municipal",
    County: "Williamson",
    Region: "Central Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "T-Bar Airport",
    County: "Lynn",
    Region: "West Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Teague Municipal Airport",
    County: "Freestone",
    Region: "Central Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Terrell County Airport",
    County: "Terrell",
    Region: "West Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Terrell Municipal Airport",
    County: "Kaufman",
    Region: "North Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 163000,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Terry County",
    County: "Terry",
    Region: "West Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Texarkana Regional Airport- Webb Field",
    County: "Bowie",
    Region: "East Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 5500,
    Other: 0,
  },
  {
    "Airport Name": "Texas Gulf Coast Regional",
    County: "Brazoria",
    Region: "Upper Gulf Coast",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "The Carter Memorial",
    County: "Caldwell",
    Region: "Central Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Throckmorton Municipal",
    County: "Throckmorton",
    Region: "West Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Tradewind",
    County: "Potter",
    Region: "Panhandle",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "TSTC Waco",
    County: "McLennan",
    Region: "Central Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Tyler County",
    County: "Tyler",
    Region: "East Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Tyler Pounds Regional",
    County: "Upton",
    Region: "West Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Upton County Airport",
    County: "Upton",
    Region: "West Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Valley International",
    County: "Cameron",
    Region: "South Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Van Zandt County Regional Airport",
    County: "Van Zandt",
    Region: "East Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 55000,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Victoria Regional",
    County: "Victoria",
    Region: "South Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 48000,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 50000,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Waco Regional",
    County: "McLennan",
    Region: "Central Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Weslaco Mid-Valley",
    County: "Hidalgo",
    Region: "South Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "West Houston Airport",
    County: "Harris",
    Region: "Upper Gulf Coast",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Wharton Regional Airport",
    County: "Wharton",
    Region: "Upper Gulf Coast",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Wheeler Municipal",
    County: "Wheeler",
    Region: "Panhandle",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Wilbarger County Airport",
    County: "Wilbarger",
    Region: "North Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "William P Hobby",
    County: "Harris",
    Region: "Upper Gulf Coast",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Winkler County",
    County: "Winkler",
    Region: "West Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Winnsboro Municipal Airport",
    County: "Franklin",
    Region: "East Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Winston Field",
    County: "Scurry",
    Region: "West Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Winters Municipal",
    County: "Runnels",
    Region: "West Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Wood County",
    County: "Wood",
    Region: "East Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Yoakum County Airport",
    County: "Yoakum",
    Region: "West Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Yoakum Muni",
    County: "De Witt",
    Region: "West Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
  {
    "Airport Name": "Zapata County Airport",
    County: "Zapata",
    Region: "South Texas",
    Type: "Gross Sales",
    "Support Activities for Agriculture or Oil & Gas": 0,
    Manufacturing: 0,
    "Retail - Miscellaneous store retailers": 0,
    "Air Transportation": 0,
    "Aircraft Support Activities": 0,
    "Hangar Rental and Development": 0,
    "Rental Car": 0,
    "Other Professional Services": 0,
    "Aviation Schools": 0,
    "Air Ambulance": 0,
    Museum: 0,
    "Other amusement and recreation industries": 0,
    "Food & Drinking Places": 0,
    "Parking Garage and Lots": 0,
    "Government Employment": 0,
    Other: 0,
  },
];

export const CAPITAL_EXPENSES = [
  {
    "Model List of Airports": "A. L. Mangham Jr. Regional Airport",
    "CITY AND FACILITIES": "A L MANGHAM JR. RGNL",
    "2015 RAMP": 42522,
    "2015 Total": 42522,
    "2016 CIP": 58746,
    "2016 RAMP": 55596,
    "2016 Total": 114342,
    "2017 CIP": 485618,
    "2017 RAMP": 99956,
    "2017 Total": 585574,
    "2018 RAMP": 89598,
    "2018 Total": 89598,
    "2019 RAMP": 96488,
    "2019 Total": 96488,
    "Grand Total": 928524,
    "3 Yr Average": 257220,
  },
  {
    "Model List of Airports": "Abilene Regional Airport",
    "CITY AND FACILITIES": "ABILENE REGIONAL",
    "2015 RAMP": 57024,
    "2015 Total": 57024,
    "2016 RAMP": 73038,
    "2016 Total": 73038,
    "2017 CIP": 1154400,
    "2017 RAMP": 72682,
    "2017 Total": 1227082,
    "2018 RAMP": 84864,
    "2018 Total": 84864,
    "2019 RAMP": 100000,
    "Grand Total": 387608,
    "3 Yr Average": 655973,
  },
  {
    "Model List of Airports": "Addison Airport",
    "CITY AND FACILITIES": "ADDISON",
    "2015 CIP": 1840390,
    "2015 RAMP": 100000,
    "2015 Total": 1940390,
    "2016 CIP": 37347,
    "2016 RAMP": 100000,
    "2016 Total": 137347,
    "2017 RAMP": 100000,
    "2017 Total": 100000,
    "2018 RAMP": 100000,
    "2018 Total": 100000,
    "2019 RAMP": 100000,
    "2019 Total": 100000,
    "Grand Total": 2377737,
    "3 Yr Average": 100000,
  },
  {
    "Model List of Airports": "Albany Municipal",
    "CITY AND FACILITIES": "ALBANY MUNI",
    "2015 RAMP": 47712,
    "2015 Total": 47712,
    "2016 RAMP": 7396,
    "2016 Total": 7396,
    "2017 RAMP": 52220,
    "2017 Total": 52220,
    "2018 RAMP": 4082,
    "2018 Total": 4082,
    "2019 RAMP": 100000,
    "2019 Total": 100000,
    "Grand Total": 211410,
    "3 Yr Average": 52100.666666666664,
  },
  {
    "Model List of Airports": "Alfred C. Bubba Thomas Airport",
    "CITY AND FACILITIES": "ALFRED C 'BUBBA' THOMAS",
    "2015 Total": 0,
    "2016 Total": 0,
    "2017 Total": 0,
    "2018 Total": 0,
    "2019 RAMP": 100000,
    "2019 Total": 100000,
    "Grand Total": 100000,
    "3 Yr Average": 33333.333333333336,
  },
  {
    "Model List of Airports": "Alice International Airport",
    "CITY AND FACILITIES": "ALICE INTL",
    "2015 RAMP": 44414,
    "2015 Total": 44414,
    "2016 Total": 0,
    "2017 CIP": 457580,
    "2017 RAMP": 1360,
    "2017 Total": 458940,
    "2018 RAMP": 6110,
    "2018 Total": 6110,
    "2019 RAMP": 49752,
    "2019 Total": 49752,
    "Grand Total": 559216,
    "3 Yr Average": 171600.66666666666,
  },
  {
    "Model List of Airports": "Alpine Casparis Municipal Airport",
    "CITY AND FACILITIES": "ALPINE-CASPARIS MUNI",
    "2015 RAMP": 3108,
    "2015 Total": 3108,
    "2016 CIP": 340180,
    "2016 RAMP": 42528,
    "2016 Total": 382708,
    "2017 RAMP": 20694,
    "2017 Total": 20694,
    "2018 CIP": 2860390,
    "2018 RAMP": 13684,
    "2018 Total": 2874074,
    "2019 RAMP": 3294,
    "2019 Total": 3294,
    "Grand Total": 3283878,
    "3 Yr Average": 966020.6666666666,
  },
  {
    "Model List of Airports": "Andrews County Airport",
    "CITY AND FACILITIES": "ANDREWS COUNTY",
    "2015 RAMP": 91124,
    "2015 Total": 91124,
    "2016 RAMP": 66168,
    "2016 Total": 66168,
    "2017 CIP": 912291,
    "2017 RAMP": 97700,
    "2017 Total": 1009991,
    "2018 RAMP": 99880,
    "2018 Total": 99880,
    "2019 RAMP": 100000,
    "2019 Total": 100000,
    "Grand Total": 1367163,
    "3 Yr Average": 403290.3333333333,
  },
  {
    "Model List of Airports": "Angelina County Airport",
    "CITY AND FACILITIES": "ANGELINA COUNTY",
    "2015 CIP": 179970,
    "2015 Total": 179970,
    "2016 Total": 0,
    "2017 CIP": 2435510,
    "2017 Total": 2435510,
    "2018 RAMP": 82906,
    "2018 Total": 82906,
    "2019 RAMP": 39398,
    "2019 Total": 39398,
    "Grand Total": 2737784,
    "3 Yr Average": 852604.6666666666,
  },
  {
    "Model List of Airports": "Aransas County Airport",
    "CITY AND FACILITIES": "ARANSAS CO",
    "2015 RAMP": 97176,
    "2015 Total": 97176,
    "2016 CIP": 77494,
    "2016 RAMP": 88326,
    "2016 Total": 165820,
    "2017 CIP": 963469,
    "2017 RAMP": 100000,
    "2017 Total": 1063469,
    "2018 CIP": 64000,
    "2018 RAMP": 100000,
    "2018 Total": 164000,
    "2019 RAMP": 100000,
    "2019 Total": 100000,
    "Grand Total": 1590465,
    "3 Yr Average": 442489.6666666667,
  },
  {
    "Model List of Airports": "Arledge Field",
    "2015 Total": 0,
    "2016 Total": 0,
    "2017 Total": 0,
    "2018 Total": 0,
    "3 Yr Average": 0,
  },
  {
    "Model List of Airports": "Arlington Municipal Airport",
    "CITY AND FACILITIES": "ARLINGTON MUNI",
    "2015 RAMP": 100000,
    "2015 Total": 100000,
    "2016 RAMP": 101850,
    "2016 Total": 101850,
    "2017 CIP": 1401556,
    "2017 RAMP": 100000,
    "2017 Total": 1501556,
    "2018 RAMP": 100000,
    "2018 Total": 100000,
    "2019 CIP": 74689,
    "2019 RAMP": 100000,
    "2019 Total": 174689,
    "Grand Total": 1978095,
    "3 Yr Average": 592081.6666666666,
  },
  {
    "Model List of Airports": "Athens Municipal",
    "CITY AND FACILITIES": "ATHENS MUNI",
    "2015 CIP": 181029,
    "2015 RAMP": 26300,
    "2015 Total": 207329,
    "2016 RAMP": 57114,
    "2016 Total": 57114,
    "2017 CIP": 139100,
    "2017 RAMP": 8314,
    "2017 Total": 147414,
    "2018 RAMP": 8002,
    "2018 Total": 8002,
    "2019 CIP": 1481849,
    "2019 RAMP": 8188,
    "2019 Total": 1490037,
    "Grand Total": 1909896,
    "3 Yr Average": 548484.3333333334,
  },
  {
    "Model List of Airports": "Austin-Bergstrom International",
    "2015 Total": 0,
    "2016 Total": 0,
    "2017 Total": 0,
    "2018 Total": 0,
    "3 Yr Average": 0,
  },
  {
    "Model List of Airports": "Avenger Field",
    "CITY AND FACILITIES": "AVENGER FIELD",
    "2015 RAMP": 36286,
    "2015 Total": 36286,
    "2016 RAMP": 35110,
    "2016 Total": 35110,
    "2017 CIP": 87596,
    "2017 RAMP": 77670,
    "2017 Total": 165266,
    "2018 RAMP": 22188,
    "2018 Total": 22188,
    "2019 RAMP": 21548,
    "2019 Total": 21548,
    "Grand Total": 280398,
    "3 Yr Average": 69667.33333333333,
  },
  {
    "Model List of Airports": "Bay City Regional Airport",
    "CITY AND FACILITIES": "BAY CITY MUNI",
    "2015 RAMP": 100000,
    "2015 Total": 100000,
    "2016 CIP": 270377,
    "2016 RAMP": 85788,
    "2016 Total": 356165,
    "2017 RAMP": 96702,
    "2017 Total": 96702,
    "2018 CIP": 66670,
    "2018 RAMP": 100000,
    "2018 Total": 166670,
    "2019 RAMP": 100000,
    "2019 Total": 100000,
    "Grand Total": 819537,
    "3 Yr Average": 121124,
  },
  {
    "Model List of Airports": "Beaumont Municipal",
    "CITY AND FACILITIES": "BEAUMONT MUNI",
    "2015 Total": 0,
    "2016 Total": 0,
    "2017 Total": 0,
    "2018 Total": 0,
    "2019 RAMP": 100000,
    "2019 Total": 100000,
    "Grand Total": 100000,
    "3 Yr Average": 33333.333333333336,
  },
  {
    "Model List of Airports": "Beeville Municipal",
    "CITY AND FACILITIES": "BEEVILLE MUNI",
    "2015 Total": 0,
    "2016 RAMP": 19722,
    "2016 Total": 19722,
    "2017 RAMP": 17290,
    "2017 Total": 17290,
    "2018 RAMP": 13556,
    "2018 Total": 13556,
    "2019 Total": 0,
    "Grand Total": 50568,
    "3 Yr Average": 10282,
  },
  {
    "Model List of Airports": "Big Spring McMahon-Wrinkle",
    "CITY AND FACILITIES": "BIG SPRING MC MAHON-WRINKLE",
    "2015 RAMP": 38106,
    "2015 Total": 38106,
    "2016 RAMP": 60290,
    "2016 Total": 60290,
    "2017 RAMP": 34828,
    "2017 Total": 34828,
    "2018 RAMP": 58754,
    "2018 Total": 58754,
    "2019 RAMP": 62530,
    "2019 Total": 62530,
    "Grand Total": 254508,
    "3 Yr Average": 52037.333333333336,
  },
  {
    "Model List of Airports": "Bishop Municipal Airport",
    "CITY AND FACILITIES": "BISHOP MUNI",
    "2015 RAMP": 736,
    "2015 Total": 736,
    "2016 RAMP": 19914,
    "2016 Total": 19914,
    "2017 RAMP": 48604,
    "2017 Total": 48604,
    "2018 RAMP": 59054,
    "2018 Total": 59054,
    "2019 RAMP": 14360,
    "2019 Total": 14360,
    "Grand Total": 142668,
    "3 Yr Average": 40672.666666666664,
  },
  {
    "Model List of Airports": "Bowie Municipal Airport",
    "CITY AND FACILITIES": "BOWIE MUNI",
    "2015 RAMP": 21006,
    "2015 Total": 21006,
    "2016 RAMP": 43584,
    "2016 Total": 43584,
    "2017 RAMP": 16538,
    "2017 Total": 16538,
    "2018 RAMP": 17074,
    "2018 Total": 17074,
    "2019 RAMP": 6482,
    "2019 Total": 6482,
    "Grand Total": 104684,
    "3 Yr Average": 13364.666666666666,
  },
  {
    "Model List of Airports": "Brenham Municipal",
    "CITY AND FACILITIES": "BRENHAM MUNI",
    "2015 RAMP": 100000,
    "2015 Total": 100000,
    "2016 RAMP": 56594,
    "2016 Total": 56594,
    "2017 RAMP": 54830,
    "2017 Total": 54830,
    "2018 RAMP": 96000,
    "2018 Total": 96000,
    "2019 RAMP": 57826,
    "2019 Total": 57826,
    "Grand Total": 365250,
    "3 Yr Average": 69552,
  },
  {
    "Model List of Airports": "Bridgeport Municipal Airport",
    "CITY AND FACILITIES": "BRIDGEPORT MUNI",
    "2015 RAMP": 6364,
    "2015 Total": 6364,
    "2016 RAMP": 53840,
    "2016 Total": 53840,
    "2017 RAMP": 49122,
    "2017 Total": 49122,
    "2018 RAMP": 100000,
    "2018 Total": 100000,
    "2019 RAMP": 14562,
    "2019 Total": 14562,
    "Grand Total": 223888,
    "3 Yr Average": 54561.333333333336,
  },
  {
    "Model List of Airports": "Brooks County",
    "CITY AND FACILITIES": "BROOKS COUNTY",
    "2015 RAMP": 7770,
    "2015 Total": 7770,
    "2016 RAMP": 9074,
    "2016 Total": 9074,
    "2017 RAMP": 16036,
    "2017 Total": 16036,
    "2018 CIP": 73593,
    "2018 RAMP": 18584,
    "2018 Total": 92177,
    "2019 Total": 0,
    "Grand Total": 125057,
    "3 Yr Average": 36071,
  },
  {
    "Model List of Airports": "Brownsville/South Padre Island International",
    "CITY AND FACILITIES": "BROWNSVILLE /S. PADRE ISLAND INTL",
    "2015 RAMP": 100000,
    "2015 Total": 100000,
    "2016 Total": 0,
    "2017 CIP": 12719002,
    "2017 RAMP": 100000,
    "2017 Total": 12819002,
    "2018 CIP": 11048454,
    "2018 RAMP": 100000,
    "2018 Total": 11148454,
    "2019 CIP": 0,
    "2019 RAMP": 100000,
    "2019 Total": 100000,
    "Grand Total": 400000,
    "3 Yr Average": 8022485.333333333,
  },
  {
    "Model List of Airports": "Brownwood Regional",
    "CITY AND FACILITIES": "BROWNWOOD RGNL",
    "2015 RAMP": 100000,
    "2015 Total": 100000,
    "2016 RAMP": 100000,
    "2016 Total": 100000,
    "2017 RAMP": 88398,
    "2017 Total": 88398,
    "2018 RAMP": 25314,
    "2018 Total": 25314,
    "2019 RAMP": 39954,
    "2019 Total": 39954,
    "Grand Total": 353666,
    "3 Yr Average": 51222,
  },
  {
    "Model List of Airports": "Bruce Field",
    "2015 Total": 0,
    "2016 Total": 0,
    "2017 Total": 0,
    "2018 Total": 0,
    "3 Yr Average": 0,
  },
  {
    "Model List of Airports": "Burnet Municipal Airport",
    "CITY AND FACILITIES": "BURNET MUNI KATE CRADDOCK FIELD",
    "2015 RAMP": 39056,
    "2015 Total": 39056,
    "2016 RAMP": 14698,
    "2016 Total": 14698,
    "2017 RAMP": 57324,
    "2017 Total": 57324,
    "2018 CIP": 500000,
    "2018 RAMP": 60000,
    "2018 Total": 560000,
    "2019 RAMP": 96024,
    "2019 Total": 96024,
    "Grand Total": 767102,
    "3 Yr Average": 237782.66666666666,
  },
  {
    "Model List of Airports": "C David Campbell Field-Corsicana Municipal",
    "CITY AND FACILITIES": "C DAVID CAMPBELL FIELD-CORSICANA MUNI",
    "2015 RAMP": 54420,
    "2015 Total": 54420,
    "2016 RAMP": 52230,
    "2016 Total": 52230,
    "2017 RAMP": 68000,
    "2017 Total": 68000,
    "2018 RAMP": 20896,
    "2018 Total": 20896,
    "2019 RAMP": 88794,
    "2019 Total": 88794,
    "Grand Total": 284340,
    "3 Yr Average": 59230,
  },
  {
    "Model List of Airports": "Caddo Mills Municipal",
    "CITY AND FACILITIES": "CADDO MILLS MUNI",
    "2015 Total": 0,
    "2016 RAMP": 5236,
    "2016 Total": 5236,
    "2017 RAMP": 1034,
    "2017 Total": 1034,
    "2018 RAMP": 8706,
    "2018 Total": 8706,
    "2019 RAMP": 10022,
    "2019 Total": 10022,
    "Grand Total": 24998,
    "3 Yr Average": 6587.333333333333,
  },
  {
    "Model List of Airports": "Caldwell Municipal",
    "CITY AND FACILITIES": "CALDWELL MUNI",
    "2015 RAMP": 83748,
    "2015 Total": 83748,
    "2016 Total": 0,
    "2017 CIP": 116865,
    "2017 RAMP": 1666,
    "2017 Total": 118531,
    "2018 Total": 0,
    "2019 RAMP": 968,
    "2019 Total": 968,
    "Grand Total": 203247,
    "3 Yr Average": 39833,
  },
  {
    "Model List of Airports": "Calhoun County Airport",
    "CITY AND FACILITIES": "CALHOUN COUNTY",
    "2015 RAMP": 27954,
    "2015 Total": 27954,
    "2016 RAMP": 19696,
    "2016 Total": 19696,
    "2017 RAMP": 34446,
    "2017 Total": 34446,
    "2018 RAMP": 41254,
    "2018 Total": 41254,
    "2019 RAMP": 30250,
    "2019 Total": 30250,
    "Grand Total": 153600,
    "3 Yr Average": 35316.666666666664,
  },
  {
    "Model List of Airports": "Cameron Municipal Airpark",
    "CITY AND FACILITIES": "CAMERON MUNI AIRPARK",
    "2015 RAMP": 5910,
    "2015 Total": 5910,
    "2016 RAMP": 928,
    "2016 Total": 928,
    "2017 RAMP": 5908,
    "2017 Total": 5908,
    "2018 RAMP": 13396,
    "2018 Total": 13396,
    "2019 Total": 0,
    "Grand Total": 26142,
    "3 Yr Average": 6434.666666666667,
  },
  {
    "Model List of Airports": "Castroville Municipal",
    "CITY AND FACILITIES": "CASTROVILLE MUNI",
    "2015 RAMP": 30704,
    "2015 Total": 30704,
    "2016 RAMP": 57838,
    "2016 Total": 57838,
    "2017 CIP": 360759,
    "2017 RAMP": 47392,
    "2017 Total": 408151,
    "2018 RAMP": 64278,
    "2018 Total": 64278,
    "2019 RAMP": 93190,
    "2019 Total": 93190,
    "Grand Total": 654161,
    "3 Yr Average": 188539.66666666666,
  },
  {
    "Model List of Airports": "Center Municipal",
    "CITY AND FACILITIES": "CENTER MUNI",
    "2015 RAMP": 4498,
    "2015 Total": 4498,
    "2016 CIP": 196941,
    "2016 RAMP": 19952,
    "2016 Total": 216893,
    "2017 CIP": 1133800,
    "2017 Total": 1133800,
    "2018 RAMP": 8484,
    "2018 Total": 8484,
    "2019 RAMP": 29590,
    "2019 Total": 29590,
    "Grand Total": 1393265,
    "3 Yr Average": 390624.6666666667,
  },
  {
    "Model List of Airports": "Chambers County - Anahuac",
    "CITY AND FACILITIES": "CHAMBERS COUNTY",
    "2015 CIP": 173844,
    "2015 RAMP": 22894,
    "2015 Total": 196738,
    "2016 RAMP": 14848,
    "2016 Total": 14848,
    "2017 CIP": 1261752,
    "2017 RAMP": 16916,
    "2017 Total": 1278668,
    "2018 RAMP": 12124,
    "2018 Total": 12124,
    "2019 RAMP": 35184,
    "2019 Total": 35184,
    "Grand Total": 1537562,
    "3 Yr Average": 441992,
  },
  {
    "Model List of Airports": "Chambers County - Winnie Stowell",
    "CITY AND FACILITIES": "CHAMBERS COUNTY-WINNIE STOWELL",
    "2015 RAMP": 16906,
    "2015 Total": 16906,
    "2016 RAMP": 9330,
    "2016 Total": 9330,
    "2017 RAMP": 24336,
    "2017 Total": 24336,
    "2018 RAMP": 11344,
    "2018 Total": 11344,
    "2019 Total": 0,
    "Grand Total": 61916,
    "3 Yr Average": 11893.333333333334,
  },
  {
    "Model List of Airports": "Charles R Johnson",
    "2015 Total": 0,
    "2016 Total": 0,
    "2017 Total": 0,
    "2018 Total": 0,
    "3 Yr Average": 0,
  },
  {
    "Model List of Airports": "Cherokee County Airport",
    "CITY AND FACILITIES": "CHEROKEE COUNTY",
    "2015 CIP": 643970,
    "2015 RAMP": 10188,
    "2015 Total": 654158,
    "2016 RAMP": 7286,
    "2016 Total": 7286,
    "2017 RAMP": 10136,
    "2017 Total": 10136,
    "2018 RAMP": 12584,
    "2018 Total": 12584,
    "2019 RAMP": 12686,
    "2019 Total": 12686,
    "Grand Total": 696850,
    "3 Yr Average": 11802,
  },
  {
    "Model List of Airports": "Childress Municipal",
    "2015 Total": 0,
    "2016 Total": 0,
    "2017 Total": 0,
    "2018 Total": 0,
    "3 Yr Average": 0,
  },
  {
    "Model List of Airports": "Cisco Municipal",
    "2015 Total": 0,
    "2016 Total": 0,
    "2017 Total": 0,
    "2018 Total": 0,
    "3 Yr Average": 0,
  },
  {
    "Model List of Airports": "City of Shamrock",
    "CITY AND FACILITIES": "SHAMROCK MUNI",
    "2015 Total": 0,
    "2016 Total": 0,
    "2017 Total": 0,
    "2018 RAMP": 16000,
    "2018 Total": 16000,
    "2019 RAMP": 26000,
    "2019 Total": 26000,
    "Grand Total": 42000,
    "3 Yr Average": 14000,
  },
  {
    "Model List of Airports": "City of Tulia/Swisher County Municipal",
    "CITY AND FACILITIES": "CITY OF TULIA/SWISHER COUNTY MUNI",
    "2015 Total": 0,
    "2016 CIP": 1321409,
    "2016 Total": 1321409,
    "2017 Total": 0,
    "2018 Total": 0,
    "2019 Total": 0,
    "Grand Total": 1321409,
    "3 Yr Average": 0,
  },
  {
    "Model List of Airports": "Clarksville/Red River County-J D Trissell Field",
    "CITY AND FACILITIES": "CLARKSVILLE/RED RIVER CTY-J D TRISSELL FLD",
    "2015 RAMP": 3684,
    "2015 Total": 3684,
    "2016 RAMP": 14940,
    "2016 Total": 14940,
    "2017 CIP": 44500,
    "2017 RAMP": 9216,
    "2017 Total": 53716,
    "2018 CIP": 111535,
    "2018 RAMP": 3324,
    "2018 Total": 114859,
    "2019 RAMP": 34184,
    "2019 Total": 34184,
    "Grand Total": 221383,
    "3 Yr Average": 67586.33333333333,
  },
  {
    "Model List of Airports": "Cleburne Regional Airport",
    "CITY AND FACILITIES": "CLEBURNE RGNL",
    "2015 RAMP": 68088,
    "2015 Total": 68088,
    "2016 CIP": 296444,
    "2016 RAMP": 84702,
    "2016 Total": 381146,
    "2017 CIP": 571790,
    "2017 RAMP": 66646,
    "2017 Total": 638436,
    "2018 RAMP": 48278,
    "2018 Total": 48278,
    "2019 RAMP": 78864,
    "2019 Total": 78864,
    "Grand Total": 1214812,
    "3 Yr Average": 255192.66666666666,
  },
  {
    "Model List of Airports": "Cleveland Municipal",
    "CITY AND FACILITIES": "CLEVELAND MUNI",
    "2015 CIP": 1077792,
    "2015 Total": 1077792,
    "2016 RAMP": 22058,
    "2016 Total": 22058,
    "2017 RAMP": 31308,
    "2017 Total": 31308,
    "2018 Total": 0,
    "2019 RAMP": 100000,
    "2019 Total": 100000,
    "Grand Total": 1231158,
    "3 Yr Average": 43769.333333333336,
  },
  {
    "Model List of Airports": "Clifton Municipal",
    "CITY AND FACILITIES": "CLIFTON MUNI/ISENHOWER FIELD",
    "2015 RAMP": 28060,
    "2015 Total": 28060,
    "2016 RAMP": 75290,
    "2016 Total": 75290,
    "2017 RAMP": 50424,
    "2017 Total": 50424,
    "2018 RAMP": 8358,
    "2018 Total": 8358,
    "2019 RAMP": 100000,
    "2019 Total": 100000,
    "Grand Total": 262132,
    "3 Yr Average": 52927.333333333336,
  },
  {
    "Model List of Airports": "Cochran County",
    "CITY AND FACILITIES": "COCHRAN COUNTY",
    "2015 Total": 0,
    "2016 Total": 0,
    "2017 Total": 0,
    "2018 RAMP": 614,
    "2018 Total": 614,
    "2019 RAMP": 440,
    "2019 Total": 440,
    "Grand Total": 1054,
    "3 Yr Average": 351.3333333333333,
  },
  {
    "Model List of Airports": "Coleman Muncipal",
    "CITY AND FACILITIES": "COLEMAN MUNI",
    "2015 RAMP": 3596,
    "2015 Total": 3596,
    "2016 Total": 0,
    "2017 RAMP": 55228,
    "2017 Total": 55228,
    "2018 RAMP": 28756,
    "2018 Total": 28756,
    "2019 RAMP": 6058,
    "2019 Total": 6058,
    "Grand Total": 93638,
    "3 Yr Average": 30014,
  },
  {
    "Model List of Airports": "Colorado City Municipal Airport",
    "CITY AND FACILITIES": "COLORADO CITY",
    "2015 RAMP": 1178,
    "2015 Total": 1178,
    "2016 RAMP": 3466,
    "2016 Total": 3466,
    "2017 Total": 0,
    "2018 Total": 0,
    "2019 Total": 0,
    "Grand Total": 4644,
    "3 Yr Average": 0,
  },
  {
    "Model List of Airports": "Comanche County - City",
    "2015 Total": 0,
    "2016 Total": 0,
    "2017 Total": 0,
    "2018 Total": 0,
    "3 Yr Average": 0,
  },
  {
    "Model List of Airports": "Commerce Municipal",
    "CITY AND FACILITIES": "COMMERCE MUNI",
    "2015 CIP": 2080436,
    "2015 Total": 2080436,
    "2016 Total": 0,
    "2017 RAMP": 36450,
    "2017 Total": 36450,
    "2018 RAMP": 11007,
    "2018 Total": 11007,
    "2019 RAMP": 11060,
    "2019 Total": 11060,
    "Grand Total": 2138953,
    "3 Yr Average": 19505.666666666668,
  },
  {
    "Model List of Airports": "Conroe North-Houston Regional",
    "CITY AND FACILITIES": "LONE STAR EXECUTIVE",
    "2015 RAMP": 99402,
    "2015 Total": 99402,
    "2016 CIP": 330645,
    "2016 RAMP": 98828,
    "2016 Total": 429473,
    "2017 CIP": 296526,
    "2017 RAMP": 91032,
    "2017 Total": 387558,
    "2018 RAMP": 93038,
    "2018 Total": 93038,
    "2019 RAMP": 45348,
    "2019 Total": 45348,
    "Grand Total": 1054819,
    "3 Yr Average": 175314.66666666666,
  },
  {
    "Model List of Airports": "Corpus Christi International",
    "CITY AND FACILITIES": "CORPUS CHRISTI INTL",
    "2015 RAMP": 100000,
    "2015 Total": 100000,
    "2016 Total": 0,
    "2017 CIP": 6342005,
    "2017 Total": 6342005,
    "2018 CIP": 13232024,
    "2018 Total": 13232024,
    "2019 CIP": 9445368,
    "2019 Total": 9445368,
    "Grand Total": 100000,
    "3 Yr Average": 9673132.333333334,
  },
  {
    "Model List of Airports": "Corsicana Muncipal Airport",
    "2015 Total": 0,
    "2016 Total": 0,
    "2017 Total": 0,
    "2018 Total": 0,
    "3 Yr Average": 0,
  },
  {
    "Model List of Airports": "Cotulla-La Salle County Airport",
    "2015 Total": 0,
    "2016 Total": 0,
    "2017 Total": 0,
    "2018 Total": 0,
    "3 Yr Average": 0,
  },
  {
    "Model List of Airports": "Coulter Field",
    "CITY AND FACILITIES": "COULTER FIELD",
    "2015 CIP": 135177,
    "2015 RAMP": 100000,
    "2015 Total": 235177,
    "2016 RAMP": 93274,
    "2016 Total": 93274,
    "2017 CIP": 754328,
    "2017 RAMP": 100000,
    "2017 Total": 854328,
    "2018 RAMP": 100000,
    "2018 Total": 100000,
    "2019 RAMP": 86262,
    "2019 Total": 86262,
    "Grand Total": 1369041,
    "3 Yr Average": 346863.3333333333,
  },
  {
    "Model List of Airports": "Cox Field",
    "CITY AND FACILITIES": "COX FIELD",
    "2015 RAMP": 17722,
    "2015 Total": 17722,
    "2016 RAMP": 20478,
    "2016 Total": 20478,
    "2017 RAMP": 11280,
    "2017 Total": 11280,
    "2018 RAMP": 73352,
    "2018 Total": 73352,
    "2019 RAMP": 99988,
    "2019 Total": 99988,
    "Grand Total": 222820,
    "3 Yr Average": 61540,
  },
  {
    "Model List of Airports": "Crane County",
    "CITY AND FACILITIES": "CRANE COUNTY",
    "2015 Total": 0,
    "2016 RAMP": 27240,
    "2016 Total": 27240,
    "2017 CIP": 49940,
    "2017 Total": 49940,
    "2018 CIP": 460511,
    "2018 RAMP": 1030,
    "2018 Total": 461541,
    "2019 RAMP": 66924,
    "2019 Total": 66924,
    "Grand Total": 605645,
    "3 Yr Average": 192801.66666666666,
  },
  {
    "Model List of Airports": "Crosbyton Municipal Airport",
    "CITY AND FACILITIES": "CROSBYTON MUNI",
    "2015 Total": 0,
    "2016 Total": 0,
    "2017 RAMP": 11416,
    "2017 Total": 11416,
    "2018 RAMP": 11680,
    "2018 Total": 11680,
    "2019 Total": 0,
    "Grand Total": 23096,
    "3 Yr Average": 7698.666666666667,
  },
  {
    "Model List of Airports": "Crystal City Municipal",
    "2015 Total": 0,
    "2016 Total": 0,
    "2017 Total": 0,
    "2018 Total": 0,
    "3 Yr Average": 0,
  },
  {
    "Model List of Airports": "Cuero Muni",
    "CITY AND FACILITIES": "CUERO MUNI",
    "2015 Total": 0,
    "2016 Total": 0,
    "2017 Total": 0,
    "2018 Total": 0,
    "2019 RAMP": 20000,
    "2019 Total": 20000,
    "Grand Total": 20000,
    "3 Yr Average": 6666.666666666667,
  },
  {
    "Model List of Airports": "Culberson County",
    "CITY AND FACILITIES": "CULBERSON COUNTY",
    "2015 Total": 0,
    "2016 RAMP": 6800,
    "2016 Total": 6800,
    "2017 RAMP": 14852,
    "2017 Total": 14852,
    "2018 Total": 0,
    "2019 Total": 0,
    "Grand Total": 21652,
    "3 Yr Average": 4950.666666666667,
  },
  {
    "Model List of Airports": "Curtis Field",
    "CITY AND FACILITIES": "CURTIS FIELD",
    "2015 CIP": 28268,
    "2015 RAMP": 96218,
    "2015 Total": 124486,
    "2016 RAMP": 26728,
    "2016 Total": 26728,
    "2017 CIP": 73148,
    "2017 RAMP": 52248,
    "2017 Total": 125396,
    "2018 RAMP": 38996,
    "2018 Total": 38996,
    "2019 RAMP": 19940,
    "2019 Total": 19940,
    "Grand Total": 335546,
    "3 Yr Average": 61444,
  },
  {
    "Model List of Airports": "Cypress River",
    "CITY AND FACILITIES": "CYPRESS RIVER",
    "2015 RAMP": 7988,
    "2015 Total": 7988,
    "2016 RAMP": 71996,
    "2016 Total": 71996,
    "2017 RAMP": 28112,
    "2017 Total": 28112,
    "2018 RAMP": 9328,
    "2018 Total": 9328,
    "2019 RAMP": 3830,
    "2019 Total": 3830,
    "Grand Total": 121254,
    "3 Yr Average": 13756.666666666666,
  },
  {
    "Model List of Airports": "Dalhart Muni",
    "CITY AND FACILITIES": "DALHART MUNI",
    "2015 RAMP": 33236,
    "2015 Total": 33236,
    "2016 Total": 0,
    "2017 Total": 0,
    "2018 Total": 0,
    "2019 Total": 0,
    "Grand Total": 33236,
    "3 Yr Average": 0,
  },
  {
    "Model List of Airports": "Dallas CBD Vertiport",
    "CITY AND FACILITIES": "DALLAS CBD VERTIPORT",
    "2015 Total": 0,
    "2016 Total": 0,
    "2017 Total": 0,
    "2018 Total": 0,
    "2019 RAMP": 16788,
    "2019 Total": 16788,
    "Grand Total": 16788,
    "3 Yr Average": 5596,
  },
  {
    "Model List of Airports": "Dallas Executive",
    "CITY AND FACILITIES": "DALLAS EXECUTIVE",
    "2015 CIP": 6214933,
    "2015 RAMP": 67374,
    "2015 Total": 6282307,
    "2016 RAMP": 48428,
    "2016 Total": 48428,
    "2017 CIP": 8260163,
    "2017 RAMP": 48880,
    "2017 Total": 8309043,
    "2018 RAMP": 100000,
    "2018 Total": 100000,
    "2019 RAMP": 100000,
    "2019 Total": 100000,
    "Grand Total": 14839778,
    "3 Yr Average": 2836347.6666666665,
  },
  {
    "Model List of Airports": "Dallas Love Field",
    "2015 Total": 0,
    "2016 Total": 0,
    "2017 Total": 0,
    "2018 Total": 0,
    "3 Yr Average": 0,
  },
  {
    "Model List of Airports": "Dallas/Fort Worth International",
    "2015 Total": 0,
    "2016 Total": 0,
    "2017 Total": 0,
    "2018 Total": 0,
    "3 Yr Average": 0,
  },
  {
    "Model List of Airports": "Dan E Richards",
    "2015 Total": 0,
    "2016 Total": 0,
    "2017 Total": 0,
    "2018 Total": 0,
    "3 Yr Average": 0,
  },
  {
    "Model List of Airports": "David Wayne Hooks Memorial",
    "2015 Total": 0,
    "2016 Total": 0,
    "2017 Total": 0,
    "2018 Total": 0,
    "3 Yr Average": 0,
  },
  {
    "Model List of Airports": "Decatur Muncipal Airport",
    "CITY AND FACILITIES": "DECATUR MUNI",
    "2015 RAMP": 18878,
    "2015 Total": 18878,
    "2016 RAMP": 86824,
    "2016 Total": 86824,
    "2017 RAMP": 28800,
    "2017 Total": 28800,
    "2018 RAMP": 51004,
    "2018 Total": 51004,
    "2019 RAMP": 61164,
    "2019 Total": 61164,
    "Grand Total": 246670,
    "3 Yr Average": 46989.333333333336,
  },
  {
    "Model List of Airports": "Del Rio International Airport",
    "CITY AND FACILITIES": "DEL RIO INTL",
    "2015 RAMP": 18194,
    "2015 Total": 18194,
    "2016 RAMP": 71066,
    "2016 Total": 71066,
    "2017 RAMP": 100000,
    "2017 Total": 100000,
    "2018 RAMP": 34348,
    "2018 Total": 34348,
    "2019 RAMP": 28916,
    "2019 Total": 28916,
    "Grand Total": 252524,
    "3 Yr Average": 54421.333333333336,
  },
  {
    "Model List of Airports": "Dell City Municipal Airport",
    "CITY AND FACILITIES": "DELL CITY MUNI",
    "2015 RAMP": 1542,
    "2015 Total": 1542,
    "2016 Total": 0,
    "2017 Total": 0,
    "2018 RAMP": 7018,
    "2018 Total": 7018,
    "2019 Total": 0,
    "Grand Total": 8560,
    "3 Yr Average": 2339.3333333333335,
  },
  {
    "Model List of Airports": "Denton Enterprise Airport",
    "CITY AND FACILITIES": "DENTON MUNI",
    "2015 CIP": 557429,
    "2015 RAMP": 100000,
    "2015 Total": 657429,
    "2016 RAMP": 126703,
    "2016 Total": 126703,
    "2017 RAMP": 100000,
    "2017 Total": 100000,
    "2018 RAMP": 99990,
    "2018 Total": 99990,
    "2019 RAMP": 100000,
    "2019 Total": 100000,
    "Grand Total": 1084122,
    "3 Yr Average": 99996.66666666667,
  },
  {
    "Model List of Airports": "Denver City Airport",
    "CITY AND FACILITIES": "DENVER CITY",
    "2015 RAMP": 2284,
    "2015 Total": 2284,
    "2016 RAMP": 8356,
    "2016 Total": 8356,
    "2017 RAMP": 8460,
    "2017 Total": 8460,
    "2018 RAMP": 7916,
    "2018 Total": 7916,
    "2019 RAMP": 4286,
    "2019 Total": 4286,
    "Grand Total": 31302,
    "3 Yr Average": 6887.333333333333,
  },
  {
    "Model List of Airports": "Desoto Heliport",
    "2015 Total": 0,
    "2016 Total": 0,
    "2017 Total": 0,
    "2018 Total": 0,
    "3 Yr Average": 0,
  },
  {
    "Model List of Airports": "Devine Municipal Airport",
    "CITY AND FACILITIES": "DEVINE MUNI",
    "2015 RAMP": 4476,
    "2015 Total": 4476,
    "2016 RAMP": 1712,
    "2016 Total": 1712,
    "2017 CIP": 322347,
    "2017 RAMP": 8460,
    "2017 Total": 330807,
    "2018 RAMP": 100000,
    "2018 Total": 100000,
    "2019 RAMP": 100000,
    "2019 Total": 100000,
    "Grand Total": 536995,
    "3 Yr Average": 176935.66666666666,
  },
  {
    "Model List of Airports": "Dilly Airport",
    "CITY AND FACILITIES": "DILLEY AIRPARK",
    "2015 Total": 0,
    "2016 Total": 0,
    "2017 Total": 0,
    "2018 CIP": 87361,
    "2018 Total": 87361,
    "2019 Total": 0,
    "Grand Total": 87361,
    "3 Yr Average": 29120.333333333332,
  },
  {
    "Model List of Airports": "Dimmit County Airport",
    "CITY AND FACILITIES": "DIMMIT COUNTY",
    "2015 RAMP": 18194,
    "2015 Total": 18194,
    "2016 RAMP": 8536,
    "2016 Total": 8536,
    "2017 RAMP": 9842,
    "2017 Total": 9842,
    "2018 RAMP": 70966,
    "2018 Total": 70966,
    "2019 RAMP": 26846,
    "2019 Total": 26846,
    "Grand Total": 134384,
    "3 Yr Average": 35884.666666666664,
  },
  {
    "Model List of Airports": "Dimmit Municipal",
    "CITY AND FACILITIES": "DIMMITT MUNI",
    "2015 RAMP": 3180,
    "2015 Total": 3180,
    "2016 Total": 0,
    "2017 Total": 0,
    "2018 Total": 0,
    "2019 RAMP": 9854,
    "2019 Total": 9854,
    "Grand Total": 13034,
    "3 Yr Average": 3284.6666666666665,
  },
  {
    "Model List of Airports": "Draughon-Miller Central Texas Regional Airport",
    "CITY AND FACILITIES": "DRAUGHON-MILLER CENTRAL TEXAS RGNL",
    "2015 CIP": 815683,
    "2015 RAMP": 100000,
    "2015 Total": 915683,
    "2016 RAMP": 283000,
    "2016 Total": 283000,
    "2017 CIP": 150000,
    "2017 RAMP": 100000,
    "2017 Total": 250000,
    "2018 RAMP": 100000,
    "2018 Total": 100000,
    "2019 RAMP": 100000,
    "2019 Total": 100000,
    "Grand Total": 1648683,
    "3 Yr Average": 150000,
  },
  {
    "Model List of Airports": "Dublin Muncipal Airport",
    "CITY AND FACILITIES": "DUBLIN MUNI",
    "2015 Total": 0,
    "2016 RAMP": 99170,
    "2016 Total": 99170,
    "2017 Total": 0,
    "2018 RAMP": 17124,
    "2018 Total": 17124,
    "2019 RAMP": 20604,
    "2019 Total": 20604,
    "Grand Total": 136898,
    "3 Yr Average": 12576,
  },
  {
    "Model List of Airports": "Duval-Freer",
    "CITY AND FACILITIES": "DUVAL-FREER",
    "2015 Total": 0,
    "2016 Total": 0,
    "2017 Total": 0,
    "2018 RAMP": 2638,
    "2018 Total": 2638,
    "2019 Total": 0,
    "Grand Total": 2638,
    "3 Yr Average": 879.3333333333334,
  },
  {
    "Model List of Airports": "Eagle Lake Regional",
    "CITY AND FACILITIES": "EAGLE LAKE",
    "2015 CIP": 151275,
    "2015 RAMP": 6342,
    "2015 Total": 157617,
    "2016 RAMP": 24874,
    "2016 Total": 24874,
    "2017 RAMP": 27360,
    "2017 Total": 27360,
    "2018 RAMP": 7554,
    "2018 Total": 7554,
    "2019 RAMP": 6476,
    "2019 Total": 6476,
    "Grand Total": 223881,
    "3 Yr Average": 13796.666666666666,
  },
  {
    "Model List of Airports": "East Texas Regional",
    "CITY AND FACILITIES": "EAST TEXAS REGIONAL",
    "2015 RAMP": 99754,
    "2015 Total": 99754,
    "2016 RAMP": 99872,
    "2016 Total": 99872,
    "2017 CIP": 1473413,
    "2017 RAMP": 99850,
    "2017 Total": 1573263,
    "2018 CIP": 1082469,
    "2018 RAMP": 96692,
    "2018 Total": 1179161,
    "2019 CIP": 525060,
    "2019 RAMP": 98392,
    "2019 Total": 623452,
    "Grand Total": 494560,
    "3 Yr Average": 1125292,
  },
  {
    "Model List of Airports": "Easterwood Field",
    "CITY AND FACILITIES": "EASTERWOOD FIELD",
    "2015 RAMP": 100000,
    "2015 Total": 100000,
    "2016 RAMP": 88028,
    "2016 Total": 88028,
    "2017 RAMP": 100000,
    "2017 Total": 100000,
    "2018 RAMP": 100000,
    "2018 Total": 100000,
    "2019 CIP": 6238758,
    "2019 RAMP": 100000,
    "2019 Total": 6338758,
    "Grand Total": 488028,
    "3 Yr Average": 2179586,
  },
  {
    "Model List of Airports": "Eastland Municipal",
    "CITY AND FACILITIES": "EASTLAND MUNI",
    "2015 RAMP": 100000,
    "2015 Total": 100000,
    "2016 CIP": 138750,
    "2016 RAMP": 100000,
    "2016 Total": 238750,
    "2017 CIP": 444376,
    "2017 RAMP": 100000,
    "2017 Total": 544376,
    "2018 RAMP": 41250,
    "2018 Total": 41250,
    "2019 RAMP": 15002,
    "2019 Total": 15002,
    "Grand Total": 939378,
    "3 Yr Average": 200209.33333333334,
  },
  {
    "Model List of Airports": "Edwards County Airport",
    "CITY AND FACILITIES": "EDWARDS COUNTY",
    "2015 Total": 0,
    "2016 Total": 0,
    "2017 Total": 0,
    "2018 RAMP": 100000,
    "2018 Total": 100000,
    "2019 Total": 0,
    "Grand Total": 100000,
    "3 Yr Average": 33333.333333333336,
  },
  {
    "Model List of Airports": "El Paso Internatinal",
    "2015 Total": 0,
    "2016 Total": 0,
    "2017 Total": 0,
    "2018 Total": 0,
    "3 Yr Average": 0,
  },
  {
    "Model List of Airports": "Eldorado Airport",
    "CITY AND FACILITIES": "ELDORADO",
    "2015 RAMP": 5942,
    "2015 Total": 5942,
    "2016 RAMP": 2176,
    "2016 Total": 2176,
    "2017 Total": 0,
    "2018 RAMP": 1034,
    "2018 Total": 1034,
    "2019 RAMP": 2230,
    "2019 Total": 2230,
    "Grand Total": 11382,
    "3 Yr Average": 1088,
  },
  {
    "Model List of Airports": "Ellington Field",
    "CITY AND FACILITIES": "ELLINGTON",
    "2015 RAMP": 20850,
    "2015 Total": 20850,
    "2016 Total": 0,
    "2017 Total": 0,
    "2018 Total": 0,
    "2019 Total": 0,
    "Grand Total": 20850,
    "3 Yr Average": 0,
  },
  {
    "Model List of Airports": "Ennis Municipal",
    "CITY AND FACILITIES": "ENNIS MUNI",
    "2015 Total": 0,
    "2016 RAMP": 53282,
    "2016 Total": 53282,
    "2017 CIP": 188006,
    "2017 RAMP": 91896,
    "2017 Total": 279902,
    "2018 Total": 0,
    "2019 RAMP": 15184,
    "2019 Total": 15184,
    "Grand Total": 348368,
    "3 Yr Average": 98362,
  },
  {
    "Model List of Airports": "Fabens",
    "CITY AND FACILITIES": "FABENS",
    "2015 Total": 0,
    "2016 Total": 0,
    "2017 RAMP": 3872,
    "2017 Total": 3872,
    "2018 RAMP": 43444,
    "2018 Total": 43444,
    "2019 RAMP": 6144,
    "2019 Total": 6144,
    "Grand Total": 53460,
    "3 Yr Average": 17820,
  },
  {
    "Model List of Airports": "Fayette Regional Air Center",
    "CITY AND FACILITIES": "FAYETTE RGNL AIR CENTER",
    "2015 CIP": 253330,
    "2015 RAMP": 31964,
    "2015 Total": 285294,
    "2016 RAMP": 35290,
    "2016 Total": 35290,
    "2017 CIP": 2622503,
    "2017 RAMP": 4436,
    "2017 Total": 2626939,
    "2018 RAMP": 14936,
    "2018 Total": 14936,
    "2019 Total": 0,
    "Grand Total": 2962459,
    "3 Yr Average": 880625,
  },
  {
    "Model List of Airports": "Fisher County Airport",
    "2015 Total": 0,
    "2016 Total": 0,
    "2017 Total": 0,
    "2018 Total": 0,
    "3 Yr Average": 0,
  },
  {
    "Model List of Airports": "Floydada Municipal Airport",
    "CITY AND FACILITIES": "FLOYDADA MUNI",
    "2015 Total": 0,
    "2016 Total": 0,
    "2017 Total": 0,
    "2018 Total": 0,
    "2019 RAMP": 20000,
    "2019 Total": 20000,
    "Grand Total": 20000,
    "3 Yr Average": 6666.666666666667,
  },
  {
    "Model List of Airports": "Follett/Lipscomb County",
    "2015 Total": 0,
    "2016 Total": 0,
    "2017 Total": 0,
    "2018 Total": 0,
    "3 Yr Average": 0,
  },
  {
    "Model List of Airports": "Fort Stockton/Pecos County",
    "CITY AND FACILITIES": "FORT STOCKTON-PECOS COUNTY",
    "2015 CIP": 147443,
    "2015 Total": 147443,
    "2016 Total": 0,
    "2017 Total": 0,
    "2018 CIP": 1252802,
    "2018 Total": 1252802,
    "2019 RAMP": 11934,
    "2019 Total": 11934,
    "Grand Total": 1412179,
    "3 Yr Average": 421578.6666666667,
  },
  {
    "Model List of Airports": "Fox Stephens Field-Gilmer Municipal",
    "CITY AND FACILITIES": "FOX STEPHENS FIELD - GILMER MUNI",
    "2015 CIP": 93913,
    "2015 RAMP": 12840,
    "2015 Total": 106753,
    "2016 RAMP": 9802,
    "2016 Total": 9802,
    "2017 RAMP": 17126,
    "2017 Total": 17126,
    "2018 RAMP": 22000,
    "2018 Total": 22000,
    "2019 RAMP": 12596,
    "2019 Total": 12596,
    "Grand Total": 168277,
    "3 Yr Average": 17240.666666666668,
  },
  {
    "Model List of Airports": "Franklin County",
    "CITY AND FACILITIES": "FRANKLIN COUNTY",
    "2015 CIP": 577719,
    "2015 Total": 577719,
    "2016 Total": 0,
    "2017 CIP": 501068,
    "2017 Total": 501068,
    "2018 Total": 0,
    "2019 Total": 0,
    "Grand Total": 1078787,
    "3 Yr Average": 167022.66666666666,
  },
  {
    "Model List of Airports": "Ft. Worth Alliance",
    "CITY AND FACILITIES": "FORT WORTH ALLIANCE",
    "2015 RAMP": 99542,
    "2015 Total": 99542,
    "2016 RAMP": 84000,
    "2016 Total": 84000,
    "2017 CIP": 12392264,
    "2017 RAMP": 93850,
    "2017 Total": 12486114,
    "2018 CIP": 1386164,
    "2018 RAMP": 100000,
    "2018 Total": 1486164,
    "2019 CIP": 14258516,
    "2019 RAMP": 100000,
    "2019 Total": 14358516,
    "Grand Total": 477392,
    "3 Yr Average": 9443598,
  },
  {
    "Model List of Airports": "Ft. Worth Meacham",
    "CITY AND FACILITIES": "FORT WORTH MEACHAM INTL",
    "2015 RAMP": 100000,
    "2015 Total": 100000,
    "2016 CIP": 500000,
    "2016 RAMP": 199998,
    "2016 Total": 699998,
    "2017 CIP": 2313507,
    "2017 RAMP": 100000,
    "2017 Total": 2413507,
    "2018 CIP": 385200,
    "2018 RAMP": 100000,
    "2018 Total": 485200,
    "2019 RAMP": 100000,
    "2019 Total": 100000,
    "Grand Total": 3798705,
    "3 Yr Average": 999569,
  },
  {
    "Model List of Airports": "Ft. Worth Spinks",
    "CITY AND FACILITIES": "FORT WORTH SPINKS",
    "2015 Total": 0,
    "2016 CIP": 68000,
    "2016 RAMP": 100000,
    "2016 Total": 168000,
    "2017 RAMP": 100000,
    "2017 Total": 100000,
    "2018 RAMP": 100000,
    "2018 Total": 100000,
    "2019 RAMP": 85072,
    "2019 Total": 85072,
    "Grand Total": 453072,
    "3 Yr Average": 95024,
  },
  {
    "Model List of Airports": "Gaines County Airport",
    "CITY AND FACILITIES": "GAINES COUNTY",
    "2015 CIP": 985644,
    "2015 RAMP": 20012,
    "2015 Total": 1005656,
    "2016 RAMP": 100000,
    "2016 Total": 100000,
    "2017 RAMP": 35598,
    "2017 Total": 35598,
    "2018 RAMP": 92716,
    "2018 Total": 92716,
    "2019 RAMP": 39716,
    "2019 Total": 39716,
    "Grand Total": 1273686,
    "3 Yr Average": 56010,
  },
  {
    "Model List of Airports": "Gainesville Municipal",
    "CITY AND FACILITIES": "GAINESVILLE MUNI",
    "2015 CIP": 76582,
    "2015 RAMP": 20826,
    "2015 Total": 97408,
    "2016 RAMP": 44684,
    "2016 Total": 44684,
    "2017 RAMP": 69444,
    "2017 Total": 69444,
    "2018 CIP": 452621,
    "2018 RAMP": 42896,
    "2018 Total": 495517,
    "2019 RAMP": 56618,
    "2019 Total": 56618,
    "Grand Total": 763671,
    "3 Yr Average": 207193,
  },
  {
    "Model List of Airports": "Garland/DFW Heliport",
    "2015 Total": 0,
    "2016 Total": 0,
    "2017 Total": 0,
    "2018 Total": 0,
    "3 Yr Average": 0,
  },
  {
    "Model List of Airports": "Garner Field Airport",
    "CITY AND FACILITIES": "GARNER FIELD",
    "2015 CIP": 1033678,
    "2015 RAMP": 20938,
    "2015 Total": 1054616,
    "2016 RAMP": 23596,
    "2016 Total": 23596,
    "2017 RAMP": 73670,
    "2017 Total": 73670,
    "2018 RAMP": 28208,
    "2018 Total": 28208,
    "2019 RAMP": 30892,
    "2019 Total": 30892,
    "Grand Total": 1210982,
    "3 Yr Average": 44256.666666666664,
  },
  {
    "Model List of Airports": "Gatesville Municipal Airport",
    "CITY AND FACILITIES": "GATESVILLE MUNI",
    "2015 CIP": 245507,
    "2015 RAMP": 82262,
    "2015 Total": 327769,
    "2016 RAMP": 9248,
    "2016 Total": 9248,
    "2017 RAMP": 16116,
    "2017 Total": 16116,
    "2018 RAMP": 90000,
    "2018 Total": 90000,
    "2019 RAMP": 6102,
    "2019 Total": 6102,
    "Grand Total": 449235,
    "3 Yr Average": 37406,
  },
  {
    "Model List of Airports": "George Bush Intercontinental/Houston",
    "2015 Total": 0,
    "2016 Total": 0,
    "2017 Total": 0,
    "2018 Total": 0,
    "3 Yr Average": 0,
  },
  {
    "Model List of Airports": "Georgetown Municipal",
    "CITY AND FACILITIES": "GEORGETOWN MUNI",
    "2015 CIP": 7487849,
    "2015 RAMP": 64030,
    "2015 Total": 7551879,
    "2016 CIP": 217986,
    "2016 RAMP": 100000,
    "2016 Total": 317986,
    "2017 RAMP": 100000,
    "2017 Total": 100000,
    "2018 RAMP": 86914,
    "2018 Total": 86914,
    "2019 RAMP": 100000,
    "2019 Total": 100000,
    "Grand Total": 8156779,
    "3 Yr Average": 95638,
  },
  {
    "Model List of Airports": "Giddings-Lee County Airport",
    "CITY AND FACILITIES": "GIDDINGS-LEE COUNTY",
    "2015 RAMP": 16794,
    "2015 Total": 16794,
    "2016 Total": 0,
    "2017 Total": 0,
    "2018 RAMP": 14284,
    "2018 Total": 14284,
    "2019 RAMP": 14342,
    "2019 Total": 14342,
    "Grand Total": 45420,
    "3 Yr Average": 9542,
  },
  {
    "Model List of Airports": "Gillespie County Airport",
    "CITY AND FACILITIES": "GILLESPIE COUNTY",
    "2015 CIP": 212589,
    "2015 RAMP": 22892,
    "2015 Total": 235481,
    "2016 RAMP": 1708,
    "2016 Total": 1708,
    "2017 CIP": 1631490,
    "2017 RAMP": 93416,
    "2017 Total": 1724906,
    "2018 RAMP": 76962,
    "2018 Total": 76962,
    "2019 RAMP": 88064,
    "2019 Total": 88064,
    "Grand Total": 2127121,
    "3 Yr Average": 629977.3333333334,
  },
  {
    "Model List of Airports": "Gladewater Municipal Airport",
    "CITY AND FACILITIES": "GLADEWATER MUNI",
    "2015 RAMP": 7250,
    "2015 Total": 7250,
    "2016 RAMP": 28500,
    "2016 Total": 28500,
    "2017 CIP": 93000,
    "2017 RAMP": 17102,
    "2017 Total": 110102,
    "2018 CIP": 773700,
    "2018 Total": 773700,
    "2019 RAMP": 2718,
    "2019 Total": 2718,
    "Grand Total": 922270,
    "3 Yr Average": 295506.6666666667,
  },
  {
    "Model List of Airports": "Goldwaite Airport",
    "CITY AND FACILITIES": "GOLDTHWAITE MUNI",
    "2015 Total": 0,
    "2016 Total": 0,
    "2017 Total": 0,
    "2018 RAMP": 41358,
    "2018 Total": 41358,
    "2019 Total": 0,
    "Grand Total": 41358,
    "3 Yr Average": 13786,
  },
  {
    "Model List of Airports": "Graham Municipal Airport",
    "CITY AND FACILITIES": "GRAHAM MUNI",
    "2015 Total": 0,
    "2016 RAMP": 28414,
    "2016 Total": 28414,
    "2017 Total": 0,
    "2018 Total": 0,
    "2019 Total": 0,
    "Grand Total": 28414,
    "3 Yr Average": 0,
  },
  {
    "Model List of Airports": "Granbury Regional Airport",
    "CITY AND FACILITIES": "GRANBURY RGNL",
    "2015 RAMP": 97724,
    "2015 Total": 97724,
    "2016 RAMP": 97936,
    "2016 Total": 97936,
    "2017 CIP": 600000,
    "2017 RAMP": 99826,
    "2017 Total": 699826,
    "2018 CIP": 3000000,
    "2018 RAMP": 95986,
    "2018 Total": 3095986,
    "2019 RAMP": 91998,
    "2019 Total": 91998,
    "Grand Total": 4083470,
    "3 Yr Average": 1295936.6666666667,
  },
  {
    "Model List of Airports": "Grand Prairie Municipal",
    "CITY AND FACILITIES": "GRAND PRAIRIE MUNI",
    "2015 RAMP": 87434,
    "2015 Total": 87434,
    "2016 CIP": 77546,
    "2016 RAMP": 74640,
    "2016 Total": 152186,
    "2017 RAMP": 95836,
    "2017 Total": 95836,
    "2018 RAMP": 96504,
    "2018 Total": 96504,
    "2019 RAMP": 100000,
    "2019 Total": 100000,
    "Grand Total": 531960,
    "3 Yr Average": 97446.66666666667,
  },
  {
    "Model List of Airports": "Greater Morris County",
    "2015 Total": 0,
    "2016 Total": 0,
    "2017 Total": 0,
    "2018 Total": 0,
    "3 Yr Average": 0,
  },
  {
    "Model List of Airports": "Greenville Municipal",
    "CITY AND FACILITIES": "MAJORS",
    "2015 RAMP": 74804,
    "2015 Total": 74804,
    "2016 RAMP": 44248,
    "2016 Total": 44248,
    "2017 RAMP": 100000,
    "2017 Total": 100000,
    "2018 RAMP": 22758,
    "2018 Total": 22758,
    "2019 RAMP": 3294,
    "2019 Total": 3294,
    "Grand Total": 245104,
    "3 Yr Average": 42017.333333333336,
  },
  {
    "Model List of Airports": "Groveton-Trinity County",
    "CITY AND FACILITIES": "GROVETON-TRINITY COUNTY",
    "2015 Total": 0,
    "2016 RAMP": 13366,
    "2016 Total": 13366,
    "2017 CIP": 368105,
    "2017 Total": 368105,
    "2018 Total": 0,
    "2019 RAMP": 13768,
    "2019 Total": 13768,
    "Grand Total": 395239,
    "3 Yr Average": 127291,
  },
  {
    "Model List of Airports": "Gruver Municipal",
    "CITY AND FACILITIES": "GRUVER MUNI",
    "2015 Total": 0,
    "2016 RAMP": 1256,
    "2016 Total": 1256,
    "2017 Total": 0,
    "2018 Total": 0,
    "2019 Total": 0,
    "Grand Total": 1256,
    "3 Yr Average": 0,
  },
  {
    "Model List of Airports": "H H Coffield Regional",
    "CITY AND FACILITIES": "H H COFFIELD RGNL",
    "2015 Total": 0,
    "2016 Total": 0,
    "2017 Total": 0,
    "2018 RAMP": 51782,
    "2018 Total": 51782,
    "2019 RAMP": 13500,
    "2019 Total": 13500,
    "Grand Total": 65282,
    "3 Yr Average": 21760.666666666668,
  },
  {
    "Model List of Airports": "Hale County",
    "CITY AND FACILITIES": "HALE COUNTY",
    "2015 RAMP": 32386,
    "2015 Total": 32386,
    "2016 CIP": 4438750,
    "2016 RAMP": 16620,
    "2016 Total": 4455370,
    "2017 RAMP": 17760,
    "2017 Total": 17760,
    "2018 RAMP": 100000,
    "2018 Total": 100000,
    "2019 RAMP": 16234,
    "2019 Total": 16234,
    "Grand Total": 4621750,
    "3 Yr Average": 44664.666666666664,
  },
  {
    "Model List of Airports": "Hallettsville Muni",
    "CITY AND FACILITIES": "HALLETTSVILLE MUNI",
    "2015 RAMP": 25358,
    "2015 Total": 25358,
    "2016 RAMP": 1668,
    "2016 Total": 1668,
    "2017 RAMP": 77914,
    "2017 Total": 77914,
    "2018 RAMP": 2590,
    "2018 Total": 2590,
    "2019 RAMP": 4224,
    "2019 Total": 4224,
    "Grand Total": 111754,
    "3 Yr Average": 28242.666666666668,
  },
  {
    "Model List of Airports": "Hall-Miller Municipal",
    "CITY AND FACILITIES": "HALL-MILLER MUNI",
    "2015 RAMP": 11058,
    "2015 Total": 11058,
    "2016 RAMP": 1006,
    "2016 Total": 1006,
    "2017 CIP": 79839,
    "2017 RAMP": 3560,
    "2017 Total": 83399,
    "2018 CIP": 726658,
    "2018 RAMP": 2934,
    "2018 Total": 729592,
    "2019 RAMP": 31842,
    "2019 Total": 31842,
    "Grand Total": 856897,
    "3 Yr Average": 281611,
  },
  {
    "Model List of Airports": "Hamilton Municipal",
    "CITY AND FACILITIES": "HAMILTON MUNI",
    "2015 RAMP": 17882,
    "2015 Total": 17882,
    "2016 Total": 0,
    "2017 CIP": 216751,
    "2017 RAMP": 54938,
    "2017 Total": 271689,
    "2018 RAMP": 14604,
    "2018 Total": 14604,
    "2019 RAMP": 33886,
    "2019 Total": 33886,
    "Grand Total": 338061,
    "3 Yr Average": 106726.33333333333,
  },
  {
    "Model List of Airports": "Harrison County Airport",
    "CITY AND FACILITIES": "HARRISON COUNTY",
    "2015 RAMP": 6420,
    "2015 Total": 6420,
    "2016 RAMP": 11114,
    "2016 Total": 11114,
    "2017 RAMP": 35832,
    "2017 Total": 35832,
    "2018 RAMP": 13558,
    "2018 Total": 13558,
    "2019 RAMP": 70794,
    "2019 Total": 70794,
    "Grand Total": 137718,
    "3 Yr Average": 40061.333333333336,
  },
  {
    "Model List of Airports": "Harrison Field",
    "2015 Total": 0,
    "2016 Total": 0,
    "2017 Total": 0,
    "2018 Total": 0,
    "3 Yr Average": 0,
  },
  {
    "Model List of Airports": "Haskell Municipal Airport",
    "CITY AND FACILITIES": "HASKELL MUNI",
    "2015 RAMP": 40028,
    "2015 Total": 40028,
    "2016 Total": 0,
    "2017 Total": 0,
    "2018 Total": 0,
    "2019 Total": 0,
    "Grand Total": 40028,
    "3 Yr Average": 0,
  },
  {
    "Model List of Airports": "Hawthorne Field",
    "2015 Total": 0,
    "2016 Total": 0,
    "2017 Total": 0,
    "2018 Total": 0,
    "3 Yr Average": 0,
  },
  {
    "Model List of Airports": "Hearne Municipal",
    "CITY AND FACILITIES": "HEARNE MUNI",
    "2015 Total": 0,
    "2016 Total": 0,
    "2017 Total": 0,
    "2018 RAMP": 51274,
    "2018 Total": 51274,
    "2019 RAMP": 6346,
    "2019 Total": 6346,
    "Grand Total": 57620,
    "3 Yr Average": 19206.666666666668,
  },
  {
    "Model List of Airports": "Hemphill County",
    "CITY AND FACILITIES": "HEMPHILL COUNTY",
    "2015 RAMP": 21746,
    "2015 Total": 21746,
    "2016 Total": 0,
    "2017 RAMP": 20000,
    "2017 Total": 20000,
    "2018 RAMP": 7060,
    "2018 Total": 7060,
    "2019 RAMP": 7060,
    "2019 Total": 7060,
    "Grand Total": 55866,
    "3 Yr Average": 11373.333333333334,
  },
  {
    "Model List of Airports": "Hereford Municipal",
    "CITY AND FACILITIES": "HEREFORD MUNI",
    "2015 Total": 0,
    "2016 CIP": 4986318.2,
    "2016 RAMP": 7190,
    "2016 Total": 4993508.2,
    "2017 Total": 0,
    "2018 Total": 0,
    "2019 RAMP": 17018,
    "2019 Total": 17018,
    "Grand Total": 5010526.2,
    "3 Yr Average": 5672.666666666667,
  },
  {
    "Model List of Airports": "Higgs/Libscomb County",
    "CITY AND FACILITIES": "HIGGINS-LIPSCOMB COUNTY",
    "2015 Total": 0,
    "2016 RAMP": 34000,
    "2016 Total": 34000,
    "2017 Total": 0,
    "2018 Total": 0,
    "2019 Total": 0,
    "Grand Total": 34000,
    "3 Yr Average": 0,
  },
  {
    "Model List of Airports": "Hillsboro Municipal Airport",
    "CITY AND FACILITIES": "HILLSBORO MUNI",
    "2015 RAMP": 4066,
    "2015 Total": 4066,
    "2016 RAMP": 38156,
    "2016 Total": 38156,
    "2017 CIP": 88804,
    "2017 RAMP": 24482,
    "2017 Total": 113286,
    "2018 CIP": 448748,
    "2018 RAMP": 20936,
    "2018 Total": 469684,
    "2019 RAMP": 34286,
    "2019 Total": 34286,
    "Grand Total": 659478,
    "3 Yr Average": 205752,
  },
  {
    "Model List of Airports": "Houston County",
    "CITY AND FACILITIES": "HOUSTON COUNTY",
    "2015 RAMP": 10966,
    "2015 Total": 10966,
    "2016 Total": 0,
    "2017 Total": 0,
    "2018 Total": 0,
    "2019 Total": 0,
    "Grand Total": 10966,
    "3 Yr Average": 0,
  },
  {
    "Model List of Airports": "Houston Southwest",
    "2015 Total": 0,
    "2016 Total": 0,
    "2017 Total": 0,
    "2018 Total": 0,
    "3 Yr Average": 0,
  },
  {
    "Model List of Airports": "Huntsville Municipal",
    "CITY AND FACILITIES": "HUNTSVILLE MUNI",
    "2015 RAMP": 6762,
    "2015 Total": 6762,
    "2016 RAMP": 33810,
    "2016 Total": 33810,
    "2017 RAMP": 31484,
    "2017 Total": 31484,
    "2018 RAMP": 4644,
    "2018 Total": 4644,
    "2019 RAMP": 65000,
    "2019 Total": 65000,
    "Grand Total": 141700,
    "3 Yr Average": 33709.333333333336,
  },
  {
    "Model List of Airports": "Hutchinson County",
    "CITY AND FACILITIES": "HUTCHINSON COUNTY",
    "2015 RAMP": 58628,
    "2015 Total": 58628,
    "2016 RAMP": 64390,
    "2016 Total": 64390,
    "2017 RAMP": 65306,
    "2017 Total": 65306,
    "2018 RAMP": 32030,
    "2018 Total": 32030,
    "2019 RAMP": 16672,
    "2019 Total": 16672,
    "Grand Total": 237026,
    "3 Yr Average": 38002.666666666664,
  },
  {
    "Model List of Airports": "Jack Brooks Regional",
    "CITY AND FACILITIES": "JACK BROOKS REGIONAL",
    "2015 RAMP": 100000,
    "2015 Total": 100000,
    "2016 RAMP": 100000,
    "2016 Total": 100000,
    "2017 CIP": 3222505,
    "2017 RAMP": 100000,
    "2017 Total": 3322505,
    "2018 CIP": 0,
    "2018 RAMP": 100000,
    "2018 Total": 100000,
    "2019 CIP": 400000,
    "2019 RAMP": 100000,
    "2019 Total": 500000,
    "Grand Total": 500000,
    "3 Yr Average": 1307501.6666666667,
  },
  {
    "Model List of Airports": "Jacksboro Municipal",
    "CITY AND FACILITIES": "JACKSBORO MUNI",
    "2015 Total": 0,
    "2016 CIP": 46108,
    "2016 Total": 46108,
    "2017 CIP": 338230,
    "2017 Total": 338230,
    "2018 Total": 0,
    "2019 RAMP": 26694,
    "2019 Total": 26694,
    "Grand Total": 411032,
    "3 Yr Average": 121641.33333333333,
  },
  {
    "Model List of Airports": "Jackson County",
    "CITY AND FACILITIES": "JACKSON COUNTY",
    "2015 RAMP": 4638,
    "2015 Total": 4638,
    "2016 RAMP": 7154,
    "2016 Total": 7154,
    "2017 RAMP": 1762,
    "2017 Total": 1762,
    "2018 RAMP": 16076,
    "2018 Total": 16076,
    "2019 RAMP": 28800,
    "2019 Total": 28800,
    "Grand Total": 58430,
    "3 Yr Average": 15546,
  },
  {
    "Model List of Airports": "Jasper County-Bell Field",
    "CITY AND FACILITIES": "JASPER COUNTY-BELL FIELD",
    "2015 RAMP": 18634,
    "2015 Total": 18634,
    "2016 RAMP": 30076,
    "2016 Total": 30076,
    "2017 RAMP": 30206,
    "2017 Total": 30206,
    "2018 RAMP": 37664,
    "2018 Total": 37664,
    "2019 RAMP": 11898,
    "2019 Total": 11898,
    "Grand Total": 128478,
    "3 Yr Average": 26589.333333333332,
  },
  {
    "Model List of Airports": "Jim Hogg County",
    "CITY AND FACILITIES": "JIM HOGG COUNTY",
    "2015 CIP": 163981,
    "2015 RAMP": 2046,
    "2015 Total": 166027,
    "2016 RAMP": 2426,
    "2016 Total": 2426,
    "2017 CIP": 1854442,
    "2017 Total": 1854442,
    "2018 Total": 0,
    "2019 Total": 0,
    "Grand Total": 2022895,
    "3 Yr Average": 618147.3333333334,
  },
  {
    "Model List of Airports": "Jones Field Airport",
    "CITY AND FACILITIES": "JONES FIELD",
    "2015 CIP": 150000,
    "2015 RAMP": 46158,
    "2015 Total": 196158,
    "2016 CIP": 164491,
    "2016 RAMP": 89786,
    "2016 Total": 254277,
    "2017 RAMP": 87108,
    "2017 Total": 87108,
    "2018 RAMP": 79660,
    "2018 Total": 79660,
    "2019 RAMP": 87564,
    "2019 Total": 87564,
    "Grand Total": 704767,
    "3 Yr Average": 84777.33333333333,
  },
  {
    "Model List of Airports": "Karnes County",
    "CITY AND FACILITIES": "KARNES COUNTY",
    "2015 CIP": 2693544,
    "2015 RAMP": 2560,
    "2015 Total": 2696104,
    "2016 RAMP": 15146,
    "2016 Total": 15146,
    "2017 CIP": 162100,
    "2017 RAMP": 51324,
    "2017 Total": 213424,
    "2018 RAMP": 2864,
    "2018 Total": 2864,
    "2019 RAMP": 100000,
    "2019 Total": 100000,
    "Grand Total": 3027538,
    "3 Yr Average": 105429.33333333333,
  },
  {
    "Model List of Airports": "Kelly Field",
    "2015 Total": 0,
    "2016 Total": 0,
    "2017 Total": 0,
    "2018 Total": 0,
    "3 Yr Average": 0,
  },
  {
    "Model List of Airports": "Kenedy Regional",
    "2015 Total": 0,
    "2016 Total": 0,
    "2017 Total": 0,
    "2018 Total": 0,
    "3 Yr Average": 0,
  },
  {
    "Model List of Airports": "Kent County",
    "CITY AND FACILITIES": "KENT COUNTY",
    "2015 Total": 0,
    "2016 Total": 0,
    "2017 RAMP": 100000,
    "2017 Total": 100000,
    "2018 Total": 0,
    "2019 Total": 0,
    "Grand Total": 100000,
    "3 Yr Average": 33333.333333333336,
  },
  {
    "Model List of Airports": "Kerrville-Kerr County",
    "CITY AND FACILITIES": "KERRVILLE MUNI/LOUIS SCHREINER FIELD",
    "2015 RAMP": 99378,
    "2015 Total": 99378,
    "2016 RAMP": 100000,
    "2016 Total": 100000,
    "2017 RAMP": 100000,
    "2017 Total": 100000,
    "2018 RAMP": 100000,
    "2018 Total": 100000,
    "2019 RAMP": 100000,
    "2019 Total": 100000,
    "Grand Total": 499378,
    "3 Yr Average": 100000,
  },
  {
    "Model List of Airports": "Kickapoo Downtown",
    "CITY AND FACILITIES": "KICKAPOO DOWNTOWN",
    "2015 RAMP": 98652,
    "2015 Total": 98652,
    "2016 RAMP": 66156,
    "2016 Total": 66156,
    "2017 CIP": 2232721,
    "2017 RAMP": 62504,
    "2017 Total": 2295225,
    "2018 CIP": 2376375,
    "2018 RAMP": 95044,
    "2018 Total": 2471419,
    "2019 RAMP": 78246,
    "2019 Total": 78246,
    "Grand Total": 5009698,
    "3 Yr Average": 1614963.3333333333,
  },
  {
    "Model List of Airports": "Killeen-Ft. Hood Regional",
    "CITY AND FACILITIES": "ROBERT GRAY AAF",
    "2015 RAMP": 100000,
    "2015 Total": 100000,
    "2016 RAMP": 100000,
    "2016 Total": 100000,
    "2017 CIP": 0,
    "2017 RAMP": 99896,
    "2017 Total": 99896,
    "2018 CIP": 4988575,
    "2018 RAMP": 43068,
    "2018 Total": 5031643,
    "2019 CIP": 5201260,
    "2019 RAMP": 100000,
    "2019 Total": 5301260,
    "Grand Total": 442964,
    "3 Yr Average": 3477599.6666666665,
  },
  {
    "Model List of Airports": "Killeen-Skylark Field",
    "CITY AND FACILITIES": "SKYLARK FIELD",
    "2015 RAMP": 23394,
    "2015 Total": 23394,
    "2016 RAMP": 14208,
    "2016 Total": 14208,
    "2017 RAMP": 19214,
    "2017 Total": 19214,
    "2018 RAMP": 12436,
    "2018 Total": 12436,
    "2019 RAMP": 25780,
    "2019 Total": 25780,
    "Grand Total": 95032,
    "3 Yr Average": 19143.333333333332,
  },
  {
    "Model List of Airports": "Kimble County",
    "CITY AND FACILITIES": "KIMBLE COUNTY",
    "2015 CIP": 1696223,
    "2015 RAMP": 2738,
    "2015 Total": 1698961,
    "2016 RAMP": 11238,
    "2016 Total": 11238,
    "2017 RAMP": 8352,
    "2017 Total": 8352,
    "2018 RAMP": 30982,
    "2018 Total": 30982,
    "2019 RAMP": 17330,
    "2019 Total": 17330,
    "Grand Total": 1766863,
    "3 Yr Average": 18888,
  },
  {
    "Model List of Airports": "Kirbyville Airport",
    "CITY AND FACILITIES": "KIRBYVILLE",
    "2015 RAMP": 744,
    "2015 Total": 744,
    "2016 RAMP": 118701,
    "2016 Total": 118701,
    "2017 RAMP": 13864,
    "2017 Total": 13864,
    "2018 RAMP": 19148,
    "2018 Total": 19148,
    "2019 RAMP": 3848,
    "2019 Total": 3848,
    "Grand Total": 156305,
    "3 Yr Average": 12286.666666666666,
  },
  {
    "Model List of Airports": "Kleberg County Airport",
    "CITY AND FACILITIES": "KLEBERG COUNTY",
    "2015 RAMP": 39372,
    "2015 Total": 39372,
    "2016 RAMP": 15524,
    "2016 Total": 15524,
    "2017 RAMP": 17208,
    "2017 Total": 17208,
    "2018 RAMP": 27266,
    "2018 Total": 27266,
    "2019 RAMP": 28746,
    "2019 Total": 28746,
    "Grand Total": 128116,
    "3 Yr Average": 24406.666666666668,
  },
  {
    "Model List of Airports": "La Porte Municipal",
    "CITY AND FACILITIES": "LA PORTE MUNI",
    "2015 RAMP": 31558,
    "2015 Total": 31558,
    "2016 CIP": 113262,
    "2016 RAMP": 5686,
    "2016 Total": 118948,
    "2017 CIP": 470071,
    "2017 RAMP": 5986,
    "2017 Total": 476057,
    "2018 RAMP": 4668,
    "2018 Total": 4668,
    "2019 RAMP": 826,
    "2019 Total": 826,
    "Grand Total": 632057,
    "3 Yr Average": 160517,
  },
  {
    "Model List of Airports": "Lago Vista Rusty Allen Airport",
    "CITY AND FACILITIES": "LAGO VISTA TX - RUSTY ALLEN",
    "2015 CIP": 579984,
    "2015 RAMP": 6604,
    "2015 Total": 586588,
    "2016 CIP": 91310,
    "2016 Total": 91310,
    "2017 CIP": 138022,
    "2017 RAMP": 13166,
    "2017 Total": 151188,
    "2018 RAMP": 79206,
    "2018 Total": 79206,
    "2019 RAMP": 100000,
    "2019 Total": 100000,
    "Grand Total": 1008292,
    "3 Yr Average": 110131.33333333333,
  },
  {
    "Model List of Airports": "Lamesa Municipal Airport",
    "CITY AND FACILITIES": "LAMESA MUNI",
    "2015 RAMP": 46718,
    "2015 Total": 46718,
    "2016 RAMP": 36144,
    "2016 Total": 36144,
    "2017 RAMP": 79628,
    "2017 Total": 79628,
    "2018 RAMP": 98460,
    "2018 Total": 98460,
    "2019 RAMP": 71242,
    "2019 Total": 71242,
    "Grand Total": 332192,
    "3 Yr Average": 83110,
  },
  {
    "Model List of Airports": "Lampasas Municipal Airport",
    "CITY AND FACILITIES": "LAMPASAS",
    "2015 RAMP": 13546,
    "2015 Total": 13546,
    "2016 RAMP": 12000,
    "2016 Total": 12000,
    "2017 RAMP": 10996,
    "2017 Total": 10996,
    "2018 Total": 0,
    "2019 RAMP": 11692,
    "2019 Total": 11692,
    "Grand Total": 48234,
    "3 Yr Average": 7562.666666666667,
  },
  {
    "Model List of Airports": "Lancaster Regional",
    "CITY AND FACILITIES": "LANCASTER RGNL",
    "2015 RAMP": 80970,
    "2015 Total": 80970,
    "2016 RAMP": 26848,
    "2016 Total": 26848,
    "2017 RAMP": 62618,
    "2017 Total": 62618,
    "2018 RAMP": 18718,
    "2018 Total": 18718,
    "2019 RAMP": 16544,
    "2019 Total": 16544,
    "Grand Total": 205698,
    "3 Yr Average": 32626.666666666668,
  },
  {
    "Model List of Airports": "Laredo International",
    "CITY AND FACILITIES": "LAREDO INTL",
    "2015 RAMP": 99116,
    "2015 Total": 99116,
    "2016 RAMP": 100000,
    "2016 Total": 100000,
    "2017 CIP": 5796502,
    "2017 RAMP": 99758,
    "2017 Total": 5896260,
    "2018 CIP": 13174233,
    "2018 RAMP": 100000,
    "2018 Total": 13274233,
    "2019 CIP": 10856303,
    "2019 RAMP": 98744,
    "2019 Total": 10955047,
    "Grand Total": 497618,
    "3 Yr Average": 10041846.666666666,
  },
  {
    "Model List of Airports": "Levelland Municipal",
    "CITY AND FACILITIES": "LEVELLAND MUNI",
    "2015 CIP": 75000,
    "2015 RAMP": 22502,
    "2015 Total": 97502,
    "2016 RAMP": 17992,
    "2016 Total": 17992,
    "2017 CIP": 783959,
    "2017 RAMP": 58620,
    "2017 Total": 842579,
    "2018 RAMP": 19548,
    "2018 Total": 19548,
    "2019 Total": 0,
    "Grand Total": 977621,
    "3 Yr Average": 287375.6666666667,
  },
  {
    "Model List of Airports": "Liberty Municipal",
    "CITY AND FACILITIES": "LIBERTY MUNI",
    "2015 Total": 0,
    "2016 RAMP": 20090,
    "2016 Total": 20090,
    "2017 CIP": 635311,
    "2017 RAMP": 20326,
    "2017 Total": 655637,
    "2018 RAMP": 35510,
    "2018 Total": 35510,
    "2019 RAMP": 36886,
    "2019 Total": 36886,
    "Grand Total": 748123,
    "3 Yr Average": 242677.66666666666,
  },
  {
    "Model List of Airports": "Live Oak County",
    "CITY AND FACILITIES": "LIVE OAK COUNTY",
    "2015 CIP": 115093,
    "2015 RAMP": 3502,
    "2015 Total": 118595,
    "2016 CIP": 1198514,
    "2016 RAMP": 10466,
    "2016 Total": 1208980,
    "2017 RAMP": 0,
    "2017 Total": 0,
    "2018 RAMP": 2158,
    "2018 Total": 2158,
    "2019 RAMP": 5316,
    "2019 Total": 5316,
    "Grand Total": 1335049,
    "3 Yr Average": 2491.3333333333335,
  },
  {
    "Model List of Airports": "Livingston Municipal",
    "2015 Total": 0,
    "2016 Total": 0,
    "2017 Total": 0,
    "2018 Total": 0,
    "3 Yr Average": 0,
  },
  {
    "Model List of Airports": "Llano Municipal Airport",
    "CITY AND FACILITIES": "LLANO MUNI",
    "2015 CIP": 69350,
    "2015 RAMP": 12766,
    "2015 Total": 82116,
    "2016 CIP": 402357,
    "2016 Total": 402357,
    "2017 RAMP": 21134,
    "2017 Total": 21134,
    "2018 RAMP": 18932,
    "2018 Total": 18932,
    "2019 RAMP": 14798,
    "2019 Total": 14798,
    "Grand Total": 539337,
    "3 Yr Average": 18288,
  },
  {
    "Model List of Airports": "Lockhart Municipal",
    "2015 Total": 0,
    "2016 Total": 0,
    "2017 Total": 0,
    "2018 Total": 0,
    "3 Yr Average": 0,
  },
  {
    "Model List of Airports": "Lubbock Preston Smith International",
    "2015 Total": 0,
    "2016 Total": 0,
    "2017 Total": 0,
    "2018 Total": 0,
    "3 Yr Average": 0,
  },
  {
    "Model List of Airports": "Madisonville Municipal 51R",
    "CITY AND FACILITIES": "MADISONVILLE MUNI",
    "2015 Total": 0,
    "2016 RAMP": 18984,
    "2016 Total": 18984,
    "2017 CIP": 66472,
    "2017 RAMP": 100000,
    "2017 Total": 166472,
    "2018 RAMP": 42654,
    "2018 Total": 42654,
    "2019 RAMP": 36882,
    "2019 Total": 36882,
    "Grand Total": 264992,
    "3 Yr Average": 82002.66666666667,
  },
  {
    "Model List of Airports": "Major Samuel B Cornelius Field",
    "CITY AND FACILITIES": "MAJOR SAMUEL B CORNELIUS FIELD",
    "2015 RAMP": 2140,
    "2015 Total": 2140,
    "2016 RAMP": 74832,
    "2016 Total": 74832,
    "2017 Total": 0,
    "2018 RAMP": 27916,
    "2018 Total": 27916,
    "2019 Total": 0,
    "Grand Total": 104888,
    "3 Yr Average": 9305.333333333334,
  },
  {
    "Model List of Airports": "Marfa Municipal",
    "CITY AND FACILITIES": "MARFA MUNI",
    "2015 RAMP": 27728,
    "2015 Total": 27728,
    "2016 RAMP": 69782,
    "2016 Total": 69782,
    "2017 CIP": 790794,
    "2017 RAMP": 29960,
    "2017 Total": 820754,
    "2018 RAMP": 32482,
    "2018 Total": 32482,
    "2019 RAMP": 14914,
    "2019 Total": 14914,
    "Grand Total": 965660,
    "3 Yr Average": 289383.3333333333,
  },
  {
    "Model List of Airports": "Marian Airpark",
    "CITY AND FACILITIES": "MARIAN AIRPARK",
    "2015 Total": 0,
    "2016 Total": 0,
    "2017 Total": 0,
    "2018 Total": 0,
    "2019 RAMP": 13964,
    "2019 Total": 13964,
    "Grand Total": 13964,
    "3 Yr Average": 4654.666666666667,
  },
  {
    "Model List of Airports": "Marlin",
    "CITY AND FACILITIES": "MARLIN",
    "2015 RAMP": 3698,
    "2015 Total": 3698,
    "2016 Total": 0,
    "2017 Total": 0,
    "2018 Total": 0,
    "2019 Total": 0,
    "Grand Total": 3698,
    "3 Yr Average": 0,
  },
  {
    "Model List of Airports": "Mason County Airport",
    "CITY AND FACILITIES": "MASON COUNTY",
    "2015 RAMP": 8312,
    "2015 Total": 8312,
    "2016 RAMP": 8312,
    "2016 Total": 8312,
    "2017 RAMP": 29982,
    "2017 Total": 29982,
    "2018 Total": 0,
    "2019 RAMP": 19032,
    "2019 Total": 19032,
    "Grand Total": 65638,
    "3 Yr Average": 16338,
  },
  {
    "Model List of Airports": "Maverick County Memorial International",
    "2015 Total": 0,
    "2016 Total": 0,
    "2017 Total": 0,
    "2018 Total": 0,
    "3 Yr Average": 0,
  },
  {
    "Model List of Airports": "McAllen Miller Intl",
    "CITY AND FACILITIES": "MC ALLEN MILLER INTL",
    "2015 RAMP": 100000,
    "2015 Total": 100000,
    "2016 RAMP": 100000,
    "2016 Total": 100000,
    "2017 CIP": 3261674,
    "2017 RAMP": 100000,
    "2017 Total": 3361674,
    "2018 CIP": 2571660,
    "2018 RAMP": 100000,
    "2018 Total": 2671660,
    "2019 CIP": 16680726,
    "2019 RAMP": 100000,
    "2019 Total": 16780726,
    "Grand Total": 500000,
    "3 Yr Average": 7604686.666666667,
  },
  {
    "Model List of Airports": "McCampbell-Porter Airport",
    "CITY AND FACILITIES": "MCCAMPBELL-PORTER",
    "2015 Total": 0,
    "2016 Total": 0,
    "2017 Total": 0,
    "2018 Total": 0,
    "2019 RAMP": 100000,
    "2019 Total": 100000,
    "Grand Total": 100000,
    "3 Yr Average": 33333.333333333336,
  },
  {
    "Model List of Airports": "McGregor Executive",
    "CITY AND FACILITIES": "MC GREGOR EXECUTIVE",
    "2015 CIP": 2230294,
    "2015 RAMP": 48118,
    "2015 Total": 2278412,
    "2016 RAMP": 100000,
    "2016 Total": 100000,
    "2017 CIP": 579930,
    "2017 RAMP": 100000,
    "2017 Total": 679930,
    "2018 RAMP": 68356,
    "2018 Total": 68356,
    "2019 RAMP": 99994,
    "2019 Total": 99994,
    "Grand Total": 3226692,
    "3 Yr Average": 282760,
  },
  {
    "Model List of Airports": "McKinley Field",
    "2015 Total": 0,
    "2016 Total": 0,
    "2017 Total": 0,
    "2018 Total": 0,
    "3 Yr Average": 0,
  },
  {
    "Model List of Airports": "McKinney National",
    "CITY AND FACILITIES": "COLLIN COUNTY RGNL AT MC KINNEY",
    "2015 CIP": 5605260,
    "2015 RAMP": 100000,
    "2015 Total": 5705260,
    "2016 RAMP": 100367,
    "2016 Total": 100367,
    "2017 RAMP": 99502,
    "2017 Total": 99502,
    "2018 RAMP": 64582,
    "2018 Total": 64582,
    "2019 RAMP": 100000,
    "2019 Total": 100000,
    "Grand Total": 6069711,
    "3 Yr Average": 88028,
  },
  {
    "Model List of Airports": "McLean/Gray County",
    "2015 Total": 0,
    "2016 Total": 0,
    "2017 Total": 0,
    "2018 Total": 0,
    "3 Yr Average": 0,
  },
  {
    "Model List of Airports": "Memphis Municipal",
    "CITY AND FACILITIES": "MEMPHIS MUNI",
    "2015 RAMP": 4336,
    "2015 Total": 4336,
    "2016 RAMP": 888,
    "2016 Total": 888,
    "2017 Total": 0,
    "2018 Total": 0,
    "2019 Total": 0,
    "Grand Total": 5224,
    "3 Yr Average": 0,
  },
  {
    "Model List of Airports": "Menard County Airport",
    "CITY AND FACILITIES": "MENARD COUNTY",
    "2015 Total": 0,
    "2016 RAMP": 4796,
    "2016 Total": 4796,
    "2017 RAMP": 3932,
    "2017 Total": 3932,
    "2018 RAMP": 1734,
    "2018 Total": 1734,
    "2019 RAMP": 3182,
    "2019 Total": 3182,
    "Grand Total": 13644,
    "3 Yr Average": 2949.3333333333335,
  },
  {
    "Model List of Airports": "Mesquite Metro",
    "CITY AND FACILITIES": "MESQUITE METRO",
    "2015 RAMP": 36974,
    "2015 Total": 36974,
    "2016 CIP": 259718,
    "2016 RAMP": 47458,
    "2016 Total": 307176,
    "2017 CIP": 2475537,
    "2017 Total": 2475537,
    "2018 RAMP": 100000,
    "2018 Total": 100000,
    "2019 RAMP": 100000,
    "2019 Total": 100000,
    "Grand Total": 3019687,
    "3 Yr Average": 891845.6666666666,
  },
  {
    "Model List of Airports": "Mexia-Limestone County",
    "2015 Total": 0,
    "2016 Total": 0,
    "2017 Total": 0,
    "2018 Total": 0,
    "3 Yr Average": 0,
  },
  {
    "Model List of Airports": "Miami-Roberts County",
    "CITY AND FACILITIES": "MIAMI-ROBERTS COUNTY",
    "2015 Total": 0,
    "2016 Total": 0,
    "2017 Total": 0,
    "2018 Total": 0,
    "2019 RAMP": 3500,
    "2019 Total": 3500,
    "Grand Total": 3500,
    "3 Yr Average": 1166.6666666666667,
  },
  {
    "Model List of Airports": "Mid Valley",
    "CITY AND FACILITIES": "MID VALLEY",
    "2015 CIP": 159807,
    "2015 Total": 159807,
    "2016 RAMP": 62550,
    "2016 Total": 62550,
    "2017 RAMP": 94498,
    "2017 Total": 94498,
    "2018 RAMP": 100000,
    "2018 Total": 100000,
    "2019 RAMP": 100000,
    "2019 Total": 100000,
    "Grand Total": 516855,
    "3 Yr Average": 98166,
  },
  {
    "Model List of Airports": "Midland Airpark",
    "CITY AND FACILITIES": "MIDLAND AIRPARK",
    "2015 RAMP": 5786,
    "2015 Total": 5786,
    "2016 RAMP": 25196,
    "2016 Total": 25196,
    "2017 RAMP": 6844,
    "2017 Total": 6844,
    "2018 Total": 0,
    "2019 CIP": 149535,
    "2019 RAMP": 39468,
    "2019 Total": 189003,
    "Grand Total": 226829,
    "3 Yr Average": 65282.333333333336,
  },
  {
    "Model List of Airports": "Midland International Air & Space Port",
    "2015 Total": 0,
    "2016 Total": 0,
    "2017 Total": 0,
    "2018 Total": 0,
    "3 Yr Average": 0,
  },
  {
    "Model List of Airports": "Mid-way Regional",
    "CITY AND FACILITIES": "MID-WAY RGNL",
    "2015 CIP": 96350,
    "2015 RAMP": 25744,
    "2015 Total": 122094,
    "2016 CIP": 1218311,
    "2016 RAMP": 63556,
    "2016 Total": 1281867,
    "2017 RAMP": 36942,
    "2017 Total": 36942,
    "2018 RAMP": 32288,
    "2018 Total": 32288,
    "2019 RAMP": 34678,
    "2019 Total": 34678,
    "Grand Total": 1507869,
    "3 Yr Average": 34636,
  },
  {
    "Model List of Airports": "Mineral Wells Airport",
    "CITY AND FACILITIES": "MINERAL WELLS",
    "2015 Total": 0,
    "2016 RAMP": 100000,
    "2016 Total": 100000,
    "2017 RAMP": 57794,
    "2017 Total": 57794,
    "2018 CIP": 99000,
    "2018 RAMP": 1200,
    "2018 Total": 100200,
    "2019 RAMP": 18428,
    "2019 Total": 18428,
    "Grand Total": 276422,
    "3 Yr Average": 58807.333333333336,
  },
  {
    "Model List of Airports": "Moore County Airport",
    "CITY AND FACILITIES": "MOORE COUNTY",
    "2015 RAMP": 100000,
    "2015 Total": 100000,
    "2016 RAMP": 222034,
    "2016 Total": 222034,
    "2017 RAMP": 100000,
    "2017 Total": 100000,
    "2018 RAMP": 100000,
    "2018 Total": 100000,
    "2019 RAMP": 71042,
    "2019 Total": 71042,
    "Grand Total": 593076,
    "3 Yr Average": 90347.33333333333,
  },
  {
    "Model List of Airports": "Mt. Pleasant Regional Airport",
    "CITY AND FACILITIES": "MOUNT PLEASANT RGNL",
    "2015 RAMP": 38706,
    "2015 Total": 38706,
    "2016 RAMP": 55224,
    "2016 Total": 55224,
    "2017 CIP": 162261,
    "2017 RAMP": 15012,
    "2017 Total": 177273,
    "2018 RAMP": 53944,
    "2018 Total": 53944,
    "2019 RAMP": 80002,
    "2019 Total": 80002,
    "Grand Total": 405149,
    "3 Yr Average": 103739.66666666667,
  },
  {
    "Model List of Airports": "Muleshoe Municipal",
    "CITY AND FACILITIES": "MULESHOE MUNI",
    "2015 CIP": 196941,
    "2015 Total": 196941,
    "2016 Total": 0,
    "2017 CIP": 1690609,
    "2017 Total": 1690609,
    "2018 Total": 0,
    "2019 Total": 0,
    "Grand Total": 1887550,
    "3 Yr Average": 563536.3333333334,
  },
  {
    "Model List of Airports": "Munday Municipal Airport",
    "2015 Total": 0,
    "2016 Total": 0,
    "2017 Total": 0,
    "2018 Total": 0,
    "3 Yr Average": 0,
  },
  {
    "Model List of Airports": "Mustang Beach",
    "CITY AND FACILITIES": "MUSTANG BEACH",
    "2015 RAMP": 17726,
    "2015 Total": 17726,
    "2016 RAMP": 11908,
    "2016 Total": 11908,
    "2017 RAMP": 33826,
    "2017 Total": 33826,
    "2018 Total": 0,
    "2019 RAMP": 19074,
    "2019 Total": 19074,
    "Grand Total": 82534,
    "3 Yr Average": 17633.333333333332,
  },
  {
    "Model List of Airports": "Navasota Municipal Airport",
    "CITY AND FACILITIES": "NAVASOTA MUNI",
    "2015 RAMP": 53270,
    "2015 Total": 53270,
    "2016 RAMP": 23844,
    "2016 Total": 23844,
    "2017 RAMP": 692,
    "2017 Total": 692,
    "2018 RAMP": 31422,
    "2018 Total": 31422,
    "2019 RAMP": 838,
    "2019 Total": 838,
    "Grand Total": 110066,
    "3 Yr Average": 10984,
  },
  {
    "Model List of Airports": "New Braunfels Regional",
    "CITY AND FACILITIES": "NEW BRAUNFELS RGNL",
    "2015 RAMP": 100000,
    "2015 Total": 100000,
    "2016 RAMP": 100000,
    "2016 Total": 100000,
    "2017 RAMP": 100000,
    "2017 Total": 100000,
    "2018 RAMP": 100000,
    "2018 Total": 100000,
    "2019 RAMP": 100000,
    "2019 Total": 100000,
    "Grand Total": 500000,
    "3 Yr Average": 100000,
  },
  {
    "Model List of Airports": "Newton Municipal",
    "CITY AND FACILITIES": "NEWTON MUNI",
    "2015 Total": 0,
    "2016 Total": 0,
    "2017 RAMP": 16198,
    "2017 Total": 16198,
    "2018 Total": 0,
    "2019 Total": 0,
    "Grand Total": 16198,
    "3 Yr Average": 5399.333333333333,
  },
  {
    "Model List of Airports": "North Texas Regional Airport",
    "CITY AND FACILITIES": "NORTH TEXAS RGNL/PERRIN FIELD",
    "2015 CIP": 667413,
    "2015 RAMP": 100000,
    "2015 Total": 767413,
    "2016 RAMP": 200000,
    "2016 Total": 200000,
    "2017 RAMP": 100000,
    "2017 Total": 100000,
    "2018 RAMP": 91730,
    "2018 Total": 91730,
    "2019 CIP": 165581,
    "2019 RAMP": 100000,
    "2019 Total": 265581,
    "Grand Total": 1424724,
    "3 Yr Average": 152437,
  },
  {
    "Model List of Airports": "Northwest Regional Airport",
    "2015 Total": 0,
    "2016 Total": 0,
    "2017 Total": 0,
    "2018 Total": 0,
    "3 Yr Average": 0,
  },
  {
    "Model List of Airports": "Nueces County Airport",
    "CITY AND FACILITIES": "NUECES COUNTY",
    "2015 RAMP": 46190,
    "2015 Total": 46190,
    "2016 RAMP": 59060,
    "2016 Total": 59060,
    "2017 RAMP": 40710,
    "2017 Total": 40710,
    "2018 RAMP": 42324,
    "2018 Total": 42324,
    "2019 RAMP": 60414,
    "2019 Total": 60414,
    "Grand Total": 248698,
    "3 Yr Average": 47816,
  },
  {
    "Model List of Airports": "Odessa-Schlemeyer Field",
    "CITY AND FACILITIES": "ODESSA-SCHLEMEYER FIELD",
    "2015 RAMP": 21090,
    "2015 Total": 21090,
    "2016 RAMP": 100000,
    "2016 Total": 100000,
    "2017 RAMP": 39900,
    "2017 Total": 39900,
    "2018 RAMP": 98236,
    "2018 Total": 98236,
    "2019 RAMP": 100000,
    "2019 Total": 100000,
    "Grand Total": 359226,
    "3 Yr Average": 79378.66666666667,
  },
  {
    "Model List of Airports": "Oldham County",
    "CITY AND FACILITIES": "OLDHAM COUNTY",
    "2015 RAMP": 3068,
    "2015 Total": 3068,
    "2016 RAMP": 2634,
    "2016 Total": 2634,
    "2017 RAMP": 12932,
    "2017 Total": 12932,
    "2018 RAMP": 1740,
    "2018 Total": 1740,
    "2019 RAMP": 39658,
    "2019 Total": 39658,
    "Grand Total": 60032,
    "3 Yr Average": 18110,
  },
  {
    "Model List of Airports": "Olney Municipal",
    "CITY AND FACILITIES": "OLNEY MUNI",
    "2015 Total": 0,
    "2016 Total": 0,
    "2017 RAMP": 3264,
    "2017 Total": 3264,
    "2018 Total": 0,
    "2019 RAMP": 926,
    "2019 Total": 926,
    "Grand Total": 4190,
    "3 Yr Average": 1396.6666666666667,
  },
  {
    "Model List of Airports": "Orange County Airport",
    "CITY AND FACILITIES": "ORANGE COUNTY",
    "2015 RAMP": 81958,
    "2015 Total": 81958,
    "2016 RAMP": 75054,
    "2016 Total": 75054,
    "2017 RAMP": 89812,
    "2017 Total": 89812,
    "2018 RAMP": 36916,
    "2018 Total": 36916,
    "2019 RAMP": 82418,
    "2019 Total": 82418,
    "Grand Total": 366158,
    "3 Yr Average": 69715.33333333333,
  },
  {
    "Model List of Airports": "Ozona Municipal",
    "CITY AND FACILITIES": "OZONA MUNI",
    "2015 Total": 0,
    "2016 RAMP": 72260,
    "2016 Total": 72260,
    "2017 RAMP": 10000,
    "2017 Total": 10000,
    "2018 Total": 0,
    "2019 RAMP": 24300,
    "2019 Total": 24300,
    "Grand Total": 106560,
    "3 Yr Average": 11433.333333333334,
  },
  {
    "Model List of Airports": "Palacios Municipal Airport",
    "CITY AND FACILITIES": "PALACIOS MUNI",
    "2015 RAMP": 11756,
    "2015 Total": 11756,
    "2016 RAMP": 14120,
    "2016 Total": 14120,
    "2017 RAMP": 12902,
    "2017 Total": 12902,
    "2018 RAMP": 6272,
    "2018 Total": 6272,
    "2019 RAMP": 24258,
    "2019 Total": 24258,
    "Grand Total": 69308,
    "3 Yr Average": 14477.333333333334,
  },
  {
    "Model List of Airports": "Palestine Municipal Airport",
    "CITY AND FACILITIES": "PALESTINE MUNI",
    "2015 CIP": 495817,
    "2015 RAMP": 17340,
    "2015 Total": 513157,
    "2016 RAMP": 19834,
    "2016 Total": 19834,
    "2017 RAMP": 34450,
    "2017 Total": 34450,
    "2018 RAMP": 15346,
    "2018 Total": 15346,
    "2019 RAMP": 41846,
    "2019 Total": 41846,
    "Grand Total": 624633,
    "3 Yr Average": 30547.333333333332,
  },
  {
    "Model List of Airports": "Panhandle-Carson County Airport",
    "CITY AND FACILITIES": "PANHANDLE-CARSON COUNTY",
    "2015 RAMP": 8238,
    "2015 Total": 8238,
    "2016 Total": 0,
    "2017 Total": 0,
    "2018 Total": 0,
    "2019 Total": 0,
    "Grand Total": 8238,
    "3 Yr Average": 0,
  },
  {
    "Model List of Airports": "Panola County-Sharpe Field",
    "CITY AND FACILITIES": "PANOLA COUNTY-SHARPE FIELD",
    "2015 CIP": 55604,
    "2015 Total": 55604,
    "2016 Total": 0,
    "2017 CIP": 449281,
    "2017 Total": 449281,
    "2018 Total": 0,
    "2019 Total": 0,
    "Grand Total": 504885,
    "3 Yr Average": 149760.33333333334,
  },
  {
    "Model List of Airports": "Pearland Regional",
    "2015 Total": 0,
    "2016 Total": 0,
    "2017 Total": 0,
    "2018 Total": 0,
    "3 Yr Average": 0,
  },
  {
    "Model List of Airports": "Pecos Municipal",
    "CITY AND FACILITIES": "PECOS MUNI",
    "2015 CIP": 1332072,
    "2015 RAMP": 14268,
    "2015 Total": 1346340,
    "2016 CIP": 222550,
    "2016 RAMP": 56688,
    "2016 Total": 279238,
    "2017 Total": 0,
    "2018 RAMP": 25000,
    "2018 Total": 25000,
    "2019 Total": 0,
    "Grand Total": 1650578,
    "3 Yr Average": 8333.333333333334,
  },
  {
    "Model List of Airports": "Perry Lefors Field",
    "CITY AND FACILITIES": "PERRY LEFORS FIELD",
    "2015 Total": 0,
    "2016 Total": 0,
    "2017 Total": 0,
    "2018 Total": 0,
    "2019 RAMP": 78562,
    "2019 Total": 78562,
    "Grand Total": 78562,
    "3 Yr Average": 26187.333333333332,
  },
  {
    "Model List of Airports": "Perryton/Ochiltree County Airport",
    "CITY AND FACILITIES": "PERRYTON OCHILTREE COUNTY",
    "2015 RAMP": 16018,
    "2015 Total": 16018,
    "2016 RAMP": 24736,
    "2016 Total": 24736,
    "2017 RAMP": 52318,
    "2017 Total": 52318,
    "2018 RAMP": 10930,
    "2018 Total": 10930,
    "2019 RAMP": 65708,
    "2019 Total": 65708,
    "Grand Total": 169710,
    "3 Yr Average": 42985.333333333336,
  },
  {
    "Model List of Airports": "Pineland Municipal Airport",
    "2015 Total": 0,
    "2016 Total": 0,
    "2017 Total": 0,
    "2018 Total": 0,
    "3 Yr Average": 0,
  },
  {
    "Model List of Airports": "Pleasanton Municpal Airport",
    "CITY AND FACILITIES": "PLEASANTON MUNI",
    "2015 RAMP": 19094,
    "2015 Total": 19094,
    "2016 RAMP": 17436,
    "2016 Total": 17436,
    "2017 RAMP": 42220,
    "2017 Total": 42220,
    "2018 RAMP": 74562,
    "2018 Total": 74562,
    "2019 RAMP": 46312,
    "2019 Total": 46312,
    "Grand Total": 199624,
    "3 Yr Average": 54364.666666666664,
  },
  {
    "Model List of Airports": "Port Isabel Camreon County Airport",
    "CITY AND FACILITIES": "PORT ISABEL-CAMERON COUNTY",
    "2015 CIP": 920778,
    "2015 RAMP": 2210,
    "2015 Total": 922988,
    "2016 RAMP": 28782,
    "2016 Total": 28782,
    "2017 RAMP": 1954,
    "2017 Total": 1954,
    "2018 RAMP": 4914,
    "2018 Total": 4914,
    "2019 RAMP": 6222,
    "2019 Total": 6222,
    "Grand Total": 964860,
    "3 Yr Average": 4363.333333333333,
  },
  {
    "Model List of Airports": "Possum Kingdom Airport",
    "2015 Total": 0,
    "2016 Total": 0,
    "2017 Total": 0,
    "2018 Total": 0,
    "3 Yr Average": 0,
  },
  {
    "Model List of Airports": "Post/Garza County Airport",
    "CITY AND FACILITIES": "POST-GARZA COUNTY MUNI",
    "2015 RAMP": 39836,
    "2015 Total": 39836,
    "2016 RAMP": 9114,
    "2016 Total": 9114,
    "2017 Total": 0,
    "2018 Total": 0,
    "2019 Total": 0,
    "Grand Total": 48950,
    "3 Yr Average": 0,
  },
  {
    "Model List of Airports": "Presidio Lely international",
    "CITY AND FACILITIES": "PRESIDIO LELY INTL",
    "2015 RAMP": 64668,
    "2015 Total": 64668,
    "2016 CIP": 306249,
    "2016 RAMP": 94248,
    "2016 Total": 400497,
    "2017 RAMP": 45068,
    "2017 Total": 45068,
    "2018 RAMP": 17562,
    "2018 Total": 17562,
    "2019 RAMP": 15188,
    "2019 Total": 15188,
    "Grand Total": 542983,
    "3 Yr Average": 25939.333333333332,
  },
  {
    "Model List of Airports": "Quanah Municipal",
    "CITY AND FACILITIES": "QUANAH MUNI",
    "2015 RAMP": 608,
    "2015 Total": 608,
    "2016 RAMP": 4348,
    "2016 Total": 4348,
    "2017 CIP": 91478,
    "2017 RAMP": 38602,
    "2017 Total": 130080,
    "2018 RAMP": 1720,
    "2018 Total": 1720,
    "2019 Total": 0,
    "Grand Total": 136756,
    "3 Yr Average": 43933.333333333336,
  },
  {
    "Model List of Airports": "Ralph M. Hall / Rockwall Municipal Airport",
    "CITY AND FACILITIES": "RALPH M HALL/ROCKWALL MUNI",
    "2015 RAMP": 21034,
    "2015 Total": 21034,
    "2016 RAMP": 43554,
    "2016 Total": 43554,
    "2017 CIP": 89449,
    "2017 RAMP": 20524,
    "2017 Total": 109973,
    "2018 CIP": 490642,
    "2018 RAMP": 21882,
    "2018 Total": 512524,
    "2019 RAMP": 100000,
    "2019 Total": 100000,
    "Grand Total": 787085,
    "3 Yr Average": 240832.33333333334,
  },
  {
    "Model List of Airports": "Reagan County",
    "CITY AND FACILITIES": "REAGAN COUNTY",
    "2015 Total": 0,
    "2016 RAMP": 50710,
    "2016 Total": 50710,
    "2017 RAMP": 31894,
    "2017 Total": 31894,
    "2018 RAMP": 39144,
    "2018 Total": 39144,
    "2019 RAMP": 18042,
    "2019 Total": 18042,
    "Grand Total": 139790,
    "3 Yr Average": 29693.333333333332,
  },
  {
    "Model List of Airports": "Real County",
    "CITY AND FACILITIES": "REAL COUNTY",
    "2015 Total": 0,
    "2016 Total": 0,
    "2017 RAMP": 1136,
    "2017 Total": 1136,
    "2018 Total": 0,
    "2019 Total": 0,
    "Grand Total": 1136,
    "3 Yr Average": 378.6666666666667,
  },
  {
    "Model List of Airports": "Refugio County Airport-Rooke Field",
    "CITY AND FACILITIES": "ROOKE FIELD",
    "2015 Total": 0,
    "2016 CIP": 335997.75,
    "2016 Total": 335997.75,
    "2017 Total": 0,
    "2018 RAMP": 41500,
    "2018 Total": 41500,
    "2019 Total": 0,
    "Grand Total": 377497.75,
    "3 Yr Average": 13833.333333333334,
  },
  {
    "Model List of Airports": "Rick Husband Amarillo Int'l Airport",
    "CITY AND FACILITIES": "RIC HUSBAND AMARILLO INTL",
    "2015 Total": 0,
    "2016 Total": 0,
    "2017 CIP": 3169171,
    "2017 RAMP": 51384,
    "2017 Total": 3220555,
    "2018 CIP": 4200000,
    "2018 RAMP": 98548,
    "2018 Total": 4298548,
    "2019 CIP": 271063,
    "2019 RAMP": 71174,
    "2019 Total": 342237,
    "Grand Total": 221106,
    "3 Yr Average": 2620446.6666666665,
  },
  {
    "Model List of Airports": "Rio Grande City Municipal Airport",
    "2015 Total": 0,
    "2016 Total": 0,
    "2017 Total": 0,
    "2018 Total": 0,
    "3 Yr Average": 0,
  },
  {
    "Model List of Airports": "Robert Lee",
    "2015 Total": 0,
    "2016 Total": 0,
    "2017 Total": 0,
    "2018 Total": 0,
    "3 Yr Average": 0,
  },
  {
    "Model List of Airports": "Robert R. Wells",
    "CITY AND FACILITIES": "ROBERT R WELLS JR",
    "2015 RAMP": 3048,
    "2015 Total": 3048,
    "2016 RAMP": 18312,
    "2016 Total": 18312,
    "2017 RAMP": 24224,
    "2017 Total": 24224,
    "2018 RAMP": 79998,
    "2018 Total": 79998,
    "2019 RAMP": 60786,
    "2019 Total": 60786,
    "Grand Total": 186368,
    "3 Yr Average": 55002.666666666664,
  },
  {
    "Model List of Airports": "Roger M. Dreyer Memorial",
    "CITY AND FACILITIES": "ROGER M. DREYER MEMORIAL",
    "2015 CIP": 83203,
    "2015 RAMP": 730,
    "2015 Total": 83933,
    "2016 Total": 0,
    "2017 CIP": 1039660,
    "2017 Total": 1039660,
    "2018 RAMP": 1982,
    "2018 Total": 1982,
    "2019 Total": 0,
    "Grand Total": 1125575,
    "3 Yr Average": 347214,
  },
  {
    "Model List of Airports": "Ron Duncan",
    "2015 Total": 0,
    "2016 Total": 0,
    "2017 Total": 0,
    "2018 Total": 0,
    "3 Yr Average": 0,
  },
  {
    "Model List of Airports": "Roy Hurd Memorial",
    "CITY AND FACILITIES": "ROY HURD MEMORIAL",
    "2015 Total": 0,
    "2016 Total": 0,
    "2017 CIP": 74689,
    "2017 RAMP": 100000,
    "2017 Total": 174689,
    "2018 Total": 0,
    "2019 Total": 0,
    "Grand Total": 174689,
    "3 Yr Average": 58229.666666666664,
  },
  {
    "Model List of Airports": "Rusk County Airport",
    "CITY AND FACILITIES": "RUSK COUNTY",
    "2015 RAMP": 37864,
    "2015 Total": 37864,
    "2016 RAMP": 91392,
    "2016 Total": 91392,
    "2017 CIP": 241687,
    "2017 RAMP": 100000,
    "2017 Total": 341687,
    "2018 RAMP": 54346,
    "2018 Total": 54346,
    "2019 RAMP": 97006,
    "2019 Total": 97006,
    "Grand Total": 622295,
    "3 Yr Average": 164346.33333333334,
  },
  {
    "Model List of Airports": "San Angelo Regional - Mathis Field",
    "CITY AND FACILITIES": "SAN ANGELO RGNL/MATHIS FIELD",
    "2015 Total": 0,
    "2016 Total": 0,
    "2017 CIP": 2382192,
    "2017 Total": 2382192,
    "2018 CIP": 827400,
    "2018 Total": 827400,
    "2019 CIP": 1100600,
    "2019 RAMP": 100000,
    "2019 Total": 1200600,
    "Grand Total": 100000,
    "3 Yr Average": 1470064,
  },
  {
    "Model List of Airports": "San Antonio International",
    "2015 Total": 0,
    "2016 Total": 0,
    "2017 Total": 0,
    "2018 Total": 0,
    "3 Yr Average": 0,
  },
  {
    "Model List of Airports": "San Augustine County",
    "CITY AND FACILITIES": "SAN AUGUSTINE COUNTY",
    "2015 RAMP": 1542,
    "2015 Total": 1542,
    "2016 CIP": 94974,
    "2016 Total": 94974,
    "2017 CIP": 487773,
    "2017 Total": 487773,
    "2018 Total": 0,
    "2019 Total": 0,
    "Grand Total": 584289,
    "3 Yr Average": 162591,
  },
  {
    "Model List of Airports": "San Marcos Regional",
    "CITY AND FACILITIES": "SAN MARCOS MUNI",
    "2015 RAMP": 89318,
    "2015 Total": 89318,
    "2016 CIP": 82639,
    "2016 RAMP": 95280,
    "2016 Total": 177919,
    "2017 RAMP": 95746,
    "2017 Total": 95746,
    "2018 RAMP": 90552,
    "2018 Total": 90552,
    "2019 RAMP": 83790,
    "2019 Total": 83790,
    "Grand Total": 537325,
    "3 Yr Average": 90029.33333333333,
  },
  {
    "Model List of Airports": "San Saba Municipal Airport",
    "CITY AND FACILITIES": "SAN SABA COUNTY MUNI",
    "2015 RAMP": 666,
    "2015 Total": 666,
    "2016 RAMP": 9306,
    "2016 Total": 9306,
    "2017 RAMP": 748,
    "2017 Total": 748,
    "2018 RAMP": 1148,
    "2018 Total": 1148,
    "2019 RAMP": 14262,
    "2019 Total": 14262,
    "Grand Total": 26130,
    "3 Yr Average": 5386,
  },
  {
    "Model List of Airports": "Scholes Intl at Galveston",
    "CITY AND FACILITIES": "SCHOLES INTL AT GALVESTON",
    "2015 RAMP": 100000,
    "2015 Total": 100000,
    "2016 CIP": 17010,
    "2016 RAMP": 86462,
    "2016 Total": 103472,
    "2017 CIP": 253530,
    "2017 RAMP": 100000,
    "2017 Total": 353530,
    "2018 CIP": 554907,
    "2018 RAMP": 100000,
    "2018 Total": 654907,
    "2019 RAMP": 100000,
    "2019 Total": 100000,
    "Grand Total": 1311909,
    "3 Yr Average": 369479,
  },
  {
    "Model List of Airports": "Scurry County/Winston Field",
    "2015 Total": 0,
    "2016 Total": 0,
    "2017 Total": 0,
    "2018 Total": 0,
    "3 Yr Average": 0,
  },
  {
    "Model List of Airports": "Seymour Municipal Airport",
    "2015 Total": 0,
    "2016 Total": 0,
    "2017 Total": 0,
    "2018 Total": 0,
    "3 Yr Average": 0,
  },
  {
    "Model List of Airports": "Sheppard AFB/Wichita Falls Municipal",
    "CITY AND FACILITIES": "SHEPPARD AFB / WICHITA FALLS MUNI",
    "2015 Total": 0,
    "2016 RAMP": 56890,
    "2016 Total": 56890,
    "2017 CIP": 1000000,
    "2017 RAMP": 99780,
    "2017 Total": 1099780,
    "2018 CIP": 0,
    "2018 RAMP": 98792,
    "2018 Total": 98792,
    "2019 CIP": 2000000,
    "2019 RAMP": 95958,
    "2019 Total": 2095958,
    "Grand Total": 351420,
    "3 Yr Average": 1098176.6666666667,
  },
  {
    "Model List of Airports": "Sherman Municipal",
    "2015 Total": 0,
    "2016 Total": 0,
    "2017 Total": 0,
    "2018 Total": 0,
    "3 Yr Average": 0,
  },
  {
    "Model List of Airports": "Slaton Municipal",
    "CITY AND FACILITIES": "SLATON MUNI",
    "2015 Total": 0,
    "2016 Total": 0,
    "2017 Total": 0,
    "2018 RAMP": 60000,
    "2018 Total": 60000,
    "2019 Total": 0,
    "Grand Total": 60000,
    "3 Yr Average": 20000,
  },
  {
    "Model List of Airports": "Smiley Johnson/Bass Field",
    "CITY AND FACILITIES": "SMILEY JOHNSON MUNI/BASS FIELD",
    "2015 Total": 0,
    "2016 Total": 0,
    "2017 Total": 0,
    "2018 Total": 0,
    "2019 RAMP": 74194,
    "2019 Total": 74194,
    "Grand Total": 74194,
    "3 Yr Average": 24731.333333333332,
  },
  {
    "Model List of Airports": "Smithville Crawford Municipal Airport",
    "CITY AND FACILITIES": "SMITHVILLE CRAWFORD MUNI",
    "2015 CIP": 96863,
    "2015 RAMP": 1960,
    "2015 Total": 98823,
    "2016 RAMP": 12884,
    "2016 Total": 12884,
    "2017 RAMP": 21920,
    "2017 Total": 21920,
    "2018 RAMP": 34694,
    "2018 Total": 34694,
    "2019 RAMP": 6978,
    "2019 Total": 6978,
    "Grand Total": 175299,
    "3 Yr Average": 21197.333333333332,
  },
  {
    "Model List of Airports": "Sonora Airport",
    "CITY AND FACILITIES": "SONORA MUNI",
    "2015 RAMP": 8072,
    "2015 Total": 8072,
    "2016 RAMP": 8488,
    "2016 Total": 8488,
    "2017 RAMP": 14764,
    "2017 Total": 14764,
    "2018 RAMP": 44608,
    "2018 Total": 44608,
    "2019 RAMP": 13752,
    "2019 Total": 13752,
    "Grand Total": 89684,
    "3 Yr Average": 24374.666666666668,
  },
  {
    "Model List of Airports": "South Texas Intl at Edinburg",
    "CITY AND FACILITIES": "SOUTH TEXAS INTL AT EDINBURG",
    "2015 CIP": 305021,
    "2015 RAMP": 96604,
    "2015 Total": 401625,
    "2016 CIP": 2697692,
    "2016 RAMP": 59670,
    "2016 Total": 2757362,
    "2017 RAMP": 100000,
    "2017 Total": 100000,
    "2018 RAMP": 82434,
    "2018 Total": 82434,
    "2019 CIP": 2427032,
    "2019 Total": 2427032,
    "Grand Total": 5768453,
    "3 Yr Average": 869822,
  },
  {
    "Model List of Airports": "South Texas Regional Airport",
    "CITY AND FACILITIES": "SOUTH TEXAS RGNL AT HONDO",
    "2015 RAMP": 100000,
    "2015 Total": 100000,
    "2016 CIP": 10898,
    "2016 RAMP": 99970,
    "2016 Total": 110868,
    "2017 RAMP": 100000,
    "2017 Total": 100000,
    "2018 RAMP": 95960,
    "2018 Total": 95960,
    "2019 RAMP": 91884,
    "2019 Total": 91884,
    "Grand Total": 498712,
    "3 Yr Average": 95948,
  },
  {
    "Model List of Airports": "Stanton Municipal",
    "CITY AND FACILITIES": "STANTON MUNI",
    "2015 Total": 0,
    "2016 Total": 0,
    "2017 Total": 0,
    "2018 Total": 0,
    "2019 RAMP": 2838,
    "2019 Total": 2838,
    "Grand Total": 2838,
    "3 Yr Average": 946,
  },
  {
    "Model List of Airports": "Stephens County",
    "CITY AND FACILITIES": "STEPHENS COUNTY",
    "2015 RAMP": 3804,
    "2015 Total": 3804,
    "2016 Total": 0,
    "2017 Total": 0,
    "2018 RAMP": 29276,
    "2018 Total": 29276,
    "2019 Total": 0,
    "Grand Total": 33080,
    "3 Yr Average": 9758.666666666666,
  },
  {
    "Model List of Airports": "Stephenville Clark Regional Airport",
    "CITY AND FACILITIES": "STEPHENVILLE CLARK RGNL",
    "2015 RAMP": 26670,
    "2015 Total": 26670,
    "2016 RAMP": 7544,
    "2016 Total": 7544,
    "2017 RAMP": 14172,
    "2017 Total": 14172,
    "2018 RAMP": 15964,
    "2018 Total": 15964,
    "2019 RAMP": 100000,
    "2019 Total": 100000,
    "Grand Total": 164350,
    "3 Yr Average": 43378.666666666664,
  },
  {
    "Model List of Airports": "Stinson Municipal",
    "CITY AND FACILITIES": "STINSON MUNI",
    "2015 RAMP": 100000,
    "2015 Total": 100000,
    "2016 RAMP": 100000,
    "2016 Total": 100000,
    "2017 RAMP": 57440,
    "2017 Total": 57440,
    "2018 RAMP": 100000,
    "2018 Total": 100000,
    "2019 RAMP": 71688,
    "2019 Total": 71688,
    "Grand Total": 429128,
    "3 Yr Average": 76376,
  },
  {
    "Model List of Airports": "Stonewall County",
    "2015 Total": 0,
    "2016 Total": 0,
    "2017 Total": 0,
    "2018 Total": 0,
    "3 Yr Average": 0,
  },
  {
    "Model List of Airports": "Sugarland Regional",
    "CITY AND FACILITIES": "SUGAR LAND RGNL",
    "2015 CIP": 5434336,
    "2015 RAMP": 99720,
    "2015 Total": 5534056,
    "2016 RAMP": 100000,
    "2016 Total": 100000,
    "2017 RAMP": 100000,
    "2017 Total": 100000,
    "2018 RAMP": 100000,
    "2018 Total": 100000,
    "2019 RAMP": 100000,
    "2019 Total": 100000,
    "Grand Total": 5934056,
    "3 Yr Average": 100000,
  },
  {
    "Model List of Airports": "Sulphur Springs Municipal",
    "CITY AND FACILITIES": "SULPHUR SPRINGS MUNI",
    "2015 RAMP": 72088,
    "2015 Total": 72088,
    "2016 RAMP": 100000,
    "2016 Total": 100000,
    "2017 RAMP": 100000,
    "2017 Total": 100000,
    "2018 RAMP": 87478,
    "2018 Total": 87478,
    "2019 RAMP": 98154,
    "2019 Total": 98154,
    "Grand Total": 457720,
    "3 Yr Average": 95210.66666666667,
  },
  {
    "Model List of Airports": "Sunray Municipal",
    "2015 Total": 0,
    "2016 Total": 0,
    "2017 Total": 0,
    "2018 Total": 0,
    "3 Yr Average": 0,
  },
  {
    "Model List of Airports": "Taylor Brown Muncipal",
    "CITY AND FACILITIES": "LITTLEFIELD MUNI",
    "2015 CIP": 145854,
    "2015 Total": 145854,
    "2016 Total": 0,
    "2017 Total": 0,
    "2018 CIP": 575022,
    "2018 RAMP": 100000,
    "2018 Total": 675022,
    "2019 Total": 0,
    "Grand Total": 820876,
    "3 Yr Average": 225007.33333333334,
  },
  {
    "Model List of Airports": "Taylor Municipal",
    "CITY AND FACILITIES": "TAYLOR MUNI",
    "2015 CIP": 2533520,
    "2015 RAMP": 12092,
    "2015 Total": 2545612,
    "2016 RAMP": 3664,
    "2016 Total": 3664,
    "2017 RAMP": 6172,
    "2017 Total": 6172,
    "2018 CIP": 156197,
    "2018 RAMP": 6480,
    "2018 Total": 162677,
    "2019 RAMP": 26660,
    "2019 Total": 26660,
    "Grand Total": 2744785,
    "3 Yr Average": 65169.666666666664,
  },
  {
    "Model List of Airports": "T-Bar Airport",
    "2015 Total": 0,
    "2016 Total": 0,
    "2017 Total": 0,
    "2018 Total": 0,
    "3 Yr Average": 0,
  },
  {
    "Model List of Airports": "Teague Municipal Airport",
    "CITY AND FACILITIES": "TEAGUE MUNI",
    "2015 Total": 0,
    "2016 Total": 0,
    "2017 Total": 0,
    "2018 RAMP": 5350,
    "2018 Total": 5350,
    "2019 RAMP": 48660,
    "2019 Total": 48660,
    "Grand Total": 54010,
    "3 Yr Average": 18003.333333333332,
  },
  {
    "Model List of Airports": "Terrell County Airport",
    "CITY AND FACILITIES": "TERRELL COUNTY",
    "2015 RAMP": 3810,
    "2015 Total": 3810,
    "2016 RAMP": 1036,
    "2016 Total": 1036,
    "2017 RAMP": 6944,
    "2017 Total": 6944,
    "2018 RAMP": 3752,
    "2018 Total": 3752,
    "2019 RAMP": 2688,
    "2019 Total": 2688,
    "Grand Total": 18230,
    "3 Yr Average": 4461.333333333333,
  },
  {
    "Model List of Airports": "Terrell Municipal Airport",
    "CITY AND FACILITIES": "TERRELL MUNI",
    "2015 CIP": 117944,
    "2015 RAMP": 92714,
    "2015 Total": 210658,
    "2016 Total": 0,
    "2017 CIP": 1531604,
    "2017 Total": 1531604,
    "2018 RAMP": 100000,
    "2018 Total": 100000,
    "2019 Total": 0,
    "Grand Total": 1842262,
    "3 Yr Average": 543868,
  },
  {
    "Model List of Airports": "Terry County",
    "CITY AND FACILITIES": "TERRY COUNTY",
    "2015 RAMP": 12638,
    "2015 Total": 12638,
    "2016 RAMP": 100000,
    "2016 Total": 100000,
    "2017 CIP": 73571,
    "2017 RAMP": 100000,
    "2017 Total": 173571,
    "2018 CIP": 506261,
    "2018 RAMP": 100000,
    "2018 Total": 606261,
    "2019 RAMP": 96696,
    "2019 Total": 96696,
    "Grand Total": 989166,
    "3 Yr Average": 292176,
  },
  {
    "Model List of Airports": "Texarkana Regional Airport- Webb Field",
    "CITY AND FACILITIES": "TEXARKANA REGIONAL",
    "2015 Total": 0,
    "2016 Total": 0,
    "2017 CIP": 6854319,
    "2017 RAMP": 64832,
    "2017 Total": 6919151,
    "2018 CIP": 419710,
    "2018 Total": 419710,
    "2019 CIP": 1634796,
    "2019 Total": 1634796,
    "Grand Total": 64832,
    "3 Yr Average": 2991219,
  },
  {
    "Model List of Airports": "Texas Gulf Coast Regional",
    "CITY AND FACILITIES": "TEXAS GULF COAST RGNL",
    "2015 CIP": 1335681,
    "2015 RAMP": 100000,
    "2015 Total": 1435681,
    "2016 RAMP": 99998,
    "2016 Total": 99998,
    "2017 RAMP": 100000,
    "2017 Total": 100000,
    "2018 RAMP": 100000,
    "2018 Total": 100000,
    "2019 RAMP": 100000,
    "2019 Total": 100000,
    "Grand Total": 1835679,
    "3 Yr Average": 100000,
  },
  {
    "Model List of Airports": "The Carter Memorial",
    "2015 Total": 0,
    "2016 Total": 0,
    "2017 Total": 0,
    "2018 Total": 0,
    "3 Yr Average": 0,
  },
  {
    "Model List of Airports": "Throckmorton Municipal",
    "CITY AND FACILITIES": "THROCKMORTON MUNI",
    "2015 RAMP": 99816,
    "2015 Total": 99816,
    "2016 RAMP": 49960,
    "2016 Total": 49960,
    "2017 RAMP": 99920,
    "2017 Total": 99920,
    "2018 RAMP": 100000,
    "2018 Total": 100000,
    "2019 RAMP": 100000,
    "2019 Total": 100000,
    "Grand Total": 449696,
    "3 Yr Average": 99973.33333333333,
  },
  {
    "Model List of Airports": "Tradewind",
    "2015 Total": 0,
    "2016 Total": 0,
    "2017 Total": 0,
    "2018 Total": 0,
    "3 Yr Average": 0,
  },
  {
    "Model List of Airports": "TSTC Waco",
    "CITY AND FACILITIES": "TSTC WACO",
    "2015 RAMP": 100000,
    "2015 Total": 100000,
    "2016 CIP": 882823,
    "2016 RAMP": 76128,
    "2016 Total": 958951,
    "2017 RAMP": 100000,
    "2017 Total": 100000,
    "2018 RAMP": 100000,
    "2018 Total": 100000,
    "2019 RAMP": 100000,
    "2019 Total": 100000,
    "Grand Total": 1358951,
    "3 Yr Average": 100000,
  },
  {
    "Model List of Airports": "Tyler County",
    "CITY AND FACILITIES": "TYLER COUNTY",
    "2015 Total": 0,
    "2016 Total": 0,
    "2017 RAMP": 1440,
    "2017 Total": 1440,
    "2018 Total": 0,
    "2019 RAMP": 482,
    "2019 Total": 482,
    "Grand Total": 1922,
    "3 Yr Average": 640.6666666666666,
  },
  {
    "Model List of Airports": "Tyler Pounds Regional",
    "CITY AND FACILITIES": "TYLER POUNDS REGIONAL",
    "2015 RAMP": 0,
    "2015 Total": 0,
    "2016 RAMP": 100000,
    "2016 Total": 100000,
    "2017 CIP": 10361858,
    "2017 RAMP": 100000,
    "2017 Total": 10461858,
    "2018 CIP": 11880170,
    "2018 RAMP": 100000,
    "2018 Total": 11980170,
    "2019 CIP": 10361858,
    "2019 RAMP": 92454,
    "2019 Total": 10454312,
    "Grand Total": 392454,
    "3 Yr Average": 10965446.666666666,
  },
  {
    "Model List of Airports": "Upton County Airport",
    "CITY AND FACILITIES": "UPTON COUNTY",
    "2015 RAMP": 25634,
    "2015 Total": 25634,
    "2016 RAMP": 100000,
    "2016 Total": 100000,
    "2017 RAMP": 94608,
    "2017 Total": 94608,
    "2018 RAMP": 100000,
    "2018 Total": 100000,
    "2019 RAMP": 3422,
    "2019 Total": 3422,
    "Grand Total": 323664,
    "3 Yr Average": 66010,
  },
  {
    "Model List of Airports": "Valley International",
    "2015 Total": 0,
    "2016 Total": 0,
    "2017 CIP": 2128437,
    "2017 Total": 2128437,
    "2018 CIP": 14413192,
    "2018 Total": 14413192,
    "2019 CIP": 1645000,
    "2019 Total": 1645000,
    "3 Yr Average": 6062209.666666667,
  },
  {
    "Model List of Airports": "Van Zandt County Regional Airport",
    "CITY AND FACILITIES": "VAN ZANDT COUNTY RGNL",
    "2015 CIP": 574202,
    "2015 RAMP": 25156,
    "2015 Total": 599358,
    "2016 RAMP": 6266,
    "2016 Total": 6266,
    "2017 RAMP": 12068,
    "2017 Total": 12068,
    "2018 RAMP": 44758,
    "2018 Total": 44758,
    "2019 RAMP": 9988,
    "2019 Total": 9988,
    "Grand Total": 672438,
    "3 Yr Average": 22271.333333333332,
  },
  {
    "Model List of Airports": "Victoria Regional",
    "CITY AND FACILITIES": "VICTORIA RGNL",
    "2015 CIP": 363909,
    "2015 RAMP": 100000,
    "2015 Total": 463909,
    "2016 RAMP": 100000,
    "2016 Total": 100000,
    "2017 RAMP": 100000,
    "2017 Total": 100000,
    "2018 RAMP": 100000,
    "2018 Total": 100000,
    "2019 RAMP": 100000,
    "2019 Total": 100000,
    "Grand Total": 863909,
    "3 Yr Average": 100000,
  },
  {
    "Model List of Airports": "Waco Regional",
    "CITY AND FACILITIES": "WACO REGIONAL",
    "2015 Total": 0,
    "2016 Total": 0,
    "2017 CIP": 0,
    "2017 Total": 0,
    "2018 CIP": 1708784,
    "2018 RAMP": 83118,
    "2018 Total": 1791902,
    "2019 CIP": 1354500,
    "2019 RAMP": 78394,
    "2019 Total": 1432894,
    "Grand Total": 161512,
    "3 Yr Average": 1074932,
  },
  {
    "Model List of Airports": "Weslaco Mid-Valley",
    "2015 Total": 0,
    "2016 Total": 0,
    "2017 Total": 0,
    "2018 Total": 0,
    "3 Yr Average": 0,
  },
  {
    "Model List of Airports": "West Houston Airport",
    "2015 Total": 0,
    "2016 Total": 0,
    "2017 Total": 0,
    "2018 Total": 0,
    "3 Yr Average": 0,
  },
  {
    "Model List of Airports": "Wharton Regional Airport",
    "CITY AND FACILITIES": "WHARTON RGNL",
    "2015 CIP": 1232855,
    "2015 RAMP": 90344,
    "2015 Total": 1323199,
    "2016 RAMP": 100000,
    "2016 Total": 100000,
    "2017 RAMP": 100000,
    "2017 Total": 100000,
    "2018 RAMP": 84636,
    "2018 Total": 84636,
    "2019 RAMP": 98152,
    "2019 Total": 98152,
    "Grand Total": 1705987,
    "3 Yr Average": 94262.66666666667,
  },
  {
    "Model List of Airports": "Wheeler Municipal",
    "2015 Total": 0,
    "2016 Total": 0,
    "2017 Total": 0,
    "2018 Total": 0,
    "3 Yr Average": 0,
  },
  {
    "Model List of Airports": "Wilbarger County Airport",
    "CITY AND FACILITIES": "WILBARGER COUNTY",
    "2015 CIP": 286929,
    "2015 RAMP": 20874,
    "2015 Total": 307803,
    "2016 RAMP": 37330,
    "2016 Total": 37330,
    "2017 RAMP": 82698,
    "2017 Total": 82698,
    "2018 CIP": 2663831,
    "2018 RAMP": 44710,
    "2018 Total": 2708541,
    "2019 RAMP": 40278,
    "2019 Total": 40278,
    "Grand Total": 3176650,
    "3 Yr Average": 943839,
  },
  {
    "Model List of Airports": "William P Hobby",
    "2015 Total": 0,
    "2016 Total": 0,
    "2017 Total": 0,
    "2018 Total": 0,
    "3 Yr Average": 0,
  },
  {
    "Model List of Airports": "Winkler County",
    "CITY AND FACILITIES": "WINKLER COUNTY",
    "2015 RAMP": 66596,
    "2015 Total": 66596,
    "2016 Total": 0,
    "2017 RAMP": 4262,
    "2017 Total": 4262,
    "2018 RAMP": 62518,
    "2018 Total": 62518,
    "2019 RAMP": 100000,
    "2019 Total": 100000,
    "Grand Total": 233376,
    "3 Yr Average": 55593.333333333336,
  },
  {
    "Model List of Airports": "Winnsboro Municipal Airport",
    "CITY AND FACILITIES": "WINNSBORO MUNI",
    "2015 RAMP": 2800,
    "2015 Total": 2800,
    "2016 RAMP": 20764,
    "2016 Total": 20764,
    "2017 Total": 0,
    "2018 Total": 0,
    "2019 Total": 0,
    "Grand Total": 23564,
    "3 Yr Average": 0,
  },
  {
    "Model List of Airports": "Winston Field",
    "CITY AND FACILITIES": "WINSTON FIELD",
    "2015 RAMP": 0,
    "2015 Total": 0,
    "2016 RAMP": 55152,
    "2016 Total": 55152,
    "2017 RAMP": 49892,
    "2017 Total": 49892,
    "2018 RAMP": 25918,
    "2018 Total": 25918,
    "2019 RAMP": 54778,
    "2019 Total": 54778,
    "Grand Total": 185740,
    "3 Yr Average": 43529.333333333336,
  },
  {
    "Model List of Airports": "Winters Municipal",
    "2015 Total": 0,
    "2016 Total": 0,
    "2017 Total": 0,
    "2018 Total": 0,
    "3 Yr Average": 0,
  },
  {
    "Model List of Airports": "Wood County",
    "CITY AND FACILITIES": "WOOD COUNTY",
    "2015 RAMP": 26816,
    "2015 Total": 26816,
    "2016 CIP": 112207,
    "2016 RAMP": 58524,
    "2016 Total": 170731,
    "2017 RAMP": 41018,
    "2017 Total": 41018,
    "2018 RAMP": 56992,
    "2018 Total": 56992,
    "2019 RAMP": 41948,
    "2019 Total": 41948,
    "Grand Total": 337505,
    "3 Yr Average": 46652.666666666664,
  },
  {
    "Model List of Airports": "Yoakum County Airport",
    "CITY AND FACILITIES": "YOAKUM COUNTY",
    "2015 RAMP": 35516,
    "2015 Total": 35516,
    "2016 RAMP": 20198,
    "2016 Total": 20198,
    "2017 RAMP": 24958,
    "2017 Total": 24958,
    "2018 RAMP": 23878,
    "2018 Total": 23878,
    "2019 RAMP": 4152,
    "2019 Total": 4152,
    "Grand Total": 108702,
    "3 Yr Average": 17662.666666666668,
  },
  {
    "Model List of Airports": "Yoakum Muni",
    "CITY AND FACILITIES": "YOAKUM MUNI",
    "2015 Total": 0,
    "2016 RAMP": 42824,
    "2016 Total": 42824,
    "2017 RAMP": 19444,
    "2017 Total": 19444,
    "2018 Total": 0,
    "2019 Total": 0,
    "Grand Total": 62268,
    "3 Yr Average": 6481.333333333333,
  },
  {
    "Model List of Airports": "Zapata County Airport",
    "CITY AND FACILITIES": "ZAPATA COUNTY",
    "2015 RAMP": 61888,
    "2015 Total": 61888,
    "2016 RAMP": 63378,
    "2016 Total": 63378,
    "2017 RAMP": 50318,
    "2017 Total": 50318,
    "2018 RAMP": 27666,
    "2018 Total": 27666,
    "2019 RAMP": 31930,
    "2019 Total": 31930,
    "Grand Total": 235180,
    "3 Yr Average": 36638,
  },
  {
    "CITY AND FACILITIES": "Grand Total",
    "2015 CIP": 55008278,
    "2015 RAMP": 7251010,
    "2015 Total": 62259288,
    "2016 CIP": 21614043.95,
    "2016 RAMP": 8973811,
    "2016 Total": 30587854.95,
    "2017 CIP": 46139268,
    "2017 RAMP": 9058720,
    "2017 Total": 55197988,
    "2018 CIP": 18686024,
    "2018 RAMP": 9177709,
    "2018 Total": 27863733,
    "2019 CIP": 4298686,
    "2019 RAMP": 9752802,
    "2019 Total": 95828808,
    "Grand Total": 189960351.95,
    "3 Yr Average": 59630176.333333336,
  },
  {
    "CITY AND FACILITIES": 57,
  },
];

const constants = {
  REGION_MULTIPLIER,
  AIRPORT_LIST,
  VISITOR_SPENDING,
  AIRPORT_ACTIVITY,
  TENANT_ACTIVITY,
  CAPITAL_EXPENSES,
};
export default constants;
