import React, { useState } from "react";
import { Modal, Button, Image, Row, Col, Container } from "react-bootstrap";
import "./PopupBox.css";
function PopupBox(props) {
  const [show, setShow] = useState(true);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <Modal
      {...props}
      show={show}
      onHide={handleClose}
      size="lg"
      // dialogClassName="modal-90w"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
          <span className="close" onClick={handleClose}>x</span>
        <h3 className="title">Small Airport Economic Impact Estimator</h3>
        <p>
          This Small Airport Economic Impact Estimator is intended to provide
          small airports (general aviation airports and non-hub commercial
          service airports) with estimates of their economic impact including
          employment, income, and total output. It includes economic impacts
          associated with airport sponsor, airport tenant, and airport visitor
          activity.
        </p>
        <p>This model was designed to provide economic impact estimates for:</p>
        <ul>
          <li>
            Small airports not included in the statewide study (or where
            incomplete information was used);
          </li>
          <li>
            Airports who had privacy concerns where disclosing
            financial/economic data may make proprietary business information
            identifiable;
          </li>
          <li>
            Airport’s whose activity levels since the last statewide study was
            performed have changed;
          </li>
          <li>
            Airports wishing to have a more detailed analysis performed than
            what was allowed during a system-level analysis; and
          </li>
          <li>
            Airports who would like to do some scenario planning to see how
            their economic impacts would change based on changes in the
            airport’s activity.
          </li>
        </ul>
        <p>
          This project was sponsored by TxDOT’s Research and Technology
          Implementation Division. The model was developed by Texas A&M
          Transportation Institute, Infrastructure Investment Analysis Program.
        </p>
        <p>For more information please contact:</p>
        <Container>
          <Row>
            <Col>
              {" "}
              <Image
                className="logo logo-txdot"
                src={require("../image/2012-TxDOT-334px-wide.png")}
              ></Image>
            </Col>
            <Col>
              <p>
                <strong>Jim Halley, A.A.E</strong>
                <br />
                Director, Planning & Programming
                <br />
                TxDOT Aviation Division
                <br />
                (512) 416-4525
                <br />
                <a href="mailto:James.Halley@txdot.gov">
                  James.Halley@txdot.gov
                </a>
              </p>
            </Col>
            <Col>
              <Image
                className="logo logo-tti"
                src={require("../image/TTI-Color.png")}
              ></Image>
            </Col>
            <Col>
              <p>
                <strong>Jeff Borowiec, Ph.D.</strong>
                <br />
                Senior Research Scientist
                <br />
                Texas A&M Transportation Institute
                <br />
                (979) 317-2283
                <br />
                <a href="mailto:jborowiec@tamu.edu">jborowiec@tamu.edu</a>
              </p>
            </Col>
          </Row>
          <p className="copy">
            © Copyright 2020. Texas Department of Transportation. All rights
            reserved.
          </p>
        </Container>
      </Modal.Body>     
    </Modal>
  );
}

export default PopupBox;
