import React, { useState, useEffect, useRef } from "react";
import L from "leaflet";
import { Map, GeoJSON, TileLayer } from "react-leaflet";
import omnivore from "@mapbox/leaflet-omnivore";
import Utils from "../data/Utils.js";
import {
  MAPBOX_ACCESSTOKEN,
  MAPBOX_STREETS_V10_URL,
  AIRPORT_LIST,
} from "../data/constants.js";
import geobuf from "geobuf";
import Pbf from "pbf";

// const { BaseLayer, Overlay } = LayersControl;

window.AIRPORT_LIST = AIRPORT_LIST;

/*****
 * 
 * 
 * 
 * 
 
airportBoundaries.features.forEach(ab => {
    let keywords = ab.properties.ARPT_NM;
    let airport = getAirportByKeywords(keywords);
    console.log('HIT?',keywords,'=>',airport);


})

*** FIND AIRPORTS by keyword from features
let results = airportBoundaries.features.map(ab => {
    let keywords = ab.properties.ARPT_NM;
    let hit = getAirportByKeywords(keywords);
    //console.log('HIT?',keywords,'=>',airport);
    if (hit.score > 0) {
      hit.record.FAA = ab.properties.FAA_CD;
    }
    return hit;
});



 */

function cleanKeywords(orig) {
  let keywordString = orig;
  keywordString = keywordString.replace(/Airpark/, ""); //doesn't contribute to a hit...
  keywordString = keywordString.replace(/Air Park/, ""); //doesn't contribute to a hit...
  keywordString = keywordString.replace(/Air Center/, ""); //doesn't contribute to a hit...
  ////  keywordString = keywordString.replace(/International/, ""); //doesn't contribute to a hit...
  keywordString = keywordString.replace(/Airport/, ""); //doesn't contribute to a hit...
  keywordString = keywordString.replace(/Municipal/, ""); //doesn't contribute to a hit...
  keywordString = keywordString.replace(/Regional/, ""); //doesn't contribute to a hit...
  keywordString = keywordString.replace(/Texas/, ""); //doesn't contribute to a hit...
  keywordString = keywordString.replace(/County/, ""); //doesn't contribute to a hit...
  keywordString = keywordString.replace(/Field/, ""); //doesn't contribute to a hit...
  keywordString = keywordString.replace(/Int'l/, "International");
  return keywordString;
}

function getAirportByKeywords(keywordString) {
  let origKeywords = keywordString;

  keywordString = cleanKeywords(keywordString);
  let keywordTags = Utils.wordify(keywordString);
  let scoredCandidates = AIRPORT_LIST.map((airport) => {
    let aTagStr = airport.Airport;
    aTagStr = cleanKeywords(aTagStr);
    let airportTags = Utils.wordify(aTagStr);
    let score = Utils.tagScore(airportTags, keywordTags);

    return {
      score: score,
      record: airport,
      keywords: keywordString,
      origKeywords: origKeywords,
    };
  });
  let sortedByScore = scoredCandidates.sort(Utils.sorter((o) => o.score));
  ///console.log('scoredCandidates', scoredCandidates,'sortedByScore',sortedByScore);

  let winner = sortedByScore[sortedByScore.length - 1];
  return winner;
  /***
  if (winner.score < 0) {
    return { error: true, keywords: keywordString, score: winner.score };
  }
  return winner.record;
  ***/
  ////return false;
}
window.getAirportByKeywords = getAirportByKeywords;

function getFeatureByARPT_NM(ARPT_NM, features) {
  return features.find((feature) => {
    return feature.properties.ARPT_NM.trim() === ARPT_NM.trim();
  });
}
window.getFeatureByARPT_NM = getFeatureByARPT_NM;

function getFeatureByFAA_CD(FAA_CD, features) {
  return features.find((feature) => {
    return feature.properties.FAA_CD.trim() === FAA_CD.trim();
  });
}
window.getFeatureByFAA_CD = getFeatureByFAA_CD;

function getFeatureByKeywords(keywordString, features) {
  let origKeywords = keywordString;

  keywordString = cleanKeywords(keywordString);
  //console.log("keywordString", keywordString);
  let keywordTags = Utils.wordify(keywordString);
  let scoredCandidates = features.map((feature) => {
    let fTagStr = feature.properties.ARPT_NM;
    fTagStr = cleanKeywords(fTagStr);
    let featureTags = Utils.wordify(fTagStr);

    let score = Utils.tagScore(featureTags, keywordTags);
    ///console.log("ft/kt/score", featureTags, keywordTags, score);

    return {
      score: score,
      record: feature,
      keywords: keywordString,
      origKeywords: origKeywords,
    };
  });
  let sortedByScore = scoredCandidates.sort(Utils.sorter((o) => o.score));

  // console.log(
  //   "scoredCandidates",
  //   scoredCandidates,
  //   "sortedByScore",
  //   sortedByScore
  // );

  let winner = sortedByScore[sortedByScore.length - 1];
  return winner;
  /***
  if (winner.score < 0) {
    return { error: true, keywords: keywordString, score: winner.score };
  }
  return winner.record;
  ***/
  ////return false;
}
window.getFeatureByKeywords = getFeatureByKeywords;

/****
 * 
 * 
 * 
 * 
 * 
//show the features if found by direct name match, otherwise undefined
 AIRPORT_LIST.map(airport => {
   return getFeatureByARPT_NM(
     airport.Airport.trim(),
     csvGeoJSON.features))

// filter down
 AIRPORT_LIST.map(airport => getFeatureByARPT_NM(airport.Airport,csvGeoJSON.features))
  .filter(o => o)

  AIRPORT_LIST.map(airport => getFeatureByARPT_NM(airport.Airport,csvGeoJSON.features))
  .filter(o => o)

    AIRPORT_LIST.forEach(airport => {
      let hit = getFeatureByARPT_NM(airport.Airport,csvGeoJSON.features));
      if (hit) {
        console.log('hit',hit);
        airport.FAA = hit.properties.FAA_CD;
      }
    })


 */

function getAirportMissingFAA() {
  return AIRPORT_LIST.find((a) => !a.FAA || a.FAA.length === 0);
}
window.getAirportMissingFAA = getAirportMissingFAA;

function getAirportMissingLat() {
  return AIRPORT_LIST.find((a) => !a.lat || a.lat.length === 0);
}
window.getAirportMissingLat = getAirportMissingLat;

function AirportMap(props) {
  const countyLayer = useRef();
  const airportLayer = useRef();
  const mapLayer = useRef();
  const [countyBoundaries, setCountyBoundaries] = useState({});
  const [airportBoundaries, setAirportBoundaries] = useState({
    features: [],
  });
  const [texasAirports, setTexasAirports] = useState({
    features: [],
  });

  const [mapReady, setMapReady] = useState(false);

  const texasBounds = [
    [36.61, -106.96],
    [25.67, -93.43],
  ];

  ///const [bounds, setBounds] = useState(texasBounds);
  /* DEBUG */
  window.countyLayer = countyLayer;
  window.mapLayer = mapLayer;
  window.airportLayer = airportLayer;
  window.omnivore = omnivore;
  window.airportBoundaries = airportBoundaries;
  window.texasAirports = texasAirports;

  const center = [31.5, -100];
  const zoom = 4;
  const airportZoom = 13;
  const panDurationSeconds = 3.0;

  var zoomTimer = false;
  ////console.log("AirportMap props", props);

  function maybeMatchLat() {
    let a = getAirportMissingLat();
    if (!a) {
      return console.log("All good");
    }
    let hit = getFeatureByFAA_CD(a.FAA, texasAirports.features);
    if (!a || !hit) {
      a.lat = "FIXME";
      a.lon = "FIXME";
      return console.log("no hits?!?", a);
    }
    console.log("hit >>", hit);

    if (hit) {
      //only modify lat/lon
      a.lat = hit.geometry.coordinates[0];
      a.lon = hit.geometry.coordinates[1];
      console.log("yay", a);
    } else {
      console.log("boo", a);
    }
  }
  window.maybeMatchLat = maybeMatchLat;

  function maybeMatchFAA() {
    let a = getAirportMissingFAA();
    if (!a) {
      return console.log("All good");
    }
    console.log("before", texasAirports.features);
    let hit = getFeatureByKeywords(a.Airport, texasAirports.features);
    if (!a || !hit) {
      return console.log("no hits?!?");
    }
    console.log("hit >>", hit);

    let msg = `
    AIRPORT (from AIRPORT_LIST):
    ${a.Airport}

    HIT:
    FAA: ${hit.record.properties.FAA_CD}
    ARPT_NM: ${hit.record.properties.ARPT_NM}


    click OK if this is a good match, otherwise click Cancel

    `;
    if (window.confirm(msg)) {
      a.FAA = hit.record.properties.FAA_CD;
      a.lat = hit.record.geometry.coordinates[0];
      a.lon = hit.record.geometry.coordinates[1];
      console.log("yay");
    } else {
      console.log("boo");
    }
  }
  window.maybeMatchFAA = maybeMatchFAA;
  function zoomToFeature(feature) {
    if (!mapLayer) {
      return console.log("zTF: no mapLayer!!");
    }
    if (!mapLayer.current) {
      return console.log("zTF: no mapLayer.current!!");
    }
    if (!feature) {
      console.log("zoomToTexas!!");

      ///return mapLayer.current.leafletElement.fitBounds(texasBounds);
      return mapLayer.current.leafletElement.flyToBounds(texasBounds, {
        duration: panDurationSeconds,
      });
    }

    if (feature.geometry.type === "Point") {
      return mapLayer.current.leafletElement.flyTo(
        feature.geometry.coordinates,
        airportZoom,
        {
          duration: panDurationSeconds,
        }
      );
      //exits early
    }
    //our feature has bounds
    var leafy = L.geoJSON(feature);
    let newBounds = leafy.getBounds();
    ///console.log("zooming to bounds ", newBounds);
    ///mapLayer.current.leafletElement.fitBounds(newBounds);
    return mapLayer.current.leafletElement.flyToBounds(newBounds, {
      duration: panDurationSeconds,
    });
  }
  window.zoomToFeature = zoomToFeature; //TESTING

  function eventuallyZoomToFeature(feature) {
    clearTimeout(zoomTimer);
    zoomTimer = setTimeout(function () {
      zoomToFeature(feature);
    }, 250);
  }

  function getAirportFeature() {
    var featureHit = airportBoundaries.features.find(
      (feature) => feature.properties.FAA_CD === props.airport.FAA
    );
    //not found in the bounds, try the texasAirports Point geometry
    /////console.log("getAirportFeature: falling back to point geometry");
    if (!featureHit) {
      featureHit = texasAirports.features.find(
        (feature) => feature.properties.FAA_CD === props.airport.FAA
      );
    }
    //create a feature object based on the airport lat and lon
    if (!featureHit && props.airport.lat && props.airport.lat !== "FIXME") {
      featureHit = {
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [props.airport.lat, props.airport.lon],
        },
        properties: {},
      };
    }

    return featureHit;
  }

  function onMapReady(e) {
    setMapReady(true);
    props.whenMapReady && props.whenMapReady();
  }

  useEffect(() => {
    ///console.log("got here", mapLayer, props.airport, airportBoundaries);
    if (props.airport && props.airport.FAA) {
      ///console.log("got here 2");
      let featureHit = getAirportFeature();
      ///console.log("got here 3", featureHit);
      eventuallyZoomToFeature(featureHit);
    }
    //eslint-disable-next-line
  }, [props.airport, mapReady]); //prevent cycle

  useEffect(() => {
    async function loadCounties() {
      let response = await fetch("data/Texas_County_Boundaries.pbf"); //geobuf format
      let arrayBuffer = await response.arrayBuffer();
      let them = geobuf.decode(new Pbf(arrayBuffer));
      ////console.log("them", them);
      ///window.them = them;

      setCountyBoundaries(them);
    }
    loadCounties();
  }, []);

  useEffect(() => {
    async function loadAirports() {
      let url = "data/Texas_Airport_Boundaries.kml";
      let omniLayer = await omnivore.kml(url).on("ready", function () {
        let geoJSON = omniLayer.toGeoJSON();
        ///console.log("geoJSON", geoJSON);
        setAirportBoundaries(geoJSON);
      });
    }
    loadAirports();
  }, []);

  useEffect(() => {
    async function loadAirportCoordinates() {
      let url = "data/Texas_Airports.csv";
      let omniLayer = await omnivore
        .csv(url, {
          latfield: "X",
          lonfield: "Y",
        })
        .on("ready", function () {
          ////console.log("CSV Layer", omniLayer);
          let geoJSON = omniLayer.toGeoJSON();
          ///console.log("geoJSON", geoJSON);
          let csvGeoJSON = geoJSON;
          setTexasAirports(geoJSON);
          //setAirportCoordinates(geoJSON);
          window.csvGeoJSON = csvGeoJSON;
          window.csvLayer = omniLayer;
        });
    }
    loadAirportCoordinates();
  }, []);

  if (
    !countyBoundaries.features ||
    countyBoundaries.features.length === 0 ||
    !airportBoundaries.features ||
    airportBoundaries.features.length === 0 ||
    !texasAirports.features ||
    texasAirports.features.length === 0
  ) {
    return <label>Loading...</label>;
  }

  return (
    <div className="map-wrap">
      <Map
        ref={mapLayer}
        bounds={texasBounds}
        center={center}
        zoom={zoom}
        /* 
        
        zoomControl={false}
        zoomSnap={false}
        zoomDelta={false}
        trackResize={false}
        touchZoom={false}
        scrollWheelZoom={false}
        
        
        
        */
        attributionContol={false}
        style={{
          height: "300px",
        }}
        whenReady={onMapReady}
      >
        <TileLayer
          url={MAPBOX_STREETS_V10_URL}
          id="mapbox.streets"
          access_token={MAPBOX_ACCESSTOKEN}
        />

        <GeoJSON
          key={Math.random()}
          data={countyBoundaries}
          ref={countyLayer}
          style={{
            color: "#777",
            fillColor: "#bbb",
            weight: 1,
          }}
        />
        <GeoJSON
          key={Math.random()}
          data={airportBoundaries}
          ref={airportLayer}
          style={{
            color: "#751851", //"#0f9",
            fillColor: "#751851", //"#0f9",
            weight: 1,
          }}
        />
      </Map>
    </div>
  );
}
export default AirportMap;
