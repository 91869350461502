import React, { useState } from "react";
import accounting from "accounting";
import TotalHelper from "../model/TotalHelper.js";
function TotalRow(props) {
  ///console.log("TotalRow props", props);
  let totals;
  if (props.data) {
    //if an array of total records
    totals = TotalHelper(props.data);
  } else {
    totals = props;
  }

  const [localTotals, setLocalTotals] = useState(totals);

  let timer = false;

  props.models.forEach(function (model) {
    model.on("recalc", function () {
      clearTimeout(timer);
      timer = setTimeout(function () {
        let newTotals = TotalHelper(props.models);
        setLocalTotals(newTotals);
      });
    });
  });

  ///console.log("localTotals", localTotals);

  return (
    <tr className="total-row">
      <td className="label item tr-label tr-item">{props.label || "Total"}</td>
      <td className="factor">&nbsp;</td>
      <td className="employment">
        {accounting.formatNumber(localTotals.employment, 0)}
      </td>
      <td className="labor-income">
        {accounting.formatMoney(localTotals.labor_income, "$", 0, ",")}
      </td>
      <td className="output">
        {accounting.formatMoney(localTotals.output, "$", 0, ",")}
      </td>
    </tr>
  );
}
export default TotalRow;
