import React, { useState } from "react";
import Utils from "../data/Utils.js";
import accounting from "accounting";

const AirportOutputRow = function (props) {
  const model = props.model;
  const row = model;
  const field = props.field;
  const slug = props.slug;
  const i = props.i;

  const [localModel, setLocalModel] = useState(model);
  const [localField, setLocalField] = useState(field);

  function handleChange(e, field) {
    //DONT UPDATE STATE HERE, it will cause too many
    // cascading state changes and extra rerenders
    ///console.log("fu", field.ui);
    ///console.log("fur", field.ui.ref);
    field.ref.current.value = Utils.prettyField(e.target.value);
  }

  /* This handler will change the state */
  function handleBlur(e, field) {
    field.onChange(e); /* changes the state */
  }

  model.on("recalc", function (updatedModel, origInput) {
    /* console.log(
      "AOR HEARD RECALC!!",
      "updatedModel",
      updatedModel,
      "origInput",
      origInput,
      "field",
      field
    );
    */
    ///beep();

    /* works good */
    /*
    setLocalModel({
      randy: Math.random(),
      output: updatedModel.output,
      employment: updatedModel.employment,
      labor_income: updatedModel.labor_income,
    });
    ***/
    setLocalModel({
      ...updatedModel,
      randy: Math.random(),
    });
  });

  field.on("change", function (updatedField, b, c) {
    ////console.log("AOR FIELD CHG", updatedField, b, c);
    setLocalField({
      ...updatedField,
      randy: Math.random(), //cache bust
    });
  });
  /*
  console.log(
    "AOR RERENDER, localModel",
    localModel,
    "localField",
    localField.numericValue
  );
  **/
  ////beep();
  /////console.log('AO:: slug',slug,'modelOutputs',modelOutputs,'row',row,'field',field);
  return (
    <tr className="airport-output-row" key={`${slug}-row-${i}`}>
      <td className="label item">{field.label}</td>
      {(field.calculated && (
        <td className="factor">{Utils.prettyField(localField.numericValue)}</td>
      )) || (
        <td className="factor factor-input">
          <input
            key={`${slug}-row-input-${i}`}
            type="text"
            ref={field.ref}
            /**
              DO NOT PROVIDE A value={}, it will make the UI value remain 
              fixed during key event "onChange" events, which arent changing the state
              until the onBlur state occurs.
              Instead, the useSAInput/useFormInput will update the value via its ref
              */
            onChange={(e) => {
              /* This onChange occurs during every keystroke event */
              //console.log('change',e.target.value)
              ////console.log("CHANGE", e);
              handleChange(e, field);
              //DONT UPDATE STATE HERE, it will cause too many
              // cascading state changes and extra rerenders
            }}
            onBlur={(e) => {
              ///console.log("BLUR", e);
              handleBlur(e, field);
            }}
          />
        </td>
      )}
      {!row.type || (row.type === "null" && <td colSpan="3">&nbsp;</td>)}
      {row.type && row.type !== "null" && (
        <td className="employment">
          {accounting.formatNumber(localModel.employment, 0)}
        </td>
      )}
      {row.type && row.type !== "null" && (
        <td className="labor-income">
          {accounting.formatMoney(localModel.labor_income, "$", 0, ",")}
        </td>
      )}
      {row.type && row.type !== "null" && (
        <td className="output">
          {accounting.formatMoney(localModel.output, "$", 0, ",")}
        </td>
      )}
    </tr>
  );
};
export default AirportOutputRow;
