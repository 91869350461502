import React from "react";
import { AIRPORT_LIST } from "../data/constants.js";

function AirportInput(props) {
  /**
  const options = AIRPORT_LIST.map((ap, i) => {
    return {
      label: ap.Airport,
      value: ap,
    };
  });
  **/
  return (
    <div className="airport-input-wrap">
      <select
        className="airport-input"
        value={props.airport.Airport}
        onChange={(e) => {
          ///console.log("e.target.value", e.target.value);
          let selected = AIRPORT_LIST.find((o) => o.Airport === e.target.value);
          //console.log("selected", selected);
          //console.log("ONCHANGE", e);
          props.setAirport(selected);
        }}
      >
        {AIRPORT_LIST.map((airport, i) => {
          return (
            <option key={"airport-" + i} value={airport.Airport}>
              {airport.Airport}
            </option>
          );
        })}
      </select>
    </div>
  );
}
export default AirportInput;
